import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

const columns = [
  { id: 'imagen', label: 'Imagen', minWidth: 100, align: 'center' },
  { id: 'fechaRegistro', label: 'Fecha Compra', minWidth: 130, align: 'left' },
  { id: 'descripcion', label: 'Producto', minWidth: 200, align: 'left' },
  { id: 'precio', label: 'Precio', minWidth: 80, align: 'left' },
  { id: 'trabajador', label: 'Trabajador', minWidth: 350, align: 'left' },
  { id: 'numdoc', label: 'Dni', minWidth: 80, align: 'left' },
  { id: 'direccion', label: 'Dirección', minWidth: 250, align: 'left' },
  { id: 'email', label: 'Correo', minWidth: 200, align: 'left' },
  { id: 'telefono', label: 'Telf.', minWidth: 120, align: 'left' },
];

const BodyOfficeFurnitureReport = (props) => {
  const { sales } = props;

  return (
    <div className='page-body-main'>
      <div className='page-body__table' style={{ overflow: 'hidden' }}>
        <div className='page-body-table'>
          <TableContainer className='table-height'>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {sales?.map((record, indexRow) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={"row" + indexRow}>
                      {columns.map((column, indexColumn) => {
                        const value = record[column.id] || 
                                      (column.id === 'descripcion' ? record.activo.descripcion : '') || 
                                      (column.id === 'precio' ? record.activo.precio : '') || 
                                      (column.id === 'direccion' ? record.persona.direccion : '');

                        return (
                          <TableCell key={'col' + indexColumn} align={column.align}>
                            {column.id === 'trabajador' ?
                              `${record.persona.nombres} ${record.persona.apePaterno} ${record.persona.apeMaterno}` :
                              column.id === 'imagen' ?
                                <img src={record.activo.ruta} alt={record.activo.descripcion} style={{ width: '150px', height: '150px' }} /> :
                                value
                            }
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </div>
  );
}

export default BodyOfficeFurnitureReport;
