import React, { useState, } from 'react';
import { Box, } from '@mui/material';
import { OrderedListOutlined, } from '@ant-design/icons/lib/icons';
import { colors } from '../../../../assets/colors';
import { sortDataListSimple } from '../../../../utils/function';
import _ from 'lodash';

const columns = [
  { id: 'sel', label: 'Sel.', width: 10, align: 'center', },
  { id: 'desArea', label: 'Área', width: 150, align: 'left', },
  { id: 'fecha', label: 'Fecha', width: 100, align: 'left', },
  { id: 'sumHorasEjecutadas', label: 'Sum. Hrs. Ejec.', width: 300, align: 'center', },
  { id: 'produccionTotal', label: 'Producción', width: 150, align: 'left', },
  { id: 'comentario', label: 'Comentario', width: 150, align: 'left', },
  { id: 'bloqueo', label: 'Bloqueado', width: 100, align: 'left', },
  { id: 'fechaBloqueo', label: 'Fec. Bloqueo', width: 150, align: 'left', },
];

const BodyProductivityLock = (props) => {
  const { id_usuarioLogin, dataProductivity, dataBloqueada, setDataBloqueada, } = props;

  /** inicio ordenamiento de columnas */
  const [sortConfigTable, setSortConfigTable] = useState({ key: null, direction: "ascending", });
  const handleSortTable = (key) => {
    let direction = "ascending";
    if (sortConfigTable && sortConfigTable.key === key && sortConfigTable.direction === "ascending") {
      direction = "descending";
    }
    setSortConfigTable({ key, direction });
  };
  const sortedData = sortDataListSimple(dataProductivity, sortConfigTable);
  /** fin ordenamiento de columnas */

  const handleChangeCheck = (value, p_productivity) => {
    let updDataBloqueada = _.cloneDeep(dataBloqueada);
    if (value === false) {
      updDataBloqueada = updDataBloqueada?.filter(e => Number(e.id_productividadC) !== Number(p_productivity.id_productividadC));
    } else {
      let newItem = {
        "id_productividadC": Number(p_productivity.id_productividadC),
        "usuBloqueo_id": id_usuarioLogin
      }
      updDataBloqueada.push(newItem);
    }
    return setDataBloqueada(updDataBloqueada)
  }

  const handleChangeCheckGeneral = (e) => {
    let updDataBloqueada = [];
    if (e.target.checked === false) {
      updDataBloqueada = [];
    } else {
      dataProductivity?.forEach((element) => {
        let newItem = {
          "id_productividadC": Number(element.id_productividadC),
          "usuBloqueo_id": id_usuarioLogin
        }
        updDataBloqueada.push(newItem)
      });
    }

    return setDataBloqueada(updDataBloqueada)
  }

  return (
    <Box className='page-body-main'>
      <Box className='page-body-table table-responsive-md'>
        <table className="table table-hover table-bordered">
          <thead className='sticky-top' style={{ backgroundColor: colors.quaternary.hex, color: '#ffff' ,whiteSpace:'nowrap' }}>
            <tr>
              {columns.map((column) => (
                <th
                  scope="col"
                  key={column.id}
                  style={{
                    textAlign: column.align,
                    width: column.width,

                    // backgroundColor: 'red',
                    // color: 'red',
                    cursor: 'pointer',
                  }}
                  onClick={() => handleSortTable(column.id)}
                >
                  {column.id === 'sel' ?
                    <input type="checkbox"
                      name="check"
                      id={`checkbox-rect-header`}
                      className={'checkbox-rect-header'}
                      onChange={handleChangeCheckGeneral}
                    />
                    : <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <div style={{ marginRight: 'auto' }}>{column.label}</div>
                      <div><OrderedListOutlined style={{ paddingBottom: 2 }} /></div>
                    </div>}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="table-group-divider">
            {sortedData?.map((productivity, indexRow) => {
              return (
                <tr key={"row" + indexRow} style={{ whiteSpace:'nowrap' }}>
                  {columns.map((column, indexColumn) => {
                    const value = productivity[column.id];
                    return (
                      <td key={'col' + indexColumn} align={column.align}>
                        {column.id === 'bloqueo' ?
                          (Number(value) === 1 ? 'Bloqueado' : 'Desbloqueado')
                          : column.id === 'sel' ?
                            <div className="checkbox-custom"
                              style={{ display: 'flex', justifyContent: 'center' }}
                            >
                              <input type="checkbox"
                                name="check"
                                id={`checkbox-rect` + productivity.id_productividadC}
                                checked={dataBloqueada?.find(e => Number(e.id_productividadC) === Number(productivity.id_productividadC)) ? true : false}
                                onChange={(e) => handleChangeCheck(e.target.checked, productivity)}
                              />
                              <label htmlFor={`checkbox-rect` + productivity.id_productividadC}></label>
                            </div>
                            : value}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </Box>
    </Box>
  );
};

export default BodyProductivityLock;