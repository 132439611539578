import axios from "axios";
import { Global } from '../utils/general';
import headerRequest from "../utils/headers"

const API_URL = Global.url;
const SYS = Global.system;

class RegionService {

    getRegion() {
        let ruta = `${API_URL}/region/region`;
        return axios
            .get(ruta, {
                headers: headerRequest(),
            })
            .catch((error) => {
                return error;
            });
    }
   
    getRelacionRegionSede(url, id_region) {
        let ruta = `${API_URL}/region/region/${id_region}?sys=${SYS}&url=${url}`;
        return axios.get(ruta, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }


    saveRegionSedes(url, body) {
        let ruta = `${API_URL}/region/sedes?sys=${SYS}&url=${url}`;
        return axios
            .post(ruta, body, {
                headers: headerRequest(),
            })
            .catch((error) => {
                return error;
            });
    }

    deleteRegionSedes(url, body) {
        let ruta = `${API_URL}/region/deleteRegionSedes?sys=${SYS}&url=${url}`;
        return axios.delete(ruta, {
            headers: headerRequest(),
            data: body
        }).catch((error) => {
            return error;
        });
    }

    deleteRegion(url, body) {
        let ruta = `${API_URL}/region/deleteRegion?sys=${SYS}&url=${url}`;
        return axios.delete(ruta, {
            headers: headerRequest(),
            data: body
        }).catch((error) => {
            return error;
        });
    }

    saveRegion(url, body) {
        let ruta = `${API_URL}/region/saveRegion?sys=${SYS}&url=${url}`;
        return axios
            .post(ruta, body, {
                headers: headerRequest(),
            })
            .catch((error) => {
                return error;
            });
    }
}

export default new RegionService();
