import axios from "axios";
import { Global } from '../utils/general';
import headerRequest from "../utils/headers"

const API_URL = Global.url;
const SYS = Global.system;

class ConsejeroService {
    getConsejeroTramaSCTRC(url, infoEmpresa, infoCliente, infoUnidad, infoSede, dateRep) {
        let ruta = `${API_URL}/trama/consejero-sctr?sys=${SYS}&url=${url}&rucEmpresa=${infoEmpresa}&rucCliente=${infoCliente}&pnCodUnidad=${infoUnidad}&pnCodSede=${infoSede}&fecha=${dateRep}`;
        return axios.get(ruta, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }

    getConsejeroTramaVida(url, infoEmpresa, infoCliente, infoUnidad, infoSede, dateRep) {
        let ruta = `${API_URL}/trama/consejero-vida?sys=${SYS}&url=${url}&rucEmpresa=${infoEmpresa}&rucCliente=${infoCliente}&pnCodUnidad=${infoUnidad}&pnCodSede=${infoSede}&fecha=${dateRep}`;
        return axios.get(ruta, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }

    deleteConsejeroTramaSctr(url, body) {
        let ruta = `${API_URL}/trama/consejero-sctr-delete?sys=${SYS}&url=${url}`;
        return axios.post(ruta, body, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }

    deleteConsejeroTramaVida(url, body) {
        let ruta = `${API_URL}/trama/consejero-vida-delete?sys=${SYS}&url=${url}`;
        return axios.post(ruta, body, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }
}


export default new ConsejeroService();
