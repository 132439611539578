import React, { } from 'react';

import MainModal from '../../../components/General/MainModal';
import DetailStaff from '../../../components/Staff/InfoStaff/DetailStaff';

const InfoStaff = (props) => {
    const { open, handleClose, setTitleSpinner, setOpenModalLoader, states, infoStaff, } = props;

    return (
        <>
            <MainModal size={'md'}
                open={open}
                handleClose={handleClose}
                title={'Adminitrador de Trabajador'}

                bodyModal={
                    <DetailStaff
                        setTitleSpinner={setTitleSpinner}
                        setOpenModalLoader={setOpenModalLoader}
                        states={states}
                        infoStaff={infoStaff}
                    />
                }

            />
        </>
    );
};

export default InfoStaff;