import axios from "axios";
import { Global } from '../utils/general';
import headerRequest from "../utils/headers"

const API_URL = Global.url;
const SYS = Global.system;

class ShiftService {
    getTurno() {
        const ruta = `${API_URL}/shift`;
        return axios
            .get(ruta, {
                headers: headerRequest(),
            })
            .catch((error) => {
                return error;
            });
    }
   
    getRelacionTurnoSedebyTurno(url, id) {
        const ruta = `${API_URL}/shift/shift/sede/${id}?sys=${SYS}&url=${url}`;
        return axios.get(ruta, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }

    saveTurnoSedes(url, body) {
        const ruta = `${API_URL}/shift/sedes?sys=${SYS}&url=${url}`;
        return axios
            .post(ruta, body, {
                headers: headerRequest(),
            })
            .catch((error) => {
                return error;
            });
    }

    saveTurno(url, body) {
        const ruta = `${API_URL}/shift?sys=${SYS}&url=${url}`;
        return axios
            .post(ruta, body, {
                headers: headerRequest(),
            })
            .catch((error) => {
                return error;
            });
    }

    deleteTurnoSedes(url, body) {
        const ruta = `${API_URL}/shift/deleteSedes?sys=${SYS}&url=${url}`;
        return axios.delete(ruta, {
            headers: headerRequest(),
            data: body
        }).catch((error) => {
            return error;
        });
    }

    getTurnoBySede(url, id) {
        const ruta = `${API_URL}/shift/shift-by-sede/${id}?sys=${SYS}&url=${url}`;
        return axios.get(ruta, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }
    
    getTurnoBySedeLista(url, lstSedes) {
        const ruta = `${API_URL}/shift/shift-by-sede/list?sys=${SYS}&url=${url}&sedes=${lstSedes}`;
        return axios.get(ruta, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }

    getTurnoByArea(queryParameters) {
        queryParameters.sys = SYS;
        const ruta = `${API_URL}/shift/by-area`;
        return axios.get(ruta, {
            params: queryParameters,
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }
}

export default new ShiftService();
