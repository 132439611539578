import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import UserService from "../../services/user";
import MainSelect from "./General/MainSelect";
import { simpleAlerts } from "../../utils/alerts";
import { CODES } from "../../utils/responseCodes";

const GroupingSedeByUser = ({
  user, mode = null,
  openModalLoader, setOpenModalLoader,
  infoCompany, setInfoCompany,
  infoClient, setInfoClient,
  infoUnit, setInfoUnit,
  infoCampus, setInfoCampus,
}) => {
  const url = "'" + window.location.pathname + "'";
  const id_usuarioLogin = user?.id_usuario;

  const [empresas, setEmpresas] = useState([]);
  const [clientes, setClientes] = useState([]);
  const [unidades, setUnidades] = useState([]);
  const [sedes, setSedes] = useState([]);

  useEffect(() => {
    if (user?.id_usuario)
      getAgrupamientoByUsuario();
  }, [user]);

  useEffect(() => {
    setInfoClient(null);
    setInfoUnit(null);
    setInfoCampus(null);
  }, [infoCompany]);

  useEffect(() => {
    setInfoUnit(null);
    setInfoCampus(null);

  }, [infoClient]);

  useEffect(() => {
    setInfoCampus(null);

  }, [infoUnit]);

  const getAgrupamientoByUsuario = async () => {
    try {
      if (!openModalLoader)
        setOpenModalLoader(true);
      const result = await UserService.getAgrupamientoByUsuario(url, id_usuarioLogin);
      if (result.status === CODES.SUCCESS_200) {
        if (result.data.areas.length === 0) {
          simpleAlerts('No tiene areas asignadas', "warning");
          return
        }
        setEmpresas(result.data.empresas);
        setClientes(result.data.clientes);
        setUnidades(result.data.unidades);
        setSedes(result.data.sedes);
      }
    } catch (error) {
      console.log(`Error en getAgrupamientoByUsuario: ${error}`);
    } finally {
      setOpenModalLoader(false)
    }
  }

  return (
    <>
      <Grid container p={1} spacing={1.2}>
        <Grid item xs={12} sm={6} md={6}>
          <MainSelect
            title={"Empresa"}
            options={empresas}
            placeholder={"Selecionar Empresa"}
            value={infoCompany?.value}
            onChange={(value, event) => setInfoCompany(empresas?.find((element) => element.value === value))}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <MainSelect
            title={"Cliente"}
            options={clientes?.filter((e) => +e.empresa_id === +infoCompany?.value)}
            placeholder={"Selecionar Cliente"}
            value={infoClient?.value}
            onChange={(value, event) => setInfoClient(clientes?.find((element) => element.value === value))}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} paddingTop="1%">
          <MainSelect
            title={"Unidad"}
            options={unidades?.filter((e) => +e.cliente_id === +infoClient?.value)}
            placeholder={"Selecionar Unidad"}
            value={infoUnit?.value}
            onChange={(value, event) => setInfoUnit(unidades?.find((element) => element.value === value))}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} paddingTop="1%">
          <MainSelect
            title={'Sede'}
            mode={mode}
            options={sedes?.filter((e) => +e.unidad_id === +infoUnit?.value)}
            placeholder={'Selecionar Sede'}
            value={infoCampus?.value}
            onChange={(value, event) => setInfoCampus(sedes?.find(element => element.value === value))}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default GroupingSedeByUser;
