import axios from "axios";
import { Global } from '../utils/general';
import headerRequest from "../utils/headers"

const API_URL = Global.url;
const SYS = Global.system;

class PlanningService {
    getPlanificacionByArea(url, id_area, inicio, fin) {
        const ruta = `${API_URL}/manage/plan/list?sys=${SYS}&url=${url}&id_area=${id_area}&inicio=${inicio}&fin=${fin}`;
        return axios.get(ruta, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }

    async savePlanning(url, body) {
        const ruta = `${API_URL}/manage/plan/planning?sys=${SYS}&url=${url}`;
        try {
            const response = await axios.post(ruta, body, {
                headers: headerRequest(),
            });
            return response;
        } catch (error) {
            console.error(error);
            return error;
        }
    }

    async copyPlanning(url, body) {
        const ruta = `${API_URL}/manage/plan/copy-planning?sys=${SYS}&url=${url}`;
        try {
            const response = await axios.post(ruta, body, {
                headers: headerRequest(),
            });
            return response;
        } catch (error) {
            console.error(error);
            return error;
        }
    }

    savePlanningMassive(url, body) {
        const ruta = `${API_URL}/manage/plan/planning/massive?sys=${SYS}&url=${url}`;
        return axios.post(ruta, body, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }

    downloadTemplate(url, body) {
        const ruta = `${API_URL}/manage/plan/download-template?sys=${SYS}&url=${url}`;
        return axios.post(ruta, body, {
            headers: headerRequest(),
            responseType: 'blob'
        }).catch((error) => {
            return error;
        });
    }

    savePlaningByExcel(url, body) {
        const ruta = `${API_URL}/manage/plan/planning/excel?sys=${SYS}&url=${url}`;
        return axios.post(ruta, body, {
            headers: {
                'Content-Type': 'multipart/form-data',
                ...headerRequest(),
            },
        }).catch((error) => {
            return error;
        });
    }
}

export default new PlanningService();
