import React, { useState, useEffect } from 'react';
import { Box, } from '@mui/material';

import { OrderedListOutlined, } from '@ant-design/icons/lib/icons';
import SpinnerSmall from '../../SpinnerLoad/SpinnerSmall';
import GroupingSede from '../../GroupingSede';
import GeneralService from '../../../../services/general';
import { CODES } from '../../../../utils/responseCodes'
import { simpleAlerts } from '../../../../utils/alerts';
import { sortDataList, sortDataListSimple } from '../../../../utils/function';
import _ from 'lodash';

const columns = [
    { id: 'sel', label: 'Sel.', width: 10, align: 'center', },
    { id: 'id_area', label: 'Cód.', width: 50, align: 'left', },
    // { id: 'desEmpresa', label: 'Empresa', width: 150, align: 'left', },
    // { id: 'desCliente', label: 'Cliente', width: 150, align: 'left', },
    // { id: 'desUnidad', label: 'Unidad', width: 150, align: 'left', },
    // { id: 'desSede', label: 'Sede', width: 150, align: 'left', },
    { id: 'desArea', label: 'Área', width: 150, align: 'left', },
];

const AssociateAreas = (props) => {
    const { dataUserAreas, userAreasCopy, setUserAreasCopy, userAreasDelete, setUserAreasDelete, userAreasSave, setUserAreasSave, dataUser, } = props;

    useEffect(() => {
        setUserAreasCopy(dataUserAreas)
    }, [dataUserAreas])

    const [openSmall, setOpenSmall] = useState(false);
    const [infoCompanyMdl, setInfoCompanyMdl] = useState(null);
    const [infoClientMdl, setInfoClientMdl] = useState(null);
    const [infoUnitMdl, setInfoUnitMdl] = useState(null);
    const [infoCampusMdl, setInfoCampusMdl] = useState(null);

    const [dataAreasCampus, setDataAreasCampus] = useState(null);

    useEffect(() => {
        if (infoCampusMdl?.value)
            getAreasBySede()
    }, [infoCampusMdl])

    useEffect(() => {
        setDataAreasCampus(null);
    }, [infoCampusMdl, infoClientMdl, infoUnitMdl])

    const getAreasBySede = async () => {
        try {
            setOpenSmall(true);
            const result = await GeneralService.getAreasBySede(infoCampusMdl?.value);
            if (result.status === CODES.SUCCESS_200) {
                setDataAreasCampus(result.data);
            } else {
                simpleAlerts('Hubo un error en buscar areas por sede', 'error');
            }
        } catch (err) {
            console.log('Error en getPersonByUser', err);
        } finally {
            setOpenSmall(false);
        }
    };

    /** inicio ordenamiento de columnas */
    const [sortConfigTable, setSortConfigTable] = useState({ key: null, direction: "ascending", });

    const handleSortTable = (key) => {
        let direction = "ascending";
        if (sortConfigTable && sortConfigTable.key === key && sortConfigTable.direction === "ascending") {
            direction = "descending";
        }
        setSortConfigTable({ key, direction });
    };

    const sortedData = sortDataListSimple(dataAreasCampus, sortConfigTable);
    /** fin ordenamiento de columnas */

    const handleChangeCheck = (value, p_area) => {
        let upduserAreasCopy = _.cloneDeep(userAreasCopy);
        let updUserAreasDelete = _.cloneDeep(userAreasDelete);
        let updUserAreasSave = _.cloneDeep(userAreasSave);
        if (value === false) {
            updUserAreasDelete.push(upduserAreasCopy?.find(e => +e.area_id === +p_area.id_area));
            upduserAreasCopy = upduserAreasCopy?.filter(e => +e.area_id !== +p_area.id_area);
            updUserAreasSave = updUserAreasSave?.filter(e => +e.area_id !== +p_area.id_area);
        } else {
            let newItem = {
                "area_id": Number(p_area.id_area),
                "usuario_id": Number(dataUser?.id_usuario),
                "area": p_area
            }
            upduserAreasCopy.push(newItem);
            updUserAreasSave.push(newItem);
        }

        return setUserAreasCopy(upduserAreasCopy), setUserAreasDelete(updUserAreasDelete), setUserAreasSave(updUserAreasSave);
    }

    const handleChangeCheckGeneral = (e) => {
        let upduserAreasCopy = _.cloneDeep(userAreasCopy);
        let updUserAreasDelete = _.cloneDeep(userAreasDelete);
        let updUserAreasSave = _.cloneDeep(userAreasSave);
        upduserAreasCopy = [];
        updUserAreasDelete = [];
        updUserAreasSave = [];

        if (e.target.checked === false) {
            dataAreasCampus?.forEach((element) => {
                let newItem = {
                    "area_id": Number(element.id_area),
                    "usuario_id": Number(dataUser?.id_usuario),
                    "area": element
                }
                updUserAreasDelete.push(newItem)
            });
        } else {
            dataAreasCampus?.forEach((element) => {
                let newItem = {
                    "area_id": Number(element.id_area),
                    "usuario_id": Number(dataUser?.id_usuario),
                    "area": element
                }
                upduserAreasCopy.push(newItem)
                updUserAreasSave.push(newItem)
            });
        }

        return setUserAreasCopy(upduserAreasCopy), setUserAreasDelete(updUserAreasDelete), setUserAreasSave(updUserAreasSave);
    }

    return (
        <>
            <GroupingSede
                infoCompany={infoCompanyMdl} setInfoCompany={setInfoCompanyMdl}
                infoClient={infoClientMdl} setInfoClient={setInfoClientMdl}
                infoUnit={infoUnitMdl} setInfoUnit={setInfoUnitMdl}
                infoCampus={infoCampusMdl} setInfoCampus={setInfoCampusMdl}
            />
            <SpinnerSmall open={openSmall} />
            <Box className='page-body-main'>
                <Box className='page-body-table page-body-table__modal table-responsive-md'>
                    <table className="table table-hover table-striped table-bordered table-sm">
                        <thead className='sticky-top table-warning'>
                            <tr>
                                {columns.map((column) => (
                                    <th
                                        scope="col"
                                        key={column.id}
                                        style={{
                                            textAlign: column.align,
                                            width: column.width,
                                            maxWidth: column.width,
                                            // backgroundColor: 'red',
                                            // color: 'red',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => handleSortTable(column.id)}
                                    >
                                        {column.id === 'sel' ?
                                            <input type="checkbox"
                                                name="check"
                                                id={`checkbox-rect-header`}
                                                className={'checkbox-rect-header'}
                                                onChange={handleChangeCheckGeneral}
                                            />
                                            : <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <div style={{ marginRight: 'auto' }}>{column.label}</div>
                                                <div><OrderedListOutlined style={{ paddingBottom: 2 }} /></div>
                                            </div>}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody className="table-group-divider">
                            {sortedData?.map((area, indexRow) => {
                                return (
                                    <tr key={"row" + indexRow}>
                                        {columns.map((column, indexColumn) => {
                                            const value = area[column.id];
                                            return (
                                                <td key={'col' + indexColumn} align={column.align}>
                                                    {column.id === 'desSede' ?
                                                        (area?.sede?.desSede) :
                                                        column.id === 'sel' ?
                                                            <div className="checkbox-custom"
                                                                style={{ display: 'flex', justifyContent: 'center' }}
                                                            >
                                                                <input type="checkbox"
                                                                    name="check"
                                                                    id={`checkbox-rect` + area.id_area}
                                                                    checked={userAreasCopy?.find(e => Number(e.area_id) === Number(area.id_area)) ? true : false}
                                                                    onChange={(e) => handleChangeCheck(e.target.checked, area)}
                                                                />
                                                                <label htmlFor={`checkbox-rect` + area.id_area}></label>
                                                            </div>
                                                            : value}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </Box>
            </Box >
        </>
    );
};

export default AssociateAreas;