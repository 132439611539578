import React, { } from 'react';

import MainModal from '../../../../components/General/MainModal';
import MainButton from '../../../../components/General/MainButton';

import { CODES } from '../../../../../utils/responseCodes';
import { simpleAlerts } from '../../../../../utils/alerts';

import InfoArea from '../../../../components/Maintenance/Areas/AddArea/InfoArea';
import AreaService from '../../../../../services/area';

const AddArea = (props) => {
    const { open, handleClose, setOpenModalLoader, setTitleSpinner, id_usuarioLogin, infoCampus, infoArea, setInfoArea, getAreasBySede } = props;
    const url = "'" + window.location.pathname + "'";

    const cleanInfo = () => {
        return setInfoArea(null);
    };

    const saveArea = async (e) => {
        e.preventDefault();
        let data = {
            ...infoArea,
            sede_id: infoCampus?.id_sede,
            usuarioMod: id_usuarioLogin,
        };

        if (data.servicio === '' || data.servicio === 0) {
            simpleAlerts('Falta seleccionar servicio!', 'warning');
            return;
        }

        setOpenModalLoader(true);
        setTitleSpinner('Guardando ...');
        try {
            const result = await AreaService.saveArea(url, data);
            if (result.status === CODES.CREATE_201) {
                simpleAlerts(result.data.message, 'success');
                cleanInfo();
                handleClose();
                getAreasBySede();
            } else {
                simpleAlerts(result.response.data.message, 'error');
            }
        } catch (err) {
            console.log('Error en saveProfile', err);
        } finally {
            setOpenModalLoader(false);
            setTitleSpinner(null);
        }
    }

    return (
        <>
            <MainModal
                open={open}
                handleClose={() => { cleanInfo(); handleClose() }}
                formSubmit={saveArea}
                centrado={'centrado'}
                title={'Mantenimiento de áreas'}
                subTitle={'Creación y edición de áreas'}
                bodyModal={
                    <InfoArea
                        infoArea={infoArea}
                        setInfoArea={setInfoArea}

                    />
                }
                buttonActions={[
                    <MainButton
                        key='btn-modal-user-save1'
                        title={'Guardar'}
                        typeStyle={'outlined'}
                        color={'primary'}
                        type={'submit'}
                    />
                ]}
            />
        </>
    );
};

export default AddArea;