import React, { useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';
import { CODES } from '../../../../utils/responseCodes';
import { simpleAlerts } from '../../../../utils/alerts';
import SpinnerLoader from '../../../components/SpinnerLoad/SpinnerLoader';
import MainInput from '../../../components/General/MainInput';
import GeneralService from '../../../../services/general';
import ReportService from '../../../../services/report';
import StaffService from '../../../../services/staff';
import MainSelect from '../../../components/General/MainSelect';
import { getDateOL } from '../../../../utils/function';
import { Tabs, Tab } from '@mui/material';
import GroupingSedeByUser from '../../../components/GroupingSedeByUser';

const ReportRegionSede = ({ userToken }) => {
  const [openModalLoader, setOpenModalLoader] = useState(false);
  const [titleModalLoader, setTitleModalLoader] = useState(null);

  const [infoRegion, setInfoRegion] = useState(null);
  const [regiones, setRegiones] = useState(null);

  const [dateInit, setDateInit] = useState(getDateOL());
  const [dateEnd, setDateEnd] = useState(getDateOL('', 'short', 7));
  const [type, setType] = useState(1);


  //* inicio variables para el agrupamiento */
  const [infoCompany, setInfoCompany] = useState(null);
  const [infoClient, setInfoClient] = useState(null);
  const [infoUnit, setInfoUnit] = useState(null);
  const [infoCampus, setInfoCampus] = useState(null);
  //* fin variables para el agrupamiento */

  const [infoWorkers, setInfoWorkers] = useState(null);
  const [dataWorkers, setDataWorkers] = useState(null);

  const [activeTab, setActiveTab] = useState('sede');

  useEffect(() => {
    init();
  }, [])

  const init = async () => {
    Promise.all([
      getRegiones(),
    ]).then(() => { setTimeout(() => { setOpenModalLoader(false); }, 5000); });
  }

  useEffect(() => {
    if (infoRegion) {
      getSedexRegion();
    }
  }, [infoRegion])

  useEffect(() => {
    if (infoCampus) {
      getPersonBySede();
      setInfoWorkers([]);
    }
  }, [infoCampus])

  const getPersonBySede = async () => {
    const url = "'" + window.location.pathname + "'";
    try {
      setOpenModalLoader(true);
      setDataWorkers([]);
      const result = await StaffService.getPersonBySede(url, { pagination: 1 }, infoCampus?.value);
      if (result.status === CODES.SUCCESS_200) {
        result.data?.forEach(element => {
          element.value = element.id_persona
          element.label = element.trabajador
        })
        setDataWorkers(result.data);
        return []
      } else {
        simpleAlerts('No hay información de Personas en sede', 'info');
      }

    } catch (error) {
      console.log(`Error en getPersonBySede: ${error}`);
    } finally {
      setOpenModalLoader(false);
    }
  }

  const getRegiones = async () => {
    try {
      const result = await GeneralService.getRegiones();
      if (result.status === CODES.SUCCESS_200) {
        result.data?.forEach(element => {
          element.value = element.id_region
          element.label = element.desRegion
        })
        setRegiones(result.data);

      } else {
        simpleAlerts('No hay información de Regiones', 'info');
      }
    } catch (error) {
      console.log(`Error en getRegiones: ${error}`);
    }
  }

  const getSedexRegion = async () => {
    try {
      const result = await GeneralService.getSedexRegion(infoRegion?.value);
      if (result.status === CODES.SUCCESS_200) {
        result.data?.forEach(element => {
          element.value = element.id_sede
          element.label = element.desSede
        })
      } else {
        simpleAlerts('No hay información de Sedes', 'info');
      }
    } catch (error) {
      console.log(`Error en getSedexRegion: ${error}`);
    }
  }

  const generateSede = async () => {
    if (!infoCampus?.value) {
      simpleAlerts('Seleccione una Sede', 'warning');
    } else {
      try {
        setTitleModalLoader('Generando Reporte de Sede...');
        setOpenModalLoader(true);

        const result = await ReportService.generateSede(dateInit, dateEnd, infoCampus?.value, type);
        if (result.status === CODES.SUCCESS_200) {
          const blob = new Blob([result.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          const url = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.download = 'TAREO_SEDE_' + infoCampus?.desSede + "_" + dateInit + '_' + dateEnd + '.xlsx';
          link.click();
          URL.revokeObjectURL(url);
          //getPeople();
          setOpenModalLoader(false);
        } else {
          setOpenModalLoader(false);
          setTitleModalLoader(null);
        }
        return []
      } catch (error) {
        console.log(`Error en generateSede: ${error}`);
      }
    }
  }

  const generateRegion = async () => {
    if (!infoRegion?.value) {
      simpleAlerts('Seleccione una Region', 'warning');
    } else {
      try {
        setTitleModalLoader('Generando Reporte de Region...');
        setOpenModalLoader(true);
        //let body = generateBody();
        const MayusculaDesRegion = infoRegion?.desRegion?.toUpperCase();
        const result = await ReportService.generateRegion(dateInit, dateEnd, infoRegion?.value, type);
        if (result.status === CODES.SUCCESS_200) {
          const blob = new Blob([result.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          const url = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.download = 'TAREO_REGION_' + MayusculaDesRegion + "_" + dateInit + '_' + dateEnd + '.xlsx';
          link.click();
          URL.revokeObjectURL(url);
          //getPeople();
          setOpenModalLoader(false);
        } else {
          setOpenModalLoader(false);
          setTitleModalLoader(null);
        }
        return []
      } catch (error) {
        console.log(`Error en generateRegion: ${error}`);
      }
    }
  }

  const generateReportTrabajador = async () => {
    if (!infoCampus?.value) {
      simpleAlerts('Seleccione una Sede', 'warning');
      return;
    }
    if (!infoWorkers?.value) {
      simpleAlerts('Seleccione un Trabajador', 'warning');
      return;
    }
    try {
      setTitleModalLoader('Generando Reporte de Trabajador...');
      setOpenModalLoader(true);
      const result = await ReportService.exportPersonaTareosExcel(dateInit, dateEnd, infoCampus?.value, infoWorkers?.codSghh);
      if (result.status === CODES.SUCCESS_200) {
        const blob = new Blob([result.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'TAREO_PERSONA_' + infoCampus?.desSede + "_" + dateInit + '_' + dateEnd + '.xlsx';
        link.click();
        URL.revokeObjectURL(url);
        //getPeople();
        setOpenModalLoader(false);
      } else {
        setOpenModalLoader(false);
        setTitleModalLoader(null);
      }
      return []
    } catch (error) {
      console.log(`Error en generateSede: ${error}`);
    }
  }

  const generateReportMarks = async () => {
    if (activeTab === 'sede') {
      if (!infoCampus?.value) {
        simpleAlerts('Seleccione una Sede', 'warning');
        return;
      }
    } else {
      if (!infoRegion?.value) {
        simpleAlerts('Seleccione una Región', 'warning');
        return;
      }
    }
    if (!dateInit || dateInit === '' || !dateEnd || dateEnd === '') {
      simpleAlerts('Debe seleciconar ambas fechas inicio y fin', 'warning');
      return;
    }
    try {
      setTitleModalLoader('Generando Reporte de Marcas...');
      setOpenModalLoader(true);
      let result = "";
      if (activeTab === 'sede') {
        result = await ReportService.exportReportMarksBySedeExcel(dateInit, dateEnd, infoCampus?.value, infoCampus?.desSede);
      } else {
        result = await ReportService.exportReportMarksByRegionExcel(dateInit, dateEnd, infoRegion?.value, infoRegion?.desRegion);
      }
      if (result.status === CODES.SUCCESS_200) {
        const blob = new Blob([result.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'MARCAS_' + (activeTab === 'sede' ? infoCampus?.desSede : infoRegion?.desRegion) + "_" + dateInit + '_' + dateEnd + '.xlsx';
        link.click();
        URL.revokeObjectURL(url);
        //getPeople();
        setOpenModalLoader(false);
      } else {
        setOpenModalLoader(false);
        setTitleModalLoader(null);
      }
      return []
    } catch (error) {
      console.log(`Error en generateSede: ${error}`);
    }
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Tabs
        centered
        value={activeTab}
        onChange={(event, newValue) => setActiveTab(newValue)}
      >
        <Tab value="sede" label="Por Sede" />
        <Tab value="region" label="Por Región" />
      </Tabs>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={10} sm={8} md={8}>
          <Grid container justifyContent="center" alignItems="center">
            {activeTab === "region" ? (
              <Grid item xs={12} sm={6} md={6}>
                <MainSelect
                  title={"Region"}
                  options={regiones}
                  placeholder={"Seleccionar Región"}
                  value={infoRegion?.value}
                  onChange={(value, event) =>
                    setInfoRegion(
                      regiones?.find((element) => element.value == value)
                    )
                  }
                />
              </Grid>
            ) : (
              <Grid item xs={12} sm={6} md={8}>
                <GroupingSedeByUser
                  user={userToken.user}
                  openModalLoader={openModalLoader} setOpenModalLoader={setOpenModalLoader}
                  infoCompany={infoCompany} setInfoCompany={setInfoCompany}
                  infoClient={infoClient} setInfoClient={setInfoClient}
                  infoUnit={infoUnit} setInfoUnit={setInfoUnit}
                  infoCampus={infoCampus} setInfoCampus={setInfoCampus}
                />
              </Grid>
            )}
          </Grid>
          <Grid container p={1} justifyContent="center" alignItems="center">
            <Grid item xs={12} sm={6} md={4}>
              <MainInput
                title={"Fecha Inicio"}
                placeholder={"Ingrese Fecha Inicio"}
                type={"date"}
                value={dateInit}
                onChange={(e) => setDateInit(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <MainInput
                title={"Fecha Fin"}
                placeholder={"Ingrese Fecha Fin"}
                type={"date"}
                value={dateEnd}
                onChange={(e) => setDateEnd(e.target.value)}
                propsInput={{ min: dateInit }}
              />
            </Grid>
          </Grid>
          <Grid container p={1} justifyContent="center" alignItems="center">
            <Grid item xs={12} sm={6} md={4} style={{ marginLeft: "2rem" }}>
              <span
                className="badge text-bg-secondary"
                style={{ marginBottom: "10px" }}
              >
                Estado Trabajador:
              </span>
              <div>
                <input
                  type="radio"
                  id={"byActive"}
                  name="typeStatus"
                  value={1}
                  checked={type === 1}
                  onChange={(e) => setType(Number(e.target.value))}
                />
                <label
                  htmlFor={"byActive"}
                  style={{ padding: "0.2rem 0.5rem" }}
                >
                  Activo
                </label>
                <br />
              </div>
              <div>
                <input
                  type="radio"
                  id={"byCesado"}
                  name="typeStatus"
                  value={2}
                  checked={type === 2}
                  onChange={(e) => setType(Number(e.target.value))}
                />
                <label
                  htmlFor={"byCesado"}
                  style={{ padding: "0.2rem 0.5rem" }}
                >
                  Cesado
                </label>
              </div>
              <div>
                <input
                  type="radio"
                  id={"byAll"}
                  name="typeStatus"
                  value={0}
                  checked={type === 0}
                  onChange={(e) => setType(Number(e.target.value))}
                />
                <label htmlFor={"byAll"} style={{ padding: "0.2rem 0.5rem" }}>
                  Ambos
                </label>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              {activeTab === "region" && (
                <button
                  type="button"
                  className="btn btn-outline-success"
                  onClick={generateRegion}
                  style={{ marginRight: "2rem" }}
                >
                  REPORTE POR REGIÓN
                </button>
              )}
              {activeTab === "sede" && (
                <button
                  type="button"
                  className="btn btn-success"
                  onClick={generateSede}
                  style={{ marginRight: "2rem" }}
                >
                  REPORTE POR SEDE
                </button>
              )}
            </Grid>
            {activeTab === "sede" && (
              <Grid
                container
                p={1}
                justifyContent="center"
                alignItems="center"
                style={{ marginTop: "1rem" }}
              >
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  sx={{ marginLeft: { md: "2rem", xs: "0" } }}
                >
                  <MainSelect
                    title={"Trabajador"}
                    options={dataWorkers}
                    placeholder={"Seleccionar Trabajador"}
                    value={infoWorkers?.value}
                    onChange={(value, event) =>
                      setInfoWorkers(
                        dataWorkers?.find((element) => element.value == value)
                      )
                    }
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  style={{ marginTop: "1rem" }}
                >
                  <button
                    type="button"
                    className="btn btn-warning"
                    onClick={generateReportTrabajador}
                    style={{ marginRight: "2rem" }}
                  >
                    REPORTE TRABAJADOR
                  </button>
                </Grid>
              </Grid>
            )}
          </Grid>
          <Grid container p={1} justifyContent="center" alignItems="center">
            <Grid item xs={12} sm={6} md={4}>
              <button
                type="button"
                className="btn btn-dark"
                onClick={generateReportMarks}
              >
                REPORTE MARCAS
              </button>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
            </Grid>
          </Grid>
          <SpinnerLoader open={openModalLoader} title={titleModalLoader} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ReportRegionSede;