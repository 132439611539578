import React, { useEffect, useState } from 'react';
import { Box, Grid, } from '@mui/material';
import AddArea from './AddArea/AddArea';
import BodyAreas from '../../../components/Maintenance/Areas/BodyAreas/BodyAreas';
import GroupingSede from '../../../components/GroupingSede';
import MainButton from '../../../components/General/MainButton';
import GeneralService from '../../../../services/general';
import { CODES } from '../../../../utils/responseCodes';
import { simpleAlerts } from '../../../../utils/alerts';
import SpinnerLoader from '../../../components/SpinnerLoad/SpinnerLoader';

const newArea = {
    'desArea': '',
    'codArea': '',
    'servicio': '',
    'sede_id': '',
    'usuarioMod': ''
}

const Areas = ({ userToken }) => {
    const id_usuarioLogin = userToken.user.id_usuario;
    const [openModalLoader, setOpenModalLoader] = useState(false);
    const [titleSpinner, setTitleSpinner] = useState(null);
    const [open, setOpen] = useState(false)
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [infoCompany, setInfoCompany] = useState(null);
    const [infoClient, setInfoClient] = useState(null);
    const [infoUnit, setInfoUnit] = useState(null);
    const [infoCampus, setInfoCampus] = useState(null);
    const [infoArea, setInfoArea] = useState(null);

    const [dataAreas, setDataAreas] = useState(null);

    useEffect(() => {
        if (infoCampus?.id_sede)
            getAreasBySede();
    }, [infoCampus])

    const getAreasBySede = async () => {
        try {
            setOpenModalLoader(true);
            setTitleSpinner('Cargando áreas!');
            const result = await GeneralService.getAreasBySede(infoCampus?.id_sede);
            if (result.status === CODES.SUCCESS_200) {
                if (result.data === 0)
                    simpleAlerts('No tiene areas la sede', "warning");
                setDataAreas(result.data);
            }
            return[];
        } catch (error) {
            console.log(`Error en getAreasBySede: ${error}`);
        }finally{
            setOpenModalLoader(false);
            setTitleSpinner(null);
        }
    }

    const cargarArea = () => {
        if (!infoCampus?.id_sede) {
            simpleAlerts('Debe seleccionar una sede!');
            return;
        }
        getAreasBySede();
    };

    const createArea = () => {
        if (!infoCampus?.id_sede) {
            simpleAlerts('Debe seleccionar una sede!');
            return;
        }
        setInfoArea(newArea)
        handleOpen();
    };

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AddArea
                open={open}
                handleClose={handleClose}
                setOpenModalLoader={setOpenModalLoader}
                setTitleSpinner={setTitleSpinner}
                id_usuarioLogin={id_usuarioLogin}

                infoCampus={infoCampus}
                infoArea={infoArea}
                setInfoArea={setInfoArea}

                getAreasBySede={getAreasBySede}
            />

            <Grid container spacing={1} justifyContent={'center'} alignItems={'center'}>
                <Grid item xs={12} sm={10} md={8}>
                    <GroupingSede
                        infoCompany={infoCompany} setInfoCompany={setInfoCompany}
                        infoClient={infoClient} setInfoClient={setInfoClient}
                        infoUnit={infoUnit} setInfoUnit={setInfoUnit}
                        infoCampus={infoCampus} setInfoCampus={setInfoCampus}
                    />
                </Grid>
            </Grid>

            <Grid container
                direction="row"
                justifyContent="center"
                alignItems="center"
            >
                <Grid item
                    xs={10}
                    display={'flex'}
                    justifyContent={'center'}
                    gap={'10px'}
                    m={1}
                >
                    <Box width={'20%'}>
                        <MainButton
                            onClick={cargarArea}
                            title={'Cargar'}
                            typeStyle={'outlined'}
                            color={'primary'}
                            fullWidth={true}
                        />
                    </Box>
                    <Box width={'20%'}>
                        <MainButton
                            onClick={createArea}
                            title={'Crear Área'}
                            typeStyle={'outlined'}
                            color={'success'}
                            fullWidth={true}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={10} md={8} paddingLeft={'0.2rem'} paddingRight={'0.2rem'}>
                    <BodyAreas
                        handleOpen={handleOpen}
                        id_usuarioLogin={id_usuarioLogin}
                        dataAreas={dataAreas}
                        setDataAreas={setDataAreas}
                        setInfoArea={setInfoArea}
                        getAreasBySede={getAreasBySede}
                        setOpenModalLoader={setOpenModalLoader}
                        setTitleSpinner={setTitleSpinner}
                    />
                </Grid>

            </Grid>

            <SpinnerLoader open={openModalLoader} title={titleSpinner} />
        </Box>
    );
};

export default Areas;