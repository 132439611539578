import React, { useEffect, useState, } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import ProductivityService from '../../../services/productivity';
import ActivityService from '../../../services/activity';
import { colors } from '../../../assets/colors';
import { simpleAlerts } from '../../../utils/alerts';
import { CODES } from '../../../utils/responseCodes';
import { prodPersona, prodTurno } from './functionProductivity';
import { checkTimeOverlapById } from './functionProductivity';
import { Box, Grid, IconButton } from "@mui/material";
import MainInputInfo from '../../components/General/MainInputInfo';
import MainInput from '../../components/General/MainInput';
import MainButton from '../../components/General/MainButton';
import MainSelect from '../../components/General/MainSelect';
import BodyProductivity from '../../components/Productivity/Movil/BodyProductivity';
import Swal from 'sweetalert2';
import _ from 'lodash';

const ProductivityMovil = ({ setValidationInfoChange,
    fechaMin, fechaMax,
    userToken, setOpenModalLoader, setTitleLoader, validationMessages,
    areasSelected, shiftSelected, dateSelected, processInformation, tipoProd,
    productivityC, setProductivityC, dataProductivity, setDataProductivity,
    workerSelected, setWorkerSelected, }) => {

    const url = "'" + window.location.pathname + "'";
    const id_usuarioLogin = userToken.user.id_usuario;
    const id_personaLogin = userToken.user.persona_id;

    const [loadValidation, setLoadValidation] = useState(false);

    const [productivityCOriginal, setProductivityCOriginal] = useState([]);
    const [dataProductivityOriginal, setDataProductivityOriginal] = useState([]);

    useEffect(() => {
        if (+tipoProd === 1) {
            setDataProductivity(prodTurno(dataProductivity, productivityC));
        } else {
            let newProductivity = prodPersona(dataProductivity, productivityC);
            setDataProductivity(newProductivity?.productividad);
            setProductivityC(newProductivity?.cabecera);
        }
    }, [productivityC?.produccionTotal]);

    //TODO: funciones productividad
    const [activities, setActivities] = useState(null);
    const [activity, setActivity] = useState(null);
    const [deleteWorkerProd, setDeleteWorkerProd] = useState([]);


    useEffect(() => {
        setActivity();
        if (areasSelected && +areasSelected > 0) {
            getActividadesByArea();
        }
    }, [areasSelected]);

    useEffect(() => {
        setProductivityCOriginal(null);
        setDataProductivityOriginal([]);

        setDataProductivity([]);
        setWorkerSelected([]);
        setProductivityC(null);
        setDeleteWorkerProd([]);
        setLoadValidation(false);
    }, [areasSelected, shiftSelected, dateSelected, activity]);

    const getActividadesByArea = async () => {
        try {
            const result = await await ActivityService.getActividadesByArea(url, areasSelected, 1);
            if (result.status === CODES.SUCCESS_200) {
                if (result?.data?.length === 0) {
                    simpleAlerts('No se tiene información de actividades!');
                    return;
                }
                result.data?.forEach(element => {
                    element.id = Number(element.id_actividad)
                    element.value = Number(element.id_actividad)
                    element.label = element.desActividad
                })
            }
            setActivities(result.data);
        } catch (error) {
            console.log(`Error en getActividadesByArea: ${error}`);
        }
    }

    const loadProductivity = async () => {
        const confirmed = await confirmReloadProductivity();
        if (confirmed === false) {
            return;
        }
        if (!areasSelected || +areasSelected === 0) {
            simpleAlerts(validationMessages?.areas, 'warning');
            return;
        }
        if (!shiftSelected || +shiftSelected === 0) {
            simpleAlerts(validationMessages?.shift, 'warning');
            return;
        }
        if (!dateSelected || +dateSelected === '') {
            simpleAlerts(validationMessages?.date, 'warning');
            return;
        }
        if (!activity || +activity === 0) {
            simpleAlerts(validationMessages?.activity, 'warning');
            return;
        }
        getProductividadByAreaByTurnoByActividad();
    }

    const getProductividadByAreaByTurnoByActividad = async () => {
        try {
            setTitleLoader('Buscando productividad...');
            setOpenModalLoader(true);
            setWorkerSelected([]);
            const result = await ProductivityService.getProductividadByAreaByTurnoByActividad(url, areasSelected, shiftSelected, activity, dateSelected, dateSelected);
            if (result.status === CODES.SUCCESS_200) {
                if (result?.data?.length === 0) {
                    simpleAlerts('No hay información de productividad, se creara una NUEVA', "info");
                    const bodyTMP = {
                        "fecha": dateSelected,
                        "area_id": areasSelected,
                        "turno_id": shiftSelected,
                        "supervisor_id": id_personaLogin,
                        "actividad_id": activity,
                        "sumHorasEjecutadas": null,
                        "productividadEspXHra": null,
                        "produccionTotal": null,
                        "comentario": null,
                        "usuario_id": id_usuarioLogin,
                    }
                    setProductivityC(bodyTMP);
                    setDataProductivity([]);

                    setProductivityCOriginal(bodyTMP);
                    setDataProductivityOriginal([])
                } else {
                    let infoTmp = result?.data[0];
                    setDataProductivity(infoTmp?.detalle);
                    setDataProductivityOriginal(infoTmp?.detalle);
                    setWorkerSelected(infoTmp?.detalle);
                    delete infoTmp?.detalle
                    setProductivityC(infoTmp);
                    setProductivityCOriginal(infoTmp);
                }
                setDeleteWorkerProd([]);
                setLoadValidation(true);
            }
            return []
        } catch (error) {
            console.log(`Error en getProductividadByAreaByTurnoByActividad: ${error}`);
        } finally {
            setTitleLoader(null);
            setOpenModalLoader(false);
        }
    }

    const saveProductivity = async () => {
        if (!loadValidation) {
            simpleAlerts("Debe buscar si ya existe productividad!", "warning");
            return;
        }
        if (checkTimeOverlapById(dataProductivity)) {
            simpleAlerts(validationMessages.overLap);
            return
        }

        let body = {
            ...productivityC,
            'detalle': dataProductivity,
            'deleteProd': deleteWorkerProd
        }
        if (!body.produccionTotal || +body.produccionTotal === 0) {
            simpleAlerts("Debe ingresar una productividad!", "warning");
            return;
        }
        const fechaMinObj = new Date(`${fechaMin}T00:00:00`);
        const fechaMaxObj = new Date(`${fechaMax}T00:00:00`);
        const fechaBodyObj = new Date(`${body.fecha}T00:00:00`);
        if (fechaBodyObj.getTime() !== fechaMinObj.getTime() && fechaBodyObj.getTime() !== fechaMaxObj.getTime()) {
            simpleAlerts("La fecha de la cabecera no es permitida!")
            return
        }

        body.supervisor_id = (body.supervisor_id ? body.supervisor_id : id_personaLogin);
        body.usuario_id = id_usuarioLogin;

        body.fecha = dateSelected;
        body.area_id = areasSelected;
        body.turno_id = shiftSelected;
        body.actividad_id = activity;

        Swal.fire({
            title: (body?.id_productividadC ? '¿Seguro de actualizar la productividad?' : '¿Seguro de crear la productividad?'),
            text: (body?.id_productividadC ? 'Se actualizara los calculos de la productividad' : 'Se generaran los calculos de la productividad'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: colors.secondary.hex,
            cancelButtonColor: colors.primary.hex,
            confirmButtonText: (body?.id_productividadC ? 'Si, actualizar' : 'Si, crear'),
        }).then((result) => {
            if (result.isConfirmed) {
                setOpenModalLoader(true);
                setTitleLoader('Guardando productividad!');
                setTimeout(async () => {
                    try {
                        const result = await ProductivityService.saveProductividad(url, body);
                        if (result.status === CODES.CREATE_201) {
                            simpleAlerts(result?.data?.message?.cabecera?.message, "success");
                            simpleAlerts(result?.data?.message?.detalle, "success");
                            if (result?.data?.message?.delete)
                                simpleAlerts(result?.data?.message?.delete, "info");
                            if (result?.data?.message?.error)
                                simpleAlerts(result?.data?.message?.error, "success");
                            loadProductivity();
                        } else {
                            simpleAlerts((result?.response?.data?.message?.error || 'Hubo un Error'), "error");
                            if (result?.response?.data?.message?.error_detail)
                                simpleAlerts(result?.response?.data?.message?.error_detail, "error");

                            console.log(result?.response?.data)
                        }
                        return [];
                    } catch (error) {
                        console.log(`Error en saveProductivity: ${error}`);
                    } finally {
                        setOpenModalLoader(false);
                        setTitleLoader(null);
                    }
                });
            }
        })
    };

    const deleteWorkerProductivity = async () => {
        if (!workerSelected || workerSelected?.length <= 0) {
            simpleAlerts(validationMessages.worker);
            return;
        }
        Swal.fire({
            title: '¿Seguro de eliminar al trabajador?',
            text: 'Se actualizara los calculos de la productividad',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: colors.secondary.hex,
            cancelButtonColor: colors.primary.hex,
            confirmButtonText: 'Si, eliminar'
        }).then((result) => {
            if (result.isConfirmed) {
                let updDeleteWorkerProd = _.cloneDeep(deleteWorkerProd);
                workerSelected?.forEach(async (work) => {
                    if (work?.id_productividadD)
                        updDeleteWorkerProd?.push(work);
                });

                let updDataProductivity = _.cloneDeep(dataProductivity);
                workerSelected?.forEach(async (work) => {
                    updDataProductivity = updDataProductivity?.filter((e) => +e.persona_id !== +work?.persona_id)
                });

                if (+tipoProd === 1) {
                    setDataProductivity(prodTurno(updDataProductivity, productivityC));
                } else {
                    let newProductivity = prodPersona(updDataProductivity, productivityC);
                    setDataProductivity(newProductivity?.productividad);
                    setProductivityC(newProductivity?.cabecera);
                }
                setDeleteWorkerProd(updDeleteWorkerProd);
            }
        })
    }

    useEffect(() => {
        validationConfirm()
    }, [productivityC]);

    // * Validación, para no perder los cambios
    const validationConfirm = () => {
        let validationTMP = true;
        if (JSON.stringify(productivityCOriginal) !== JSON.stringify(productivityC) ||
            JSON.stringify(dataProductivityOriginal) !== JSON.stringify(dataProductivity)) {
            setValidationInfoChange(true);
        } else {
            setValidationInfoChange(false);
            validationTMP = false
        }
        return validationTMP
    }
    const confirmReloadProductivity = async () => {
        let resultSwal;
        if (validationConfirm()) {
            resultSwal = await Swal.fire({
                title: `Seguro de volver a cargar la productividad`,
                html: 'Se perderán los cambios realizados',
                showCancelButton: true,
                confirmButtonText: "Si",
                confirmButtonColor: "#CB4335",
                cancelButtonText: "No",
                cancelButtonColor: "#239B56",
            });
        }

        return resultSwal?.isConfirmed;
    };

    return (
        <div>
            <Grid container direction="row" justifyContent="center">
                <Grid item xs={7} md={4} display='flex' alignItems={'end'}>
                    <div style={{ width: '100%' }}>
                        <MainSelect
                            title={'Actividades'}
                            placeholder={'Selecionar actividad'}
                            options={activities}
                            value={activity}
                            onChange={(value, e) => { setActivity(value) }}
                        />
                    </div>
                    <IconButton aria-label="productividad" color="primary"
                        style={{ marginBottom: '0.2rem', border: `solid 0.02rem`, padding: '0.03rem', }}
                        onClick={loadProductivity}
                    >
                        <SearchIcon style={{ fontSize: '1.62rem' }} />
                    </IconButton>
                </Grid>
                <Grid item xs={4} md={2} ml={1}>
                    {Number(tipoProd) === 1 ?
                        <MainInput
                            title={'Producción'}
                            placeholder={'Ingrese Fecha Inicio'}
                            type={'number'}
                            propsInput={{ type: 'number', step: 0.0001, min: 0, }}
                            value={productivityC?.produccionTotal ?? 0}
                            onChange={(e) => { setProductivityC({ ...productivityC, produccionTotal: e.target.value }) }}
                        />
                        : <MainInputInfo
                            title={'Producción'}
                            value={productivityC?.produccionTotal}
                        />
                    }
                </Grid>
            </Grid>
            {loadValidation &&
                <Grid container direction="row" justifyContent="center" pt={1}>
                    <Grid item xs={11} md={6}>
                        <Box
                            sx={{
                                display: 'grid',
                                gridTemplateColumns: '1fr 1fr 1fr',
                                gap: '10px',
                                flexWrap: 'wrap',
                                justifyContent: 'center',
                            }}
                        >
                            <MainButton
                                onClick={() => processInformation(0, 'Agregar trabajador', 'PROD')}
                                typeStyle={'outlined'}
                                color={'primary'}
                                title={'Agregar'}
                            />
                            <MainButton
                                onClick={() => processInformation(1, 'Horario de trabajo', 'PROD')}
                                typeStyle={'outlined'}
                                color={'success'}
                                title={'Horario'}
                            />
                            <MainButton
                                onClick={deleteWorkerProductivity}
                                typeStyle={'outlined'}
                                color={'secondary'}
                                title={'Borrar'}
                            />
                        </Box>
                    </Grid>
                </Grid>
            }

            {loadValidation &&
                <Grid container direction="row" justifyContent="center" mt={1}>
                    <Grid item xs={11} md={6}>
                        <Box
                            sx={{
                                display: 'grid',
                                gridTemplateColumns: '1fr 1fr',
                                gap: '10px',
                                flexWrap: 'wrap',
                                justifyContent: 'center',
                            }}
                        >
                            <MainButton
                                onClick={loadProductivity}
                                color={'secondary'}
                                title={'Cancelar'}
                            />
                            <MainButton
                                onClick={saveProductivity}
                                color={'success'}
                                title={'Guardar'}
                            />
                        </Box>
                    </Grid>
                </Grid>
            }
            <Grid container direction="row" justifyContent="center" mt={1}>
                <Grid item xs={12} md={6}>
                    <BodyProductivity
                        productivityC={productivityC}
                        setProductivityC={setProductivityC}
                        dataProductivity={dataProductivity}
                        setDataProductivity={setDataProductivity}
                        tipoProd={tipoProd}
                        workerSelected={workerSelected}
                        setWorkerSelected={setWorkerSelected}
                    />
                </Grid>
            </Grid>

        </div>
    );
};

export default ProductivityMovil;