import React, { useState, useEffect } from 'react';
import { Box, } from '@mui/material';
import MainWithoutResult from '../../General/MainWithoutResult';
import { formatDate, nombreDia, nombreMes } from '../../../../utils/function';
import { colors } from '../../../../assets/colors';


const BodyPlanByWorker = (props) => {
  const { dateInit, primerDiaG, ultimoDiaG, shift, dataPlanning, editPlanning, type, processPlanningData, } = props;

  const dataBody = processPlanningData(dataPlanning, type);
  const [columns, setColumns] = useState(null);
  useEffect(() => {
    let columnsArmado = [
      { id: 'trabajador', label: 'Trabajador', width: 100, align: 'left', },
    ];
    for (let fecha = new Date(`${primerDiaG}T00:00:00`); fecha <= new Date(`${ultimoDiaG}T00:00:00`); fecha.setDate(fecha.getDate() + 1)) {
      let fechaString = formatDate(fecha, 'date');
      const diaSemana = nombreDia(fechaString, 's');
      const mes = nombreMes(fechaString, 's');
      const dia = fecha.getDate();
      columnsArmado?.push({ id: fecha?.getDay(), label: `${diaSemana} ${dia} (${mes})`, width: 80, align: 'center', });
    }
    setColumns(columnsArmado);
  }, [dateInit])

  return (
    <Box className='page-body-main'>
      <Box className='page-body-table table-responsive-md'>
        <table className="table table-hover table-striped table-bordered">
          <thead className='sticky-top' style={{ backgroundColor: colors.quaternary.hex, color: '#ffff' }}>
            <tr>
              {columns?.map((column) => (
                <th
                  scope="col"
                  key={column.id}
                  style={{
                    textAlign: column.align,
                    minWidth: column.width,
                  }}
                >
                  {column.label}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="table-group-divider">
            {dataBody?.map((worker, indexRow) => {
              return (
                <tr key={"row" + indexRow}>
                  {columns?.map((column, indexColumn) => {
                    const value = worker[column.id];
                    return (
                      <td key={'col' + indexColumn} align={column.align}>
                        {column.id === 'trabajador' ?
                          value :
                          <label
                            className='badge-planificacion'
                            style={{
                              backgroundColor: (value?.split("/")[2] || '#DC3545'),
                            }}
                            title={(value?.split("/")[5] || '')}
                            onClick={() => editPlanning((value ? value?.split("/")[6] : column.id), worker, shift?.find(e => Number(e.value) === Number(value?.split("/")[3])), value?.split("/")[0])}
                          >
                            {value ?
                              (value?.split("/")[1] + ' - ' + value?.split("/")[4])
                              : 'No planificado'}
                          </label>
                        }
                      </td>
                    );
                  })}
                </tr>
              );
            })}

            {(dataPlanning?.length === 0 || !dataPlanning) &&
              <MainWithoutResult />
            }
          </tbody>
        </table>
      </Box>
    </Box>
  );
}

export default BodyPlanByWorker;