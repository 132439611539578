import React, { } from 'react';
import { ButtonGroup, IconButton, Box } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { colors } from '../../../../../assets/colors';
import { CODES } from '../../../../../utils/responseCodes';
import { simpleAlerts } from '../../../../../utils/alerts';
import _ from 'lodash';
import Swal from 'sweetalert2';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AsistenciaPuestoService from '../../../../../services/AsistenciaPuesto';

const columns = [
  { id: 'id_asistenciaPuesto', label: '#', width: 50, align: 'left', },
  { id: 'puesto', label: 'Ubicacion', width: 150, align: 'left', },
  { id: 'ubicacion', label: 'Puesto', width: 150, align: 'left', },
  { id: 'actions', label: 'Acciones', width: 80, align: 'center', },
];

const BodyAsistenciaPuesto = (props) => {
  const { handleOpen, asistenciaPuesto, setAsistenciaPuesto, setInfoAsistenciaPuesto, setOpenModalLoader, setTitleSpinner } = props;

  const url = "'" + window.location.pathname + "'";

 
  const deleteAsistenciaPuesto = async (info) => {
    let updateAsistenciaPuesto = _.cloneDeep(asistenciaPuesto);
    updateAsistenciaPuesto = updateAsistenciaPuesto.filter(e => e.id_asistenciaPuesto !== info?.id_asistenciaPuesto);
  

    Swal.fire({
      title: '¿Seguro de eliminar Asistencia Puesto?',
      text: 'Se perdera informacion de Asistencia Puesto',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: colors.secondary.hex,
      cancelButtonColor: colors.primary.hex,
      confirmButtonText: 'Si, eliminar'
    }).then((result) => {
      if (result.isConfirmed) {
        setOpenModalLoader(true);
        setTitleSpinner('Eliminando...');
        setTimeout(async () => {
          try {
           const result = await AsistenciaPuestoService.deleteAsistenciaPuesto(url, info);
            if (result.status === CODES.DELETE_204) {
              simpleAlerts("Asistencia Puesto Eliminada", "success");
              setAsistenciaPuesto(updateAsistenciaPuesto);
            }
            return [];
          } catch (error) {
            console.log(`Error en deleteAsistenciaPuesto: ${error}`);
          } finally {
            setOpenModalLoader(false);
            setTitleSpinner(null);
          }
        });
      }
    })
  };

  return (
    <Box className='page-body-main'>
      <Box className='page-body-table table-responsive-md'>
        <table className="table table-hover table-striped table-bordered">
          <thead className='sticky-top' style={{ backgroundColor: colors.quaternary.hex, color: '#ffff' }}>
            <tr>
              {columns.map((column) => (
                <th
                  scope="col"
                  key={column.id}
                  style={{
                    textAlign: column.align,
                    width: column.width,
                    maxWidth: column.width
                  }}
                >
                  {column.label}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="table-group-divider">
            {asistenciaPuesto?.map((areaPuestos, indexRow) => {
              return (
                <tr key={"row" + indexRow}>
                  {columns.map((column, indexColumn) => {
                    const value = areaPuestos[column.id];
                    return (
                      <td key={'col' + indexColumn} align={column.align}>
                        {column.id === 'actions' ?
                            <ButtonGroup
                              disableElevation
                              variant="contained"
                              aria-label="Disabled elevation buttons"
                            >
                              <IconButton aria-label="delete" size="small" title='Editar'
                                onClick={() => { setInfoAsistenciaPuesto(areaPuestos); handleOpen() }}
                              >
                                <EditIcon fontSize='small' color={'primary'} />
                              </IconButton>
                              <IconButton aria-label="delete" size="small"
                                  onClick={() => { deleteAsistenciaPuesto(areaPuestos) }}
                                 
                              >
                              
                                <DeleteForeverIcon fontSize='small' color={'secondary'} />
                              </IconButton>
                            </ButtonGroup> :
                            value}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </Box>
    </Box >
  );
}

export default BodyAsistenciaPuesto;