import React, { useState, useEffect } from 'react';
import MainModal from '../../../components/General/MainModal';
import MainButton from '../../../components/General/MainButton';
import MassiveAssistance from '../../../components/Assistance/AddAssistance/MassiveAssistance';
import AuthorizationAssistance from '../../../components/Assistance/AddAssistance/AuthorizationAssistance';
import AssistanceService from '../../../../services/assistance';
import { getDateOL, minutesDiff } from '../../../../utils/function';
import { CODES } from '../../../../utils/responseCodes';
import { simpleAlerts } from '../../../../utils/alerts';

const AddAssistance = (props) => {
    const { open, handleClose, setOpenModalLoader, setTitleLoader, id_personaLogin, id_usuarioLogin, nameLogin,
        infoCompany, infoClient, infoUnit, infoCampus,
        areas, dataShift, dataAssistanceCondition, getAsistenciaCompleta, dateInit, dateEnd,
        view, setView, infoDelete, } = props;
    const url = "'" + window.location.pathname + "'";

    /** variables para asistencia masiva */
    const [shift, setShift] = useState(null);
    const [typeAssistance, setTypeAssistance] = useState(null);
    const [markIn, setMarkIn] = useState(null);
    const [markOut, setMarkOut] = useState(null);
    const [breakLunch, setBreakLunch] = useState(null);
    const [valueArea, setValueArea] = useState(null);
    const [dateInitMassive, setDateInitMassive] = useState(dateInit); //getDateOL());
    const [dateEndMassive, setDateEndMassive] = useState(dateEnd); //getDateOL());
    const [workersAssistance, setWorkersAssistance] = useState([]);
    const [areasOpt, setAreasOpt] = useState(null);

    useEffect(() => {
        let areaTmp = areas?.filter(e => Number(e.sede_id) === Number(infoCampus?.value));
        return setAreasOpt(areaTmp)
    }, [infoCampus])

    const saveMasiveAssistance_0 = async (e) => {
        e.preventDefault();
        if (new Date(`${dateEndMassive}T00:00:00`) < new Date(`${dateInitMassive}T00:00:00`)) {
            simpleAlerts('La fecha de inicio de be ser menor a la fecha final');
            return;
        }
        if (!workersAssistance || workersAssistance?.length === 0) {
            simpleAlerts('Debe seleccionar al menos 01 trabajdor!');
            return;
        }
        if (valueArea === 0 || !valueArea) {
            simpleAlerts('Debe seleccionar un área!');
            return;
        }
        if (shift === 0 || !shift) {
            simpleAlerts('Debe seleccionar un turno!');
            return;
        }
        if (typeAssistance === 0 || !typeAssistance) {
            simpleAlerts('Debe seleccionar un tipo de asistencia!');
            return;
        }
        let minutes = minutesDiff(dateEndMassive, dateInitMassive);
        let hours = minutes / 60;
        let days = hours / 24;
        if (workersAssistance?.length > 15 && days > 7) {
            simpleAlerts('El maximo de dias es 7, para una asistencia masiva!');
            return;
        }
        if (days > 30) {
            simpleAlerts('El maximo de dias es 30, para una asistencia masiva!');
            return;
        }

        let data = new FormData(e.currentTarget);
        data.append("turno", shift);
        data.append("area", valueArea);
        data.append("tipoasis", typeAssistance);
        data.append("supervisor", id_personaLogin); //Referencia a la tabla persona
        data.append("usuario", id_usuarioLogin);
        data.append("persona", '');
        let AssisTypeTmp = data.get('tipoasis');
        let indoCondTmp = dataAssistanceCondition?.find(e => +e.value === +AssisTypeTmp)
        let timeInTmp = data.get('ingreso');
        let timeOutTmp = data.get('ingreso');
        // console.log(data.get('inicio'))
        // console.log(data.get('fin'))
        // console.log(data.get('turno'))
        // console.log(data.get('area'))
        // console.log(data.get('tipoasis'))
        // console.log(data.get('ingreso'))
        // console.log(data.get('salida'))
        // console.log(data.get('refrigerio'))
        // console.log(data.get('persona'))
        // console.log(data.get('usuario'))

        if ((!timeInTmp || !timeOutTmp) && +indoCondTmp?.conMarca === 1) {
            simpleAlerts('Debe ingresar hora inicio y salida!');
            return;
        }
        try {
            setOpenModalLoader(true);
            setTitleLoader('Guardando asistencias ...');
            const savePromises = workersAssistance?.map(async (info) => {
                try {
                    data.set("persona", info.id_persona);
                    const result = await AssistanceService.saveMassiveAssistance(url, data);
                    if (result.status === CODES.SUCCESS_200) {
                        simpleAlerts(result.data.message, Number(result.data.status) === 1 ? "success" : "error");
                    } else {
                        simpleAlerts(result.response.data.message, "error");
                    }
                } catch (error) {
                    console.log(`Error en saveMassiveAssistance: ${error}`);
                }
            });

            await Promise.all([...savePromises]);
            setOpenModalLoader(false);
            setTitleLoader(null);
            getAsistenciaCompleta();
            cleanInfo();
            return [];
        } catch (error) {
            console.log(`Error en saveAssociateAreas: ${error}`);
        }
    }

    const saveMasiveAssistance = async (e) => {
        e.preventDefault();
        const now = getDateOL();
        if (!workersAssistance || workersAssistance?.length === 0) {
            simpleAlerts('Debe seleccionar al menos 01 trabajdor!');
            return;
        }
        if (valueArea === 0 || !valueArea) {
            simpleAlerts('Debe seleccionar un área!');
            return;
        }
        if (shift === 0 || !shift) {
            simpleAlerts('Debe seleccionar un turno!');
            return;
        }
        if (typeAssistance === 0 || !typeAssistance) {
            simpleAlerts('Debe seleccionar un tipo de asistencia!');
            return;
        }
        if (now < dateInitMassive || now < dateEndMassive) {
            simpleAlerts('Necesita permiso para asistencias futuras!');
            setView(2);
            return;
        }

        let minutes = minutesDiff(dateEndMassive, dateInitMassive);
        let hours = minutes / 60;
        let days = hours / 24;
        if (workersAssistance?.length > 15 && days > 7) {
            simpleAlerts('El maximo de dias es 7, para una asistencia masiva!');
            return;
        }

        let indoCondTmp = dataAssistanceCondition?.find(e => +e.value === +typeAssistance)
        if ((!markIn || !markOut) && +indoCondTmp?.conMarca === 1) {
            simpleAlerts('Debe ingresar hora inicio y salida!');
            return;
        }
        try {
            const body = {
                refrigerio: breakLunch,
                inicio: dateInitMassive,
                fin: dateEndMassive,
                ingreso: markIn,
                salida: markOut,
                turno: shift,
                area: valueArea,
                tipoasis: typeAssistance,
                supervisor: id_personaLogin,
                usuario: id_usuarioLogin,
                workers: workersAssistance
            }
            setOpenModalLoader(true);
            setTitleLoader('Guardando asistencias ...');
            const result = await AssistanceService.saveMassiveAssistanceBucle(url, body);
            if (result.status === CODES.SUCCESS_200) {
                simpleAlerts(result.data.message, "success");
            } else {
                simpleAlerts(result.response.data.message || "Hubo un error", "error");
            }
        } catch (error) {
            console.log(`Error en saveMassiveAssistanceBucle: ${error}`);
        } finally {
            setOpenModalLoader(false);
            setTitleLoader(null);
            getAsistenciaCompleta();
            cleanInfo();
        }
    }

    const cleanInfo = () => {
        setWorkersAssistance([]);
        setShift(null);
        setTypeAssistance(null);
        setMarkIn(null);
        setMarkOut(null);
        setBreakLunch(null);
        setValueArea(null);
        setDateInitMassive(getDateOL());
        setDateEndMassive(getDateOL());
        setView(1);
        handleClose();
    }

    return (
        <MainModal
            open={open}
            handleClose={cleanInfo}
            size={view === 1 ? "md" : "sm"}
            formSubmit={saveMasiveAssistance}
            title={view === 1 ? 'Asistencia masiva' : 'Listado de aprobadores'}
            subTitle={view === 1 ? 'Creación y edición de asistencia' : 'Aprobadores'}
            bodyModal={
                view === 1 ?
                    <MassiveAssistance
                        infoCampus={infoCampus}
                        areas={areas}
                        dataShift={dataShift}
                        dataAssistanceCondition={dataAssistanceCondition}
                        shift={shift}
                        setShift={setShift}
                        valueArea={valueArea}
                        setValueArea={setValueArea}
                        typeAssistance={typeAssistance}
                        setTypeAssistance={setTypeAssistance}
                        markIn={markIn}
                        setMarkIn={setMarkIn}
                        markOut={markOut}
                        setMarkOut={setMarkOut}
                        breakLunch={breakLunch}
                        setBreakLunch={setBreakLunch}
                        dateInitMassive={dateInitMassive}
                        setDateInitMassive={setDateInitMassive}
                        dateEndMassive={dateEndMassive}
                        setDateEndMassive={setDateEndMassive}
                        workersAssistance={workersAssistance}
                        setWorkersAssistance={setWorkersAssistance}
                        dateInit={dateInit}
                        dateEnd={dateEnd}
                        areasOpt={areasOpt}
                    />
                    : <AuthorizationAssistance //view:2 o 3
                        id_personaLogin={id_personaLogin}
                        id_usuarioLogin={id_usuarioLogin}
                        cleanInfo={cleanInfo}
                        dataAssistanceCondition={dataAssistanceCondition}
                        nameLogin={nameLogin}
                        setOpenModalLoader={setOpenModalLoader}
                        setTitleLoader={setTitleLoader}
                        areasOpt={areasOpt}
                        infoCompany={infoCompany}
                        infoClient={infoClient}
                        infoUnit={infoUnit}
                        infoCampus={infoCampus}
                        shift={shift}
                        valueArea={valueArea}
                        typeAssistance={typeAssistance}
                        markIn={markIn}
                        markOut={markOut}
                        breakLunch={breakLunch}
                        dateInitMassive={dateInitMassive}
                        dateEndMassive={dateEndMassive}
                        workersAssistance={workersAssistance}
                        view={view}
                        setView={setView}
                        infoDelete={infoDelete}
                    />
            }
            buttonActions={
                view === 1 &&
                <MainButton
                    title={'Guardar'}
                    typeStyle={'outlined'}
                    color={'primary'}
                    type={'submit'}
                />}
        />
    );
};

export default AddAssistance;