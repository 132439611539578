import React, { useEffect, useState } from 'react';
import { Box, Grid, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import MainButton from '../../../components/General/MainButton';
import MainSelect from '../../../components/General/MainSelect';
import RegionService from '../../../../services/region'
import { CODES } from '../../../../utils/responseCodes';
import { simpleAlerts } from '../../../../utils/alerts';
import SpinnerLoader from '../../../components/SpinnerLoad/SpinnerLoader';
import BodyRegionSede from '../../../components/Region/BodyRegionSede/BodyRegionSede';
import AddRegionSede from './AddRegionSede/AddRegionSede';

const RegionSede = ({ userToken }) => {
    const url = "'" + window.location.pathname + "'";
    const [titleSpinner, setTitleSpinner] = useState(null);
    const [openModalLoader, setOpenModalLoader] = useState(true);
    const [open, setOpen] = useState(false)
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [region, setRegion] = useState(null);
    const [dataRegion, setDataRegion] = useState(null);
    const [dataRegionSedes, setDataRegionSedes] = useState(null);

    useEffect(() => {
        init();
    }, [])

    useEffect(() => {
        if (dataRegion?.id_region)
            getRegionSedes();
    }, [dataRegion])

    const init = async () => {
        Promise.all([
            getRegion(),
        ]).then(() => setOpenModalLoader(false));
    }

  
    
    const getRegion = async () => {
        try {
            const result = await RegionService.getRegion();
            if (result.status === CODES.SUCCESS_200) {
                result.data?.forEach(element => {
                    element.value = element.id_region;
                    element.label = element.desRegion;
                })
                setRegion(result.data);
            } else {
                simpleAlerts('Hubo un error la relacion usuarios areas', 'error');
            }
            return [];
        } catch (error) {
            console.log(`Error en getUsuarioAreas: ${error}`);
        }
    }

    const getRegionSedes = async () => {
       
        setOpenModalLoader(true);
        setTitleSpinner('Cargando...');
        try {
            const result = await RegionService.getRelacionRegionSede(url, dataRegion?.id_region);
            if (result.status === CODES.SUCCESS_200)
                 setDataRegionSedes(result.data);
            else
                simpleAlerts('Hubo un error la relacion turno sede', 'error');
            return [];
        } catch (error) {
            console.log(`Error en getTurnoSedes: ${error}`);
        } finally {
            setOpenModalLoader(false);
            setTitleSpinner(null);
        }
    }

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AddRegionSede
                open={open}
                handleClose={handleClose}
                setOpenModalLoader={setOpenModalLoader}
                setTitleSpinner={setTitleSpinner}
                dataRegion={dataRegion}
                dataRegionSedes={dataRegionSedes}
                getRegionSedes={getRegionSedes}
            />
            <Box display={'flex'} justifyContent={'space-evenly'} alignItems={'center'}>
                <Box sx={{ width:{xs:'50%',md:'20%'} }}>
                    <MainSelect
                        title={'Regiones'}
                        options={region}
                        value={Number(dataRegion?.value)}
                        onChange={(value, event) => setDataRegion(region?.find(element => Number(element.value) === Number(value)))}
                    />
                </Box>
                <Box display={'flex'} justifyContent={'space-evenly'} alignItems={'end'}>
                    <IconButton aria-label="search-areas" color="primary" sx={{ border: 'solid 0.05rem' }} size='small'
                       onClick={(e) => { (dataRegion?.id_region ? getRegionSedes() : simpleAlerts('¡Debe Seleccionar una Región!')) }}
                      
                    >
                        <SearchIcon fontSize='1px' />
                    </IconButton>
                    &nbsp;
                    <MainButton
                        onClick={(e) => { (dataRegion?.id_region ? handleOpen() : simpleAlerts('¡Debe Seleccionar una Región!')) }}
                        title={'Asociar Sedes'}
                        typeStyle={'outlined'}
                        color={'primary'}
                    />
                </Box>
            </Box>

            <Grid container
                direction="row"
                justifyContent="center"
                alignItems="center"
                pt={2}
            >
                <Grid item xs={12} sm={10} paddingLeft={'0.2rem'} paddingRight={'0.2rem'}>
                    <BodyRegionSede
                        setTitleSpinner={setTitleSpinner}
                        setOpenModalLoader={setOpenModalLoader}
                        dataRegionSedes={dataRegionSedes}
                        setDataRegionSedes={setDataRegionSedes}
                    />
                </Grid>

            </Grid>

            <SpinnerLoader open={openModalLoader} title={titleSpinner} />
        </Box>
    );
};

export default RegionSede;