import axios from "axios";
import { Global } from '../utils/general';
import headerRequest from "../utils/headers"

const API_URL = Global.url;
//const SYS = Global.system;

class AuxiliaryService {
    getEmpresas() {
        let ruta = `${API_URL}/auxiliary/empresa`;
        return axios.get(ruta, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }

    getClientes(rucEmpresa) {
        let ruta = `${API_URL}/auxiliary/cliente/${rucEmpresa}`;
        return axios.get(ruta, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }

    getUnidades(rucEmpresa, rucCliente) {
        let ruta = `${API_URL}/auxiliary/unidad/${rucEmpresa}/${rucCliente}`;
        return axios.get(ruta, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }

    getSedes(rucEmpresa, rucCliente, codUnidad) {
        let ruta = `${API_URL}/auxiliary/sede/${rucEmpresa}/${rucCliente}/${codUnidad}`;
        return axios.get(ruta, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }

    getSCTRPeople(fecha, rucEmpresa, rucCliente, codUnidad, codSede) {
        let ruta = `${API_URL}/auxiliary/trama/sctr?fecha=${fecha}&rucEmpresa=${rucEmpresa}&rucCliente=${rucCliente}&pnCodUnidad=${codUnidad}&pnCodSede=${codSede}`;
        return axios.get(ruta, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }

    getVidaLeyPeople(fecha, rucEmpresa, rucCliente, codUnidad, codSede) {
        let ruta = `${API_URL}/auxiliary/trama/vida-ley?fecha=${fecha}&rucEmpresa=${rucEmpresa}&rucCliente=${rucCliente}&pnCodUnidad=${codUnidad}&pnCodSede=${codSede}`;
        return axios.get(ruta, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }

    generateSCTR(body) {
        let ruta = `${API_URL}/auxiliary/trama/sctr`;
        if (body.tipoTrama === 1) {
            return axios.post(ruta, body, {
                headers: headerRequest(),
            }).catch((error) => {
                return error;
            });
        } else {
            return axios.post(ruta, body, {
                headers: headerRequest(),
                responseType: 'blob'
            });
        }
    };

    generateVidaLey(body) {
        let ruta = `${API_URL}/auxiliary/trama/vida-ley`;
        return axios.post(ruta, body, {
            headers: headerRequest(),
            responseType: 'blob'
        });
    };
}

export default new AuxiliaryService();
