import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import MainWithoutResult from '../../../General/MainWithoutResult';
import StaffService from '../../../../../services/staff';
import SpinnerSmall from '../../../SpinnerLoad/SpinnerSmall';
import { sortDataListSimple, filterData } from '../../../../../utils/function';
import { CODES } from '../../../../../utils/responseCodes';
import { colors } from '../../../../../assets/colors';
import _ from 'lodash';

const columns = [
    { id: 'codSghh', label: 'Código', width: 50, align: 'center', },
    { id: 'trabajador', label: 'Nombres', width: 100, align: 'left', },
    { id: 'sel', label: 'Sel.', width: 20, align: 'center', },
];

const AddWorkers = (props) => {
    const { pageOri, dataAssistance, lstSedes, sedes, areas, areasSelected, shiftSelected, workerNew, setWorkerNew, dateSelected, } = props;

    const url = "'" + window.location.pathname + "'";
    const [openSmall, setOpenSmall] = useState(true);
    const [dataWorkers, setDataWorkers] = useState(null);
    const [dataWorkersComplete, setDataWorkersComplete] = useState(null);
    const [search, setSearch] = useState('');

    const [onlyArea, setOnlyArea] = useState(true);

    let resultSearch = filterData(dataWorkers, search, ['trabajador', 'nombres', 'codSghh']);
    /** inicio ordenamiento de columnas */
    const [sortConfigTable, setSortConfigTable] = useState({ key: null, direction: "ascending", });
    const handleSortTable = (key) => {
        let direction = "ascending";
        if (sortConfigTable && sortConfigTable.key === key && sortConfigTable.direction === "ascending") {
            direction = "descending";
        }
        setSortConfigTable({ key, direction });
    };
    const sortedData = sortDataListSimple(resultSearch, sortConfigTable); //sortDataListSimple(dataWorkers, sortConfigTable);
    /** fin ordenamiento de columnas */

    useEffect(() => {
        const infoArea = areas.find(e => +e.id_area === +areasSelected);
        const infoSede = sedes.find(e => +e.id_sede === +infoArea?.sede_id);
        if (pageOri === 'PROD') {
            if (+infoSede?.reloj === 1) {
                getPersonByAreaTurnoWithMarca();
            } else {
                getPersonBySedeWithAssistance();
            }
        } else {
            getPersonBySede();
        }
    }, [])

    const getPersonBySedeWithAssistance = async () => {
        try {
            setOpenSmall(true);
            const params = {
                url: url,
                pagination: 1,
                id_sede: lstSedes.join(', '),
                fecha: dateSelected
            };
            const result = await StaffService.getPersonBySedeWithAssistance(params);
            if (result.status === CODES.SUCCESS_200) {
                setDataWorkers(result.data);
                setDataWorkersComplete(result.data);
            }
            return []
        } catch (error) {
            console.log(`Error en getPersonBySedeWithAssistance: ${error}`);
        } finally {
            setOpenSmall(false);
        }
    }

    const getPersonByAreaTurnoWithMarca = async () => {
        try {
            setOpenSmall(true);
            const params = {
                url: url,
                id_area: areasSelected,
                id_turno: shiftSelected,
                fecha: dateSelected,
            };
            const result = await StaffService.getPersonByAreaTurnoWithMarca(params);
            if (result.status === CODES.SUCCESS_200) {
                setDataWorkers(result.data);
                setDataWorkersComplete(result.data);
            }
            return []
        } catch (error) {
            console.log(`Error en getPersonByAreaTurnoWithMarca: ${error}`);
        } finally {
            setOpenSmall(false);
        }
    }

    useEffect(() => {
        if (onlyArea && pageOri === 'PROD') {
            return setDataWorkers(dataWorkersComplete?.filter(e => +e?.id_area === +areasSelected))
        } else {
            return setDataWorkers(dataWorkersComplete)
        }
    }, [onlyArea, dataWorkersComplete])

    const getPersonBySede = async () => {
        try {
            setOpenSmall(true);
            const result = await StaffService.getPersonBySede(url, { pagination: 1 }, lstSedes);
            if (result.status === CODES.SUCCESS_200) {
                setDataWorkers(result.data);
            }
            return []
        } catch (error) {
            console.log(`Error en getPersonBySede: ${error}`);
        } finally {
            setOpenSmall(false);
        }
    }

    const handleChangeGeneral = (checked) => {
        let dataWorkersTmp = dataWorkers.filter(personaB => {
            return !dataAssistance.some(personaA => +personaA.persona_id === +personaB.id_persona);
        });

        let newArray;
        if (checked) {
            newArray = dataWorkersTmp;
            const confirmed = window.confirm('¿Desea seleccionar todos los trabajadores?');
            if (!confirmed) {
                newArray = [];
            }
        } else {
            newArray = [];
        }
        return setWorkerNew(newArray);
    }

    const handleChangeCheck = (checked, idx, info) => {
        let newArray = _.cloneDeep(workerNew);
        if (!dataAssistance?.find(e => +e.persona_id === +info.id_persona)) {
            if (checked) {
                newArray.push(info);
            } else {
                newArray = newArray.filter((e) => +e.id_persona !== +info.id_persona);
            }
        }
        return setWorkerNew(newArray);
    }

    return (
        <div>
            <SpinnerSmall open={openSmall} />
            <fieldset className='fieldset'>
                <legend className='title-legend' style={{ fontSize: '15px', letterSpacing: '0.03rem', marginBottom: "5px", fontStyle: 'italic', }}>
                    <div className='d-flex justify-content-center align-items-end'>
                        <input
                            type='text'
                            placeholder='Buscar trabajador'
                            style={{
                                backgroundColor: 'transparent',
                                border: `solid 1px ${colors.primary.hex}`,
                                borderRadius: 10,
                                paddingLeft: 10,
                                marginRight: 25,
                                fontStyle: 'italic',
                                width: '100%',
                                float: 'right',
                            }}
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                        />
                        <label style={{ fontSize: 'small', textAlign: 'center', fontWeight: 'bolder', }}>
                            Área<input type="checkbox" id="cbox1" value="first_checkbox" checked={onlyArea} onChange={() => setOnlyArea(!onlyArea)} />
                        </label>
                    </div>
                </legend>
                <Box className='page-body-table small page-body-table__modal table-responsive-md'>
                    <table className="table table-hover table-striped table-bordered table-sm" style={{ marginTop: '0px !important' }}>
                        <thead className='sticky-top table-warning'>
                            <tr>
                                {columns.map((column) => (
                                    <th
                                        scope="col"
                                        key={column.id}
                                        style={{
                                            textAlign: column.align,
                                            width: column.width,
                                            maxWidth: column.width,
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => handleSortTable(column.id)}
                                    >
                                        {column.id === 'sel' ?
                                            <label className={`checkbox-title`}>
                                                <input type="checkbox" className='blue' name="check" id={`checkbox-rect-header` + column.label}
                                                    checked={(workerNew?.length === dataWorkers?.length && workerNew?.length > 0) ? true :
                                                        workerNew?.length + dataWorkers?.filter(personaB => {
                                                            return dataAssistance.some(personaA => +personaA.persona_id === +personaB.id_persona);
                                                        })?.length === dataWorkers?.length ? true : false
                                                    }
                                                    onChange={(e) => handleChangeGeneral(e.target.checked)}
                                                    title={`${(workerNew?.length === dataWorkers?.length && workerNew?.length > 0) ? 'Retirara' : 'Seleccionara'} a todos los trabajadores`}
                                                />
                                                <span className="indicator"></span>
                                            </label>
                                            : column.label}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody className="table-group-divider">
                            {sortedData?.map((person, indexRow) => {
                                return (
                                    <tr key={"row" + indexRow}>
                                        {columns.map((column, indexColumn) => {
                                            const value = person[column.id];
                                            return (
                                                <td key={'col' + indexColumn} align={column.align}>
                                                    {column.id === 'sel' ?
                                                        <div className="checkbox-custom"
                                                            style={{ display: 'flex', justifyContent: 'center' }}
                                                        >
                                                            <input type="checkbox"
                                                                name="check"
                                                                id={`checkbox-rect` + person.id_persona}
                                                                checked={
                                                                    workerNew?.find(e => +e.id_persona === +person.id_persona) ?
                                                                        true
                                                                        : dataAssistance?.find(e => +e.persona_id === +person.id_persona) ?
                                                                            true
                                                                            : false}
                                                                onChange={(e) => handleChangeCheck(e.target.checked, indexRow, person)}
                                                            //disabled={dataAssistance?.find(e => +e.persona_id === +person.id_persona) ? true : false}
                                                            />
                                                            <label htmlFor={`checkbox-rect` + person.id_persona}></label>
                                                        </div>
                                                        : value}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                );
                            })}
                            {(dataWorkers?.length === 0 || !dataWorkers) &&
                                <MainWithoutResult />
                            }
                        </tbody>
                    </table>
                </Box>
            </fieldset>
        </div>
    );
};

export default AddWorkers;