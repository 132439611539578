import React, { useState, } from 'react';

import UserService from '../../../../../services/user';
import MainModal from '../../../../components/General/MainModal';
import MainButton from '../../../../components/General/MainButton';

import { CODES } from '../../../../../utils/responseCodes';
import { simpleAlerts } from '../../../../../utils/alerts';
import AssociateAreas from '../../../../components/Users/AddUserAreas/AssociateAreas';

const AddUserAreas = (props) => {
    const { open, handleClose, setOpenModalLoader, setTitleSpinner, dataUserAreas, dataUser, getUsuarioAreas, } = props;
    const url = "'" + window.location.pathname + "'";

    const [userAreasDelete, setUserAreasDelete] = useState([]);
    const [userAreasSave, setUserAreasSave] = useState([]);
    const [userAreasCopy, setUserAreasCopy] = useState([]);

    const saveAssociateAreas = async (e) => {
        e.preventDefault();
        const body = {
            dataSave: userAreasSave,
            dataDelete: userAreasDelete,
        }

        setOpenModalLoader(true);
        setTitleSpinner('Guardando asociaciones ...');
        try {
            const result = await UserService.saveUsuarioAreasGeneral(url, body);
            if (result.status === CODES.SUCCESS_200) {
                simpleAlerts(result?.data?.save || "Guradado exitoso!", "success");
                if (result?.data?.delete) {
                    simpleAlerts(result?.data?.delete || "Retiro exitoso!", "info");
                }
            }
            getUsuarioAreas();
            closeModal();
        } catch (error) {
            console.log(`Error en saveUsuarioAreasGeneral: ${error}`);
        }
    };

    const closeModal = () => {
        setUserAreasDelete([]);
        setUserAreasCopy([]);
        setUserAreasSave([]);
        return handleClose();
    }

    return (
        <>
            <MainModal
                size={'lg'}
                open={open}
                handleClose={closeModal}
                formSubmit={saveAssociateAreas}
                title={'Asociar Areas'}
                subTitle={'Asociar nuevas areas'}
                bodyModal={
                    <AssociateAreas
                        dataUserAreas={dataUserAreas}
                        userAreasCopy={userAreasCopy}
                        setUserAreasCopy={setUserAreasCopy}
                        userAreasDelete={userAreasDelete}
                        setUserAreasDelete={setUserAreasDelete}
                        userAreasSave={userAreasSave}
                        setUserAreasSave={setUserAreasSave}
                        dataUser={dataUser}
                    />
                }
                buttonActions={[
                    <MainButton
                        key='btn-modal-user-save1'
                        title={'Guardar'}
                        typeStyle={'outlined'}
                        color={'primary'}
                        type={'submit'}
                    />
                ]}
            />
        </>
    );
};

export default AddUserAreas;