import React, { useEffect, useState } from 'react';
import MainModal from '../../../../components/General/MainModal';
import MainButton from '../../../../components/General/MainButton';
import { CODES } from '../../../../../utils/responseCodes';
import { simpleAlerts } from '../../../../../utils/alerts';
import RegionService from '../../../../../services/region';
import InfoRegion from '../../../../components/Maintenance/Regions/AddRegion/InfoRegion';


const AddRegions = (props) => {
    const { open, handleClose, id_usuarioLogin, setOpenModalLoader, setTitleSpinner,
      typeEdition,infoRegion,setInfoRegion,getRegion } = props;

    const url = "'" + window.location.pathname + "'";
    const [system, setSystem] = useState('ASISPROD');


    const cleanInfo = () => {
        return setInfoRegion(null)
          
    };

    useEffect(() => {
        if (typeEdition === 1) { //crear perfil
           // setInfoProfile(newProfile);
        }
    }, [])

  const saveRegion = async () => {
        if (!infoRegion?.desRegion?.trim() || infoRegion?.desRegion?.trim() === '') {
            simpleAlerts('Debe ingresar nombre de Región', 'warning');
            return;
        }
        saveGeneral();
    }

    const saveGeneral = async () => {
        setOpenModalLoader(true);
        setTitleSpinner('Guardando ...');
        let data = {
            ...infoRegion,
            id_region: infoRegion?.id_region,
            usuarioMod: id_usuarioLogin,
        };
        try {
            const result = await RegionService.saveRegion(url, data);
            if (result.status === CODES.CREATE_201) {
                simpleAlerts(result.data.message, 'success');
                getRegion();
                handleClose();
            } else {
                simpleAlerts(result?.response?.data?.message, 'error');
                setOpenModalLoader(false);
                setTitleSpinner(null);
                return;
            }
        } catch (err) {
            console.log('Error en saveProfile', err);
        }
       
        setOpenModalLoader(false);

   
    }



    return (
        <>
            <MainModal
                open={open}
                handleClose={() => { handleClose(); cleanInfo() }}
                centrado={'centrado'}
                title={'Mantenimiento de Regiones'}
                subTitle={'Creación y edición de Regiones'}
                bodyModal={
                    <>
                       
                        <InfoRegion
                            typeEdition={typeEdition}
                            system={system}
                            infoRegion={infoRegion}
                            setInfoRegion={setInfoRegion}
                            
                        />
                    </>
                }
                buttonActions={[
                    <MainButton
                        key='btn-modal-user-save1'
                        title={'Guardar'}
                        typeStyle={'outlined'}
                        color={'primary'}
                        onClick={saveRegion}
                    />
                ]}
            />
        </>
    );
};

export default AddRegions;