import React, { useEffect, useState } from 'react';
import { Box, Grid, } from '@mui/material';
import BodyUserAreas from '../../../components/Users/BodyUserAreas/BodyUserAreas';
import AddUserAreas from './AddUserAreas/AddUserAreas';
import MainButton from '../../../components/General/MainButton';
import MainSelect from '../../../components/General/MainSelect';
import UserService from '../../../../services/user';
import { CODES } from '../../../../utils/responseCodes';
import { simpleAlerts } from '../../../../utils/alerts';
import SpinnerLoader from '../../../components/SpinnerLoad/SpinnerLoader';

const UserAreas = ({ userToken }) => {
    const url = "'" + window.location.pathname + "'";
    const [titleSpinner, setTitleSpinner] = useState(null);
    const [openModalLoader, setOpenModalLoader] = useState(true);
    const [open, setOpen] = useState(false)
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [users, setUsers] = useState(null);
    const [dataUser, setDataUser] = useState(null);
    const [dataUserAreas, setDataUserAreas] = useState(null);

    useEffect(() => {
        init();
    }, [])

    useEffect(() => {
        if (dataUser?.id_usuario)
            getUsuarioAreas();
    }, [dataUser])

    const init = async () => {
        Promise.all([
            getUsuariosActivos(),
        ]).then(() => setOpenModalLoader(false));
    }

    const getUsuariosActivos = async () => {
        await UserService.getUsuariosActivos(url).then(result => {
            if (result.status === CODES.SUCCESS_200) {
                result.data?.forEach(element => {
                    element.value = element.id_usuario;
                    element.label = element.usuario + '    /    ' + element.nombreCompleto;
                })
                setUsers(result.data);
            } else {
                simpleAlerts('Hubo un error en perfiles', 'error');
            }
        }).catch(err => {
            console.log('Error en getPerfiles', err)
        })
    }

    const getUsuarioAreas = async () => {
        setOpenModalLoader(true);
        setTitleSpinner('Obteniendo areas...');
        try {
            const result = await UserService.getUsuarioAreas(url, dataUser?.id_usuario);
            if (result.status === CODES.SUCCESS_200) {
                setDataUserAreas(result.data.areas);
            } else {
                simpleAlerts('Hubo un error la relacion usuarios areas', 'error');
            }
            return [];
        } catch (error) {
            console.log(`Error en getUsuarioAreas: ${error}`);
        } finally {
            setOpenModalLoader(false);
            setTitleSpinner(null);
        }
    }

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AddUserAreas
                open={open}
                handleClose={handleClose}
                setOpenModalLoader={setOpenModalLoader}
                setTitleSpinner={setTitleSpinner}

                dataUserAreas={dataUserAreas}

                dataUser={dataUser}
                getUsuarioAreas={getUsuarioAreas}
            />
            <Grid container justifyContent={'center'} alignItems={'center'} >
                <Grid item xs={10} md={4}>
                    <MainSelect
                        title={'Usuarios'}
                        options={users}
                        value={Number(dataUser?.value)}
                        name={'usuario_id'}
                        onChange={(value, event) => setDataUser(users?.find(element => element.value === Number(value)))}
                    />
                </Grid>
                <Grid item xs={12} sm={10} md={6} sx={{ paddingTop: { xs: "0.8rem" } }}
                    style={{
                        display: 'flex',
                        justifyContent: 'center',

                    }} >
                    <MainButton

                        onClick={handleOpen}
                        title={'Asociar Áreas'}
                        typeStyle={'outlined'}
                        color={'primary'}
                    />
                </Grid>
            </Grid>

            <Grid container
                direction="row"
                justifyContent="center"
                alignItems="center"
                pt={2}
            >
                <Grid item xs={12} md={10} paddingLeft={'0.2rem'} paddingRight={'0.2rem'}>
                    <BodyUserAreas
                        setTitleSpinner={setTitleSpinner}
                        setOpenModalLoader={setOpenModalLoader}

                        dataUserAreas={dataUserAreas}
                        setDataUserAreas={setDataUserAreas}
                    />
                </Grid>

            </Grid>

            <SpinnerLoader open={openModalLoader} title={titleSpinner} />
        </Box>
    );
};

export default UserAreas;