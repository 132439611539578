import React, { useEffect, useState, } from 'react';
import AssistanceMovil from './AssistanceMovil';
import ProductivityMovil from '../Productivity/ProductivityMovil';
import SpinnerLoader from '../../components/SpinnerLoad/SpinnerLoader';
import GeneralService from '../../../services/general';
import AssistanceService from '../../../services/assistance';
import ShiftService from '../../../services/shift';
import UserService from '../../../services/user';
import { simpleAlerts } from '../../../utils/alerts';
import { CODES } from '../../../utils/responseCodes';
import { getDateOL, } from '../../../utils/function';
import { Box, Grid, Divider, Tabs, Tab, } from "@mui/material";
import MainInput from '../../components/General/MainInput';
import MainSelect from '../../components/General/MainSelect';
import AddAssistance from './AddAssistanceMovil/AddAssistance';

const validationMessages = {
    shift: 'Debe seleccionar un turno!',
    areas: 'Debe seleccionar un área!',
    date: 'Debe escribir una fecha!',
    worker: 'Debe seleccionar algún trabajador!',
    overLap: 'Hay trabajadores que tienen horas incorrectas!',
    activity: 'Debe seleccionar una actividad!',
};

const AssistanceGeneral = ({ setValidationInfoChange, userToken, setPageTitle }) => {
    const url = "'" + window.location.pathname + "'";
    const id_usuarioLogin = userToken.user.id_usuario;
    const id_personaLogin = userToken.user.persona_id;
    const [openModalLoader, setOpenModalLoader] = useState(true);
    const [titleLoader, setTitleLoader] = useState('Carga inicial ...');

    const fechaMin = getDateOL('', 'short', -1);
    const fechaMax = getDateOL('', 'short');
    const [sedes, setSedes] = useState(null);
    const [lstSedes, setLstSedes] = useState([]);
    const [areasSelected, setAreasSelected] = useState([]);
    const [shiftSelected, setShiftSelected] = useState([]);
    const [hourIn, setHourIn] = useState([]);
    const [hourOut, setHourOut] = useState([]);
    const [dateSelected, setDateSelected] = useState(getDateOL('', 'short'));
    const [areas, setAreas] = useState([]);
    const [dataShift, setDataShift] = useState(null);
    const [dataAssistance, setDataAssistance] = useState(null);
    const [workerSelected, setWorkerSelected] = useState([]);
    const [assistanceCondition, setAssistanceCondition] = useState([]);

    const [typeModal, setTypeModal] = useState(null);
    const [titleModal, setTitleModal] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleOpenModal = () => setIsModalOpen(true);
    const handleCloseModal = () => setIsModalOpen(false);
    const [view, setView] = useState(0);
    const handleChangeView = (event, newValue) => setView(newValue);
    const [pageOri, setPageOri] = useState(null);

    useEffect(() => {
        setPageTitle('Asistencia movil')
        init();
    }, []);


    useEffect(() => {
        setShiftSelected(null);
        if (areasSelected || areasSelected?.length > 0) {
            getTurnoByArea();
        }
    }, [areasSelected]);

    const init = async () => {
        Promise.all([
            getAgrupamientoByUsuario(),
            getAsistenciaCondicion()
        ]).then(() => { setOpenModalLoader(false); setTitleLoader(null); });
    }

    const getAsistenciaCondicion = async () => {
        try {
            const result = await GeneralService.getAsistenciaCondicion();
            if (result.status === CODES.SUCCESS_200) {
                result.data?.forEach(element => {
                    element.value = element.id_asisCond
                    element.label = element.desAsistencia
                })
                setAssistanceCondition(result.data.filter(e => +e.conMarca === 0));
            } else {
                simpleAlerts('No hay información de tipo de asistencia', 'info');
            }
            return []
        } catch (error) {
            console.log(`Error en getAgrupamientoByUsuario: ${error}`);
        }
    }

    const getAgrupamientoByUsuario = async () => {
        try {
            const result = await UserService.getAgrupamientoByUsuario(url, id_usuarioLogin);
            if (result.status === CODES.SUCCESS_200) {
                if (result.data.areas.length === 0)
                    simpleAlerts('No tiene areas asignadas', "warning");
                let sedes = []
                result?.data?.areas?.forEach(element => {
                    element.value = Number(element.id_area);
                    element.label = element.desArea;
                    if(sedes.findIndex(e => +e === +element.sede_id) === -1){
                        sedes.push(element.sede_id)
                    }
                })
                setSedes(result.data.sedes);
                setAreas(result.data.areas);
                setLstSedes(sedes);
                // getTurnoBySedeLista(sedes); //cambio de validación por turnos x area
            }
            return []
        } catch (error) {
            console.log(`Error en getAgrupamientoByUsuario: ${error}`);
        }
    }

    const getTurnoByArea = async () => {
        try {
            setOpenModalLoader(true);
            setTitleLoader('Listando turnos ...');
            const params = {
                url: url,
                id_area: areasSelected
            };
            setDataShift(null);
            const result = await ShiftService.getTurnoByArea(params);
            if (result.status === CODES.SUCCESS_200) {
                if (result.data?.length > 0) {
                    result.data?.forEach(element => {
                        element.value = element.id_turno
                        element.label = element.desTurno
                    })
                    setDataShift(result.data);
                } else {
                    simpleAlerts('No hay turnos disponibles!', 'warning');
                }
            }
        } catch (error) {
            console.log(`Error en getTurnoByArea: ${error}`);
        }finally{
            setOpenModalLoader(false);
            setTitleLoader(null);
        }
    }

    const getAsistenciaSimple = async () => {
        setWorkerSelected([]);
        setDataAssistance(null);
        setOpenModalLoader(true);
        setTitleLoader('Listando asistencias...');
        try {
            const result = await AssistanceService.getAsistenciaSimple(url, areasSelected, shiftSelected, dateSelected);
            if (result?.status === CODES.SUCCESS_200) {
                if (result.data?.length === 0) {
                    simpleAlerts('No hay información disponible!!', 'warning');
                }
                setDataAssistance(result.data);
            }
        } catch (error) {
            console.log(`Error en getAsistenciaSimple: ${error}`);
        } finally {
            setOpenModalLoader(false);
            setTitleLoader(null);
        }
    };

    const processInformation = async (type, title, page) => {
        if (!shiftSelected || +shiftSelected <= 0) {
            simpleAlerts(validationMessages.shift);
            return;
        }
        if (!areasSelected || +areasSelected <= 0) {
            simpleAlerts(validationMessages.areas);
            return;
        }
        if (!dateSelected || dateSelected === '') {
            simpleAlerts(validationMessages.date);
            return;
        }
        if (page === 'ASIS' &&
            (!workerSelected || workerSelected?.length <= 0) &&
            (type === 1 || type === 2 || type === 3)) {
            simpleAlerts(validationMessages.worker);
            return;
        }
        if (page === 'PROD' &&
            (!workerSelected || workerSelected?.length <= 0) &&
            (type === 1)) {
            simpleAlerts(validationMessages.worker);
            return;
        }

        setHourIn(dataShift?.find((e) => +e.id_turno === +shiftSelected)?.ingreso?.substr(0, 5))
        setHourOut(dataShift?.find((e) => +e.id_turno === +shiftSelected)?.salida?.substr(0, 5))
        handleOpenModal();
        setTypeModal(type);
        setTitleModal(title);
        setPageOri(page);
    };

    /*********************************************/
    const [workStation, setWorkStation] = useState(null);

    useEffect(() => {
        if (areasSelected && +areasSelected > 0) {
            getAreaAsistenciaPuesto();
        }
    }, [areasSelected]);

    const getAreaAsistenciaPuesto = async () => {
        try {
            const result = await GeneralService.getAreaAsistenciaPuesto(areasSelected);
            if (result.status === CODES.SUCCESS_200) {
                result.data?.forEach(element => {
                    element.value = element.id_asistenciaPuesto
                    element.label = element.ubicacion + ' - ' + element.puesto
                })
                setWorkStation(result.data);
            } else {
                simpleAlerts('No hay información de tipo de asistencia', 'info');
            }
            return []
        } catch (error) {
            console.log(`Error en getAgrupamientoByUsuario: ${error}`);
        }
    }

    /*********************************************/
    const sede = sedes?.find((s) => +s.value === +areas?.find(e => +e.value === +areasSelected)?.sede_id);
    const tipoProd = sede?.tipoProd;
    const [productivityC, setProductivityC] = useState([]);
    const [dataProductivity, setDataProductivity] = useState([]);

    return (
        <div>
            <SpinnerLoader open={openModalLoader} title={titleLoader} />
            <AddAssistance
                setOpenModalLoader={setOpenModalLoader}
                setTitleLoader={setTitleLoader}
                isModalOpen={isModalOpen}
                handleCloseModal={handleCloseModal}
                typeModal={typeModal}
                titleModal={titleModal}
                pageOri={pageOri}

                id_usuarioLogin={id_usuarioLogin}
                id_personaLogin={id_personaLogin}

                workStation={workStation}
                assistanceCondition={assistanceCondition}
                dataAssistance={dataAssistance}

                sedes={sedes}
                areas={areas}
                dateSelected={dateSelected}
                areasSelected={areasSelected}
                shiftSelected={shiftSelected}
                hourIn={hourIn}
                setHourIn={setHourIn}
                hourOut={hourOut}
                setHourOut={setHourOut}
                workerSelected={workerSelected}
                setWorkerSelected={setWorkerSelected}

                getAsistenciaSimple={getAsistenciaSimple}

                lstSedes={lstSedes}

                dataShift={dataShift}
                sede={sede} 
                tipoProd={tipoProd}
                productivityC={productivityC} 
                setProductivityC={setProductivityC}
                dataProductivity={dataProductivity}
                setDataProductivity={setDataProductivity}
            />

            <Box sx={{ width: '100%', marginTop: '-0.8rem', marginBottom: '0.5rem' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={view} onChange={handleChangeView} aria-label="basic tabs example" centered>
                        <Tab label="Asistencia" sx={{ padding: '0px 5px', fontSize: '11px', }} />
                        <Tab label="Producción" sx={{ padding: '0px 5px', fontSize: '11px', }} />
                    </Tabs>
                </Box>
            </Box>
            <Grid container direction="row" justifyContent="center" alignItems="center" p={1}>
                <Grid item xs={11} md={2}>
                    <MainSelect
                        title={'Área'}
                        options={areas}
                        placeholder={'Selecionar Área'}
                        value={areasSelected}
                        onChange={(value, e) => { setAreasSelected(value) }}
                    />
                </Grid>
                <Grid item xs={7} md={2}>
                    <MainSelect
                        title={'Turno'}
                        options={dataShift}
                        placeholder={'Selecionar Truno'}
                        value={shiftSelected}
                        onChange={(value, e) => { setShiftSelected(value) }}
                    />
                </Grid>
                <Grid item xs={4} md={2}>
                    <MainInput
                        title={'Fecha Inicio'}
                        placeholder={'Ingrese Fecha Inicio'}
                        type={'date'}
                        value={dateSelected}
                        onChange={(e) => setDateSelected(e.target.value)}
                        propsInput={{
                            min: fechaMin,
                            max: fechaMax,
                        }}
                    />
                </Grid>

            </Grid>

            <Divider className="divider" sx={{ margin: '0 0.8rem 0.7rem 0.8rem' }} />

            {view === 0 ?
                <AssistanceMovil
                    userToken={userToken}
                    setOpenModalLoader={setOpenModalLoader}
                    setTitleLoader={setTitleLoader}
                    getAsistenciaSimple={getAsistenciaSimple}

                    processInformation={processInformation}
                    validationMessages={validationMessages}
                    areasSelected={areasSelected}
                    shiftSelected={shiftSelected}

                    dateSelected={dateSelected}
                    dataAssistance={dataAssistance}

                    workerSelected={workerSelected}
                    setWorkerSelected={setWorkerSelected}
                />
                : <ProductivityMovil
                    setValidationInfoChange={setValidationInfoChange}
                    userToken={userToken}
                    setOpenModalLoader={setOpenModalLoader}
                    setTitleLoader={setTitleLoader}
                    validationMessages={validationMessages}

                    fechaMin={fechaMin}
                    fechaMax={fechaMax}
                    areasSelected={areasSelected}
                    shiftSelected={shiftSelected}
                    dateSelected={dateSelected}
                    processInformation={processInformation}
                    tipoProd={tipoProd}

                    productivityC={productivityC}
                    setProductivityC={setProductivityC}
                    dataProductivity={dataProductivity}
                    setDataProductivity={setDataProductivity}

                    workerSelected={workerSelected}
                    setWorkerSelected={setWorkerSelected}
                />
            }
        </div>
    );
};

export default AssistanceGeneral;