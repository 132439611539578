import React, { useEffect, useState } from 'react';
import { Box, Grid, } from '@mui/material';
import BodyProfiles from '../../../components/Maintenance/Profiles/BodyProfiles/BodyProfiles';
import AddProfile from './AddProfile/AddProfile';
import MainButton from '../../../components/General/MainButton';
import ProfileService from '../../../../services/profile';
import GeneralService from '../../../../services/general';
import { CODES } from '../../../../utils/responseCodes';
import { simpleAlerts } from '../../../../utils/alerts';
import SpinnerLoader from '../../../components/SpinnerLoad/SpinnerLoader';


const Profiles = ({ userToken }) => {
    const id_usuarioLogin = userToken.user.id_usuario;
    const [openModalLoader, setOpenModalLoader] = useState(true);
    const [titleSpinner, setTitleSpinner] = useState(null);
    const [open, setOpen] = useState(false)
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [states, setStates] = useState(null);
    const [profiles, setProfiles] = useState(null);
    const [screens, setScreens] = useState(null);
    const [typeEdition, setTypeEdition] = useState(null); //1 = crear, 2 = editar
    const [infoProfile, setInfoProfile] = useState(null);

    useEffect(() => {
        init();
    }, [])

    const init = async () => {
        Promise.all([
            getEstados(),
            getPerfiles(),
            getPantallas(),
        ]).then(() => setOpenModalLoader(false));
    }

    const getEstados = async () => {
        try {
            const result = await GeneralService.getEstados();
            if (result.status === CODES.SUCCESS_200) {
                result.data?.forEach(element => {
                    element.id = Number(element.corr3)
                    element.value = Number(element.corr3)
                    element.label = element.desc1?.trim()
                })
                setStates(result.data);
            }
            return []
        } catch (error) {
            console.log(`Error en getEstados: ${error}`);
        }
    }

    const getPerfiles = async () => {
        try {
            setOpenModalLoader(true);
            setTitleSpinner('Buscando perfiles ...');
            const result = await ProfileService.getPerfiles();
            if (result.status === CODES.SUCCESS_200) {
                setProfiles(result.data);
            } else {
                simpleAlerts('Hubo un error en perfiles', 'error');
            }
            return []
        } catch (error) {
            console.log(`Error en getPerfiles: ${error}`);
        } finally {
            setOpenModalLoader(false);
            setTitleSpinner(null);
        }

    }

    const getPantallas = async () => {
        await GeneralService.getPantallas()
            .then(result => {
                if (result.status === CODES.SUCCESS_200) {
                    setScreens(result.data);
                    setOpenModalLoader(false);
                } else {
                    simpleAlerts('Hubo un error en las pantallas', 'error');
                }
            })
            .catch(err => {
                console.log('Error en getPantallas', err)
            })

    }

    const createProfile = () => {
        setTypeEdition(1)
        handleOpen();
    };

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AddProfile
                open={open}
                handleClose={handleClose}
                id_usuarioLogin={id_usuarioLogin}
                setOpenModalLoader={setOpenModalLoader}
                setTitleSpinner={setTitleSpinner}

                screens={screens}
                typeEdition={typeEdition}
                setTypeEdition={setTypeEdition}

                infoProfile={infoProfile}
                setInfoProfile={setInfoProfile}

                getPerfiles={getPerfiles}
            />

            <Grid container
                direction="row"
                justifyContent="center"
                alignItems="center"
            >
                <Grid item
                    xs={10}
                    display={'flex'}
                    justifyContent={'center'}
                    m={1}
                >
                    <Box width={'500px'}>
                        <MainButton
                            onClick={createProfile}
                            title={'Crear Perfil'}
                            //typeStyle={'outlined'}
                            color={'primary'}
                            fullWidth={true}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={8} md={8} paddingLeft={'0.2rem'} paddingRight={'0.2rem'}>
                    <BodyProfiles
                        handleOpen={handleOpen}
                        setTypeEdition={setTypeEdition}
                        states={states}
                        profiles={profiles}
                        setProfiles={setProfiles}
                        setInfoProfile={setInfoProfile}
                        getPerfiles={getPerfiles}
                        setOpenModalLoader={setOpenModalLoader}
                        setTitleSpinner={setTitleSpinner}
                    />
                </Grid>

            </Grid>

            <SpinnerLoader open={openModalLoader} title={titleSpinner} />
        </Box>
    );
};

export default Profiles;