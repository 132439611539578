import React from 'react';
import { colors } from '../../../../../assets/colors';
import { filterData } from '../../../../../utils/function';
import _ from 'lodash';

const columns = [
    { id: 'n', label: '#', width: 50, align: 'center', },
    { id: 'trabajador', label: 'Trabajador', width: 220, align: 'center', },
    { id: 'NroDoc', label: 'Documento', width: 120, align: 'center', },
    { id: 'sel', label: 'Sel.', width: 50, align: 'center', },
];

const BodyTrama = ({ dataWorker, dataReport, setDataReport, search }) => {

    let resultSearch = filterData(dataWorker, search, ['trabajador', 'NroDoc']);

    const handleChangeGeneral = (checked) => {
        let newArray;
        if (checked) {
            newArray = dataWorker;
        } else {
            newArray = [];
        }
        return setDataReport(newArray);
    }

    const handleChangeCheck = (checked, idx, info) => {
        let newArray = _.cloneDeep(dataReport);
        if (checked) {
            newArray.push(info);
        } else {
            newArray = newArray.filter((e) => e.NroDoc !== info.NroDoc);
        }
        return setDataReport(newArray);
    }

    return (
        <div className='page-body-main'>
            <div className='page-body-table table-responsive-md' style={{ height: '60vh' }}>
                <table className="table table-hover table-striped table-bordered">
                    <thead className='sticky-top' style={{ backgroundColor: colors.quaternary.hex, color: '#ffff' }}>
                        <tr>
                            {columns.map((column) => (
                                <th
                                    scope="col"
                                    key={column.id}
                                    style={{
                                        textAlign: column.align,
                                        width: column.width,
                                        maxWidth: column.width,
                                        cursor: 'pointer',
                                    }}
                                >
                                    {(column.id === 'sel' || column.id === 'des') ?
                                        <label className={`checkbox-title red`}>
                                            <input type="checkbox" name="check" id={`checkbox-rect-header` + column.label}
                                                checked={(dataReport?.length === dataWorker?.length && dataReport?.length > 0) ? true : false}
                                                onChange={(e) => handleChangeGeneral(e.target.checked)}
                                                title={`${(dataReport?.length === dataWorker?.length && dataReport?.length > 0) ? 'Retirara' : 'Seleccionara'} a todos los trabajadores`}
                                            />
                                            <span className="indicator"></span>
                                        </label>
                                        : column.label}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody className="table-group-divider">
                        {resultSearch?.map((row, idx) => {
                            return (
                                <tr key={"row" + idx}>
                                    {columns.map((column, indexColumn) => {
                                        const value = row[column.id];
                                        return (
                                            <td key={'col' + indexColumn} align={column.align}>
                                                {column.id === 'n' ? (idx + 1) :
                                                    column.id === 'sel' ?
                                                        <div className="checkbox-custom" style={{ display: 'flex', justifyContent: 'center' }}>
                                                            <input
                                                                type="checkbox"
                                                                name="check"
                                                                id={`checkbox-rect-${idx}`}
                                                                checked={(dataReport?.find(e => e.NroDoc === row.NroDoc) ? true : false)}
                                                                onChange={(e) => handleChangeCheck(e.target.checked, idx, row)}
                                                            />
                                                            <label htmlFor={`checkbox-rect-${idx}`}></label>
                                                        </div>
                                                        : value}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default BodyTrama