import React, { useState, useEffect  } from 'react';
import { Grid, TextField, InputAdornment, IconButton, Button } from '@mui/material';
import MainInput from '../../General/MainInput';
import SpinnerSmall from '../../SpinnerLoad/SpinnerSmall';
import KeyIcon from '@mui/icons-material/Key';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import AuthService from '../../../../services/auth';

const InfoChangePassword = (props) => {
    
    const {oldPassword,setOldPassword,password,setPassword,
        confirmPassword, setConfirmPassword,viewOldPassword, setViewOldPassword,viewPassword,setViewPassword,viewConfirmPassword, 
        setViewConfirmPassword,errorPassword, setErrorPassword,errorConfirmPassword,setErrorConfirmPassword, handlePasswordChange,handleConfirmPasswordChange
        } = props;
   

    return (

            <Grid justifyContent="center" alignItems="center" container  m={2} spacing={2} paddingLeft={2}>
                <Grid item xs={10}>
                    <TextField
                        id="input-old-password"
                        label="Ingrese contraseña anterior"
                        placeholder="Contraseña anterior"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <KeyIcon fontSize='small' />
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton aria-label="toggle visibility" size="small"
                                        onClick={() => setViewOldPassword(!viewOldPassword)}
                                    >
                                        {viewOldPassword ? <VisibilityIcon fontSize='inherit' /> : <VisibilityOffIcon fontSize="inherit" />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        type={viewOldPassword ? 'text' : 'password'}
                        variant="standard"
                        style={{ width: '80%' }}
                        value={oldPassword}
                        onChange={(e) => setOldPassword(e.target.value)}
                    />
                </Grid>

                <Grid item xs={10}>
                    <TextField
                        id="input-password"
                        label="Ingrese nueva contraseña"
                        placeholder="Nueva contraseña"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    {viewPassword ? <LockOpenIcon fontSize='small' /> : <LockIcon fontSize='small' />}
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton aria-label="toggle visibility" size="small"
                                        onClick={() => setViewPassword(!viewPassword)}
                                    >
                                        {viewPassword ? <VisibilityIcon fontSize='inherit' /> : <VisibilityOffIcon fontSize="inherit" />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        type={viewPassword ? 'text' : 'password'}
                        variant="standard"
                        style={{ width: '80%' }}
                        value={password}
                        onChange={(e) => handlePasswordChange(e.target.value)}
                        error={Boolean(errorPassword)}
                        helperText={errorPassword}
                    />
                </Grid>

                <Grid item xs={10}>
                    <TextField
                        id="input-confirm-password"
                        label="Confirme contraseña"
                        placeholder="Confirme contraseña"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    {viewConfirmPassword ? <LockOpenIcon fontSize='small' /> : <LockIcon fontSize='small' />}
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton aria-label="toggle visibility" size="small"
                                        onClick={() => setViewConfirmPassword(!viewConfirmPassword)}
                                    >
                                        {viewConfirmPassword ? <VisibilityIcon fontSize='inherit' /> : <VisibilityOffIcon fontSize="inherit" />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        type={viewConfirmPassword ? 'text' : 'password'}
                        variant="standard"
                        style={{ width: '80%' }}
                        value={confirmPassword}
                        onChange={(e) => handleConfirmPasswordChange(e.target.value)}
                        error={Boolean(errorConfirmPassword)}
                        helperText={errorConfirmPassword}
                    />
                </Grid>
            </Grid>
        
    );
};

export default InfoChangePassword;