import React, { useEffect, useState } from 'react';
import { IconButton, Grid, InputAdornment, Input, } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import BodyWorkerModal from './BodyWorkerModal'
import SpinnerSmall from '../../SpinnerLoad/SpinnerSmall';
import StaffService from '../../../../services/staff';
import { CODES } from '../../../../utils/responseCodes';
import { colors } from '../../../../assets/colors';
import { simpleAlerts } from '../../../../utils/alerts';
import _ from 'lodash';

const AddWorkerAreaOrg = (props) => {
    const { organigramaArea, infoAreaOrg, newWorkers, setNewWorkers } = props;

    const url = "'" + window.location.pathname + "'";

    const [openSmall, setOpenSmall] = useState(true);
    const [dataWorkers, setDataWorkers] = useState([]);

    const [search, setSearch] = useState("");
    const [page, setPage] = useState(1);
    const pageSize = 7;
    const [totalPages, setTotalPages] = useState(1);

    useEffect(() => {
        getStaff();
    }, [page])

    const getStaff = async () => {
        try {
            setOpenSmall(true);
            const result = await StaffService.getStaff(url, { search: search, page: page, pageSize: pageSize, pagination: 0 });
            if (result.status === CODES.SUCCESS_200) {
                setDataWorkers(result.data.data);
                setTotalPages(result.data.last_page);
            } else {
                simpleAlerts(result.response.data.message, 'error');
            }
            return [];
        } catch (error) {
            console.log(`Error en getStaff: ${error}`);
        } finally {
            setOpenSmall(false);
        }
    }

    const bodyItem = (person) => {
        let newItem =
        {
            "subAreaOrg_id": infoAreaOrg?.value,
            "persona_id": person?.id_persona,
        }

        return newItem;
    }

    const handleChangeCheckGeneral = (e) => {
        if (infoAreaOrg?.value === 0 || !infoAreaOrg) {
            simpleAlerts('Debe seleccionar un área!');
            return;
        }

        let newWorkersTmp = _.cloneDeep(newWorkers);
        if (e.target.checked === true) {
            dataWorkers?.forEach((person) => {
                if (!organigramaArea?.find(e => Number(e.id_Trabajador) === Number(person?.id_persona))) {
                    newWorkersTmp.push(bodyItem(person));
                }
            });
        } else {
            newWorkersTmp = [];
        }

        setNewWorkers(newWorkersTmp);
    }

    const handleChangeCheck = (value, p_person) => {
        if (infoAreaOrg?.value === 0 || !infoAreaOrg) {
            simpleAlerts('Debe seleccionar un área!');
            return;
        }

        if (organigramaArea?.find(e => Number(e.id_Trabajador) === Number(p_person?.id_persona))) {
            simpleAlerts('el trabajador ya se encuentra en el área!');
            return;
        }

        let newWorkersTmp = _.cloneDeep(newWorkers);
        if (value === true) {
            newWorkersTmp.push(bodyItem(p_person));
        } else {
            newWorkersTmp = newWorkersTmp?.filter(e => Number(e.persona_id) !== Number(p_person?.id_persona));
        }

        setNewWorkers(newWorkersTmp);
    }

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            getStaff();
        }
    };

    return (
        <Grid container>
            <Grid item xs={12} display='flex' alignItems={'center'} m={'0.8rem 0.5rem 0.5rem 0.5rem'}>
                <Input
                    id="standard-adornment-amount"
                    placeholder='Ingrese código / trabajador (apeP apeM, Nom)'
                    startAdornment={<InputAdornment position="start"><SearchIcon /></InputAdornment>}
                    style={{ marginRight: '0.5rem' }}
                    fullWidth
                    size='small'
                    onChange={(e) => setSearch(e.target.value)}
                    onKeyPress={handleKeyPress}
                />
                <IconButton
                    aria-label="trabajador"
                    color="primary"
                    size='small'
                    sx={{ border: `solid 0.05rem ${colors.primary.hex}` }}
                    onClick={getStaff}
                >
                    <PersonSearchIcon fontSize={'small'} />
                </IconButton>
            </Grid>

            <Grid item xs={12} mt={2}>
                <SpinnerSmall open={openSmall} />
                <BodyWorkerModal
                    dataWorkers={dataWorkers}
                    page={page}
                    setPage={setPage}
                    totalPages={totalPages}

                    handleChangeCheckGeneral={handleChangeCheckGeneral}
                    handleChangeCheck={handleChangeCheck}
                    newWorkers={newWorkers}
                />
            </Grid>
        </Grid>
    );
};

export default AddWorkerAreaOrg;