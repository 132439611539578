import React, { useEffect, useState } from 'react';
import { Box, Grid, } from '@mui/material';
import AddShift from './AddShift/AddShift';
import BodyShifts from '../../../components/Maintenance/Shifts/BodyShifts/BodyShifts';
import MainButton from '../../../components/General/MainButton';
import GeneralService from '../../../../services/general';
import ShiftService from '../../../../services/shift';
import { CODES } from '../../../../utils/responseCodes';
import { simpleAlerts } from '../../../../utils/alerts';
import SpinnerLoader from '../../../components/SpinnerLoad/SpinnerLoader';

const newShift = {
    'desTurno': '',
    'ingreso': '',
    'salida': '',
    'refrigerio': 0,
    'ingresoNoc': '',
    'salidaNoc': '',
    'lunes': 0,
    'martes': 0,
    'miercoles': 0,
    'jueves': 0,
    'viernes': 0,
    'sabado': 0,
    'domingo': 0,
    'codsghh': '',
    'cliente_id': null,
    'regimen_id': '',
    'usuario_id': '',
    'nota': null,
    'estado': 1,
    'Hnormales': 0
}

const weekDays = [
    { id: 'lunes', label: 'Lunes' },
    { id: 'martes', label: 'Martes' },
    { id: 'miercoles', label: 'Miercoles' },
    { id: 'jueves', label: 'Jueves' },
    { id: 'viernes', label: 'Viernes' },
    { id: 'sabado', label: 'Sabado' },
    { id: 'domingo', label: 'Domingo' },
];

const Shifts = ({ userToken }) => {
    const id_usuarioLogin = userToken.user.id_usuario;
    const [openModalLoader, setOpenModalLoader] = useState(false);
    const [titleSpinner, setTitleSpinner] = useState(null);
    const [open, setOpen] = useState(false)
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [states, setStates] = useState(null);
    const [dataTurnos, setDataTurnos] = useState(null);
    const [regimes, setRegimes] = useState(null);
    const [infoTurno, setInfoTurno] = useState(null);

    useEffect(() => {
        init();
    }, [])

    const init = async () => {
        Promise.all([
            getTurno(),
            getRegimen(),
            getEstados(),
        ]).then(() => setOpenModalLoader(false));
    }

    const getEstados = async () => {
        try {
            const result = await GeneralService.getEstados();
            if (result.status === CODES.SUCCESS_200) {
                result.data?.forEach(element => {
                    element.id = Number(element.corr3)
                    element.value = Number(element.corr3)
                    element.label = element.desc1?.trim()
                })
                setStates(result.data);
            }
            return []
        } catch (error) {
            console.log(`Error en getEstados: ${error}`);
        }
    }

    const getTurno = async () => {
        try {
            const result = await ShiftService.getTurno();
            if (result.status === CODES.SUCCESS_200) {
                if (result.data === 0)
                    simpleAlerts('No tiene turnos', "warning");
                setDataTurnos(result.data);
            }
        } catch (error) {
            console.log(`Error en getAreasBySede: ${error}`);
        }
    }

    const getRegimen = async () => {
        try {
            const result = await GeneralService.getRegimen();
            if (result.status === CODES.SUCCESS_200) {
                result.data?.forEach(element => {
                    element.value = element.id_regimen;
                    element.label = element.desRegimen;
                })
                setRegimes(result.data);
            }
        } catch (error) {
            console.log(`Error en getAreasBySede: ${error}`);
        }
    }

    const createShift = () => {
        setInfoTurno(newShift)
        handleOpen();
    };

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AddShift
                open={open}
                handleClose={handleClose}
                setOpenModalLoader={setOpenModalLoader}
                setTitleSpinner={setTitleSpinner}
                id_usuarioLogin={id_usuarioLogin}
                weekDays={weekDays}
                regimes={regimes}
                infoTurno={infoTurno}
                setInfoTurno={setInfoTurno}
                getTurno={getTurno}
            />
            <Grid container
                direction="row"
                justifyContent="center"
                alignItems="center"
            >
                <Grid item
                    xs={10}
                    display={'flex'}
                    justifyContent={'center'}
                    m={1}
                >
                    <Box width={'500px'}>
                        <MainButton
                            onClick={createShift}
                            title={'Crear Turno'}
                            color={'primary'}
                            fullWidth={true}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={10} md={10} paddingLeft={'0.2rem'} paddingRight={'0.2rem'}>
                    <BodyShifts
                        handleOpen={handleOpen}
                        id_usuarioLogin={id_usuarioLogin}
                        weekDays={weekDays}
                        states={states}
                        regimes={regimes}
                        dataTurnos={dataTurnos}
                        setDataTurnos={setDataTurnos}
                        setInfoTurno={setInfoTurno}
                        setOpenModalLoader={setOpenModalLoader}
                        setTitleSpinner={setTitleSpinner}
                    />
                </Grid>

            </Grid>

            <SpinnerLoader open={openModalLoader} title={titleSpinner} />
        </Box>
    );
};

export default Shifts;