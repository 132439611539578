import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import SpinnerLoader from '../../components/SpinnerLoad/SpinnerLoader';
import MainSelect from '../../components/General/MainSelect';
import MainButton from '../../components/General/MainButton';
import AddaAreaOrg from './AddWorker/AddaAreaOrg';
import AreaOrgService from '../../../services/areaOrg';
import BodyWorker from '../../components/OrganizationalChart/BodyWorker/BodyWorker';
import { CODES } from '../../../utils/responseCodes';
import { simpleAlerts } from '../../../utils/alerts';

const OrganizationalChart = () => {
    const url = "'" + window.location.pathname + "'";
    const [titleSpinner, setTitleSpinner] = useState(null);
    const [openModalLoader, setOpenModalLoader] = useState(true);
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [areasOrg, setAreasOrg] = useState([]);
    const [infoAreaOrg, setInfoAreaOrg] = useState(null);
    const [organigramaArea, setOrganigramaArea] = useState([]);

    useEffect(() => {
        getAreaOrganigrama();
    }, [])

    useEffect(() => {
        if (infoAreaOrg?.value)
            getOrganigramaArea();
    }, [infoAreaOrg])

    const getAreaOrganigrama = async () => {
        setOpenModalLoader(true);
        try {
            const result = await AreaOrgService.getAreaOrganigrama();
            if (result.status === CODES.SUCCESS_200) {
                result.data?.forEach(element => {
                    element.id = element.id_areaOrg
                    element.value = element.id_areaOrg
                    element.label = element.desAreaOrg
                })
                setAreasOrg(result.data);
            }
        } catch (error) {
            console.log(`Error en getAreaOrganigrama: ${error}`);
        } finally {
            setOpenModalLoader(false);
        }
    }

    const getOrganigramaArea = async () => {
        try {
            setOpenModalLoader(true);
            setTitleSpinner('Buscando Organigrama');
            const result = await AreaOrgService.getOrganigramaArea(url, infoAreaOrg?.value);
            if (result.status === CODES.SUCCESS_200) {
                setOrganigramaArea(result.data);
            } else {
                simpleAlerts((result?.response?.data[0] || 'Error al buscar organigrama'), 'error');
            }
        } catch (error) {
            console.log(`Error en getOrganigramaArea: ${error}`);
        } finally {
            setOpenModalLoader(false);
            setTitleSpinner(null);
        }
    }

    const agregarWorker = () => {
        handleOpen();
    }

    return (
        <>
            <AddaAreaOrg
                open={open}
                handleClose={handleClose}
                setOpenModalLoader={setOpenModalLoader}
                setTitleSpinner={setTitleSpinner}

                organigramaArea={organigramaArea}
                infoAreaOrg={infoAreaOrg}

                getOrganigramaArea={getOrganigramaArea}
            />
            <Grid container direction="row" justifyContent="center" alignItems="flex-end">
                <Grid item xs={9}
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'end',
                    }}
                >
                    <div style={{ width: '30%', }}>
                        <MainSelect
                            title={'Áreas'}
                            placeholder={'Selecionar área'}
                            options={areasOrg}
                            value={Number(infoAreaOrg?.value)}
                            onChange={(value, event) => setInfoAreaOrg(areasOrg?.find(element => Number(element.value) === Number(value)))}
                        />
                    </div>
                    <div style={{ width: '30%', }}>
                        <MainButton
                            onClick={agregarWorker}
                            title={'Agregar trabajador'}
                        />
                    </div>
                </Grid>
                
                <Grid item xs={10}>
                    {infoAreaOrg?.value &&
                        <div style={{ margin: '1rem 0' }}>
                            <div style={{ padding: '0 5rem', margin: '5px' }}>
                                <label>
                                    <u><strong>Nombre Área:</strong></u>
                                    &nbsp;&nbsp;&nbsp;
                                    <em>{infoAreaOrg?.Area}</em>
                                </label>
                            </div>
                            <div style={{ padding: '0 5rem', margin: '5px' }}>
                                <label>
                                    <u><strong>Jefe Área:</strong></u>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <em>{infoAreaOrg?.Jefe}</em>
                                </label>
                            </div>
                        </div>
                    }
                    <BodyWorker
                        titleBody={'Organigrama'}
                        setTitleSpinner={setTitleSpinner}
                        setOpenModalLoader={setOpenModalLoader}
                        organigramaArea={organigramaArea}
                        setOrganigramaArea={setOrganigramaArea}
                    />
                </Grid>
            </Grid>

            <SpinnerLoader open={openModalLoader} title={titleSpinner} />
        </>
    )
}

export default OrganizationalChart