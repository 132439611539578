import React, { useState, createElement, useEffect,useRef  } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import ListItemIcon from '@mui/material/ListItemIcon';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import HomeIcon from '@mui/icons-material/Home';
import LockResetIcon from '@mui/icons-material/LockReset';
import { colors } from '../../assets/colors';
import { CODES } from '../../utils/responseCodes';
import { navigatePages } from '../../utils/function';
import AuthService from '../../services/auth';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { AppstoreAddOutlined, AppstoreTwoTone, } from '@ant-design/icons/lib/icons';
import Swal from 'sweetalert2';
import { Global } from '../../utils/general';
import MainModal from './General/MainModal';
import MainButton from './General/MainButton';
import InfoChangePassword from './Users/AddUser/InfoChangePassword';

import { simpleAlerts } from '../../utils/alerts';
import SpinnerLoader from './SpinnerLoad/SpinnerLoader';

//const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];
const settings = [];

const BarHeader = ({ validationInfoChange = true, setValidationInfoChange, userToken, collapsed, setCollapsed, menu, logoClient, pageTitle, setPageTitle }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const user = userToken.user;
    const id_usuarioLogin =  userToken.user.id_usuario;
    const [anchorElUser, setAnchorElUser] = useState(null);
    const handleOpenUserMenu = (event) => setAnchorElUser(event.currentTarget);
    const handleCloseUserMenu = () => setAnchorElUser(null);
    const [categories, setCategories] = useState(null);
    const path = Global.path
 
    const [open, setOpen] = useState(false)
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    
    
    const [openModalLoader, setOpenModalLoader] = useState(false);
    const [titleSpinner, setTitleSpinner] = useState(null);
    
    const [oldPassword, setOldPassword] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [viewOldPassword, setViewOldPassword] = useState(false);
    const [viewPassword, setViewPassword] = useState(false);
    const [viewConfirmPassword, setViewConfirmPassword] = useState(false);
    const [errorPassword, setErrorPassword] = useState('');
    const [errorConfirmPassword, setErrorConfirmPassword] = useState('');

    useEffect(() => {
        let tmp = menu?.filter(e => Number(e.ubicacionMenu) === 1);
        let categorias = tmp?.reduce((acc, item) => { //itera cada elemento del tmp
            if (!acc.includes(item.categoria)) { //esta condicion valida que no este antes
                acc.push(item.categoria); //agrega al acc temporal
            }
            return acc;
        }, []);
        //categorias?.sort();
        setCategories(categorias);
    }, [menu])

    const cleanInfo = () => {
        setOldPassword('');
        setPassword('');
        setConfirmPassword('');
        setViewOldPassword(false);
        setViewPassword(false);
        setViewConfirmPassword(false);
        setErrorPassword('');
        setErrorConfirmPassword('');
    };

    const signOut = async () => {
        Swal.fire({
            title: '¿Seguro de Cerrar Sesión?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: colors.secondary.hex,
            cancelButtonColor: colors.primary.hex,
            confirmButtonText: 'Si, Cerrar Sesión'
        }).then((result) => {
            if (result.isConfirmed) {
                forceSignOut();
            }
        })
    }

    const forceSignOut = async () => {
        Swal.fire({
            icon: 'error',
            title: 'Cerrando...',
            timer: 4000
        });
    
        setTimeout(
            AuthService.signOut().then(result => {
                if (result.status === CODES.BAD_TOKEN_498 || result.status === CODES.SUCCESS_200) {
                    localStorage.removeItem("infoAssisProd");
                    navigate(path);
                    window.location.reload();
                }
            }), 10000);
    };

    const recoveryPass = () => {
        handleOpen();
    };

    
    const handlePasswordChange = (value) => {
        // Actualizar el estado y mostrar mensajes de error si es necesario
        setPassword(value);

        // Validación básica
        const regex = /^(?=.*[@#$%&*])(?=.*\d)(?=.*[a-z])(?!.*[ \/]).{6,}$/;
        if (!regex.test(value)) {
            setErrorPassword('La contraseña debe tener al menos 6 caracteres, un número y un carácter especial.');
        } else {
            setErrorPassword('');
        }
    };

    const handleConfirmPasswordChange = (value) => {
        // Comparar con la contraseña
        if (value !== password) {
            setErrorConfirmPassword('Las contraseñas no coinciden.');
        } else {
            setErrorConfirmPassword('');
        }

        // Actualizar el estado
        setConfirmPassword(value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (password === oldPassword) {
            simpleAlerts('La nueva clave no debe ser igual a la anterior', "error");
            return;
        }
        if (password !== confirmPassword) {
            simpleAlerts('Las clave y la confirmación no coinciden', "error");
            return;
        }

        let body = {
            id_usuario: id_usuarioLogin,
            oldClave: oldPassword,
            clave: password,
            clave_confirmation: confirmPassword
        };

        try {
            setTitleSpinner('Actualizando');
            setOpenModalLoader(true);
            const result = await AuthService.updatePassword(body);

            if (result.status === CODES.SUCCESS_200) {
                simpleAlerts(result?.data?.message || 'Éxito en la actualización', 'success');
                cleanInfo();handleClose();
                setTimeout(() => {
                    forceSignOut();  // Forzar cierre de sesión después del retraso
                }, 1500);
            } else {
                simpleAlerts(result?.response?.data?.message || 'Error en la actualización', 'error');
            }

            return [];
        } catch (error) {
            console.log(`Error en actualizar contraseña: ${error}`);
        } finally {
            setTitleSpinner(null);
            setOpenModalLoader(false);
        }
    };

    return (
        
        <AppBar position="static" className='bar-menu'
            sx={{
                border: `solid 0.05rem ${colors.primary.hex}`,

                width: { xs: '97%', md: '99%' },
                margin: '0.5rem',
                borderRadius: '15px',
                background: { xs: colors.primary.hex, md: 'rgb(247, 247, 247, 0.7)', },

            }}
        >
            <MainModal
                open={open}
                handleClose={() => { handleClose(); cleanInfo() ;
                }}
                centrado={'centrado'}
                title={'Cambiar contraseña'}
                subTitle={'Actualización'}
                bodyModal={
                    <>
                       <InfoChangePassword
                            id_usuarioLogin={id_usuarioLogin}
                            pathInit={path}
                            navigate={navigate}
                            openModalLoader={openModalLoader}setOpenModalLoader={setOpenModalLoader}
                            titleSpinner={titleSpinner} setTitleSpinner={setTitleSpinner}
                            oldPassword={oldPassword} setOldPassword={setOldPassword}
                            password={password} setPassword={setPassword}
                            confirmPassword={confirmPassword} setConfirmPassword={setConfirmPassword}
                            viewOldPassword={viewOldPassword} setViewOldPassword={setViewOldPassword}
                            viewPassword={viewPassword} setViewPassword={setViewPassword}
                            viewConfirmPassword={viewConfirmPassword} setViewConfirmPassword={setViewConfirmPassword}
                            errorPassword={errorPassword} setErrorPassword={setErrorPassword}
                            errorConfirmPassword={errorConfirmPassword} setErrorConfirmPassword={setErrorConfirmPassword}
                            handlePasswordChange={handlePasswordChange}
                            handleConfirmPasswordChange={handleConfirmPasswordChange}
                        />
                    </>
                }
                buttonActions={[
                    <MainButton
                        key='btn-modal-user-save1'
                        title={'Guardar'}
                        typeStyle={'outlined'}
                        color={'primary'}
                       onClick={handleSubmit} // Llama a handleSubmit aquí
                    />
                ]}
            />
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    {/* inicio logo pantalla grande */}
                    <div className='logo-client'>
                        <img
                            style={{ marginRight: 5 }}
                            height={20}
                            src={logoClient}
                        />
                    </div>
                    <Typography
                        variant="h6"
                        noWrap
                        sx={{
                            mr: 2,
                            ml: 1,
                            display: { xs: 'none', md: 'flex' },
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.02rem',
                        }}
                        color='primary'
                    >
                        {pageTitle}
                    </Typography>
                    {/* fin logo pantalla grande */}


                    {/* inicio menu pantalla responsive */}
                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                        {/* menu al momento de volverse responsive */}
                        <NavDropdown
                            title={<MenuIcon style={{ fontSize: 'xx-large' }} />}
                            id="basic-nav-dropdown"
                        >
                            {categories?.map((category) => {
                                let menuTmp = menu?.filter(e => e.categoria === category && Number(e.ubicacionMenu) === 1);
                                return (
                                    <NavDropdown
                                        key={category}
                                        title={category}
                                        style={{ paddingLeft: 15, paddingTop: 5 }}>
                                        {menuTmp?.map((item) => {
                                            return (
                                                <NavDropdown.Item
                                                    key={item.desPagina}
                                                    onClick={() => navigatePages(location, navigate, setPageTitle, item.desPagina, item.link, validationInfoChange, setValidationInfoChange)}
                                                >{item.desPagina}</NavDropdown.Item>
                                            )
                                        })}
                                    </NavDropdown>
                                )
                            })}
                            <NavDropdown.Divider />
                            <NavDropdown.Item
                                style={{ textAlign: 'center' }}
                                onClick={() => setCollapsed(!collapsed)}
                            ><MenuOpenIcon /></NavDropdown.Item>
                        </NavDropdown>
                    </Box>
                    {/* fin menu pantalla responsive */}


                    {/* inicio logo pantalla responsive */}
                    <div className='logo-client-responsive'>
                        <img
                            style={{ marginRight: 8, marginLeft: 8 }}
                            height={12}
                            src={logoClient}
                        />
                    </div>
                    <Typography
                        variant="subtitle1"
                        noWrap
                        sx={{
                            display: { xs: 'flex', md: 'none' },
                            flexGrow: 1,
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.02rem',
                            color: 'inherit',
                            textAlign: 'center'
                        }}
                    >
                        {pageTitle}
                    </Typography>
                    {/* fin logo pantalla responsive */}


                    {/* inicio menu pantalla grande */}
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                        {categories?.map((category) => {
                            let menuTmp = menu?.filter(e => e.categoria === category && Number(e.ubicacionMenu) === 1);
                            return (
                                <NavDropdown title={category} key={category}
                                    className='menu-bar'
                                >
                                    {menuTmp?.map((item) => {
                                        return (
                                            <NavDropdown.Item
                                                key={item.desPagina}
                                                onClick={() => navigatePages(location, navigate, setPageTitle, item.desPagina, item.link, validationInfoChange, setValidationInfoChange)}
                                            >{item.desPagina}</NavDropdown.Item>
                                        )
                                    })}
                                </NavDropdown>
                            )
                        })}
                    </Box>
                    {/* fin menu pantalla grande */}

                    {createElement(collapsed ? AppstoreAddOutlined : AppstoreTwoTone, {
                        className: 'trigger',
                        onClick: () => setCollapsed(!collapsed),
                        style: { marginRight: '10px', fontSize: 'x-large', color: colors.primary.hex },
                    })}

                    <Box sx={{ flexGrow: 0 }}>
                        <Tooltip title={user?.trabajador}>
                            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                <Avatar alt={user?.usuario?.toUpperCase()} src="/static/images/avatar/2.jpg" sx={{ fontSize: '1rem' }} />
                            </IconButton>
                        </Tooltip>
                        <Menu
                            sx={{ mt: '45px' }}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top', horizontal: 'right',
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        //onClick={signOut}
                        >
                            <MenuItem
                                onClick={() => navigate(path)}
                            >
                                <ListItemIcon>
                                    <HomeIcon fontSize="small" />
                                </ListItemIcon>
                                <Typography variant="body2" color="text.secondary">
                                    Home
                                </Typography>
                            </MenuItem>
                            {settings.map((setting) => {
                                return (
                                    <MenuItem key={setting}
                                        onClick={() => navigate(setting.key)}
                                    >
                                        <Typography textAlign="center">{setting}</Typography>
                                    </MenuItem>
                                );
                            })}
                             <MenuItem
                               onClick={recoveryPass}
                                >
                                <ListItemIcon>
                                    <LockResetIcon fontSize="small" color='warning' />
                                </ListItemIcon>
                                <Typography variant="body2">
                                     Cambiar contraseña
                                </Typography>
                            </MenuItem>
                            <MenuItem
                                onClick={signOut}>
                                <ListItemIcon>
                                    <PowerSettingsNewIcon fontSize="small" color='error' />
                                </ListItemIcon>
                                <Typography variant="body2" color="text.secondary">
                                    Cerrar Sesión
                                </Typography>
                            </MenuItem>
                        </Menu>
                    </Box>

                </Toolbar>
            </Container>
            <SpinnerLoader open={openModalLoader} title={titleSpinner} />
        </AppBar>
    );
};

export default BarHeader;