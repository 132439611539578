import React, { useEffect, useState } from 'react';
import { Box, Grid, TextField, InputAdornment } from '@mui/material';
import BodyStaffs from '../../components/Staff/BodyStaffs/BodyStaffs';
import MainButton from '../../components/General/MainButton';
import StaffService from '../../../services/staff';
import ProfileService from '../../../services/profile';
import GeneralService from '../../../services/general';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import { CODES } from '../../../utils/responseCodes';
import { simpleAlerts } from '../../../utils/alerts';
import SpinnerLoader from '../../components/SpinnerLoad/SpinnerLoader';
import InfoStaff from './InfoStaff/InfoStaff';

const Staff = ({ userToken }) => {
    const url = "'" + window.location.pathname + "'";
    const [titleSpinner, setTitleSpinner] = useState(null);
    const [openModalLoader, setOpenModalLoader] = useState(true);
    const [open, setOpen] = useState(false)
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [states, setStates] = useState(null);
    const [profiles, setProfiles] = useState(null);
    const [typeDocument, setTypeDocument] = useState(null);
    const [dataStaffs, setDataStaffs] = useState(null);
    const [infoStaff, setInfoStaff] = useState(null);
    const [searchStaff, setSearchStaff] = useState("");
    const [pageStaff, setPageStaff] = useState(1);
    const pageSizeStaff = 10;
    const [totalPagesStaff, setTotalPagesStaff] = useState(1);

    useEffect(() => {
        init();
    }, [])

    useEffect(() => {
        getStaff();
    }, [searchStaff, pageStaff])

    const init = async () => {
        setTitleSpinner('Cargando ...')
        Promise.all([
            getEstados(),
            getStaff(),
            getPerfiles(),
            getTipoDocumento(),
        ]).then(() => setOpenModalLoader(false), setTitleSpinner(null));
    }

    const getEstados = async () => {
        try {
            const result = await GeneralService.getEstados();
            if (result.status === CODES.SUCCESS_200) {
                result.data?.forEach(element => {
                    element.id = Number(element.corr3)
                    element.value = Number(element.corr3)
                    element.label = element.desc1?.trim()
                })
                setStates(result.data);
            }
            return []
        } catch (error) {
            console.log(`Error en getEstados: ${error}`);
        }
    }

    const getStaff = async () => {
        try {
            const result = await StaffService.getStaff(url, { search: searchStaff, page: pageStaff, pageSize: pageSizeStaff, pagination: 0 });
            if (result.status === CODES.SUCCESS_200) {
                setDataStaffs(result.data.data);
                setTotalPagesStaff(result.data.last_page);
            } else {
                simpleAlerts(result.response.data.message, 'error');
            }
            return [];
        } catch (error) {
            console.log(`Error en getStaff: ${error}`);
        }
    }

    const getPerfiles = async () => {
        try {
            const result = await ProfileService.getPerfiles();
            if (result.status === CODES.SUCCESS_200) {
                result.data?.forEach(element => {
                    element.value = element.id_perfil
                    element.label = element.desPerfil
                })
                setProfiles(result.data);
            }
            return []
        } catch (error) {
            console.log(`Error en getPerfiles: ${error}`);
        }
    }

    const getTipoDocumento = async () => {
        try {
            const result = await GeneralService.getTipoDocumento();
            if (result.status === CODES.SUCCESS_200) {
                result.data?.forEach(element => {
                    element.value = element.id_Documento
                    element.label = element.codDocumento + ' - ' + element.Documento
                })
                setTypeDocument(result.data);
            }
            return []
        } catch (error) {
            console.log(`Error en getPerfiles: ${error}`);
        }
    }

    return (
        <Box sx={{ flexGrow: 1 }}>
            <InfoStaff
                open={open}
                handleClose={handleClose}
                setTitleSpinner={setTitleSpinner}
                setOpenModalLoader={setOpenModalLoader}

                states={states}
                infoStaff={infoStaff}
                setInfoStaff={setInfoStaff}

                profiles={profiles}
                typeDocument={typeDocument}
            />

            <Grid container
                direction="row"
                justifyContent="center"
                alignItems="center"
                mt={2}
            >
                <Grid item xs={8} sm={8} md={6} paddingLeft={'2rem'} >
                    <TextField
                        id="input-with-icon-textfield"
                        label="Búsqueda"
                        placeholder="Buscar personal"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <TravelExploreIcon color='tertiary' />
                                </InputAdornment>
                            )
                        }}
                        variant="standard"
                        style={{ width: '100%' }}
                        onChange={(e) => setSearchStaff(e.target.value)}
                    />

                </Grid>
                <Grid item xs={4} sm={2} md={2} paddingTop={'0.8rem'} paddingLeft={'2rem'} paddingRight={'1rem'}>
                    <MainButton
                        onClick={() => {
                            Promise.all([
                                getStaff(),
                                setOpenModalLoader(true),
                                setTitleSpinner('Cargando personal...')
                            ]).then(() => { setOpenModalLoader(false); setTitleSpinner(null); });
                        }}
                        title={'Buscar'}
                        typeStyle={'outlined'}
                        color={'primary'}
                        fullWidth
                    />
                </Grid>
            </Grid>

            <Grid container
                direction="row"
                justifyContent="center"
                alignItems="center"
                mt={2}
            >
                <Grid item xs={12} sm={10} md={8} paddingLeft={'1rem'} paddingRight={'1rem'}>
                    <BodyStaffs
                        dataStaffs={dataStaffs}
                        setDataStaffs={setDataStaffs}
                        totalPagesStaff={totalPagesStaff}
                        pageStaff={pageStaff}
                        setPageStaff={setPageStaff}

                        setInfoStaff={setInfoStaff}
                        handleOpen={handleOpen}
                    />
                </Grid>
            </Grid>

            <SpinnerLoader open={openModalLoader} title={titleSpinner} />
        </Box>
    );
};

export default Staff;