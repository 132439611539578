import axios from "axios";
import { Global } from '../utils/general';
import headerRequest from "../utils/headers"

const API_URL = Global.url;
const SYS = Global.system;

class AssistanceService {
    getAsistenciaCompleta(url, idCampus, dateInit, dateEnd) {
        let ruta = `${API_URL}/assistance/show/${idCampus}/${dateInit}/${dateEnd}?sys=${SYS}&url=${url}`;
        return axios.get(ruta, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }

    getAsistenciaSimple(url, id_area, id_turno, fecha) {
        let ruta = `${API_URL}/assistance/simple/${id_area}/${id_turno}/${fecha}?sys=${SYS}&url=${url}`;
        return axios.get(ruta, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }

    getColumnasAsistencias() {
        let ruta = `${API_URL}/assistance/columns`;
        return axios
            .get(ruta, {
                headers: headerRequest(),
            })
            .catch((error) => {
                return error;
            });
    }

    updateAsistenciaBucle(url, body) {
        let ruta = `${API_URL}/assistance/update-bucle?sys=${SYS}&url=${url}`;
        return axios.put(ruta, body, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }

    updateAsistencia(url, body) {
        let ruta = `${API_URL}/assistance/update?sys=${SYS}&url=${url}`;
        return axios.put(ruta, body, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }

    //! Se dejo de usar para reemplazar por el bucle en el back
    saveMassiveAssistance(url, body) {
        let ruta = `${API_URL}/assistance/massive?sys=${SYS}&url=${url}`;
        return axios.post(ruta, body, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }
    
    saveMassiveAssistanceBucle(url, body) {
        let ruta = `${API_URL}/assistance/massive/bucle?sys=${SYS}&url=${url}`;
        return axios.post(ruta, body, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }

    saveAssistanceHours(url, body) {
        let ruta = `${API_URL}/assistance/hours-movil?sys=${SYS}&url=${url}`;
        return axios.post(ruta, body, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }

    updateAddAsistenciaMovil(url, body) {
        let ruta = `${API_URL}/assistance/add-movil?sys=${SYS}&url=${url}`;
        return axios.post(ruta, body, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }

    getAsistenciaByAprobar(url, id_solicitante, stateApprove, fecIni, fecFin) {
        let ruta = `${API_URL}/assistance/to-approve/${id_solicitante}/${stateApprove}/${fecIni}/${fecFin}?sys=${SYS}&url=${url}`;
        return axios.get(ruta, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }
    
    saveApproveAssistance(url, body) {
        let ruta = `${API_URL}/assistance/approve?sys=${SYS}&url=${url}`;
        return axios.post(ruta, body, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }

    saveRequestApprove(url, body) {
        let ruta = `${API_URL}/assistance/request-approve?sys=${SYS}&url=${url}`;
        return axios.post(ruta, body, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }
    
    saveRequestDelete(url, body) {
        let ruta = `${API_URL}/assistance/request-delete?sys=${SYS}&url=${url}`;
        return axios.post(ruta, body, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }

    getAsistenciaToEliminar(url, id_aprobador, stateApprove, inicio, fin) {
        let ruta = `${API_URL}/assistance/to-delete/${id_aprobador}/${stateApprove}/${inicio}/${fin}?sys=${SYS}&url=${url}`;
        return axios.get(ruta, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }
    
    saveDeleteAssistance(url, body) {
        let ruta = `${API_URL}/assistance/delete?sys=${SYS}&url=${url}`;
        return axios.post(ruta, body, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }
}

export default new AssistanceService();
