import axios from "axios";
import { Global } from '../utils/general';
import headerRequest from "../utils/headers"

const API_URL = Global.url;

class GeneralService {
    getEmpresas() {
        let ruta = `${API_URL}/general/companies`;
        return axios
            .get(ruta, {
                headers: headerRequest(),
            })
            .catch((error) => {
                return error;
            });
    }

    getSedexRegion(region_id) {
        let ruta = `${API_URL}/general/sede-region/${region_id}`;
        return axios
            .get(ruta, {
                headers: headerRequest(),
            })
            .catch((error) => {
                return error;
            });
    }

    getRegiones() {
        let ruta = `${API_URL}/general/region`;
        return axios
            .get(ruta, {
                headers: headerRequest(),
            })
            .catch((error) => {
                return error;
            });
    }

    getClientesByEmpresa(empresa_id) {
        let ruta = `${API_URL}/general/client/${empresa_id}`;
        return axios
            .get(ruta, {
                headers: headerRequest(),
            })
            .catch((error) => {
                return error;
            });
    }

    getUnidadesByCliente(cliente_id) {
        let ruta = `${API_URL}/general/units/${cliente_id}`;
        return axios
            .get(ruta, {
                headers: headerRequest(),
            })
            .catch((error) => {
                return error;
            });
    }

    getSedesByUnidad(unidad_id) {
        let ruta = `${API_URL}/general/campus/${unidad_id}`;
        return axios
            .get(ruta, {
                headers: headerRequest(),
            })
            .catch((error) => {
                return error;
            });
    }

    getSedesByUnidadPersonalActivo(unidad_id) {
        let ruta = `${API_URL}/general/campus-personal-activo/${unidad_id}`;
        return axios
            .get(ruta, {
                headers: headerRequest(),
            })
            .catch((error) => {
                return error;
            });
    }

    getAreasBySede(sede_id) {
        let ruta = `${API_URL}/general/areas/${sede_id}`;
        return axios
            .get(ruta, {
                headers: headerRequest(),
            })
            .catch((error) => {
                return error;
            });
    }

    getTipoDocumento() {
        let ruta = `${API_URL}/general/type-document`;
        return axios
            .get(ruta, {
                headers: headerRequest(),
            })
            .catch((error) => {
                return error;
            });
    }

    getPantallas() {
        let ruta = `${API_URL}/general/screens`;
        return axios
            .get(ruta, {
                headers: headerRequest(),
            })
            .catch((error) => {
                return error;
            });
    }

    getAsistenciaCondicion() {
        let ruta = `${API_URL}/general/assistance-condition`;
        return axios
            .get(ruta, {
                headers: headerRequest(),
            })
            .catch((error) => {
                return error;
            });
    }

    getRegimen() {
        let ruta = `${API_URL}/general/regimes`;
        return axios.get(ruta, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }

    getEstados() {
        let ruta = `${API_URL}/general/states`;
        return axios.get(ruta, {
            headers: headerRequest(),
        })
            .catch((error) => {
                return error;
            });
    }

    getTipoPlanificacion() {
        let ruta = `${API_URL}/general/planning-type`;
        return axios.get(ruta, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }

    getSistemas() {
        let ruta = `${API_URL}/general/systems`;
        return axios.get(ruta, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }

    getAreaAsistenciaPuesto(area_id) {
        let ruta = `${API_URL}/general/areas-puesto/${area_id}`;
        return axios
            .get(ruta, {
                headers: headerRequest(),
            })
            .catch((error) => {
                return error;
            });
    }

    getEstadoAprobar() {
        let ruta = `${API_URL}/general/states-approve`;
        return axios.get(ruta, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }
}

export default new GeneralService();
