import React from 'react';
import TextField from '@mui/material/TextField'

const MainInput = (props) => {
    const { title, readonly, placeholder, type, value, required, propsInput, name, onChange, style, styleTitle } = props;

    return (
        <fieldset className='fieldset'>
            <legend className='title-legend' style={{ ...styleTitle }}>{title}</legend>
            <TextField
                className="text"
                fullWidth
                required={required || false}

                placeholder={placeholder || ''}
                size='small'
                type={type || 'text'}
                value={value || ''}
                onChange={onChange}
                name={name || ''}

                style={{ ...style, borderRadius: '10px' }}
                inputProps={{
                    ...propsInput,
                    style: {
                        fontSize: 12,  // font size of input text
                        backgroundColor: readonly ? '#F1EFEF' : 'white',
                        borderRadius: '10px'
                    },
                    readOnly: (readonly || false),
                }}

                InputLabelProps={{ style: { fontSize: 12 } }} // font size of input label
            />
        </fieldset>
    );
};

export default MainInput;