import React, { } from 'react';
import { ButtonGroup, IconButton, Box } from '@mui/material';
import MainPagination from '../../../General/MainPagination'
import { colors } from '../../../../../assets/colors';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
const columns = [
  { id: 'id_persona', label: 'Cod', width: 40, align: 'left', },
  { id: 'nombres', label: 'Trabajador', width: 220, align: 'left', },
  { id: 'actions', label: 'Asignar', width: 80, align: 'center', },
];

const BodyStaffs = (props) => {
  const { dataStaffs, totalPagesStaff, pageStaff, setPageStaff,infoAreaOrg,setInfoAreaOrg,id_usuarioLogin } = props;
  
  const url = "'" + window.location.pathname + "'";
  
  function asignarJefe(persona){
    
    let updInfo = JSON.parse(JSON.stringify(infoAreaOrg));
    updInfo.CodJefe = persona.id_persona;
    updInfo.Jefe = persona.nombres+" "+persona.apePaterno+" "+persona.apeMaterno;
    updInfo.usuarioMod = id_usuarioLogin;
    
    
    return setInfoAreaOrg(updInfo);


    
  }



  return (
    <>
      <Box className='page-body-main'>
        <Box className='page-body-table table-responsive-md page-body-table__modal'>
          <table className="table table-hover table-striped table-bordered">
            <thead className='sticky-top' style={{ backgroundColor: colors.quaternary.hex, color: '#ffff' }}>
              <tr>
                {columns.map((column) => (
                  <th
                    scope="col"
                    key={column.id}
                    style={{
                      textAlign: column.align,
                      width: column.width,
                      maxWidth: column.width
                    }}
                  >
                    {column.label}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="table-group-divider">
              {dataStaffs?.map((user, indexRow) => {
                return (
                  <tr key={"row" + indexRow}>
                    {columns.map((column, indexColumn) => {
                      const value = user[column.id];
                      return (
                        <td key={'col' + indexColumn} align={column.align}>
                          {column.id === 'nombres' ?
                            (user?.nombres+" "+user?.apePaterno+" "+user?.apeMaterno) :
                            column.id === 'actions' ?
                              <ButtonGroup
                                disableElevation
                                variant="contained"
                                aria-label="Disabled elevation buttons"
                              >
                                <IconButton aria-label="asignar" size="small" title='asignar'
                                  onClick={() => {asignarJefe(user)}}
                                >
                                  <PublishedWithChangesIcon fontSize='small' color={'primary'} />
                                </IconButton>
                              </ButtonGroup> :
                              value}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Box>

        {totalPagesStaff > 1 &&
          <MainPagination
            size={'small'}
            totalPages={totalPagesStaff}
            page={pageStaff}
            setPage={setPageStaff}
          />
        }
      </Box>
    </>
  );
}

export default BodyStaffs;