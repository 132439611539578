import React, { useState, useEffect } from 'react';
import { Box, } from '@mui/material';

import { OrderedListOutlined, } from '@ant-design/icons/lib/icons';
import SpinnerSmall from '../../SpinnerLoad/SpinnerSmall';
import GroupingSede from '../../GroupingSede';
import GeneralService from '../../../../services/general';
import { CODES } from '../../../../utils/responseCodes'
import { simpleAlerts } from '../../../../utils/alerts';
import _ from 'lodash';

const columns = [
    { id: 'sel', label: 'Sel.', width: 10, align: 'center', },
    { id: 'id_area', label: 'Cód.', width: 30, align: 'left', },
    { id: 'desArea', label: 'Area', width: 50, align: 'left', },
];

const AssociateAreas = (props) => {
    const { dataAreaAsistenciaPuestos,areaAsistenciaPuestosCopy,setAreaAsistenciaPuestosCopy,areaAsistenciaPuestosDelete,setAreaAsistenciaPuestosDelete,dataAsistenciaPuesto} = props;
    const [openSmall, setOpenSmall] = useState(false);
    const [infoCompanyMdl, setInfoCompanyMdl] = useState(null);
    const [infoClientMdl, setInfoClientMdl] = useState(null);
    const [infoUnitMdl, setInfoUnitMdl] = useState(null);
    const [infoCampusMdl, setInfoCampusMdl] = useState(null);
       
    const [dataAreasCampus, setDataAreasCampus] = useState(null);

   
    useEffect(() => {
        setAreaAsistenciaPuestosCopy(dataAreaAsistenciaPuestos)
    }, [dataAreaAsistenciaPuestos])


    useEffect(() => {
        if (infoCampusMdl?.value)
            getAreasBySede()
    }, [infoCampusMdl])

    useEffect(() => {
        setDataAreasCampus(null);
    }, [infoCampusMdl, infoClientMdl, infoUnitMdl])

    /** inicio ordenamiento de columnas */
    const [sortConfigTable, setSortConfigTable] = useState({ key: null, direction: "ascending", });

    const handleSortTable = (key) => {
        let direction = "ascending";
        if (sortConfigTable && sortConfigTable.key === key && sortConfigTable.direction === "ascending") {
            direction = "descending";
        }
        setSortConfigTable({ key, direction });
    };

    //const sortedDataAreasCampus = dataSedesCampus?.sort((a, b) => {
        dataAreasCampus?.sort((a, b) => {
        if (a[sortConfigTable.key] < b[sortConfigTable.key]) {
            return sortConfigTable.direction === "ascending" ? -1 : 1;
        }
        if (a[sortConfigTable.key] > b[sortConfigTable.key]) {
            return sortConfigTable.direction === "ascending" ? 1 : -1;
        }
        return 0;
    });
    /** fin ordenamiento de columnas */

    const handleChangeCheck = (value, p_area) => {
        let upduserAreasCopy = _.cloneDeep(areaAsistenciaPuestosCopy);
        
        let updUserAreasDelete = _.cloneDeep(areaAsistenciaPuestosDelete);
        if (value === false) {
            updUserAreasDelete.push(upduserAreasCopy?.find(e => Number(e.area_id) === Number(p_area.id_area)));
            upduserAreasCopy = upduserAreasCopy?.filter(e => Number(e.area_id) !== Number(p_area.id_area));
        } else {
            let newItem = {
                "area_id": Number(p_area.id_area),
                "asistenciaPuesto_id":dataAsistenciaPuesto?.id_asistenciaPuesto,
                "id_area": Number(p_area.id_area),
            }
            upduserAreasCopy.push(newItem);
           
        }

        return setAreaAsistenciaPuestosCopy(upduserAreasCopy), setAreaAsistenciaPuestosDelete(updUserAreasDelete);
    }

    const handleChangeCheckGeneral = (e) => {
        let upduserAreasCopy = _.cloneDeep(areaAsistenciaPuestosCopy);
        let updUserAreasDelete = _.cloneDeep(areaAsistenciaPuestosDelete);
        upduserAreasCopy = [];
        updUserAreasDelete = [];
    
        if (e.target.checked === false) {
            dataAreasCampus?.forEach((element) => {
                let newItem = {
                    "area_id": Number(element.id_area),
                    "asistenciaPuesto_id":dataAsistenciaPuesto?.id_asistenciaPuesto,
                     "id_area": element.id_rea
                }
                updUserAreasDelete.push(newItem)
            });
        } else {
            dataAreasCampus?.forEach((element) => {
                let newItem = {
                    "area_id": Number(element.id_area),
                    "asistenciaPuesto_id":dataAsistenciaPuesto?.id_asistenciaPuesto,
                    "id_area": element.id_area
                }
                upduserAreasCopy.push(newItem)
            });
        }
        return setAreaAsistenciaPuestosCopy(upduserAreasCopy), setAreaAsistenciaPuestosDelete(updUserAreasDelete);
    }

    const getAreasBySede = async () => {
        setOpenSmall(true);
        await GeneralService.getAreasBySede(infoCampusMdl?.value)
            .then(result => {
           
                if (result.status === CODES.SUCCESS_200) {
                    setDataAreasCampus(result.data);
                } else {
                    simpleAlerts('No hay información de areas', 'info');
                }
                setOpenSmall(false);
            })
            .catch(err => {
                console.log('Error en getEmpresas', err)
            })

    }

    return (
        <>
            <GroupingSede
                infoCompany={infoCompanyMdl} setInfoCompany={setInfoCompanyMdl}
                infoClient={infoClientMdl} setInfoClient={setInfoClientMdl}
                infoUnit={infoUnitMdl} setInfoUnit={setInfoUnitMdl}
                infoCampus={infoCampusMdl} setInfoCampus={setInfoCampusMdl}
            />
            <SpinnerSmall open={openSmall} />
            <Box className='page-body-main'>
                <Box className='page-body-table page-body-table__modal table-responsive-md'>
                    <table className="table table-hover table-striped table-bordered table-sm">
                        <thead className='sticky-top table-warning'>
                            <tr>
                                {columns.map((column) => (
                                    <th
                                        scope="col"
                                        key={column.id}
                                        style={{
                                            textAlign: column.align,
                                            width: column.width,
                                            maxWidth: column.width,
                                            // backgroundColor: 'red',
                                            // color: 'red',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => handleSortTable(column.id)}
                                    >
                                        {column.id === 'sel' ?
                                            <input type="checkbox"
                                                name="check"
                                                id={`checkbox-rect-header`}
                                                className={'checkbox-rect-header'}
                                                onChange={handleChangeCheckGeneral}
                                            />
                                            : <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <div style={{ marginRight: 'auto' }}>{column.label}</div>
                                                <div><OrderedListOutlined style={{ paddingBottom: 2 }} /></div>
                                            </div>}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody className="table-group-divider">
                            {dataAreasCampus?.map((area, indexRow) => {
                                return (
                                    <tr key={"row" + indexRow}>
                                        {columns.map((column, indexColumn) => {
                                            const value = area[column.id];
                                            return (
                                                <td key={'col' + indexColumn} align={column.align}>
                                                    {column.id === 'desArea' ?
                                                        (area?.desArea) :
                                                        column.id === 'sel' ?
                                                            <div className="checkbox-custom"
                                                                style={{ display: 'flex', justifyContent: 'center' }}
                                                            >
                                                                <input type="checkbox"
                                                                    name="check"
                                                                    id={`checkbox-rect` + area.id_area}
                                                                    checked={areaAsistenciaPuestosCopy?.find(e => Number(e.id_area) === Number(area.id_area)) ? true : false}
                                                                    onChange={(e) => handleChangeCheck(e.target.checked, area)}
                                                                />
                                                                <label htmlFor={`checkbox-rect` + area.id_area}></label>
                                                            </div>
                                                            : value}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </Box>
            </Box >
        </>
    );
};

export default AssociateAreas;