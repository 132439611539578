import React, { } from 'react';
import { ButtonGroup, IconButton, Box } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import RegionService from '../../../../../services/region';
import { colors } from '../../../../../assets/colors';
import { CODES } from '../../../../../utils/responseCodes';
import { simpleAlerts } from '../../../../../utils/alerts';
import _ from 'lodash';
import Swal from 'sweetalert2';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

const columns = [
  { id: 'id_region', label: '#', width: 50, align: 'left', },
  { id: 'desRegion', label: 'Descripción', width: 220, align: 'left', },
  { id: 'actions', label: 'Acciones', width: 80, align: 'center', },
];

const BodyRegions = (props) => {
  const { handleOpen, region, setRegion, setInfoRegion, setOpenModalLoader, setTitleSpinner } = props;

  const url = "'" + window.location.pathname + "'";

 
  const deleteRegion = async (info) => {
    let updDataRegionSedes = _.cloneDeep(region);
    updDataRegionSedes = updDataRegionSedes.filter(e => e.id_region !== info?.id_region);
  

    Swal.fire({
      title: '¿Seguro de eliminar la relación?',
      text: 'Se perdera la relación region - sede',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: colors.secondary.hex,
      cancelButtonColor: colors.primary.hex,
      confirmButtonText: 'Si, eliminar'
    }).then((result) => {
      if (result.isConfirmed) {
        setOpenModalLoader(true);
        setTitleSpinner('Eliminando...');
        setTimeout(async () => {
          try {
           const result = await RegionService.deleteRegion(url, info);
            if (result.status === CODES.DELETE_204) {
              simpleAlerts("Relación eliminada", "success");
              setRegion(updDataRegionSedes);
            }
            return [];
          } catch (error) {
            console.log(`Error en saveAssociateAreas: ${error}`);
          } finally {
            setOpenModalLoader(false);
            setTitleSpinner(null);
          }
        });
      }
    })
  };

  return (
    <Box className='page-body-main'>
      <Box className='page-body-table table-responsive-md'>
        <table className="table table-hover table-striped table-bordered">
          <thead className='sticky-top' style={{ backgroundColor: colors.quaternary.hex, color: '#ffff' }}>
            <tr>
              {columns.map((column) => (
                <th
                  scope="col"
                  key={column.id}
                  style={{
                    textAlign: column.align,
                    width: column.width,
                    maxWidth: column.width
                  }}
                >
                  {column.label}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="table-group-divider">
            {region?.map((regions, indexRow) => {
              return (
                <tr key={"row" + indexRow}>
                  {columns.map((column, indexColumn) => {
                    const value = regions[column.id];
                    return (
                      <td key={'col' + indexColumn} align={column.align}>
                        {column.id === 'actions' ?
                            <ButtonGroup
                              disableElevation
                              variant="contained"
                              aria-label="Disabled elevation buttons"
                            >
                              <IconButton aria-label="delete" size="small" title='Editar'
                                onClick={() => { setInfoRegion(regions); handleOpen() }}
                              >
                                <EditIcon fontSize='small' color={'primary'} />
                              </IconButton>
                              <IconButton aria-label="delete" size="small"
                                  onClick={() => { deleteRegion(regions) }}
                                 
                              >
                              
                                <DeleteForeverIcon fontSize='small' color={'secondary'} />
                              </IconButton>
                            </ButtonGroup> :
                            value}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </Box>
    </Box >
  );
}

export default BodyRegions;