import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import BodyDetailOpenClose from './BodyDetailOpenClose';
import ManageService from '../../../../../services/manage';
import MainInputInfo from '../../../General/MainInputInfo';
import { CODES } from '../../../../../utils/responseCodes';

const DetailOpenClose = (props) => {
    const { setTitleSpinner, setOpenModalLoader, infoSede, dateInit, dateEnd, } = props;

    const url = "'" + window.location.pathname + "'";
    const [sedesOpen, setSedesOpen] = useState([]);

    useEffect(() => {
        if (infoSede?.id_sede)
            getSedesOpenClose();
    }, [infoSede])

    const getSedesOpenClose = async () => {
        try {
            setOpenModalLoader(true);
            setTitleSpinner('Buscando información ...');
            const result = await ManageService.getSedesOpenClose(url, infoSede?.id_sede, dateInit, dateEnd,);
            if (result.status === CODES.SUCCESS_200) {
                setSedesOpen(result?.data);
            }
            return [];
        } catch (error) {
            console.log(`Error en getSedesOpenByUnidad: ${error}`);
        } finally {
            setOpenModalLoader(false);
            setTitleSpinner(null);
        }
    }

    return (
        <Grid container alignItems={'center'} spacing={1}>
            <Grid item xs={6} >
                <MainInputInfo
                    title={'Código Sede:'}
                    value={infoSede?.id_sede + ' - ' + infoSede?.codSede}
                />
            </Grid>
            <Grid item xs={6}>
                <MainInputInfo
                    title={'Sede:'}
                    value={infoSede?.desSede}
                />
            </Grid>
            <Grid item xs={12} pt={5}>
                <BodyDetailOpenClose
                    sedesOpen={sedesOpen}
                />
            </Grid>
        </Grid>
    );
};

export default DetailOpenClose;