import React, { } from 'react';
import { useNavigate, } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import mainImg from '../../assets/img/home/main.png';
import notFoundImg from '../../assets/img/notFound.png';
import MainButton from '../components/General/MainButton';
import { YouTube } from '@mui/icons-material';
import { Box } from '@mui/system';
import { colors } from '../../assets/colors'

const Page404 = ({ pathInit }) => {
    const navigate = useNavigate();
    return (
        <Grid container direction="row" justifyContent="center">
            <Grid item xs={12} md={4} style={{marginTop: '2rem'}}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                    }}
                >
                    <img
                        alt='main image'
                        src={notFoundImg}
                        style={{
                            margin: 0,
                            padding: 0,
                            borderRadius: '10% 50% 50% 50%',
                            //height: '80%'
                            border: `solid 1px ${colors.primary.hex}`,
                        }}
                    />
                </Box>
            </Grid>
            <Grid item xs={12} md={5} style={{marginTop: '2rem'}}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <h1 style={{ fontSize: '3rem', textAlign: 'center' }}>Oops! PÁGINA NO ENCONTRADA</h1>
                    <p>
                        Pagina en mantenimiento, verifique la url o comuniquese con TI!
                    </p>
                    <button className='btn shakeFix-button-main btn-home' type='button'
                        onClick={() => navigate(`${pathInit || '/'}`)}
                    >
                        Home
                    </button>
                </Box>
            </Grid>
        </Grid>
    );
};

export default Page404;