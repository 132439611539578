import React, { useEffect, useState } from 'react';

import { Grid } from "@mui/material";

import GeneralService from '../../services/general';
import { CODES } from '../../utils/responseCodes';
import { simpleAlerts } from '../../utils/alerts';
import MainSelect from './General/MainSelect';

const GroupingUnitByUser = ({
    empresas, clientes, unidades,
    infoCompany, setInfoCompany,
    infoClient, setInfoClient,
    infoUnit, setInfoUnit,
}) => {

    useEffect(() => {
        setInfoClient(null);
        setInfoUnit(null);
    }, [infoCompany])

    useEffect(() => {
        setInfoUnit(null);
    }, [infoClient])


    return (
        <Grid container p={1} spacing={1.2}>
            <Grid item xs={12} sm={6} md={4}>
                <MainSelect
                    title={'Empresa'}
                    options={empresas}
                    placeholder={'Selecionar Empresa'}
                    value={infoCompany?.value}
                    onChange={(value, event) => setInfoCompany(empresas?.find(element => Number(element.value) === Number(value)))}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <MainSelect
                    title={'Cliente'}
                    options={clientes?.filter(e => Number(e.empresa_id) === Number(infoCompany?.value))}
                    placeholder={'Selecionar Cliente'}
                    value={infoClient?.value}
                    onChange={(value, event) => setInfoClient(clientes?.find(element => Number(element.value) === Number(value)))}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <MainSelect
                    title={'Unidad'}
                    options={unidades?.filter(e => Number(e.cliente_id) === Number(infoClient?.value))}
                    placeholder={'Selecionar Unidad'}
                    value={infoUnit?.value}
                    onChange={(value, event) => setInfoUnit(unidades?.find(element => Number(element.value) === Number(value)))}
                />
            </Grid>

        </Grid>
    );
};

export default GroupingUnitByUser;