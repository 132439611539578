import React, { useState, } from 'react';
import { Grid,  } from '@mui/material';
import MainInput from '../../../General/MainInput';
import MainSelect from '../../../General/MainSelect';

const InfoArea = (props) => {
    const { infoArea, setInfoArea, } = props;

    return (
        <>
            <Grid container>
                <Grid item md={6} xs={12} pt={1}>
                    <MainInput
                        title={'Descripción'}
                        value={infoArea?.desArea}
                        name={'desArea'}
                        placeholder={"Ingrese descripción"}
                        onChange={(e) => setInfoArea({ ...infoArea, desArea: e.target.value })}
                        required={true}
                    />
                </Grid>
                <Grid item md={6} xs={12} pt={1}>
                    <MainInput
                        title={'Código'}
                        value={infoArea?.codArea}
                        name={'codArea'}
                        placeholder={"Ingrese código"}
                        onChange={(e) => setInfoArea({ ...infoArea, codArea: e.target.value })}
                        required={true}
                    />
                </Grid>
                <Grid item md={6} xs={12} pt={1}>
                    <MainSelect
                        title='Servicio'
                        placeholder='Seleccionar servicio'
                        options={[{ value: 'Asistencia', label: 'Asistencia' }]}
                        value={infoArea?.servicio}
                        name={'servicio'}
                        onChange={(value, event) => setInfoArea({ ...infoArea, servicio: value })}
                        required={true}
                    />
                </Grid>
            </Grid>
        </>
    );
};

export default InfoArea;