import React, { useState, } from 'react';

import ShiftService from '../../../../../services/shift';
import MainModal from '../../../../components/General/MainModal';
import MainButton from '../../../../components/General/MainButton';
import { CODES } from '../../../../../utils/responseCodes';
import { simpleAlerts } from '../../../../../utils/alerts';
import AssociateSedes from '../../../../components/Shift/AddShiftSede/AssociateSedes';

const AddShiftSede = (props) => {
    const { open, handleClose, setOpenModalLoader, setTitleSpinner, dataShiftSedes, dataShift, getTurnoSedes } = props;
    const url = "'" + window.location.pathname + "'";

    const [shiftSedesDelete, setShiftSedesDelete] = useState([]);
    const [shiftSedesCopy, setShiftSedesCopy] = useState(null);

    const saveAssociateSedes = async (e) => {
        e.preventDefault();
        setOpenModalLoader(true);
        setTitleSpinner('Guardando ...');
        try {
            const savePromises = shiftSedesCopy.map(async (sede) => {
                try {
                    const result = await ShiftService.saveTurnoSedes(url, sede);
                    if (result.status === CODES.CREATE_201 && result.data.id === 0) {
                        simpleAlerts(result.data.message, "success");
                    }
                } catch (error) {
                    console.log(`Error en saveTurnoSedes: ${error}`);
                }
            });

            const deletePromises = shiftSedesDelete.map(async (sede) => {
                try {
                    const result = await ShiftService.deleteTurnoSedes(url, sede);
                    if (result.status === CODES.DELETE_204) {
                        simpleAlerts("Relación eliminada", "success");
                    }
                } catch (error) {
                    console.log(`Error en deleteTurnoSedes: ${error}`);
                }
            });

            await Promise.all([...savePromises, ...deletePromises]);

            simpleAlerts("Fin de asociaciónes", "success");
            getTurnoSedes();
            closeModal();
        } catch (error) {
            console.log(`Error en saveAssociateAreas: ${error}`);
        }
    };

    const closeModal = () => {
        return handleClose(), setShiftSedesDelete([]), setShiftSedesCopy([]);
    }

    return (
        <>
            <MainModal
                size={'lg'}
                open={open}
                handleClose={closeModal}
                formSubmit={saveAssociateSedes}
                title={'Asociar Sedes'}
                subTitle={'Asociar nuevas sedes'}
                bodyModal={
                    <AssociateSedes
                        dataShiftSedes={dataShiftSedes}
                        shiftSedesCopy={shiftSedesCopy}
                        setShiftSedesCopy={setShiftSedesCopy}
                        shiftSedesDelete={shiftSedesDelete}
                        setShiftSedesDelete={setShiftSedesDelete}
                        dataShift={dataShift}


                    />
                }
                buttonActions={[
                    <MainButton
                        key='btn-modal-user-save1'
                        title={'Guardar'}
                        typeStyle={'outlined'}
                        color={'primary'}
                        type={'submit'}
                    />
                ]}
            />
        </>
    );
};

export default AddShiftSede;