import React, { useState, useEffect } from 'react';
import { Route, Routes, } from "react-router-dom";
import "./App.css";
import "./assets/main.scss"
import { ThemeProvider } from '@mui/material/styles';
import theme from './assets/themeConfig'
import MenuService from './services/menu';
import { validateUser } from './utils/function';
import { CODES } from './utils/responseCodes';
import BarHeader from './app/components/BarHeader';
import SideMenu from './app/components/SideMenu';
import Page404 from './app/pages/Page404';
import Home from './app/pages/Home';
import Login from './app/pages/Login/Login';
import User from './app/pages/Users/User';
import Staff from './app/pages/Staff/Staff';
import Assistance from './app/pages/Assistance/Assistance';
import Profiles from './app/pages/Maintenance/Profiles/Profiles';
import Areas from './app/pages/Maintenance/Areas/Areas';
import Shifts from './app/pages/Maintenance/Shifts/Shifts';
import AreasOrganigrama from './app/pages/Maintenance/Organigrama/AreaOrganigrama';
import Activities from './app/pages/Maintenance/Activities/Activities';
import ShiftsSede from './app/pages/Shift/ShiftsSede/ShiftsSede';
import Region from './app/pages/Region/RegionSede/RegionSede';
import UserAreas from './app/pages/Users/UserAreas/UserAreas';
import ProductivityLock from './app/pages/Productivity/ProductivityLock';
import Productivity from './app/pages/Productivity/Productivity';
import OpenSede from './app/pages/Manage/OpenAndClose/OpenSede';
import EditPlanning from './app/pages/Manage/Planning/EditPlanning';
import AssignArea from './app/pages/Manage/AssignArea/AssignArea';
import ReportRegionSede from './app/pages/Report/ReportRegionSede/ReportRegionSede';
import OrganizationalChart from './app/pages/OrganizationalChart/OrganizationalChart';
import RecoverPassword from './app/pages/Users/RecoverPassword';
import AssistanceApprove from './app/pages/Assistance/AssistanceApprove';
import { simpleAlerts } from './utils/alerts';
import { ToastContainer, } from "react-toastify";
import ReportTrama from './app/pages/Report/Trama/ReportTrama'
import ReportProductivity from './app/pages/Report/Productivity/ReportProductivity'
import ReportAssitanceDetail from './app/pages/Report/AssistanceDetail/AssistanceDetail'
import Regions from './app/pages/Maintenance/Regions/Regions';
import AsistenciaPuesto from './app/pages/Maintenance/AsistenciaPuesto/AsistenciaPuesto';
import AreaxAsistenciaPuesto from './app/pages/AsistenciaPuesto/AreaAsistenciaPuesto/AreaAsistenciaPuesto';
import RecursoRRHH from './app/pages/Complementary/RecursoRRHH';
import TecnologiaInformacion from './app/pages/Complementary/TecnologiaInformacion';
import Contabilidad from './app/pages/Complementary/Contabilidad';
import { Global } from './utils/general';
import { Layout, } from "antd";
import OfficeFurnitureReport from './app/pages/OfficeFurniture/OfficeFurnitureReport';
import ReportPersonaActividad from './app/pages/Report/ReportPersonaActividad/ReportPersonaActividad';
const { Content } = Layout;

const pathLogo = require.context('./assets/img/clients', true);
const isMovil = (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
const isDesktop = !window.matchMedia("(max-widimport { useLocation, useNavigate } from 'react-router-dom';th: 768px)").matches;
const userAgent = /Mobi|Android/i.test(navigator.userAgent);


function App() {
  const [validationInfoChange, setValidationInfoChange] = useState(false);

  const [collapsed, setCollapsed] = useState(true);
  const [userToken, setUserToken] = useState(null);
  const [pageTitle, setPageTitle] = useState('Bienvenido!');
  const [menu, setMenu] = useState(null);
  const [logoClient, setLogoClient] = useState(pathLogo('./LP.png'));
  const urlMaster = (window.location.pathname.split('/')[window.location.pathname.split('/').length - 1]).substring(9, 17);
 
  useEffect(() => {
    if (!userToken) {
      setUserToken(JSON.parse(validateUser()))
    } else {
      setTimeout(async () => {
        try {
          const result = await MenuService.getMenu();
          if (result?.status === CODES.SUCCESS_200) {
            if (result?.data?.length === 0) {
              simpleAlerts('Lo sentimos el perfil asignado no tiene ningun permiso!', 'info');
            }
            result?.data?.forEach(element => {
              element.label = element.desPagina
              element.key = element.link
            })
            setMenu(result?.data)
          } else if (result?.response?.status === CODES.BAD_TOKEN_498 || result?.message === 'Network Error') {
            simpleAlerts((result?.response?.data.error || 'Sesión expirada'), 'error');
            localStorage.removeItem('infoAssisProd');
            setUserToken(null)
            window.location.reload();
          }
          return [];
        } catch (error) {
          console.log(`Error en MenuService: ${error}`);
        }
      });
    }
  }, [userToken])

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (validationInfoChange) {
        const confirmationMessage = '¿Estás seguro de que deseas salir de esta página, se perderan los cambios?';
        event.returnValue = confirmationMessage;
        return confirmationMessage;
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [validationInfoChange]);

  return (
    <ThemeProvider theme={theme}>
      <ToastContainer limit={3} position="bottom-right" />
      {urlMaster === 'password' ?
        <Layout style={{ minHeight: "100vh" }}>
          <Layout className="site-layout body-main">
            <Content style={{ padding: '10px' }} >
              <BodyWeb userToken={userToken} setLogoClient={setLogoClient} />
            </Content>
          </Layout>
        </Layout> :
        (userToken ?
          <Layout style={{ minHeight: "100vh" }}>
            <SideMenu validationInfoChange={validationInfoChange} setValidationInfoChange={setValidationInfoChange} collapsed={collapsed} setCollapsed={setCollapsed} menu={menu} setPageTitle={setPageTitle} />
            <Layout className="site-layout body-main">
              <BarHeader validationInfoChange={validationInfoChange} setValidationInfoChange={setValidationInfoChange} userToken={userToken} collapsed={collapsed} setCollapsed={setCollapsed} menu={menu} logoClient={logoClient} pageTitle={pageTitle} setPageTitle={setPageTitle} />
              <Content style={{ padding: '10px' }} >
                <BodyWeb setValidationInfoChange={setValidationInfoChange} userToken={userToken} setLogoClient={setLogoClient} setPageTitle={setPageTitle} />
              </Content>
            </Layout>
          </Layout>
          : <Login setUserToken={setUserToken} />
        )
      }
    </ThemeProvider>
  );
}

function BodyWeb({ setValidationInfoChange, userToken, setLogoClient, setPageTitle }) {
  const pathInit = Global.path;
  const idPerfil = userToken?.user?.perfil?.id_perfil;
  const idPersona = userToken?.user?.id_usuario;
  return (
    <div style={{ height: "100%", }}>
      <Routes>
        <Route path={`${(pathInit || '/')}`} element={<Home />}></Route>
        <Route path={`${(pathInit || '')}/assistance`} element={<Assistance setValidationInfoChange={setValidationInfoChange} userToken={userToken} setLogoClient={setLogoClient} pathLogo={pathLogo} isMovil={isMovil} isDesktop={isDesktop} userAgent={userAgent} setPageTitle={setPageTitle} />}></Route>
        <Route path={`${(pathInit || '')}/assistance/to-approve`} element={<AssistanceApprove userToken={userToken} />}></Route>
        <Route path={`${(pathInit || '')}/productivity/edition`} element={<Productivity setValidationInfoChange={setValidationInfoChange} userToken={userToken} isMovil={isMovil} isDesktop={isDesktop} userAgent={userAgent} setPageTitle={setPageTitle} />}></Route>
        <Route path={`${(pathInit || '')}/user`} element={<User userToken={userToken} />}></Route>
        <Route path={`${(pathInit || '')}/user/areas`} element={<UserAreas userToken={userToken} />}></Route>
        <Route path={`${(pathInit || '')}/staff`} element={<Staff userToken={userToken} />}></Route>
        <Route path={`${(pathInit || '')}/sede/shift`} element={<ShiftsSede userToken={userToken} />}></Route>
        <Route path={`${(pathInit || '')}/profiles`} element={<Profiles userToken={userToken} />}></Route>
        <Route path={`${(pathInit || '')}/areas`} element={<Areas userToken={userToken} />}></Route>
        <Route path={`${(pathInit || '')}/shifts`} element={<Shifts userToken={userToken} />}></Route>
        <Route path={`${(pathInit || '')}/productivity/lock`} element={<ProductivityLock userToken={userToken} />}></Route>
        <Route path={`${(pathInit || '')}/activities`} element={<Activities userToken={userToken} />}></Route>
        <Route path={`${(pathInit || '')}/manage/OpenAndClose/open`} element={<OpenSede userToken={userToken} />}></Route>
        <Route path={`${(pathInit || '')}/manage/plan`} element={<EditPlanning userToken={userToken} />}></Route>
        <Route path={`${(pathInit || '')}/manage/assign-area`} element={<AssignArea userToken={userToken} />}></Route>
        <Route path={`${(pathInit || '')}/report/region-sede`} element={<ReportRegionSede userToken={userToken} />}></Route>
        <Route path={`${(pathInit || '')}/report/consejeros`} element={<ReportTrama userToken={userToken} />}></Route>
        <Route path={`${(pathInit || '')}/report/assistance-detail`} element={<ReportAssitanceDetail userToken={userToken} />}></Route>
        <Route path={`${(pathInit || '')}/organizational-chart`} element={<OrganizationalChart userToken={userToken} />}></Route>
        <Route path={`${(pathInit || '')}/area-organigrama`} element={<AreasOrganigrama userToken={userToken} />}></Route>
        <Route path={`${(pathInit || '')}/recovery-password`} element={<RecoverPassword />}></Route>
        <Route path={`${(pathInit || '')}/maintenance/region`} element={<Region userToken={userToken} />}></Route>
        <Route path={`${(pathInit || '')}/regions`} element={<Regions userToken={userToken} />}></Route>
        <Route path={`${(pathInit || '')}/puestos`} element={<AsistenciaPuesto userToken={userToken} />}></Route>
        <Route path={`${(pathInit || '')}/maintenance/puesto`} element={<AreaxAsistenciaPuesto userToken={userToken} />}></Route>
        <Route path={`${(pathInit || '')}/report/productivity`} element={<ReportProductivity userToken={userToken} />}></Route> 
        <Route path={`${(pathInit || '')}/RRHH`} element={<RecursoRRHH userToken={userToken} />}></Route>
        <Route path={`${(pathInit || '')}/ti`} element={<TecnologiaInformacion userToken={userToken} />}></Route>
        <Route path={`${(pathInit || '')}/contabilidad`} element={<Contabilidad userToken={userToken} />}></Route>
        {(idPerfil === 1 || [61, 65, 60,].includes(idPersona)) &&
          <Route path={`${(pathInit || '')}/office-furniture-report`} element={<OfficeFurnitureReport userToken={userToken} />}></Route>
        }
        <Route path={`${(pathInit || '')}/report/person-activity`} element={<ReportPersonaActividad userToken={userToken} />}></Route>
        {/* <Route path={`${(pathInit || '')}/pruebas`} element={<Pruebas />}></Route> */}
        <Route path="*" element={<Page404 pathInit={pathInit} />}/>
      </Routes>
    </div>
  )
}

export default App;