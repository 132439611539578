import React, { useState, } from 'react';
import { ButtonGroup, IconButton, Box } from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { OrderedListOutlined, } from '@ant-design/icons/lib/icons';
import { colors } from '../../../../assets/colors';
import { CODES } from '../../../../utils/responseCodes';
import { simpleAlerts } from '../../../../utils/alerts';
import { sortDataList, filterData, } from '../../../../utils/function';
import UserService from '../../../../services/user';
import MainWithoutResult from '../../General/MainWithoutResult';
import MainInput from '../../General/MainInput';
import Swal from 'sweetalert2';
import _ from 'lodash';

const columns = [
  { id: 'area_id', label: 'Cód.', width: 50, align: 'left', },
  { id: 'desEmpresa', label: 'Empresa', width: 150, align: 'left', },
  { id: 'desCliente', label: 'Cliente', width: 150, align: 'center', },
  { id: 'desUnidad', label: 'Unidad', width: 150, align: 'center', },
  { id: 'desSede', label: 'Sede', width: 150, align: 'center', },
  { id: 'desArea', label: 'Área', width: 150, align: 'center', },
  { id: 'delete', label: 'Eliminar', width: 80, align: 'center', },
];

const BodyUserAreas = (props) => {
  const { setTitleSpinner, setOpenModalLoader, dataUserAreas, setDataUserAreas, } = props;
  const url = "'" + window.location.pathname + "'";
  const [search, setSearch] = useState('');

  const deleteArea = async (info) => {
    let updDataUserAreas = _.cloneDeep(dataUserAreas);
    updDataUserAreas = updDataUserAreas.filter(e => e.area_id !== info?.area_id);

    Swal.fire({
      title: '¿Seguro de eliminar la relación?',
      text: 'Se perdera la relación usuario - area',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: colors.secondary.hex,
      cancelButtonColor: colors.primary.hex,
      confirmButtonText: 'Si, eliminar'
    }).then((result) => {
      if (result.isConfirmed) {
        setOpenModalLoader(true);
        setTitleSpinner('Eliminando...');
        setTimeout(async () => {
          try {
            const result = await UserService.deleteUsuarioAreas(url, info);
            if (result.status === CODES.DELETE_204) {
              simpleAlerts("Relación eliminada", "success");
              setDataUserAreas(updDataUserAreas);
            }
            return [];
          } catch (error) {
            console.log(`Error en saveAssociateAreas: ${error}`);
          } finally {
            setOpenModalLoader(false);
            setTitleSpinner(null);
          }
        });
      }
    })
  };

  let resultSearch = filterData(dataUserAreas, search, [
    'area_id',
    'area.desArea',
    'area.sede.desSede',
    'area.sede.unidad.desUnidad',
    'area.sede.unidad.cliente.desCliente',
    'area.sede.unidad.cliente.empresa.desEmpresa']);
  // /** inicio ordenamiento de columnas */
  const [sortConfigTable, setSortConfigTable] = useState({ key: null, direction: "ascending" });
  const handleSortTable = (key) => {
    if (key === 'desEmpresa') {
      key = "area.sede.unidad.cliente.desEmpresa";
    }
    if (key === 'desCliente') {
      key = "area.sede.unidad.cliente.desCliente";
    }
    if (key === 'desUnidad') {
      key = "area.sede.unidad.desUnidad";
    }
    if (key === 'desSede') {
      key = "area.sede.desSede";
    }
    if (key === 'desArea') {
      key = "area.desArea";
    }

    let direction = "ascending";
    if (sortConfigTable && sortConfigTable.key === key && sortConfigTable.direction === "ascending") {
      direction = "descending";
    }
    setSortConfigTable({ key, direction });
  };
  const sortedData = sortDataList(resultSearch, sortConfigTable);
  // /** fin ordenamiento de columnas */

  return (
    <>
      <MainInput
        placeholder={'Busqueda por empresa, cliente, unidad, sede, área'}
        style={{ width: '90%', margin: '0 auto 5px auto' }}
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />
      <Box className='page-body-main'>
        <Box className='page-body-table table-responsive-md'>
          <table className="table table-hover table-striped table-bordered">
            <thead className='sticky-top' style={{ backgroundColor: colors.quaternary.hex, color: '#ffff',whiteSpace:'nowrap'  }}>
              <tr>
                {columns.map((column) => (
                  <th
                    scope="col"
                    key={column.id}
                    style={{
                      textAlign: column.align,
                      width: column.width,
                      maxWidth: column.width,
                      cursor: 'pointer',
                    }}
                    onClick={() => handleSortTable(column.id)}
                  >
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <div style={{ marginRight: 'auto' }}>{column.label}</div>
                      <div><OrderedListOutlined style={{ paddingBottom: 2 }} /></div>
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="table-group-divider">
              {sortedData?.map((area, indexRow) => {
                return (
                  <tr key={"row" + indexRow} style={{ whiteSpace:'nowrap' }}>
                    {columns.map((column, indexColumn) => {
                      const value = area[column.id];
                      return (
                        <td key={'col' + indexColumn} align={column.align}>
                          {column.id === 'desEmpresa' ?
                            area?.area?.sede?.unidad?.cliente?.empresa?.desEmpresa :
                            column.id === 'desCliente' ?
                              area?.area?.sede?.unidad?.cliente?.desCliente :
                              column.id === 'desUnidad' ?
                                area?.area?.sede?.unidad?.desUnidad :
                                column.id === 'desSede' ?
                                  area?.area?.sede?.desSede :
                                  column.id === 'desArea' ?
                                    area?.area?.desArea :
                                    column.id === 'delete' ?
                                      <ButtonGroup
                                        disableElevation
                                        variant="contained"
                                        aria-label="Disabled elevation buttons"
                                      >
                                        <IconButton aria-label="delete" size="small" title='Eliminar'
                                          onClick={() => { deleteArea(area) }}
                                        >
                                          <DeleteForeverIcon fontSize='small' color={'secondary'} />
                                        </IconButton>
                                      </ButtonGroup> :
                                      value}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}

              {sortedData?.length === 0 &&
                <MainWithoutResult />
              }
            </tbody>
          </table>
        </Box>
      </Box>
    </>
  );
}

export default BodyUserAreas;