import axios from "axios";
import { Global } from '../utils/general';
import headerRequest from "../utils/headers"

const API_URL = Global.url;
const SYS = Global.system;

class AsistenciaPuestoService {

    getAsistenciaPuesto() {
        let ruta = `${API_URL}/asistenciaPuesto/asistenciaPuesto`;
        return axios
            .get(ruta, {
                headers: headerRequest(),
            })
            .catch((error) => {
                return error;
            });
    }
   
    getAreaAsistenciaPuestos(url, id_asistenciaPuesto) {
        let ruta = `${API_URL}/asistenciaPuesto/asistenciaPuesto/${id_asistenciaPuesto}?sys=${SYS}&url=${url}`;
        return axios.get(ruta, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }


    saveAreaAsistenciaPuestos(url, body) {
        let ruta = `${API_URL}/asistenciaPuesto/areas?sys=${SYS}&url=${url}`;
        return axios
            .post(ruta, body, {
                headers: headerRequest(),
            })
            .catch((error) => {
                return error;
            });
    }

    deleteAreaAsistenciaPuestos(url, body) {
        let ruta = `${API_URL}/asistenciaPuesto/deleteAreaAsistenciaPuestos?sys=${SYS}&url=${url}`;
        return axios.delete(ruta, {
            headers: headerRequest(),
            data: body
        }).catch((error) => {
            return error;
        });
    }

    deleteAsistenciaPuesto(url, body) {
        let ruta = `${API_URL}/asistenciaPuesto/deleteAsistenciaPuesto?sys=${SYS}&url=${url}`;
        return axios.delete(ruta, {
            headers: headerRequest(),
            data: body
        }).catch((error) => {
            return error;
        });
    }

    saveAsistenciaPuesto(url, body) {
        let ruta = `${API_URL}/asistenciaPuesto/saveAsistenciaPuesto?sys=${SYS}&url=${url}`;
        return axios
            .post(ruta, body, {
                headers: headerRequest(),
            })
            .catch((error) => {
                return error;
            });
    }
}

export default new AsistenciaPuestoService();
