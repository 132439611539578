import React, { useEffect, } from 'react';
import { Grid } from "@mui/material";
import MainSelect from './General/MainSelect';

const GroupingAreaByUser = ({ user,
    empresas, clientes, unidades, sedes, areas,
    infoCompany, setInfoCompany,
    infoClient, setInfoClient,
    infoUnit, setInfoUnit,
    infoCampus, setInfoCampus,
    infoArea, setInfoArea,
}) => {

    useEffect(() => {
        setInfoClient(null);
        setInfoUnit(null);
        setInfoCampus(null);
        setInfoArea(null);
    }, [infoCompany])

    useEffect(() => {
        setInfoUnit(null);
        setInfoCampus(null);
        setInfoArea(null);
    }, [infoClient])

    useEffect(() => {
        setInfoCampus(null);
        setInfoArea(null);
    }, [infoUnit])

    useEffect(() => {
        setInfoArea(null);
    }, [infoCampus])

    return (
        <>
            <Grid container style={{ padding: '0.2rem 0.8rem' }}>
                <Grid item xs={12} sm={4}>
                    <MainSelect
                        title={'Empresa'}
                        options={empresas}
                        placeholder={'Selecionar Empresa'}
                        value={infoCompany?.value}
                        onChange={(value, event) => setInfoCompany(empresas?.find(element => element.value === value))}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <MainSelect
                        title={'Cliente'}
                        options={clientes?.filter(e => Number(e.empresa_id) === Number(infoCompany?.value))}
                        placeholder={'Selecionar Cliente'}
                        value={infoClient?.value}
                        onChange={(value, event) => setInfoClient(clientes?.find(element => element.value === value))}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <MainSelect
                        title={'Unidad'}
                        options={unidades?.filter(e => Number(e.cliente_id) === Number(infoClient?.value))}
                        placeholder={'Selecionar Unidad'}
                        value={infoUnit?.value}
                        onChange={(value, event) => setInfoUnit(unidades?.find(element => element.value === value))}
                    />
                </Grid>
            </Grid>
            <Grid container style={{ padding: '0.2rem 0.8rem' }}>
                <Grid item xs={12} sm={6}>
                    <MainSelect
                        title={'Sede'}
                        options={sedes?.filter(e => Number(e.unidad_id) === Number(infoUnit?.value))}
                        placeholder={'Selecionar Sede'}
                        value={infoCampus?.value}
                        onChange={(value, event) => setInfoCampus(sedes?.find(element => element.value === value))}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <MainSelect
                        title={'Area'}
                        options={areas?.filter(e => Number(e.sede_id) === Number(infoCampus?.value))}
                        placeholder={'Selecionar Sede'}
                        value={infoArea?.value}
                        onChange={(value, event) => setInfoArea(areas?.find(element => element.value === value))}
                    />
                </Grid>
            </Grid>
        </>
    );
};

export default GroupingAreaByUser;