import axios from "axios";
import { Global } from '../utils/general';
import headerRequest from "../utils/headers"

const API_URL = Global.url;
const SYS = Global.system;

class StaffService {
    getStaff(url, { search = '', page = 1, pageSize = 20, pagination = '' }) {
        const ruta = `${API_URL}/staff?sys=${SYS}&url=${url}&search=${search}&page=${page}&pageSize=${pageSize}&pagination=${pagination}`;
        return axios.get(ruta, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }

    getPersonBySede(url, { search = '', page = 1, pageSize = 20, pagination = '' }, id) {
        const ruta = `${API_URL}/staff/sede?sys=${SYS}&url=${url}&search=${search}&page=${page}&pageSize=${pageSize}&pagination=${pagination}&id_sede=${id}`;
        return axios.get(ruta, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }

    getPersonBySedeWithAssistance(queryParameters) {
        queryParameters.sys = SYS;
        const ruta = `${API_URL}/staff/sede/with-assistance`;
        return axios.get(ruta, {
            params: queryParameters,
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }

    getPersonByAreaTurnoWithMarca(queryParameters) {
        queryParameters.sys = SYS;
        const ruta = `${API_URL}/staff/area/shift/with-mark`;
        return axios.get(ruta, {
            params: queryParameters,
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }
    
    getPersonBySedeRuna(url, { search = '', page = 1, pageSize = 20, pagination = '' }, id) {
        const ruta = `${API_URL}/staff/sede/runa?sys=${SYS}&url=${url}&search=${search}&page=${page}&pageSize=${pageSize}&pagination=${pagination}&id_sede=${id}`;
        return axios.get(ruta, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }

    getPersonByArea(url, { search = '', page = 1, pageSize = 20, pagination = 0 }, id) {
        const ruta = `${API_URL}/staff/workerArea?sys=${SYS}&url=${url}&search=${search}&page=${page}&pageSize=${pageSize}&pagination=${pagination}&id_area=${id}`;
        return axios.get(ruta, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }

    getStaffWorker(id) {
        const ruta = `${API_URL}/staffWorker/${id}`;
        return axios.get(ruta, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }
}

export default new StaffService();