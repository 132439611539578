import axios from "axios";
import { Global } from '../utils/general';
import headerRequest from "../utils/headers"

const API_URL = Global.url;
const SYS = Global.system;

class ActivityService {
    getTipoActividad() {
        let ruta = `${API_URL}/activity/type`;
        return axios
            .get(ruta, {
                headers: headerRequest(),
            })
            .catch((error) => {
                return error;
            });
    }

    getActividadesByArea(url, id_area, estado = 0) {
        let ruta = `${API_URL}/activity/area/${id_area}?sys=${SYS}&url=${url}&estado=${estado}`;
        return axios.get(ruta, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }

    getActividades() {
        let ruta = `${API_URL}/activity/all`;
        return axios.get(ruta, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }

    // savePerfil(url, body) {
    //     let ruta = `${API_URL}/activity?sys=${SYS}&url=${url}`;
    //     return axios.post(ruta, body, {
    //         headers: headerRequest(),
    //     }).catch((error) => {
    //         return error;
    //     });
    // }
    async saveActivity(url, body) {
        let ruta = `${API_URL}/activity?sys=${SYS}&url=${url}`;
        try {
            const response = await axios.post(ruta, body, {
                headers: headerRequest(),
            });
            return response;
        } catch (error) {
            console.error(error);
            return error;
        }
    }
}

export default new ActivityService();
