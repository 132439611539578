import React, { useEffect, useState } from 'react';
import { Box, Grid, IconButton } from '@mui/material';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import BodyTotalWorker from '../../../components/Manage/AssignArea/BodyTotalWorker';
import BodyAssignedWorker from '../../../components/Manage/AssignArea/BodyAssignedWorker';
import MainButton from '../../../components/General/MainButton';
import { CODES } from '../../../../utils/responseCodes';
import { simpleAlerts } from '../../../../utils/alerts';
import SpinnerLoader from '../../../components/SpinnerLoad/SpinnerLoader';
import MainInput from '../../../components/General/MainInput';
import GeneralService from '../../../../services/general';
import UserService from '../../../../services/user';
import AssignmentService from '../../../../services/assignment';
import MainSelect from '../../../components/General/MainSelect';
import StaffService from '../../../../services/staff';
import Swal from 'sweetalert2';
import { colors } from '../../../../assets/colors';
import _ from 'lodash';
import AssociateWorker from '../../../components/Manage/AssignArea/AssociateWorkerArea';
import MainModal from '../../../components/General/MainModal';
import MainInputInfo from '../../../components/General/MainInputInfo';

const AssignArea = ({ userToken }) => {
    const url = "'" + window.location.pathname + "'";
    const id_usuarioLogin = userToken.user.id_usuario;
    const [openModalLoader, setOpenModalLoader] = useState(false);
    const [titleModalLoader, setTitleModalLoader] = useState(null);

    const [infoCompany, setInfoCompany] = useState(null);
    const [infoClient, setInfoClient] = useState(null);
    const [infoUnit, setInfoUnit] = useState(null);
    const [infoCampus, setInfoCampus] = useState(null);
    const [areas, setAreas] = useState(null);
    const [infoArea, setInfoArea] = useState(null);

    const [dataWorkersTotal, setDataWorkersTotal] = useState(null);
    const [dataWorkersTotalcpy, setDataWorkersTotalcpy] = useState(null);
    const [DataWorkersAssigned, setDataWorkersAssigned] = useState(null);

    const [dataWorkersTotalArea, setDataWorkersTotalArea] = useState([]);
    const [dataWorkersAssignedArea, setDataWorkersAssignedArea] = useState([]);

    const [search, setSearch] = useState('');
    const [searchA, setSearchA] = useState('');

    const [dataWorkerExt, setDataWorkerExt] = useState(null);

    const [searchStaff, setSearchStaff] = useState("");
    const [pageStaff, setPageStaff] = useState(1);
    const pageSizeStaff = 10;

     //* inicio variables para el agrupamiento */
     const [empresas, setEmpresas] = useState([]);
     const [clientes, setClientes] = useState([]);
     const [unidades, setUnidades] = useState([]);
     const [sedes, setSedes] = useState([]);

    const [open, setOpen] = useState(false)
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    useEffect(() => {
        init();
    }, [])

     //* inicio limpieza de datos
     useEffect(() => {
        setInfoClient(null);
        setInfoUnit(null);
        setInfoCampus(null);
    }, [infoCompany]);

    useEffect(() => {
        setInfoUnit(null);
        setInfoCampus(null);
    }, [infoClient]);

    useEffect(() => {
        setInfoCampus(null);
    }, [infoUnit]);

    useEffect(() => {
        setInfoArea(null);
        setDataWorkersTotalArea([]);
        if (infoCampus) {
            getPersonBySede();
        }
    }, [infoCampus])

    useEffect(() => {
        setDataWorkersAssigned(null);
        if (infoArea) {
            getPersonByArea();
        }
    }, [infoArea])

    const init = async () => {
        Promise.all([
            getAgrupamientoByUsuario()
        ]).then(() => setOpenModalLoader(false));
    }

    const getPersonBySede = async () => {
        try {
            setOpenModalLoader(true);
            const result = await StaffService.getPersonBySede(url, { pagination: 1 }, infoCampus?.value);
            if (result.status === CODES.SUCCESS_200) {
                setDataWorkersTotal(result.data);
                setDataWorkersTotalcpy(result.data);
            }
            return []
        } catch (error) {
            console.log(`Error en getPersonBySede: ${error}`);
        } finally {
            setOpenModalLoader(false);
        }
    }

    const getPersonByArea = async () => {
        try {
            setTitleModalLoader("Actualizando informacion");
            setOpenModalLoader(true);
            const result = await StaffService.getPersonByArea(url, {}, infoArea?.value);
            if (result.status === CODES.SUCCESS_200) {
                setDataWorkersAssigned(result.data);
            }
            return []
        } catch (error) {
            console.log(`Error en getPersonByArea: ${error}`);
        } finally {
            setOpenModalLoader(false);
            setTitleModalLoader(null);
        }
    }

    const getAreasBySede = async () => {
        await GeneralService.getAreasBySede(infoCampus?.value).then(result => {
            if (result.status === CODES.SUCCESS_200) {
                result.data?.forEach(element => {
                    element.value = element.id_area
                    element.label = element.desArea
                })
                setAreas(result.data);
            } else {
                simpleAlerts('No hay información de areas', 'info');
            }
        }).catch(err => {
            console.log('Error en getEmpresas', err)
        })
    }

    const saveAsignacionArea = async () => {
        if (infoArea) {
            if (dataWorkersTotalArea?.length === 0) {
                simpleAlerts("Debes seleccionar un trabajador para asignar", "warning");
                return;
            }
            try {
                setTitleModalLoader("Guardando Asignaciones");
                setOpenModalLoader(true);
                const savePromises = dataWorkersTotalArea.map(async (worker) => {
                    let body = {
                        'id_TrabajadorArea': dataWorkersTotal?.find(e => Number(e.id_persona) === Number(worker))?.id_TrabajadorArea
                        , 'id_area': infoArea?.id_area
                        , 'id_persona': worker
                        , 'id_usuario': id_usuarioLogin
                    }
                    try {
                        const result = await AssignmentService.saveAsignacionArea(url, body);
                        if (result.status === CODES.SUCCESS_200) {
                            simpleAlerts(result.data.message, (Number(result.data.status) === 1 ? "success" : "error"));
                        }
                    } catch (error) {
                        console.log(`Error en saveAsignacionArea: ${error}`);
                    }
                });

                await Promise.all([...savePromises]);
                simpleAlerts("Fin de Asignación", "success");
                setOpenModalLoader(false);
                setTitleModalLoader(null);
                getPersonBySede();
                getPersonByArea();
                setDataWorkersTotalArea([]);
            } catch (error) {
                console.log(`Error en saveAsignacionArea: ${error}`);
            }
        } else {
            simpleAlerts("Debes seleccionar Area", "warning");
        }
    }

    const deleteAsignacionArea = async () => {
        try {
            setTitleModalLoader("Guardando Asignaciones");
            setOpenModalLoader(true);
            const savePromises = dataWorkersTotalArea.map(async (worker) => {
                let body = {
                    'id_TrabajadorArea': dataWorkersTotal?.find(e => Number(e.id_persona) === Number(worker))?.id_TrabajadorArea
                    , 'id_area': infoArea?.id_area
                    , 'id_persona': worker
                    , 'id_usuario': id_usuarioLogin
                }
                try {
                    const result = await AssignmentService.saveAsignacionArea(url, body);
                    if (result.status === CODES.SUCCESS_200) {
                        simpleAlerts(result.data.message, (Number(result.data.status) === 1 ? "success" : "error"));
                    }
                } catch (error) {
                    console.log(`Error en saveAsignacionArea: ${error}`);
                }

            });
            await Promise.all([...savePromises]);
            simpleAlerts("Fin de Asignación", "success");
            setOpenModalLoader(false);
            setTitleModalLoader(null);
            getPersonBySede();
            getPersonByArea();
            setDataWorkersTotalArea([]);
        } catch (error) {
            console.log(`Error en saveAsignacionArea: ${error}`);
        }

        if (dataWorkersAssignedArea?.length === 0) {
            simpleAlerts("Debes seleccionar un trabajador asignado", "warning");

        } else {
            Swal.fire({
                title: '¿Seguro de Eliminar la Asignacion?',
                text: 'Perdera todos los datos relacionados a esa Asignacion!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: colors.secondary.hex,
                cancelButtonColor: colors.primary.hex,
                confirmButtonText: 'Si, eliminar Asignacion'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    setOpenModalLoader(true);
                    setTitleModalLoader('Eliminando');

                    try {
                        const savePromises = dataWorkersAssignedArea.map(async (worker) => {
                            try {
                                const result = await AssignmentService.deleteAsignacionArea(url, DataWorkersAssigned?.find(e => Number(e.id_persona) === Number(worker))?.id_TrabajadorArea);
                                if (result.status === CODES.DELETE_204) {
                                    simpleAlerts("Trabajador removido de la lista", "success");
                                }
                            } catch (error) {
                                console.log(`Error en savePromises: ${error}`);
                            }

                        });
                        await Promise.all([...savePromises]);
                        simpleAlerts("Aginacion eliminada", "success");
                        setOpenModalLoader(false);
                        setTitleModalLoader(null);
                        getPersonBySede();
                        getPersonByArea();
                        setDataWorkersTotalArea([]);
                    } catch (error) {
                        console.log(`Error en deleteAsignacionArea: ${error}`);
                    }
                }
            })
        }
    }

    const getAgrupamientoByUsuario = async () => {
        try {
            const result = await UserService.getAgrupamientoByUsuario(url, id_usuarioLogin);
            if (result.status === CODES.SUCCESS_200) {
                if (result.data.areas.length === 0)
                    simpleAlerts('No tiene areas asignadas', "warning");
                setEmpresas(result.data.empresas);
                setClientes(result.data.clientes);
                setUnidades(result.data.unidades);
                setSedes(result.data.sedes);
                setAreas(result.data.areas);
            }
        } catch (error) {
            console.log(`Error en getAgrupamientoByUsuario: ${error}`);
        }
    }

    const unassigned = (e) => {
        if (dataWorkersTotal) {
            let upddataWorkersTotal = _.cloneDeep(dataWorkersTotal);
            if (e.target.checked === true) {
                upddataWorkersTotal = upddataWorkersTotal?.filter((e) => e.id_area === null);
            }
            return setDataWorkersTotalcpy(upddataWorkersTotal);
        }
    };

    let countWorkerTotal;
    let countWorkerAssigned;

    if (dataWorkersTotalcpy === null) {
        countWorkerTotal = 0;
    } else {
        countWorkerTotal = dataWorkersTotalcpy.length;
    }

    if (DataWorkersAssigned === null) {
        countWorkerAssigned = 0;
    } else {
        countWorkerAssigned = DataWorkersAssigned.length;
    }

    const getStaff = async () => {
        if (!searchStaff || searchStaff?.trim() === '') {
            simpleAlerts('No hay ningun dato de busqueda!');
            return;
        }
        if (!infoArea || infoArea === 0 || infoArea?.value === 0) {
            simpleAlerts('Debe seleccionar un área!');
            return;
        }

        handleOpen()
        setOpenModalLoader(true);
        try {
            const result = await StaffService.getStaff(url, { search: searchStaff, page: pageStaff, pageSize: pageSizeStaff, pagination: 0 });
            if (result.status === CODES.SUCCESS_200) {
                if (result.data.data.length === 0) {
                    simpleAlerts('No se encontro información con la palabra clave!', 'error', 'colored');
                }
                setDataWorkerExt(result.data.data);
            } else {
                simpleAlerts(result.response.data.message, 'error');
            }
            return [];
        } catch (error) {
            console.log(`Error en getStaff: ${error}`);
        } finally {
            setOpenModalLoader(false);
        }
    }

    const closeModal = () => {
        return handleClose(), setDataWorkerExt([]), getPersonBySede(), getPersonByArea();
    }

    return (
        <Box sx={{ flexGrow: 1 }} >
            <Grid container direction="row" justifyContent="center" alignItems="center">
            <Grid item xs={12} md={11} style={{ padding: '0.2rem 0.8rem' }}>
                    <Grid container >
                        <Grid item xs={3} >
                            <MainSelect
                                title={'Empresa'}
                                options={empresas}
                                placeholder={'Selecionar Empresa'}
                                value={infoCompany?.value}
                                onChange={(value, event) => setInfoCompany(empresas?.find(element => element.value === value))}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <MainSelect
                                title={'Cliente'}
                                options={clientes?.filter(e => Number(e.empresa_id) === Number(infoCompany?.value))}
                                placeholder={'Selecionar Cliente'}
                                value={infoClient?.value}
                                onChange={(value, event) => setInfoClient(clientes?.find(element => element.value === value))}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <MainSelect
                                title={'Unidad'}
                                options={unidades?.filter(e => Number(e.cliente_id) === Number(infoClient?.value))}
                                placeholder={'Selecionar Unidad'}
                                value={infoUnit?.value}
                                onChange={(value, event) => setInfoUnit(unidades?.find(element => element.value === value))}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <MainSelect
                                title={'Sede'}
                                options={sedes?.filter(e => Number(e.unidad_id) === Number(infoUnit?.value))}
                                placeholder={'Selecionar Sede'}
                                value={infoCampus?.value}
                                onChange={(value, event) => setInfoCampus(sedes?.find(element => element.value === value))}
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={10} md={11}>
                    <Grid container direction="row" justifyContent="space-evenly" alignItems={'center'}>
                        <Grid item xs={12} md={5} mt={1}>
                            <span className="badge text-secondary" style={{ paddingBottom: "0.5rem", fontSize: "0.9rem" }}>TOTAL DE TRABAJADORES:</span>
                            <div style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'end', paddingBottom: '0.5rem' }}>
                                <MainInput
                                    title="Buscar"
                                    placeholder={'Buscar por nombre o codigo'}
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                    style={{ width: '105%' }}
                                />
                                <label>
                                    <input
                                        type="checkbox"
                                        name="check"
                                        id={`checkbox-rect-header`}
                                        className={'checkbox-rect-header'}
                                        onChange={unassigned}
                                    />
                                    Sin asignar
                                </label>
                            </div>

                            <BodyTotalWorker
                                dataWorkersTotalcpy={dataWorkersTotalcpy}
                                setDataWorkersTotal={setDataWorkersTotal}
                                dataWorkersTotalArea={dataWorkersTotalArea}
                                setDataWorkersTotalArea={setDataWorkersTotalArea}
                                search={search}
                            />
                            <MainInputInfo
                                title={'Total:'}
                                value={countWorkerTotal || 0}
                                width={'50%'}
                            />
                        </Grid>

                        <Grid item xs={12} md={1} mt={2}>
                            <Grid container direction="row" justifyContent="center" spacing={3}>
                                <Grid item xs={6} md={12} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                    <MainButton
                                        onClick={saveAsignacionArea}
                                        title={'>'}
                                        color={'success'}
                                    />
                                </Grid>
                                <Grid item xs={6} md={12} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                    <MainButton
                                        onClick={deleteAsignacionArea}
                                        title={'<'}
                                        color={'secondary'}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={5} mt={1}>
                            <span className="badge text-secondary" style={{ paddingBottom: "0.5rem", fontSize: "0.9rem" }}>TRABAJADORES ASIGNADOS:</span>
                            <Grid container style={{ display: "flex", alignItems: "flex-end", marginBottom: "15px" }}>
                                <Grid item xs={4} sm={12} md={4}>
                                    <MainSelect
                                        options={areas?.filter(e => Number(e.sede_id) === Number(infoCampus?.value))}
                                        title="Areas"
                                        placeholder={'Selecionar Área'}
                                        value={infoArea?.value}
                                        onChange={(value, event) => setInfoArea(areas?.find(element => element.value === value))}
                                    />
                                </Grid>
                                <Grid item xs={4} sm={12} md={4}>
                                    <MainInput
                                        title="Buscar"
                                        placeholder={'Buscar por nombre o codigo'}
                                        value={searchA}
                                        onChange={(f) => setSearchA(f.target.value)}

                                    />
                                </Grid>
                                <Grid item xs={4} sm={12} md={4}>
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'end', }}>
                                        <MainInput
                                            title={'Externo'}
                                            placeholder={'codigo / dni / apellido'}
                                            value={searchStaff}
                                            setInfo={setSearchStaff}
                                            onChange={(e) => setSearchStaff(e.target.value)}
                                        />
                                        <IconButton onClick={() => { getStaff(); }} aria-label="trabajador" color="primary" size='small' sx={{ marginTop: 2, border: `solid 0.05rem ${colors.secondary.hex}` }}>
                                            <PersonSearchIcon fontSize={'small'} />
                                        </IconButton>
                                    </div>
                                </Grid>
                                <MainModal
                                    size={'lg'}
                                    open={open}
                                    handleClose={closeModal}
                                    title={'Trabajador externo'}
                                    subTitle={'Asociar trabajador al area'}
                                    bodyModal={
                                        <AssociateWorker
                                            dataWorkerExt={dataWorkerExt}
                                            infoArea={infoArea}
                                            id_usuarioLogin={id_usuarioLogin}
                                        />
                                    }
                                />
                            </Grid>
                            <BodyAssignedWorker
                                DataWorkersAssigned={DataWorkersAssigned}
                                setDataWorkersAssigned={setDataWorkersAssigned}
                                dataWorkersAssignedArea={dataWorkersAssignedArea}
                                setDataWorkersAssignedArea={setDataWorkersAssignedArea}
                                searchA={searchA}
                            />
                            <MainInputInfo
                                title={'Total:'}
                                value={countWorkerAssigned || 0}
                                width={'50%'}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <SpinnerLoader open={openModalLoader} title={titleModalLoader} />
        </Box >
    );
};

export default AssignArea;