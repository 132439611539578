import React, { useEffect, useState } from 'react';
import { Box, } from '@mui/material';
import MainWithoutResult from '../../General/MainWithoutResult';
import { colors } from '../../../../assets/colors';
import { formatDate, nombreDia, nombreMes } from '../../../../utils/function';
import _ from 'lodash';

const BodyPlanByShift = (props) => {
  const { dateInit, primerDiaG, ultimoDiaG, shift, dataPlanning, editPlanning, type, processPlanningData, } = props;

  const [columns, setColumns] = useState(null);

  useEffect(() => {
    let columnsArmado = [
      { id: 'turno', label: 'Turno', width: 7.2, align: 'left', },
      { id: 'trabajador', label: 'Trabajador', width: 12, align: 'left', },
    ];
    for (let fecha = new Date(`${primerDiaG}T00:00:00`); fecha <= new Date(`${ultimoDiaG}T00:00:00`); fecha.setDate(fecha.getDate() + 1)) {
      let fechaString = formatDate(fecha, 'date');
      const diaSemana = nombreDia(fechaString, 's');
      const mes = nombreMes(fechaString, 's');
      const dia = fecha.getDate();
      columnsArmado?.push({ id: fechaString, label: `${diaSemana} ${dia} (${mes})`, width: 8, align: 'center', });
    }
    return setColumns(columnsArmado);
  }, [dateInit])

  return (
    <Box className='page-body-main'>
      <Box className='page-body-table table-responsive-md'>
        <table className="table table-hover table-striped table-bordered">
          <thead className='sticky-top' style={{ backgroundColor: colors.quaternary.hex, color: '#ffff' }}>
            <tr>
              {columns?.map((column) => (
                <th
                  scope="col"
                  key={column.id}
                  style={{
                    textAlign: column.align,
                    width: `${column.width}rem`,
                  }}
                >
                  {column.label}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="table-group-divider">
            {shift?.map((data, indexRow) => {
              return (
                <RowPlanning
                  key={'shift' + indexRow}
                  shift={data}
                  columns={columns}
                  data={dataPlanning}
                  editPlanning={editPlanning}
                  type={type}
                  processPlanningData={processPlanningData}
                />
              );
            })}
            {(shift?.length === 0 || !shift) &&
              <MainWithoutResult />
            }
          </tbody>
        </table>
      </Box>
    </Box>
  );
}

const RowPlanning = ({ shift, columns, data, editPlanning, type, processPlanningData }) => {
  return (
    <tr>
      <td key={'col' + shift.id_turno} align={'left'}
        style={{
          width: `${columns?.find(e => e.id === 'turno')?.width}rem`,
          height: '7rem',
        }}
      >
        {shift.id_turno + '-' + shift?.desTurno}
      </td>
      <td colSpan={999999}>
        <ColPlanning
          shift={shift}
          columns={columns}
          data={data?.filter(e => Number(e.turno_id) === Number(shift?.value))}
          editPlanning={editPlanning}
          type={type}
          processPlanningData={processPlanningData}
        />
      </td>
    </tr>
  )
}

const ColPlanning = ({ shift, columns, data, editPlanning, type, processPlanningData }) => {
  let columnsTmp = _.cloneDeep(columns);
  columnsTmp?.shift();

  const datosPlanificada = processPlanningData(data, type)

  return (
    datosPlanificada?.map((dato, idxDato) => {
      return (
        <div
          key={'worker' + idxDato + dato?.id_persona}
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
          }}
        >
          {columnsTmp?.map((col) => {
            const value = dato[col.id];
            return (
              <div
                key={col.id}
                style={{
                  textAlign: col.align,
                  width: `${col.width}rem`,
                }}
              >
                {col.id === 'trabajador' ?
                  <label
                    className='badge-planificacion'
                    style={{
                      backgroundColor: '#EBEBEC',
                      fontSize: 'xx-small',
                      color: 'black',
                      cursor: 'default',
                      fontWeight: 'bold',
                      letterSpacing: '.02rem',
                    }}
                  >
                    {value}
                  </label>
                  :
                  <label
                    className='badge-planificacion'
                    style={{
                      backgroundColor: (value?.split("/")[2] || '#DC3545'),
                      fontSize: 'x-small'
                    }}
                    title={(value?.split("/")[5] || '')}
                    onClick={() => editPlanning(col.id, dato, shift, value?.split("/")[0])}
                  >
                    {value ?
                      (value?.split("/")[1])
                      : 'No planificado'}
                  </label>
                }
              </div>
            )
          })}
        </div>
      )
    })
  )
}

export default BodyPlanByShift;