import React, { useState, useEffect } from 'react';
import { ButtonGroup, IconButton, Box, TextField, } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import MainWithoutResult from '../../General/MainWithoutResult';
import { colors } from '../../../../assets/colors';
import { formatDate } from '../../../../utils/function';
import Swal from 'sweetalert2';
import _ from 'lodash';

const columnsTurno = [
  { id: 'id_productividadD', label: '#', width: 50, align: 'left', },
  { id: 'codSghh', label: 'Código', width: 100, align: 'center', },
  { id: 'trabajador', label: 'Nombre', width: 220, align: 'left', },
  { id: 'horaInicio', label: 'H. Inicio', width: 120, align: 'center', edit: true, type: 'datetime', },
  { id: 'horaFin', label: 'H. Fin', width: 120, align: 'center', edit: true, type: 'datetime', },
  { id: 'produccion', label: 'Prod. x Turno', width: 120, align: 'center', type: 'number', format: (value) => parseFloat(value)?.toFixed(4), },
  { id: 'actions', label: 'Eliminar', width: 80, align: 'center', },
];

const columnsPersona = [
  { id: 'id_productividadD', label: '#', width: 50, align: 'left', },
  { id: 'codSghh', label: 'Código', width: 100, align: 'center', },
  { id: 'trabajador', label: 'Nombre', width: 220, align: 'left', },
  { id: 'horaInicio', label: 'H. Inicio', width: 120, align: 'center', type: 'datetime', format: (value) => formatDate(new Date(value), 'time', 'short') },
  { id: 'horaFin', label: 'H. Fin', width: 120, align: 'center', type: 'datetime', format: (value) => formatDate(new Date(value), 'time', 'short') },
  { id: 'produccion', label: 'Prod. x Persona', width: 120, align: 'center', edit: true, type: 'number', format: (value) => parseFloat(value)?.toFixed(4), },
  { id: 'actions', label: 'Eliminar', width: 80, align: 'center', },
];

const BodyEdition = (props) => {
  const { infoCampus, productivityC, dataProductivity, deleteWorkerProd, setDeleteWorkerProd, prodTurno, prodPersona } = props;

  const [columns, setColumns] = useState([]);

  useEffect(() => {
    if (Number(infoCampus?.tipoProd) === 1)
      setColumns(columnsTurno);
    else
      setColumns(columnsPersona);
  }, [infoCampus])

  const changeValue = (idx, key, value) => {
    let updDataProductivity = _.cloneDeep(dataProductivity);
    if (columns?.find(e => e.id === key)?.type === 'datetime') {
      let fechaSinHora = updDataProductivity[idx][key]?.split(' ')[0];
      let nuevaHoraCompleta = `${fechaSinHora} ${value}:00.000`;
      updDataProductivity[idx][key] = nuevaHoraCompleta;
    } else {
      updDataProductivity[idx][key] = value;
    }

    if (Number(infoCampus?.tipoProd) === 1)
      prodTurno(updDataProductivity, productivityC)
    else
      prodPersona(updDataProductivity, productivityC)
  }

  const deleteWorker = (persona) => {
    Swal.fire({
      title: '¿Seguro de eliminar al trabajador?',
      text: 'Se actualizara los calculos de la productividad',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: colors.secondary.hex,
      cancelButtonColor: colors.primary.hex,
      confirmButtonText: 'Si, eliminar'
    }).then((result) => {
      if (result.isConfirmed) {

        let updDeleteWorkerProd = _.cloneDeep(deleteWorkerProd);
        updDeleteWorkerProd.push(persona)

        let updDataProductivity = dataProductivity?.filter((e) => e !== persona);
        if (Number(infoCampus?.tipoProd) === 1)
          prodTurno(updDataProductivity, productivityC)
        else
          prodPersona(updDataProductivity, productivityC)
        setDeleteWorkerProd(updDeleteWorkerProd);
      }
    })
  }

  return (
    <Box className='page-body-main'>
      <Box className='page-body-table table-responsive-md'>
        <table className="table table-hover table-striped table-bordered">
          <thead className='sticky-top' style={{ backgroundColor: colors.quaternary.hex, color: '#ffff' }}>
            <tr>
              {columns.map((column) => (
                <th
                  scope="col"
                  key={column.id}
                  style={{
                    textAlign: column.align,
                    minWidth: column.width,
                  }}
                >
                  {column.label}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="table-group-divider">
            {dataProductivity?.map((productivity, indexRow) => {
              return (
                <tr key={"row" + indexRow}>
                  {columns.map((column, indexColumn) => {
                    const value = productivity[column.id];
                    return (
                      <td key={'col' + indexColumn} align={column.align}>
                        {column.id === 'actions' ? (
                          <ButtonGroup disableElevation variant="contained" aria-label="Disabled elevation buttons">
                            <IconButton aria-label="delete" size="small"
                              onClick={() => deleteWorker(productivity)}
                            >
                              <CancelIcon fontSize='small' color={'secondary'} />
                            </IconButton>
                          </ButtonGroup>
                        ) : column.edit ? (
                          <TextField
                            fullWidth
                            className="input"
                            size='small'
                            type={column.type === 'datetime' ? 'time' : column.type}
                            value={(column.type === 'datetime' ? formatDate(new Date(value), 'time', 'short') : value) || ''}
                            onChange={(e) => changeValue(indexRow, column.id, e.target.value)}
                            inputProps={{
                              type: (column.type === 'datetime' ? 'time' : column.type),
                              min: (column.type === 'datetime' ? '00:00' : 0),
                              step: (column.type === 'datetime' ? '' : 0.0001),
                              style: { fontSize: 12, backgroundColor: 'white' },
                            }}
                            InputLabelProps={{ style: { fontSize: 12 } }} // font size of input label
                          />
                        ) : column.format ? (
                          column.type === 'datetime' ?
                            column.format(value)
                            : (isNaN(column.format(value)) ? '' : column.format(value))
                        ) : (
                          value
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })}

            {(dataProductivity?.length === 0 || !dataProductivity) &&
              <MainWithoutResult />
            }
          </tbody>
        </table>
      </Box>
    </Box>
  );
}

export default BodyEdition;