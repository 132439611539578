import React from 'react';
import { IconButton, Grid, } from '@mui/material';
import AttachEmailIcon from '@mui/icons-material/AttachEmail';
import DeleteIcon from '@mui/icons-material/Delete';
import MainInput from '../../../General/MainInput';

const PlanningByExcel = (props) => {
    const { fechaIniExcel, setFechaIniExcel, fechaFinExcel, setFechaFinExcel, templatePlanning, setTemplatePlanning, } = props;

    return (
        <Grid container justifyContent="center" alignItems={'end'}>
            <Grid item xs={5}>
                <MainInput
                    title={'Fecha desde'}
                    value={fechaIniExcel}
                    name={'FecDes'}
                    type={'date'}
                    onChange={(e) => setFechaIniExcel(e.target.value)}
                    propsInput={{
                        // min: getDateOL(),
                        // max: getDateOL('', 'short', 7),
                        type: 'date',
                    }}
                />
            </Grid>
            <Grid item xs={5}>
                <MainInput
                    title={'Fecha hasta'}
                    value={fechaFinExcel}
                    name={'FecDes'}
                    type={'date'}
                    onChange={(e) => setFechaFinExcel(e.target.value)}
                    propsInput={{
                        // min: getDateOL(),
                        // max: getDateOL('', 'short', 7),
                        type: 'date',
                    }}
                />
            </Grid>
            <Grid item xs={templatePlanning?.nameFile ? 12 : 2} >
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'end', }}>
                    {templatePlanning?.nameFile ?
                        <div style={{ marginTop: '10px', }}>
                            <label className="group-upload__contenido--label">{templatePlanning?.nameFile || 'vacio'}</label>
                            <IconButton aria-label="delete" size="small" color='secondary'
                                onClick={(e) => setTemplatePlanning({ ...templatePlanning, file: null, nameFile: '' })}
                            >
                                <DeleteIcon fontSize="inherit" />
                            </IconButton>
                        </div>
                        : <>
                            <input
                                type="file"
                                id={`fileInput-template-planning`}
                                accept=".xlsx"
                                name='conformidadFile' style={{ display: 'none' }}
                                onChange={(e) => setTemplatePlanning({ ...templatePlanning, file: e.target.files[0], nameFile: e.target.files[0].name })}
                            />
                            <button type="button" className="btn btn-outline-success"
                                title="Seleccionar plantilla"
                                onClick={() => document.getElementById(`fileInput-template-planning`).click()}
                            >
                                <AttachEmailIcon fontSize='medium' />
                            </button>
                        </>
                    }
                </div>
            </Grid>
        </Grid>
    );
};

export default PlanningByExcel;