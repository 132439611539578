import React, { useState } from 'react';
import { OrderedListOutlined, } from '@ant-design/icons/lib/icons';
import { Box } from '@mui/material';
import MainWithoutResult from '../../General/MainWithoutResult';
import MainPagination from '../../General/MainPagination';
import { sortDataListSimple } from '../../../../utils/function';

const columns = [
    { id: 'sel', label: 'Sel.', width: 10, align: 'left', },
    { id: 'id_persona', label: '#', width: 18, align: 'center', },
    { id: 'codSghh', label: 'Código', width: 100, align: 'left', },
    { id: 'trabajador', label: 'Nombres', width: 100, align: 'left', },
];

const BodyWorkerModal = (props) => {
    const { dataWorkers, page, setPage, totalPages, handleChangeCheckGeneral, handleChangeCheck, newWorkers } = props;

    /** inicio ordenamiento de columnas */
    const [sortConfigTable, setSortConfigTable] = useState({ key: null, direction: "ascending", });

    const handleSortTable = (key) => {
        let direction = "ascending";
        if (sortConfigTable && sortConfigTable.key === key && sortConfigTable.direction === "ascending") {
            direction = "descending";
        }
        setSortConfigTable({ key, direction });
    };

    const sortedData = sortDataListSimple(dataWorkers, sortConfigTable);
    /** fin ordenamiento de columnas */

    return (
        <Box className='page-body-main'>
            <Box className='page-body-table page-body-table__modal table-responsive-md'>
                <table className="table table-hover table-striped table-bordered table-sm">
                    <thead className='sticky-top table-warning'>
                        <tr>
                            {columns.map((column) => (
                                <th
                                    scope="col"
                                    key={column.id}
                                    style={{
                                        textAlign: column.align,
                                        width: column.width,
                                        maxWidth: column.width,
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => handleSortTable(column.id)}
                                >
                                    {column.id === 'sel' ?
                                        <input type="checkbox"
                                            name="check"
                                            id={`checkbox-rect-header`}
                                            className={'checkbox-rect-header'}
                                            onChange={handleChangeCheckGeneral}
                                        />
                                        : <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <div style={{ marginRight: 'auto' }}>{column.label}</div>
                                            <div><OrderedListOutlined style={{ paddingBottom: 2 }} /></div>
                                        </div>}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody className="table-group-divider">
                        {sortedData?.map((person, indexRow) => {
                            return (
                                <tr key={"row" + indexRow}>
                                    {columns.map((column, indexColumn) => {
                                        const value = person[column.id];
                                        return (
                                            <td key={'col' + indexColumn} align={column.align}>
                                                {column.id === 'sel' ?
                                                    <div className="checkbox-custom"
                                                        style={{ display: 'flex', justifyContent: 'center' }}
                                                    >
                                                        <input type="checkbox"
                                                            name="check"
                                                            id={`checkbox-rect` + person.id_persona}
                                                            checked={newWorkers?.find(e => Number(e.persona_id) === Number(person.id_persona)) ? true : false}
                                                            onChange={(e) => handleChangeCheck(e.target.checked, person)}
                                                        />
                                                        <label htmlFor={`checkbox-rect` + person.id_persona}></label>
                                                    </div>
                                                    : value}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                        {(dataWorkers?.length === 0 || !dataWorkers) &&
                            <MainWithoutResult />
                        }
                    </tbody>
                </table>
            </Box>
            {totalPages > 1 &&
                <MainPagination
                    size={'small'}
                    totalPages={totalPages}
                    page={page}
                    setPage={setPage}
                />
            }
        </Box>
    );
};

export default BodyWorkerModal;