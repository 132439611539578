import React, { } from 'react';
import { Box } from '@mui/material';
import { colors } from '../../../../../assets/colors';

const columns = [
  { id: 'id_area', label: 'Cód', width: 50, align: 'left', },
  { id: 'desArea', label: 'Área', width: 120, align: 'left', },
  { id: 'fecha', label: 'Fecha', width: 90, align: 'left', },
  { id: 'estado', label: 'Estado', width: 90, align: 'left', },
  { id: 'usuario', label: 'Usuario', width: 90, align: 'center', },
  { id: 'fechaCierre', label: 'Fecha de proceso', width: 90, align: 'center', },
];

const BodyDetailOpenClose = (props) => {
  const { sedesOpen } = props;

  return (
    <Box className='page-body-main'>
      <Box className='page-body-table page-body-table__modal table-responsive-md'>
        <table className="table table-hover table-striped table-bordered table-sm">
          <thead className='sticky-top' style={{ backgroundColor: colors.quaternary.hex, color: '#ffff' }}>
            <tr>
              {columns.map((column) => (
                <th
                  scope="col"
                  key={column.id}
                  style={{
                    textAlign: column.align,
                    width: column.width,
                    maxWidth: column.width
                  }}
                >
                  {column.label}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="table-group-divider">
            {sedesOpen?.map((sede, indexRow) => {
              return (
                <tr key={"row" + indexRow}>
                  {columns.map((column, indexColumn) => {
                    const value = sede[column.id];
                    return (
                      <td key={'col' + indexColumn} align={column.align} style={{ fontSize: "11px" }}>
                        {value}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </Box>


    </Box>
  );
}

export default BodyDetailOpenClose;