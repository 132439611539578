import axios from "axios";
import { Global } from '../utils/general';
import headerRequest from "../utils/headers"

const API_URL = Global.url;
const SYS = Global.system;

class AreaOrgService {
    saveAreaOrg(url, body) {
        let ruta = `${API_URL}/organizational?sys=${SYS}&url=${url}`;
        console.log("🚀 ~ file: areaOrg.js:12 ~ AreaOrgService ~ saveAreaOrg ~ ruta:", ruta)
        return axios
            .post(ruta, body, {
                headers: headerRequest(),
            })
            .catch((error) => {
                return error;
            });
    }

    saveAssingAreaOrg(url, body) {
        let ruta = `${API_URL}/organizational/saveAssing?sys=${SYS}&url=${url}`;
        return axios
            .post(ruta, body, {
                headers: headerRequest(),
            })
            .catch((error) => {
                return error;
            });
    }

    getAreaOrganigrama() {
        let ruta = `${API_URL}/organizational/organigrama`;
        return axios
            .get(ruta, {
                headers: headerRequest(),
            })
            .catch((error) => {
                return error;
            });
    }

    getAreasOrg() {
        let ruta = `${API_URL}/organizational/areaOrg`;
        return axios
            .get(ruta, {
                headers: headerRequest(),
            })
            .catch((error) => {
                return error;
            });
    }

    getOrganigramaArea(url, id_areaOrg) {
        let ruta = `${API_URL}/organizational/area/${id_areaOrg}?sys=${SYS}&url=${url}`;
        return axios.get(ruta, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }

    saveTrabajadorArea(url, body) {
        let ruta = `${API_URL}/organizational/area-worker?sys=${SYS}&url=${url}`;
        return axios
            .post(ruta, body, {
                headers: headerRequest(),
            })
            .catch((error) => {
                return error;
            });
    }

    deleteWorkerAreaOrg(url, body) {
        let ruta = `${API_URL}/organizational/area-worker?sys=${SYS}&url=${url}`;
        return axios.delete(ruta, {
            headers: headerRequest(),
            data: body
        }).catch((error) => {
            return error;
        });
    }
}

export default new AreaOrgService();
