import React, { useState, } from 'react';
import { IconButton, Grid, Box, } from '@mui/material';
import { OrderedListOutlined, } from '@ant-design/icons/lib/icons';
import SearchIcon from '@mui/icons-material/Search';
import MainSelect from '../../../General/MainSelect';
import MainInput from '../../../General/MainInput';
import SpinnerSmall from '../../../SpinnerLoad/SpinnerSmall';
import { filterData, sortDataListSimple } from '../../../../../utils/function';
import { colors } from '../../../../../assets/colors';
import _ from 'lodash';

const columns = [
    { id: 'sel', label: 'Sel.', width: 10, align: 'center', },
    { id: 'codSghh', label: 'Código', width: 50, align: 'left', },
    { id: 'trabajador', label: 'Nombre', width: 150, align: 'left', },
];

const Incidence = (props) => {
    const { openSmall, shift, id_usuarioLogin, id_personaLogin, planningType, dataWorkers,
        fechaDesde, fechaHasta, fechaIncidencia, setFechaIncidencia, valuePlanType, setValuePlanType,
        shiftPlan, setShiftPlan, workerPlan, setWorkerPlan, infoArea,
    } = props;

    const [search, setSearch] = useState('');

    let resultSearch = filterData(dataWorkers, search, ['codSghh', 'trabajador']);
    /** inicio ordenamiento de columnas */
    const [sortConfigTable, setSortConfigTable] = useState({ key: null, direction: "ascending", });
    const handleSortTable = (key) => {
        let direction = "ascending";
        if (sortConfigTable && sortConfigTable.key === key && sortConfigTable.direction === "ascending") {
            direction = "descending";
        }
        setSortConfigTable({ key, direction });
    };
    const sortedData = sortDataListSimple(resultSearch, sortConfigTable);
    /** fin ordenamiento de columnas */

    const handleChangeCheck = (value, p_worker) => {
        let updWorkerPlan = _.cloneDeep(workerPlan);
        if (value === false) {
            updWorkerPlan = updWorkerPlan?.filter(e => Number(e.persona_id) !== Number(p_worker.id_persona));
        } else {
            updWorkerPlan = updWorkerPlan?.filter(e => Number(e.id_persona) !== Number(p_worker.id_persona));
            let newItem = {
                "persona_id": Number(p_worker.id_persona),
                "turno_id": Number(shiftPlan),
                "area_id": Number(infoArea?.value),
                "supervisor_id": id_personaLogin,
                "tipPla_id": 1,
                "usuario_id": id_usuarioLogin,
            }
            updWorkerPlan.push(newItem)
        }

        return setWorkerPlan(updWorkerPlan);
    }

    const handleChangeCheckGeneral = (e) => {
        let updWorkerPlan = [];
        if (e.target.checked === true) {
            dataWorkers?.forEach((element) => {
                let newItem = {
                    "persona_id": Number(element.id_persona),
                    "turno_id": Number(shiftPlan),
                    "area_id": Number(infoArea?.value),
                    "supervisor_id": id_personaLogin,
                    "tipPla_id": 1,
                    "usuario_id": id_usuarioLogin,
                }
                updWorkerPlan.push(newItem)
            });
        }

        return setWorkerPlan(updWorkerPlan);
    }

    return (
        <Grid container >
            <Grid item xs={4} marginTop={'0.5rem'}>
                <MainSelect
                    title={'Turno'}
                    placeholder={'Selecionar turno'}
                    options={shift}
                    value={Number(shiftPlan)}
                    onChange={(value, e) => { setShiftPlan(value) }}
                    name='turno'
                />
            </Grid>
            <Grid item xs={4} marginTop={'0.5rem'}>
                <MainSelect
                    title={'Incidencia'}
                    placeholder={'Selecionar Incidencia'}
                    options={planningType?.filter(e => Number(e.incidencia) === 1)}
                    value={Number(valuePlanType)}
                    onChange={(value, e) => { setValuePlanType(value) }}
                    name='incidencia'
                />
            </Grid>
            <Grid item xs={4} marginTop={'0.5rem'}>
                <MainInput
                    title={'Fecha incidencia'}
                    value={fechaIncidencia}
                    name={'fechaIncidencia'}
                    propsInput={{
                        min: fechaDesde,
                        max: fechaHasta,
                        type: 'date',
                    }}
                    onChange={(e) => setFechaIncidencia(e.target.value)}
                />
            </Grid>

            <Grid item xs={12}
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'end',
                }}
            >
                <Box
                    width={'100%'}
                >
                    <MainInput
                        placeholder={'Buscar trabajador'}
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                </Box>
                <IconButton aria-label="trabajador" color="primary" size='small' sx={{ marginTop: 2, border: `solid 0.05rem ${colors.secondary.hex}` }}>
                    <SearchIcon fontSize={'small'} />
                </IconButton>
            </Grid>

            <Grid item xs={12} marginTop={'1rem'}>
                <SpinnerSmall open={openSmall} />
                <Box className='page-body-main'>
                    <Box className='page-body-table page-body-table__modal table-responsive-md' sx={{ height: '45vh !important' }}>
                        <table className="table table-hover table-striped table-bordered table-sm">
                            <thead className='sticky-top table-warning'>
                                <tr>
                                    {columns.map((column) => (
                                        <th
                                            scope="col"
                                            key={column.id}
                                            style={{
                                                textAlign: column.align,
                                                width: column.width,
                                                maxWidth: column.width,
                                                cursor: 'pointer',
                                            }}
                                            onClick={() => handleSortTable(column.id)}
                                        >
                                            {column.id === 'sel' ?
                                                <input type="checkbox"
                                                    name="check"
                                                    id={`checkbox-rect-header`}
                                                    className={'checkbox-rect-header'}
                                                    onChange={handleChangeCheckGeneral}
                                                />
                                                : <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                    <div style={{ marginRight: 'auto' }}>{column.label}</div>
                                                    <div><OrderedListOutlined style={{ paddingBottom: 2 }} /></div>
                                                </div>}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody className="table-group-divider">
                                {sortedData?.map((work, indexRow) => {
                                    return (
                                        <tr key={"row" + indexRow}>
                                            {columns.map((column, indexColumn) => {
                                                const value = work[column.id];
                                                return (
                                                    <td key={'col' + indexColumn} align={column.align}>
                                                        {column.id === 'desSede' ?
                                                            (work?.sede?.desSede) :
                                                            column.id === 'sel' ?
                                                                <div className="checkbox-custom"
                                                                    style={{ display: 'flex', justifyContent: 'center' }}
                                                                >
                                                                    <input type="checkbox"
                                                                        name="check"
                                                                        id={`checkbox-rect` + work.id_persona}
                                                                        checked={workerPlan?.find(e => Number(e.persona_id) === Number(work.id_persona)) ? true : false}
                                                                        onChange={(e) => handleChangeCheck(e.target.checked, work)}
                                                                    />
                                                                    <label htmlFor={`checkbox-rect` + work.id_persona}></label>
                                                                </div>
                                                                : value}
                                                    </td>
                                                );
                                            })}
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </Box>
                </Box >
            </Grid>
        </Grid>
    );
};

export default Incidence;