import React, { useState } from 'react';
import { ButtonGroup, IconButton, Box } from '@mui/material';
import { OrderedListOutlined, } from '@ant-design/icons/lib/icons';
import EditIcon from '@mui/icons-material/Edit';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import AreaService from '../../../../../services/area';
import { colors } from '../../../../../assets/colors';
import { CODES } from '../../../../../utils/responseCodes';
import { simpleAlerts } from '../../../../../utils/alerts';
import { sortDataListSimple } from '../../../../../utils/function';
import Swal from 'sweetalert2';
const columns = [
  { id: 'id_area', label: '#', width: 50, align: 'left', },
  { id: 'desArea', label: 'Descripción', width: 220, align: 'left', },
  { id: 'codArea', label: 'Cód.', width: 120, align: 'center', },
  { id: 'servicio', label: 'Servicio', width: 120, align: 'center', },
  { id: 'actions', label: 'Acciones', width: 80, align: 'center', },
];

const BodyAreas = (props) => {
  const { handleOpen, id_usuarioLogin, dataAreas, setInfoArea, getAreasBySede,setOpenModalLoader,setTitleSpinner } = props;

  const url = "'" + window.location.pathname + "'";

  // /** inicio ordenamiento de columnas */
  const [sortConfigTable, setSortConfigTable] = useState({ key: null, direction: "ascending", });

  const handleSortTable = (key) => {
    let direction = "ascending";
    if (sortConfigTable && sortConfigTable.key === key && sortConfigTable.direction === "ascending") {
      direction = "descending";
    }
    setSortConfigTable({ key, direction });
  };

  const sortedDataAreas = sortDataListSimple(dataAreas, sortConfigTable);
  // /** fin ordenamiento de columnas */

  const deleteArea = async (info) => {
    let data = {
      ...info,
      usuarioMod: id_usuarioLogin,
    };
    Swal.fire({
      title: '¿Seguro de Eliminar el Area?',
      text: 'Perdera todos los datos relacionados a esa Area!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: colors.secondary.hex,
      cancelButtonColor: colors.primary.hex,
      confirmButtonText: 'Si, eliminar Area'
    }).then((result) => {
      if (result.isConfirmed) {
        setOpenModalLoader(true);
        setTitleSpinner('Eliminando');
        setTimeout(async () => {
          try {
            const result = await AreaService.deleteArea(url, data?.id_area);
            if (result.status === CODES.DELETE_204) {
              simpleAlerts('Área eliminada', 'success');
              getAreasBySede();
            } else {
              simpleAlerts(result.response.data.message, 'error');
            }
            return []
          } catch (err) {
            console.log(`Error en deleteArea ${err}`);
          } finally {
            setOpenModalLoader(false);
          }
        });
      }
    })
  }

  return (
    <Box className='page-body-main'>
      <Box className='page-body-table table-responsive-md'>
        <table className="table table-hover table-striped table-bordered">
          <thead className='sticky-top' style={{ backgroundColor: colors.quaternary.hex, color: '#ffff' }}>
            <tr>
              {columns.map((column) => (
                <th
                  scope="col"
                  key={column.id}
                  style={{
                    textAlign: column.align,
                    width: column.width,
                    maxWidth: column.width,
                    cursor: 'pointer',
                  }}
                  onClick={() => (column.id !== 'actions' && handleSortTable(column.id))}
                >
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div style={{ marginRight: 'auto' }}>{column.label}</div>
                    {column.id !== 'actions' && <div><OrderedListOutlined style={{ paddingBottom: 2 }} /></div>}
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="table-group-divider">
            {sortedDataAreas?.map((area, indexRow) => {
              return (
                <tr key={"row" + indexRow}>
                  {columns.map((column, indexColumn) => {
                    const value = area[column.id];
                    return (
                      <td key={'col' + indexColumn} align={column.align}>
                        {column.id === 'actions' ?
                          <ButtonGroup
                            disableElevation
                            variant="contained"
                            aria-label="Disabled elevation buttons"
                          >
                            <IconButton aria-label="edit" size="small" title='Editar'
                              onClick={() => { setInfoArea(area); handleOpen() }}
                            >
                              <EditIcon fontSize='small' color={'primary'} />
                            </IconButton>
                            <IconButton aria-label="delete" size="small" title='Eliminar'
                              onClick={() => { deleteArea(area); }}
                            >
                              <DeleteSweepIcon fontSize='small' color={'secondary'} />
                            </IconButton>
                          </ButtonGroup> :
                          value}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </Box>
    </Box >
  );
}

export default BodyAreas;