import React from 'react';
import MainInput from '../../../General/MainInput';

const HoursTime = ({ hourIn, setHourIn, hourOut, setHourOut, }) => {

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'space-evenly',
            }}
        >
            <MainInput
                title={'Inicio'}
                type={'time'}
                value={hourIn}
                onChange={(e) => setHourIn(e.target.value)}
            />
            <MainInput
                title={'Fin'}
                type={'time'}
                value={hourOut}
                onChange={(e) => setHourOut(e.target.value)}
            />
        </div>
    );
};

export default HoursTime;