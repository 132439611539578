import React, { useEffect, useState } from 'react';
import { Box, Grid, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import BodyShiftSede from '../../../components/Shift/BodyShiftSede/BodyShiftSede';
import AddShiftSede from './AddShiftSede/AddShiftSede';
import MainButton from '../../../components/General/MainButton';
import MainSelect from '../../../components/General/MainSelect';
import ShiftService from '../../../../services/shift'
import { CODES } from '../../../../utils/responseCodes';
import { simpleAlerts } from '../../../../utils/alerts';
import SpinnerLoader from '../../../components/SpinnerLoad/SpinnerLoader';

const ShiftsSede = ({ userToken }) => {
    const url = "'" + window.location.pathname + "'";
    const [titleSpinner, setTitleSpinner] = useState(null);
    const [openModalLoader, setOpenModalLoader] = useState(true);
    const [open, setOpen] = useState(false)
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [shifts, setShift] = useState(null);
    const [dataShift, setDataShift] = useState(null);
    const [dataShiftSedes, setDataShiftSedes] = useState(null);

    useEffect(() => {
        init();
    }, [])

    useEffect(() => {
        if (dataShift?.id_turno)
            getTurnoSedes();
    }, [dataShift])

    const init = async () => {
        Promise.all([
            getTurno(),
        ]).then(() => setOpenModalLoader(false));
    }

    const getTurno = async () => {
        try {
            const result = await ShiftService.getTurno();
            if (result.status === CODES.SUCCESS_200) {
                result.data?.forEach(element => {
                    element.value = element.id_turno;
                    element.label = element.desTurno + ' (' + element.ingreso?.substring(0, 5) + '  -  ' + element.salida?.substring(0, 5) + ')';
                })
                setShift(result.data);
            } else {
                simpleAlerts('Hubo un error la relacion usuarios areas', 'error');
            }
            return [];
        } catch (error) {
            console.log(`Error en getUsuarioAreas: ${error}`);
        }
    }

    const getTurnoSedes = async () => {
        setOpenModalLoader(true);
        setTitleSpinner('Cargando...');
        try {
            const result = await ShiftService.getRelacionTurnoSedebyTurno(url, dataShift?.id_turno);
            if (result.status === CODES.SUCCESS_200)
                setDataShiftSedes(result.data);
            else
                simpleAlerts('Hubo un error la relacion turno sede', 'error');
            return [];
        } catch (error) {
            console.log(`Error en getTurnoSedes: ${error}`);
        } finally {
            setOpenModalLoader(false);
            setTitleSpinner(null);
        }
    }

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AddShiftSede
                open={open}
                handleClose={handleClose}
                setOpenModalLoader={setOpenModalLoader}
                setTitleSpinner={setTitleSpinner}
                dataShift={dataShift}
                dataShiftSedes={dataShiftSedes}
                getTurnoSedes={getTurnoSedes}
            />
            <Box display={'flex'} justifyContent={'space-evenly'} alignItems={'center'}>
                <Box sx={{ width: '30%' }}>
                    <MainSelect
                        title={'Turnos'}
                        options={shifts}
                        value={Number(dataShift?.value)}
                        onChange={(value, event) => setDataShift(shifts?.find(element => Number(element.value) === Number(value)))}
                    />
                </Box>
                <Box display={'flex'} justifyContent={'space-evenly'} alignItems={'end'}>
                    <IconButton aria-label="search-areas" color="primary" sx={{ border: 'solid 0.05rem' }} size='small'
                        onClick={(e) => { (dataShift?.id_turno ? getTurnoSedes() : simpleAlerts('¡Debe Seleccionar un turno!')) }}
                    >
                        <SearchIcon fontSize='1px' />
                    </IconButton>
                    &nbsp;
                    <MainButton
                       // onClick={handleOpen}
                        onClick={(e) => { (dataShift?.id_turno ? handleOpen() : simpleAlerts('¡Debe Seleccionar un Turno!')) }}
                        title={'Asociar Sedes'}
                        typeStyle={'outlined'}
                        color={'primary'}
                    />
                </Box>
            </Box>

            <Grid container
                direction="row"
                justifyContent="center"
                alignItems="center"
                pt={2}
            >
                <Grid item xs={12} sm={10} paddingLeft={'0.2rem'} paddingRight={'0.2rem'}>
                    <BodyShiftSede
                        setTitleSpinner={setTitleSpinner}
                        setOpenModalLoader={setOpenModalLoader}
                        dataShiftSedes={dataShiftSedes}
                        setDataShiftSedes={setDataShiftSedes}
                    />
                </Grid>

            </Grid>

            <SpinnerLoader open={openModalLoader} title={titleSpinner} />
        </Box>
    );
};

export default ShiftsSede;