import React from 'react';

const FaultsWorker = ({ assistanceCondition, faultSelected, setFaultSelected,}) => {
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'space-evenly',
            }}
        >
            <fieldset
                className='fieldset'
            >
                <legend className='title-legend'>Tipo de Ausencia</legend>
                <select class="form-select form-select-sm" aria-label="Small select example"
                    value={faultSelected}
                    onChange={(event) => {
                        setFaultSelected(event.target.value);
                    }}
                >
                    <option selected value={0}>Seleccionar</option>
                    {assistanceCondition?.map((opt, idx) => {
                        return (
                            <option value={opt.value} key={`faults-${idx}`}>{opt.label}</option>
                        );
                    })}
                </select>
            </fieldset>
        </div>
    );
};

export default FaultsWorker;