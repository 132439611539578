import React from 'react';

const Workstation = ({ workStation, workStationSel, setWorkStationSel, }) => {
    return (
        <div style={{ display: 'flex', justifyContent: 'space-evenly', }}>
            <fieldset className='fieldset'>
                <legend className='title-legend'>Puesto</legend>
                <select className="form-select form-select-sm" aria-label="Small select example"
                    value={workStationSel || 0}
                    onChange={(event) => { setWorkStationSel(event.target.value); }}
                >
                    <option value={0}>Seleccionar</option>
                    {workStation?.map((opt, idx) => {
                        return (
                            <option value={opt.value} key={`faults-${idx}`}>{opt.label}</option>
                        );
                    })}
                </select>
            </fieldset>
        </div>
    );
};

export default Workstation;