import React, { useState, useEffect } from 'react';
import { Grid, Box, IconButton } from '@mui/material';
import { OrderedListOutlined, } from '@ant-design/icons/lib/icons';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MainInput from '../../General/MainInput';
import SpinnerSmall from '../../SpinnerLoad/SpinnerSmall';
import MailsService from '../../../../services/mails';
import UserService from '../../../../services/user';
import AssistanceService from '../../../../services/assistance';
import { sortDataListSimple, filterData } from '../../../../utils/function';
import { CODES } from '../../../../utils/responseCodes';
import { simpleAlerts } from '../../../../utils/alerts';

const columns = [
    { id: 'id_persona', label: '#', width: 10, align: 'center', },
    { id: 'codSghh', label: 'Código', width: 50, align: 'left', },
    { id: 'trabajador', label: 'Nombres', width: 100, align: 'left', },
    { id: 'send', label: 'Solicitar', width: 50, align: 'center', },
];

const AuthorizationAssistance = (props) => {
    const { id_personaLogin, id_usuarioLogin, cleanInfo, dataAssistanceCondition, nameLogin, setOpenModalLoader, setTitleLoader,
        areasOpt, infoCompany, infoClient, infoUnit, infoCampus, shift, valueArea, typeAssistance,
        markIn, markOut, breakLunch, dateInitMassive, dateEndMassive,
        workersAssistance, view, setView, infoDelete,
    } = props;

    const url = "'" + window.location.pathname + "'";
    const [openSmall, setOpenSmall] = useState(true);
    const [dataWorkers, setDataWorkers] = useState(null);
    const [search, setSearch] = useState('');

    let resultSearch = filterData(dataWorkers, search, ['codSghh', 'trabajador']);

    /** inicio ordenamiento de columnas */
    const [sortConfigTable, setSortConfigTable] = useState({ key: null, direction: "ascending", });
    const handleSortTable = (key) => {
        let direction = "ascending";
        if (sortConfigTable && sortConfigTable.key === key && sortConfigTable.direction === "ascending") {
            direction = "descending";
        }
        setSortConfigTable({ key, direction });
    };
    const sortedData = sortDataListSimple(resultSearch, sortConfigTable); //sortDataListSimple(dataWorkers, sortConfigTable);
    /** fin ordenamiento de columnas */

    useEffect(() => {
        getBossesApproveAssistance();
    }, [])

    const getBossesApproveAssistance = async () => {
        try {
            setOpenSmall(true);
            const result = await UserService.getBossesApproveAssistance(url, infoCampus?.value);
            if (result.status === CODES.SUCCESS_200) {
                setDataWorkers(result.data);
            }
            return []
        } catch (error) {
            console.log(`Error en getPersonBySede: ${error}`);
        } finally {
            setOpenSmall(false);
        }
    }

    const saveRequestApprove = async (info) => {
        const body = {
            Inicio: dateInitMassive,
            Fin: dateEndMassive,
            turno_id: +shift,
            area_id: +valueArea,
            tipoAsis_id: typeAssistance,
            ingreso: markIn,
            salida: markOut,
            refrigerio: breakLunch,
            supervisor_id: id_personaLogin,
            usuario_id: id_usuarioLogin,
            aprobador_id: info.id_persona,
            solicitante_id: id_personaLogin,
            trabajadores: workersAssistance,
            conMarca: (dataAssistanceCondition?.find(e=>+e.id_asisCond === typeAssistance)?.conMarca ||0)
        }
        setOpenModalLoader(true);
        setTitleLoader('Generando solicitud!');
        try {
            const result = await AssistanceService.saveRequestApprove(url, body);
            if (result.status === CODES.SUCCESS_200) {
                simpleAlerts(result?.data?.message || 'Generado!', 'success');
                sendRequest(info);
            } else {
                setOpenModalLoader(false);
                setTitleLoader(null);
                cleanInfo();
                simpleAlerts(result?.response?.data?.error || result?.response?.data?.message || 'Hubo un error!', 'error');
            }
            return []
        } catch (error) {
            console.log(`Error en saveRequestApprove: ${error}`);
        }
    }

    const sendRequest = async (info) => {
        const body = {
            desEmpresa: infoCompany.label,
            desCliente: infoClient.label,
            desUnidad: infoUnit.label,
            desSede: infoCampus.label,
            desArea: areasOpt?.find(e => +e.value === (valueArea ? +valueArea : +infoDelete.area_id))?.label,
            solicitante: nameLogin,
            id_persona: info.id_persona,
            aprobador: info.trabajador,
            Inicio: dateInitMassive,
            Fin: dateEndMassive,
            type: (view === 2 ? 'Aprobación asistencia futuras' : 'Elimnación de asistecia')
        }
        try {
            setOpenModalLoader(true);
            setTitleLoader('Enviando solicitud!');
            const result = await MailsService.sendAuthorizationRequest(url, body);
            if (result.status === CODES.SUCCESS_200) {
                simpleAlerts(result?.data?.message || 'Enviado!', 'success');
            } else {
                simpleAlerts('No se pudo enviar correo al aprobador, comuniquese con él!', 'error');
            }
            return []
        } catch (error) {
            console.log(`Error en sendRequest: ${error}`);
        } finally {
            setOpenModalLoader(false);
            setTitleLoader(null);
            cleanInfo();
        }
    }

    const searcher = (e) => {
        setSearch(e.target.value);
    }

    const handleReturnAssistance = () => {
        setView(1);
    }

    //ELIMINAR 
    const saveRequestDelete = async (info) => {
        const body = {
            ...infoDelete,
            aprobador_id: info.id_persona,
            solicitante_id: id_personaLogin,
            usuarioSol_id: id_usuarioLogin,
        }
        setOpenModalLoader(true);
        setTitleLoader('Generando solicitud!');
        try {
            const result = await AssistanceService.saveRequestDelete(url, body);
            if (result.status === CODES.SUCCESS_200) {
                simpleAlerts(result?.data?.message || 'Generado!', 'success');
                sendRequest(info);
            } else {
                setOpenModalLoader(false);
                setTitleLoader(null);
                cleanInfo();
                simpleAlerts(result?.response?.data?.error || 'Hubo un error!', 'error');
            }
            return []
        } catch (error) {
            console.log(`Error en saveRequestApprove: ${error}`);
        }
    }

    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'end' }}>
                {view === 2 &&
                    <IconButton aria-label="regresar" size="small" style={{ border: 'solid 1px' }} onClick={handleReturnAssistance}>
                        <ArrowBackIcon fontSize="inherit" />
                    </IconButton>
                }
                <div style={{ width: '80%' }}>
                    <MainInput
                        placeholder={'Busqueda por Nombres / Código'}
                        value={search}
                        onChange={searcher}
                    />
                </div>
            </div>
            <Grid container>
                <Grid item xs={12} mt={2}>
                    <SpinnerSmall open={openSmall} />
                    <Box className='page-body-main'>
                        <Box className='page-body-table page-body-table__modal table-responsive-md'>
                            <table className="table table-hover table-striped table-bordered table-sm">
                                <thead className='sticky-top table-warning'>
                                    <tr style={{ fontSize: '12px' }}>
                                        {columns.map((column) => (
                                            <th
                                                scope="col"
                                                key={column.id}
                                                style={{
                                                    textAlign: column.align,
                                                    width: column.width,
                                                    maxWidth: column.width,
                                                    cursor: 'pointer',
                                                }}
                                                onClick={() => handleSortTable(column.id)}
                                            >
                                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                    <div style={{ marginRight: 'auto' }}>{column.label}</div>
                                                    {(column.id !== 'id_persona' && column.id !== 'send') &&
                                                        <div><OrderedListOutlined style={{ paddingBottom: 2 }} /></div>
                                                    }
                                                </div>
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody className="table-group-divider">
                                    {sortedData?.map((row, indexRow) => {
                                        return (
                                            <tr key={"row" + indexRow} style={{ fontSize: '12px' }}>
                                                {columns.map((column, indexColumn) => {
                                                    const value = row[column.id];
                                                    return (
                                                        <td key={'col' + indexColumn} align={column.align}>
                                                            {column.id === 'send' ?
                                                                <IconButton aria-label="send" size="small" style={{ border: 'solid 1px' }}
                                                                    onClick={() => view === 2 ? saveRequestApprove(row) : saveRequestDelete(row)}
                                                                >
                                                                    <ForwardToInboxIcon fontSize="inherit" />
                                                                </IconButton>
                                                                : value}
                                                        </td>
                                                    );
                                                })}
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </>
    );
};

export default AuthorizationAssistance;