import axios from "axios";
import { Global } from '../utils/general';
import headerRequest from "../utils/headers"

const API_URL = Global.url;

class SaleService {
    getVentas(dateStart,dateEnd) {
        const ruta = `${API_URL}/sale?date1=${dateStart}&date2=${dateEnd}`;
        return axios
        .get(ruta, {
            headers: headerRequest(),
        })
        .catch((error) => {
            return error;
        });
    }

    exportSalesExcel = function (date1, date2) {
        let ruta = `${API_URL}/report/sales/${date1}/${date2}`;
        return ruta;
    }
}



export default new SaleService();