import React, { useState, useEffect } from 'react';
import { Grid, } from '@mui/material'
import UserService from '../../../../services/user';
import ActivityService from '../../../../services/activity';
import GeneralService from '../../../../services/general';
import MainButton from '../../../components/General/MainButton';
import MainSelect from '../../../components/General/MainSelect';
import GroupingAreaByUser from '../../../components/GroupingAreaByUser';
import BodyActivities from '../../../components/Maintenance/Activities/BodyActivities';
import SpinnerLoader from '../../../components/SpinnerLoad/SpinnerLoader';
import { colors } from '../../../../assets/colors';
import { simpleAlerts } from '../../../../utils/alerts';
import { CODES } from '../../../../utils/responseCodes';
import _ from 'lodash';

const Activities = ({ userToken }) => {
    const url = "'" + window.location.pathname + "'";
    const id_usuarioLogin = userToken.user?.id_usuario;
    const [openModalLoader, setOpenModalLoader] = useState(true);
    const [titleSpinner, setTitleSpinner] = useState(null);

    //* inicio variables para el agrupamiento */
    const [empresas, setEmpresas] = useState([]);
    const [clientes, setClientes] = useState([]);
    const [unidades, setUnidades] = useState([]);
    const [sedes, setSedes] = useState([]);
    const [areas, setAreas] = useState([]);

    const [infoCompany, setInfoCompany] = useState(null);
    const [infoClient, setInfoClient] = useState(null);
    const [infoUnit, setInfoUnit] = useState(null);
    const [infoCampus, setInfoCampus] = useState(null);
    const [infoArea, setInfoArea] = useState(null);
    //* fin variables para el agrupamiento */

    /*********************** */
    const [states, setStates] = useState(null);
    const [typeActivity, setTypeActivity] = useState(null);
    const [valueTypeActivity, setValueTypeActivity] = useState(null);
    const [activitiesMaster, setActivitiesMaster] = useState(null);
    const [activities, setActivities] = useState(null);

    useEffect(() => {
        init();
    }, [])

    useEffect(() => {
        if (infoArea?.value)
            getActividadesByArea();
    }, [infoArea])

    useEffect(() => {
        if (valueTypeActivity === -1) {
            setActivities(activitiesMaster);
        } else if (valueTypeActivity > 0) {
            setActivities(activitiesMaster?.filter(e => Number(e.tipoActividad_id) === Number(valueTypeActivity)));
        }
    }, [valueTypeActivity])

    useEffect(() => {
        setActivities(null);
        setActivitiesMaster(null);
    }, [infoCompany, infoClient, infoUnit, infoCampus])

    const init = async () => {
        Promise.all([
            getEstados(),
            getAgrupamientoByUsuario(),
            getTipoActividad(),
        ]).then(() => setOpenModalLoader(false));
    }

    const getEstados = async () => {
        try {
            const result = await GeneralService.getEstados();
            if (result.status === CODES.SUCCESS_200) {
                result.data?.forEach(element => {
                    element.id = Number(element.corr3)
                    element.value = Number(element.corr3)
                    element.label = element.desc1?.trim()
                })
                setStates(result.data);
            }
            return []
        } catch (error) {
            console.log(`Error en getEstados: ${error}`);
        }
    }

    const getTipoActividad = async () => {
        try {
            const result = await await ActivityService.getTipoActividad();
            if (result.status === CODES.SUCCESS_200) {
                result.data?.forEach(element => {
                    element.id = Number(element.corr3)
                    element.value = Number(element.corr3)
                    element.label = element.desc1
                })
                let tmp = result.data;
                tmp.unshift({ id: -1, value: -1, label: 'Todo' });
                setTypeActivity(result.data);
            }
            return []
        } catch (error) {
            console.log(`Error en getTipoActividad: ${error}`);
        }
    }

    const getActividadesByArea = async () => {
        setOpenModalLoader(true);
        setTitleSpinner('Buscando actividades ...');
        try {
            const result = await await ActivityService.getActividadesByArea(url, infoArea?.value);
            if (result.status === CODES.SUCCESS_200) {
                setActivities(result.data);
                setActivitiesMaster(result.data);
            }
        } catch (error) {
            console.log(`Error en getActividadesByArea: ${error}`);
        } finally {
            setOpenModalLoader(false);
            setTitleSpinner(null);
        }
    }

    const getAgrupamientoByUsuario = async () => {
        try {
            const result = await UserService.getAgrupamientoByUsuario(url, id_usuarioLogin);
            if (result.status === CODES.SUCCESS_200) {
                if (result.data.areas.length === 0)
                    simpleAlerts('No tiene areas asignadas', "warning");
                setEmpresas(result.data.empresas);
                setClientes(result.data.clientes);
                setUnidades(result.data.unidades);
                setSedes(result.data.sedes);
                setAreas(result.data.areas);
            }
        } catch (error) {
            console.log(`Error en getAgrupamientoByUsuario: ${error}`);
        }
    }

    const createActivity = () => {
        if (!infoArea || !infoArea?.value || Number(infoArea?.value) === 0) {
            simpleAlerts('Debe seleccionar una área!', 'warning');
            return;
        }
        if (!valueTypeActivity || Number(valueTypeActivity) === 0 || Number(valueTypeActivity) === -1) {
            simpleAlerts('Debe seleccionar un tipo de actividad!', 'warning');
            return;
        }

        const newActivity = {
            area_id: Number(infoArea?.value),
            desActividad: null,
            estado: 1,
            metaProduccion: 0,
            tipoActividad_id: valueTypeActivity,
            edit: 0
        }
        let updActivities = _.cloneDeep(activities);
        updActivities.push(newActivity);
        setActivities(updActivities);
    }

    return (
        <div>
            <SpinnerLoader open={openModalLoader} title={titleSpinner} />

            <Grid container spacing={1} justifyContent={'center'} alignItems={'center'}>
                <Grid item xs={12} sm={10} md={8}
                    sx={{
                        border: `solid 0.05rem ${colors.primary.hex}`,
                        borderRadius: '25px',
                        paddingBottom: 1,
                    }}
                >
                    <GroupingAreaByUser
                        user={id_usuarioLogin}
                        empresas={empresas} clientes={clientes} unidades={unidades} sedes={sedes} areas={areas}
                        infoCompany={infoCompany} setInfoCompany={setInfoCompany}
                        infoClient={infoClient} setInfoClient={setInfoClient}
                        infoUnit={infoUnit} setInfoUnit={setInfoUnit}
                        infoCampus={infoCampus} setInfoCampus={setInfoCampus}
                        infoArea={infoArea} setInfoArea={setInfoArea}
                    />
                </Grid>
            </Grid>

            <section aria-label='button-filter' style={{ paddingTop: 8, }}>
                <Grid container
                    style={{ padding: '0.2rem 0.8rem',}}
                    direction="row"
                    justifyContent="center"
                    alignItems="end"
                    spacing={3}
                >
                    <Grid item xs={2}>
                        <MainSelect
                            title={'Tipo de Actividades'}
                            options={typeActivity}
                            placeholder={'Selecionar Cliente'}
                            value={Number(valueTypeActivity)}
                            onChange={(value, event) => setValueTypeActivity(Number(value))}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <MainButton
                            size={'medium'}
                            onClick={getActividadesByArea}
                            title={'Cargar'}
                            typeStyle={'outlined'}
                            fullWidth={true}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <MainButton
                            size={'medium'}
                            onClick={createActivity}
                            title={'Crear'}
                            color={'success'}
                            typeStyle={'outlined'}
                            fullWidth={true}
                        />
                    </Grid>
                </Grid>
            </section>

            <section aria-label='body-edition-productivity' style={{ paddingTop: 8 }}>
                <Grid container spacing={2} justifyContent={'center'} alignItems={'center'}>
                    <Grid item xs={12} sm={10} md={8}>
                        <BodyActivities
                            states={states}
                            typeActivity={typeActivity}
                            activities={activities}
                            setActivities={setActivities}
                            setOpenModalLoader={setOpenModalLoader}
                            setTitleSpinner={setTitleSpinner}
                        />
                    </Grid>
                </Grid>
            </section>
        </div>
    );
};

export default Activities;