import React, { useState, useEffect } from 'react';
import { Box, } from '@mui/material';
import { OrderedListOutlined, } from '@ant-design/icons/lib/icons';
import { colors } from '../../../../assets/colors';
import { sortDataListSimple, filterData } from '../../../../utils/function';
import _ from 'lodash';

const columns = [
  { id: 'sel', label: 'Sel.', width: 10, align: 'center', },
  { id: 'codSghh', label: 'Codigo', width: 20, align: 'left', },
  { id: 'trabajador', label: 'Trabajador', width: 200, align: 'left', },
  { id: 'desPuesto', label: 'Puesto', width: 100, align: 'left', },
  { id: 'desArea', label: 'Área', width: 100, align: 'left', }
];

const BodyTotalWorker = (props) => {
  const { dataWorkersTotalcpy, dataWorkersTotalArea, setDataWorkersTotalArea, search } = props;

  let resultSearch = filterData(dataWorkersTotalcpy, search, ['codSghh', 'trabajador']);

  /** inicio ordenamiento de columnas */
  const [sortConfigTable, setSortConfigTable] = useState({ key: null, direction: "ascending", });
  const handleSortTable = (key) => {
    let direction = "ascending";
    if (sortConfigTable && sortConfigTable.key === key && sortConfigTable.direction === "ascending") {
      direction = "descending";
    }
    setSortConfigTable({ key, direction });
  };
  const sortedDataWorkersTotal = sortDataListSimple(resultSearch, sortConfigTable);
  /** fin ordenamiento de columnas */

  const handleChangeCheck = (value, p_worker) => {
    let updTotalWorkerCopy = _.cloneDeep(dataWorkersTotalArea);
    if (value === false) {
      let index = updTotalWorkerCopy?.indexOf(p_worker.id_persona);
      if (index !== -1) {
        updTotalWorkerCopy.splice(index, 1);
      }
    } else {
      let index = updTotalWorkerCopy?.indexOf(p_worker.id_persona);
      if (index === -1) {
        updTotalWorkerCopy.push(p_worker.id_persona);
      }
    }

    return setDataWorkersTotalArea(updTotalWorkerCopy);
  }

  const handleChangeCheckGeneral = (e) => {
    let updTotalWorkerCopy = [];//contenedor

    if (e.target.checked === false) {
      updTotalWorkerCopy = [];
    } else {
      dataWorkersTotalcpy?.forEach((element) => {
        updTotalWorkerCopy.push(element.id_persona);
      });
    }

    return setDataWorkersTotalArea(updTotalWorkerCopy);
  }

  return (
    <>
      <Box className='page-body-main'>
        <Box className='page-body-table table-responsive-md' sx={{ height: "20rem", maxHeight: "30rem" }}>
          <table className="table table-hover table-bordered">
            <thead className='sticky-top' style={{ backgroundColor: colors.quaternary.hex, color: '#ffff' }}>
              <tr>
                {columns.map((column) => {
                  return (
                    <th
                      scope="col"
                      key={column.id + "BodyTotalWorker"}
                      style={{
                        textAlign: column.align,
                        width: column.width,
                        cursor: 'pointer',
                      }}
                      onClick={() => handleSortTable(column.id)}
                    >
                      {column.id === 'sel' ?
                        <input type="checkbox"
                          name="check"
                          id={`checkbox-rect-header`}
                          className={'checkbox-rect-header'}
                          onChange={handleChangeCheckGeneral}
                        />
                        : <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                          <div style={{ marginRight: 'auto' }}>{column.label}</div>
                          <div><OrderedListOutlined style={{ paddingBottom: "" }} /></div>
                        </div>}
                    </th>)
                })}
              </tr>
            </thead>
            <tbody className="table-group-divider">
              {sortedDataWorkersTotal?.map((workertotal, indexRow) => {
                return (
                  <tr key={"row" + indexRow}>
                    {columns.map((column, indexColumn) => {
                      const value = workertotal[column.id];
                      return (
                        <td key={'col' + indexColumn} align={column.align}>
                          {
                            column.id === 'sel' ?
                              <div className="checkbox-custom"
                                style={{ display: 'flex', justifyContent: 'center' }}
                              >
                                <input type="checkbox"
                                  name="check"
                                  id={`checkbox-rect` + workertotal.id_persona + "Total"}
                                  checked={dataWorkersTotalArea?.find(e => Number(e) === Number(workertotal.id_persona)) ? true : false}
                                  onChange={(e) => handleChangeCheck(e.target.checked, workertotal)}
                                />
                                <label htmlFor={`checkbox-rect` + workertotal.id_persona + "Total"}></label>
                              </div> :
                              value}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Box>
      </Box >
    </>
  );
};

export default BodyTotalWorker;