import React, { useEffect, useState } from 'react';
import { OrderedListOutlined, } from '@ant-design/icons/lib/icons';
import { IconButton, Grid, InputAdornment, Input, Box } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import MainWithoutResult from '../../General/MainWithoutResult';
import MainPagination from '../../General/MainPagination';
import SpinnerSmall from '../../SpinnerLoad/SpinnerSmall';
import StaffService from '../../../../services/staff';
import { CODES } from '../../../../utils/responseCodes';
import { colors } from '../../../../assets/colors';
import { sortDataListSimple, formatDate, globalIndex } from '../../../../utils/function';
import { simpleAlerts } from '../../../../utils/alerts';
import _ from 'lodash';

const columns = [
    { id: 'sel', label: 'Sel.', width: 10, align: 'left', order: false, },
    { id: '#', label: '#', width: 18, align: 'center', order: false, },
    { id: 'codSghh', label: 'Código', width: 100, align: 'left', order: true, },
    { id: 'trabajador', label: 'Nombres', width: 100, align: 'left', order: true, },
];

const AddWorker = (props) => {
    const { infoCampus, infoArea, valueActivities, valueShift, shift, dateInit, newWorkers, setNewWorkers } = props;

    const url = "'" + window.location.pathname + "'";
    const [openSmall, setOpenSmall] = useState(true);
    const [dataWorkers, setDataWorkers] = useState([]);

    const [search, setSearch] = useState("");
    const [page, setPage] = useState(1);
    const pageSize = 20;
    const [totalPages, setTotalPages] = useState(1);

    useEffect(() => {
        getPersonBySede();
    }, [])

    useEffect(() => {
        getPersonBySede();
    }, [search, page])

    const getPersonBySede = async () => {
        try {
            setOpenSmall(true);
            const params = {
                url: url,
                search: search,
                page: page,
                pageSize: pageSize,
                pagination: 0,
                id_sede: infoCampus?.value,
                fecha: dateInit
            };
            setDataWorkers(null);
            const result = await StaffService.getPersonBySedeWithAssistance(params);
            if (result.status === CODES.SUCCESS_200) {
                setDataWorkers(result.data.data);
                setTotalPages(result.data.last_page);
            }
            return []
        } catch (error) {
            console.log(`Error en getPersonBySede: ${error}`);
        } finally {
            setOpenSmall(false);
        }
    }

    /** inicio ordenamiento de columnas */
    const [sortConfigTable, setSortConfigTable] = useState({ key: null, direction: "ascending", });

    const handleSortTable = (key) => {
        let direction = "ascending";
        if (sortConfigTable && sortConfigTable.key === key && sortConfigTable.direction === "ascending") {
            direction = "descending";
        }
        setSortConfigTable({ key, direction });
    };

    const sortedData = sortDataListSimple(dataWorkers, sortConfigTable);
    /** fin ordenamiento de columnas */

    const bodyItem = (person, turnos, fecha, turno) => {
        let tiempo1 = `${fecha} ${turnos?.find(e => Number(e.id_turno) === Number(turno))?.ingreso?.substring(0, 5)}:00.000`;
        let tiempo2 = `${fecha} ${turnos?.find(e => Number(e.id_turno) === Number(turno))?.salida?.substring(0, 5)}:00.000`;

        const fecha1 = new Date(tiempo1);
        const fecha2 = new Date(tiempo2);
        if (fecha1.getTime() > fecha2.getTime()) {
            fecha2.setDate(fecha2.getDate() + 1);
        }

        let newItem =
        {
            "persona_id": person?.id_persona,
            "horaInicio": `${formatDate(fecha1)}:00.000`,
            "horaFin": `${formatDate(fecha2)}:00.000`,
            "horasEjecutadas": null,
            "productividadEspXHra": '',
            "productividadRealXHra": '',
            "produccion": '',
            "trabajador": person?.trabajador,
            "codSghh": person?.codSghh,
        }

        return newItem;
    }

    const handleChangeCheckGeneral = (e) => {
        if (infoArea?.value === 0 || !infoArea) {
            simpleAlerts('Debe seleccionar un área!');
            return;
        }
        if (valueShift === 0 || !valueShift) {
            simpleAlerts('Debe seleccionar un turno!');
            return;
        }
        if (valueActivities === 0 || !valueActivities) {
            simpleAlerts('Debe seleccionar una actividad!');
            return;
        }
        if (dateInit === '' || !dateInit) {
            simpleAlerts('Debe seleccionar una fecha!');
            return;
        }

        let newWorkersTmp = _.cloneDeep(newWorkers);
        if (e.target.checked === true) {
            dataWorkers?.forEach((person) => {
                newWorkersTmp.push(bodyItem(person, shift, dateInit, valueShift));
            });
        } else {
            newWorkersTmp = [];
        }

        setNewWorkers(newWorkersTmp);
    }

    const handleChangeCheck = (value, p_person) => {
        if (infoArea?.value === 0 || !infoArea) {
            simpleAlerts('Debe seleccionar un área!');
            return;
        }
        if (valueShift === 0 || !valueShift) {
            simpleAlerts('Debe seleccionar un turno!');
            return;
        }
        if (valueActivities === 0 || !valueActivities) {
            simpleAlerts('Debe seleccionar una actividad!');
            return;
        }
        if (dateInit === '' || !dateInit) {
            simpleAlerts('Debe seleccionar una fecha!');
            return;
        }

        let newWorkersTmp = _.cloneDeep(newWorkers);
        if (value === true) {
            newWorkersTmp.push(bodyItem(p_person, shift, dateInit, valueShift));
        } else {
            newWorkersTmp = newWorkersTmp?.filter(e => Number(e.persona_id) !== Number(p_person?.id_persona));
        }

        setNewWorkers(newWorkersTmp);
    }

    return (
        <Grid container>
            <Grid item xs={12} display='flex' alignItems={'center'} m={'0.8rem 0.5rem 0.5rem 0.5rem'}>
                <Input
                    id="standard-adornment-amount"
                    placeholder='Ingrese código / nombre / apellido'
                    startAdornment={<InputAdornment position="start"><SearchIcon /></InputAdornment>}
                    style={{ marginRight: '0.5rem' }}
                    fullWidth
                    size='small'
                    onChange={(e) => setSearch(e.target.value)}
                />
                <IconButton aria-label="trabajador" color="primary" size='small' sx={{ border: `solid 0.05rem ${colors.primary.hex}` }}
                    onClick={getPersonBySede}
                >
                    <PersonSearchIcon fontSize={'small'} />
                </IconButton>
            </Grid>

            <Grid item xs={12} mt={2}>
                <SpinnerSmall open={openSmall} />
                <Box className='page-body-main'>
                    <Box className='page-body-table page-body-table__modal table-responsive-md'>
                        <table className="table table-hover table-striped table-bordered table-sm">
                            <thead className='sticky-top table-warning'>
                                <tr>
                                    {columns.map((column) => (
                                        <th
                                            scope="col"
                                            key={column.id}
                                            style={{
                                                textAlign: column.align,
                                                width: column.width,
                                                maxWidth: column.width,
                                                cursor: 'pointer',
                                            }}
                                            onClick={() => handleSortTable(column.id)}
                                        >
                                            {column.id === 'sel' ?
                                                <input type="checkbox"
                                                    name="check"
                                                    id={`checkbox-rect-header`}
                                                    className={'checkbox-rect-header'}
                                                    onChange={handleChangeCheckGeneral}
                                                />
                                                : <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                    <div style={{ marginRight: 'auto' }}>{column.label}</div>
                                                    {column.order &&
                                                        <div><OrderedListOutlined style={{ paddingBottom: 2 }} /></div>
                                                    }
                                                </div>}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody className="table-group-divider">
                                {sortedData?.map((person, indexRow) => {
                                    return (
                                        <tr key={"row" + indexRow}>
                                            {columns.map((column, indexColumn) => {
                                                const value = person[column.id];
                                                return (
                                                    <td key={'col' + indexColumn} align={column.align}>
                                                        {column.id === 'sel' ?
                                                            <div className="checkbox-custom"
                                                                style={{ display: 'flex', justifyContent: 'center' }}
                                                            >
                                                                <input type="checkbox"
                                                                    name="check"
                                                                    id={`checkbox-rect` + person.id_persona}
                                                                    checked={newWorkers?.find(e => Number(e.persona_id) === Number(person.id_persona)) ? true : false}
                                                                    onChange={(e) => handleChangeCheck(e.target.checked, person)}
                                                                />
                                                                <label htmlFor={`checkbox-rect` + person.id_persona}></label>
                                                            </div>
                                                            : column.id === '#' ?
                                                                globalIndex(page, pageSize, indexRow)
                                                                : value}
                                                    </td>
                                                );
                                            })}
                                        </tr>
                                    );
                                })}
                                {(dataWorkers?.length === 0 || !dataWorkers) &&
                                    <MainWithoutResult />
                                }
                            </tbody>
                        </table>
                    </Box>
                    {totalPages > 1 &&
                        <MainPagination
                            size={'small'}
                            totalPages={totalPages}
                            page={page}
                            setPage={setPage}
                        />
                    }
                </Box>
            </Grid>
        </Grid>
    );
};

export default AddWorker;