import axios from "axios";
import { Global } from '../utils/general';
import headerRequest from "../utils/headers"

const API_URL = Global.url;
const SYS = Global.system;

class ManageService {
    getSedesOpenClose(url, id_sede, fecha1, fecha2) {
        let ruta = `${API_URL}/manage/sedes/open?sys=${SYS}&url=${url}&id_sede=${id_sede}&FecDesde=${fecha1}&FecHasta=${fecha2}`;
        return axios.get(ruta, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }

    updateCloseSede(url, body) {
        let ruta = `${API_URL}/manage/sedes/close-sede?sys=${SYS}&url=${url}`;
        return axios.post(ruta, body, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }

    scheduleClosing(url, body) {
        let ruta = `${API_URL}/manage/sedes/schedule-close?sys=${SYS}&url=${url}`;
        return axios.post(ruta, body, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }

    getSeeClosing(url, id_empresa) {
        let ruta = `${API_URL}/manage/sedes/see-closures/${id_empresa}?sys=${SYS}&url=${url}`;
        return axios.get(ruta, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }

    getSeeJobs(url) {
        let ruta = `${API_URL}/manage/sedes/see-jobs?sys=${SYS}&url=${url}`;
        return axios.get(ruta, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }
}

export default new ManageService();
