import React, { } from 'react';
import { Box } from '@mui/material';
import { formatDate, } from '../../../../utils/function';
import { colors } from '../../../../assets/colors';
import _ from 'lodash';

const columns = [
  { id: 'abreviatura', label: 'E', width: 50, align: 'center', },
  { id: 'trabajador', label: 'Trabajador', width: 200, align: 'left', },
  { id: 'ingreso', label: 'Ingr.', width: 100, align: 'center', },
  { id: 'salida', label: 'Sal.', width: 100, align: 'center', },
  { id: 'puesto', label: 'Puesto', width: 100, align: 'center', },
  { id: 'sel', label: 'Sel.', width: 50, align: 'center', },
];

const BodyAssistance = (props) => {
  const { dataAssistance, workerSelected, setWorkerSelected, } = props;

  const handleChangeGeneral = (checked) => {
    let newArray;
    if (checked) {
      newArray = dataAssistance;
    } else {
      newArray = [];
    }
    return setWorkerSelected(newArray);
  }

  const handleChangeCheck = (checked, info) => {
    let newArray = _.cloneDeep(workerSelected);
    if (checked) {
      newArray.push(info);
    } else {
      newArray = newArray.filter((e) => +e.id_Asistencia !== +info.id_Asistencia);
    }
    return setWorkerSelected(newArray);
  }

  return (
    <Box className='page-body-main'>
      <Box className='page-body-table table-responsive-md'>
        <table className="table table-hover table-bordered table-sm">
          <thead className='sticky-top' style={{ backgroundColor: colors.quaternary.hex, color: '#ffff' }}>
            <tr>
              {columns?.map((col) => {
                return (
                  <th
                    scope="col"
                    key={col.id}
                    style={{
                      textAlign: col.align,
                      width: `${col.width}px`
                    }}
                  >
                    {col.id === 'sel' ?
                      <label className={`checkbox-title red`}>
                        <input type="checkbox" name="check" id={`checkbox-rect-header` + col.label}
                          checked={(workerSelected?.length === dataAssistance?.length && workerSelected?.length > 0) ? true : false}
                          onChange={(e) => handleChangeGeneral(e.target.checked)}
                          title={`${(workerSelected?.length === dataAssistance?.length && workerSelected?.length > 0) ? 'Retirara' : 'Seleccionara'} a todos los trabajadores`}
                        />
                        <span className="small"></span>
                      </label> :
                      col.label
                    }
                  </th>
                )
              })}
            </tr>
          </thead>
          <tbody className="table-group-divider font-table">
            {dataAssistance?.map((asis, indexRow) => {
              return (
                <tr key={"row" + indexRow} className='align-middle' style={{fontSize: '11px'}}>
                  {columns.map((column, indexColumn) => {
                    const value = asis[column.id];
                    return (
                      <td
                        key={'col' + indexColumn}
                        align={column.align}
                      >
                        {column.id === 'sel' ?
                          <div className="checkbox-custom"
                            style={{ display: 'flex', justifyContent: 'center' }}
                          >
                            <input type="checkbox" name="check"
                              id={`checkbox-rect-${asis.id_Asistencia}`}
                              checked={workerSelected?.find(e => +e.id_Asistencia === +asis.id_Asistencia) ? true : false}
                              onChange={(e) => handleChangeCheck(e.target.checked, asis)}
                            />
                            <label htmlFor={`checkbox-rect-${asis.id_Asistencia}`}></label>
                          </div> :
                          column.id === 'ingreso' || column.id === 'salida' ?
                            (value ? formatDate(new Date(value), 'time') : '')
                            : value
                        }
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </Box>
    </Box>
  );
}

export default BodyAssistance;