import axios from "axios";
import { Global } from '../utils/general';
import headerRequest from "../utils/headers"

const API_URL = Global.url;
const SYS = Global.system;

class ProductivityService {
    getProductividadByAreaByTurnoByActividad(url, area_id, turno_id, actividad_id, FecDesde, FecHasta) {
        let ruta = `${API_URL}/productivity/area/shift/activity?area_id=${area_id}&turno_id=${turno_id}&actividad_id=${actividad_id}&FecDesde=${FecDesde}&FecHasta=${FecHasta}&sys=${SYS}&url=${url}`;
        return axios.get(ruta, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }

    getProductividadBySede(url, sede_id, FecDesde, FecHasta) {
        let ruta = `${API_URL}/productivity/sede?sys=${SYS}&url=${url}&sede_id=${sede_id}&FecDesde=${FecDesde}&FecHasta=${FecHasta}`;
        return axios.get(ruta, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }

    deleteProductividad(url, id_productividad) {
        let ruta = `${API_URL}/productivity/${id_productividad}?sys=${SYS}&url=${url}`;
        return axios.delete(ruta, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }

    saveProductividad(url, body) {
        let ruta = `${API_URL}/productivity/save?sys=${SYS}&url=${url}`;
        return axios.post(ruta, body, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }

    saveBloquearProductividad(url, body) {
        let ruta = `${API_URL}/productivity/lock?sys=${SYS}&url=${url}`;
        return axios.post(ruta, body, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }
}

export default new ProductivityService();