import React, { } from 'react';
import { ButtonGroup, IconButton, Box } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import ProfileService from '../../../../../services/profile';
import { colors } from '../../../../../assets/colors';
import { CODES } from '../../../../../utils/responseCodes';
import { simpleAlerts } from '../../../../../utils/alerts';
import _ from 'lodash';
import Swal from 'sweetalert2';

const columns = [
  { id: 'id_perfil', label: '#', width: 50, align: 'left', },
  { id: 'desPerfil', label: 'Descripción', width: 220, align: 'left', },
  { id: 'codPerfil', label: 'Cód.', width: 120, align: 'center', },
  { id: 'estado', label: 'Estado', width: 120, align: 'center', },
  { id: 'actions', label: 'Acciones', width: 80, align: 'center', },
];

const BodyProfiles = (props) => {
  const { handleOpen, setTypeEdition, states, profiles, setProfiles, setInfoProfile, setOpenModalLoader, setTitleSpinner } = props;

  const url = "'" + window.location.pathname + "'";

  const editProfile = async (idx, key, value) => {
    let updProfiles = _.cloneDeep(profiles);
    updProfiles[idx][key] = value;
    try {
      Swal.fire({
        title: (value === 2 ? '¿Seguro de desactivar el Perfil?' : '¿Seguro de activar el Perfil?'),
        text: (value === 2 ? 'Perdera todos los accesos al sistema!' : 'Se repondran todos sus ultimos accesos!'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: (value === 2 ? colors.secondary.hex : '#28A745'),
        cancelButtonColor: (value === 2 ? colors.primary.hex : colors.secondary.hex),
        confirmButtonText: (value === 2 ? 'Si, desactivar' : 'Si, sactivar')
      }).then((result) => {
        if (result.isConfirmed) {
          setOpenModalLoader(true);
          setTitleSpinner((value === 2 ? 'Desactivando' : 'Activando'));
          setTimeout(async () => {
            const result = await ProfileService.savePerfil(url, updProfiles[idx])
            if (result.status === CODES.CREATE_201) {
              simpleAlerts(result.data.message, 'success');
              setOpenModalLoader(false);
              setTitleSpinner(null);
              return setProfiles(updProfiles);
            } else {
              simpleAlerts('Error al guardar el perfil', 'error');
            }
          });
        }
      })
    } catch (err) {
      console.log(`Error en editUser ${err}`);
    }

  }

  return (
    <Box className='page-body-main'>
      <Box className='page-body-table table-responsive-md'>
        <table className="table table-hover table-striped table-bordered">
          <thead className='sticky-top' style={{ backgroundColor: colors.quaternary.hex, color: '#ffff' }}>
            <tr>
              {columns.map((column) => (
                <th
                  scope="col"
                  key={column.id}
                  style={{
                    textAlign: column.align,
                    width: column.width,
                    maxWidth: column.width
                  }}
                >
                  {column.label}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="table-group-divider">
            {profiles?.map((profile, indexRow) => {
              return (
                <tr key={"row" + indexRow}>
                  {columns.map((column, indexColumn) => {
                    const value = profile[column.id];
                    return (
                      <td key={'col' + indexColumn} align={column.align}>
                        {column.id === 'estado' ?
                          states?.find(e => Number(e.value) === Number(value))?.label
                          : column.id === 'actions' ?
                            <ButtonGroup
                              disableElevation
                              variant="contained"
                              aria-label="Disabled elevation buttons"
                            >
                              <IconButton aria-label="delete" size="small" title='Editar'
                                onClick={() => { setInfoProfile(profile); setTypeEdition(2); handleOpen() }}
                              >
                                <EditIcon fontSize='small' color={'primary'} />
                              </IconButton>
                              <IconButton aria-label="delete" size="small"
                                onClick={() => { editProfile(indexRow, 'estado', (Number(profile?.estado) === 1 ? 2 : 1)); }} title={Number(profile?.estado) === 1 ? 'Desactivar' : 'Activar'}
                              >
                                {Number(profile?.estado) === 1 ?
                                  <ToggleOffIcon fontSize='small' color={'secondary'} /> :
                                  <VolunteerActivismIcon fontSize='small' color={'success'} />
                                }
                              </IconButton>
                            </ButtonGroup> :
                            value}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </Box>
    </Box >
  );
}

export default BodyProfiles;