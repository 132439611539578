import React, { useEffect, useState } from 'react';
import MainModal from '../../../../components/General/MainModal';
import MainButton from '../../../../components/General/MainButton';
import { CODES } from '../../../../../utils/responseCodes';
import { simpleAlerts } from '../../../../../utils/alerts';
import AsistenciaPuestoService from '../../../../../services/AsistenciaPuesto';
import InfoAsistenciaPuesto from '../../../../components/Maintenance/AsistenciaPuesto/AddAsistenciaPuesto/InfoAsistenciaPuesto';


const AddAsistenciaPuesto = (props) => {
    const { open, handleClose, id_usuarioLogin, setOpenModalLoader, setTitleSpinner,
      typeEdition,infoAsistenciaPuesto,setInfoAsistenciaPuesto,getAsistenciaPuesto } = props;

    const url = "'" + window.location.pathname + "'";
    const [system, setSystem] = useState('ASISPROD');


    const cleanInfo = () => {
        return setInfoAsistenciaPuesto(null)
          
    };

    useEffect(() => {
        if (typeEdition === 1) { //crear perfil
           // setInfoProfile(newProfile);
        }
    }, [])

  const saveAsistenciaPuesto = async () => {
        if (!infoAsistenciaPuesto?.puesto?.trim() || infoAsistenciaPuesto?.puesto?.trim() === '') {
            simpleAlerts('Debe ingresar nombre de Puesto', 'warning');
            return;
        }
        if (!infoAsistenciaPuesto?.ubicacion?.trim() || infoAsistenciaPuesto?.ubicacion?.trim() === '') {
            simpleAlerts('Debe ingresar nombre de Ubicacion', 'warning');
            return;
        }
        saveGeneral();
    }

    const saveGeneral = async () => {
        setOpenModalLoader(true);
        setTitleSpinner('Guardando ...');
        let data = {
            ...infoAsistenciaPuesto,
            id_asistenciaPuesto: infoAsistenciaPuesto?.id_asistenciaPuesto,
           
        };
        try {
            const result = await AsistenciaPuestoService.saveAsistenciaPuesto(url, data);
            if (result.status === CODES.CREATE_201) {
                simpleAlerts(result.data.message, 'success');
                getAsistenciaPuesto();
                handleClose();
            } else {
                simpleAlerts(result?.response?.data?.message, 'error');
                setOpenModalLoader(false);
                setTitleSpinner(null);
                return;
            }
        } catch (err) {
            console.log('Error en saveAsistenciaPuesto', err);
        }
       
        setOpenModalLoader(false);

   
    }



    return (
        <>
            <MainModal
                open={open}
                handleClose={() => { handleClose(); cleanInfo() }}
                centrado={'centrado'}
                title={'Mantenimiento de Asistencia Puesto'}
                subTitle={'Creación y edición de Asistencia Puesto'}
                bodyModal={
                    <>
                       
                        <InfoAsistenciaPuesto
                            typeEdition={typeEdition}
                            system={system}
                            infoAsistenciaPuesto={infoAsistenciaPuesto}
                            setInfoAsistenciaPuesto={setInfoAsistenciaPuesto}
                            
                        />
                    </>
                }
                buttonActions={[
                    <MainButton
                        key='btn-modal-user-save1'
                        title={'Guardar'}
                        typeStyle={'outlined'}
                        color={'primary'}
                        onClick={saveAsistenciaPuesto}
                    />
                ]}
            />
        </>
    );
};

export default AddAsistenciaPuesto;