import axios from "axios";
import { Global } from '../utils/general';
import headerRequest from "../utils/headers"

const API_URL = Global.url;
const SYS = Global.system;

class ComplementaryService {

    AsistencaPlanificacion(fechaAP) {
        let ruta = `${API_URL}/complementary/asistencia-planificacion?fechaAP=${fechaAP}`;
        return axios
            .get(ruta, {
                headers: headerRequest(),
            })
            .catch((error) => {
                return error;
            });
    }
   

    SincronizadorTrabajador() {
        let ruta = `${API_URL}/complementary/sincronizar-trabajador`;
        return axios
            .get(ruta, {
                headers: headerRequest(),
            })
            .catch((error) => {
                return error;
            });
    }

    generateReporteLA(dateInit, dateEnd) {
        const ruta = `${API_URL}/report/complementario/la?dateInit=${dateInit}&dateEnd=${dateEnd}`;
        return axios.post(ruta, null, {
            headers: headerRequest(),
            responseType: 'blob'
        });
    }

    exportPlanilla() {
        const ruta = `${API_URL}/report/planilla`;
        return axios.post(ruta, null, {
            headers: headerRequest(),
            responseType: 'blob'
        });
    }

    exportExcelPlanilla(fechaPlanilla) {
        const ruta = `${API_URL}/report/planilla?fechaPlanilla=${fechaPlanilla}`;
        return axios.post(ruta, null, {
            headers: headerRequest(),
            responseType: 'blob'
        });
    }
    
}

export default new ComplementaryService();
