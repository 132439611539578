import React, { } from 'react';
import { Box, } from '@mui/material';
import { filterData } from '../../../../utils/function';
import { colors } from '../../../../assets/colors';
import _ from 'lodash';

const columns = [
  { id: 'desEmpresa', label: 'Empresa', width: 80, align: 'center', },
  { id: 'desCliente', label: 'Cliente', width: 80, align: 'center', },
  { id: 'desUnidad', label: 'Unidad', width: 80, align: 'center', },
  { id: 'desSede', label: 'Sede', width: 80, align: 'center', },
  { id: 'desArea', label: 'Area', width: 80, align: 'center', },
  { id: 'solicitante', label: 'Solicitante', width: 100, align: 'center', },
  { id: 'codSghh', label: 'Doc.', width: 80, align: 'center', },
  { id: 'trabajador', label: 'Trabajador', width: 100, align: 'center', },
  { id: 'inicio', label: 'Inicio', width: 85, align: 'center', },
  { id: 'fin', label: 'Fin', width: 85, align: 'center', },
  { id: 'ingreso', label: 'Ing.', width: 50, align: 'center', format: (value) => value?.substring(0, 5) },
  { id: 'salida', label: 'Sal.', width: 50, align: 'center', format: (value) => value?.substring(0, 5) },
  { id: 'refrigerio', label: 'Refri.', width: 50, align: 'center', },
  { id: 'desTurno', label: 'Turno', width: 50, align: 'center', },
  { id: 'desAsistencia', label: 'Tipo', width: 50, align: 'center', },
  { id: 'sel', label: 'Acción.', width: 50, align: 'center', },
];

const BodyAssistanceApprove = (props) => {
  const { stateApprove, search, data, approved, setApproved } = props;

  let resultSearch = filterData(data, search, ['numdocSolicitante', 'solicitante', 'codSghh', 'trabajador']);

  const handleChangeGeneral = (value) => {
    let updData = _.cloneDeep(approved);
    updData = [];
    if (value === true) {
      data?.forEach((e) => {
        updData.push(e);
      })
    }
    setApproved(updData);
  }

  const handleChangeCheck = (value, info) => {
    let updData = _.cloneDeep(approved);
    if (value === true) {
      if (!updData?.find((e) => +e.id_asistenciaAprobar === +info.id_asistenciaAprobar)) {
        updData.push(info);
      }
    } else {
      updData = updData?.filter((e) => +e.id_asistenciaAprobar !== +info.id_asistenciaAprobar)
    }
    setApproved(updData);
  }

  return (
    <Box className='page-body-main'>
      <Box className='page-body-table table-responsive-md'>
        <table className="table table-hover table-bordered">
          <thead className='sticky-top' style={{ backgroundColor: colors.quaternary.hex, color: '#ffff' }}>
            <tr>
              {columns?.map((col) => {
                return (
                  <th
                    scope="col"
                    key={col.id}
                    style={{
                      textAlign: col.align,
                      minWidth: col.width,
                    }}
                  >
                    {col.id === 'sel' ?
                      stateApprove === 1 &&
                      <label className={`checkbox-title`}>
                        <input type="checkbox" className='red' name="check" id={`checkbox-rect-header` + col.label}
                          checked={(approved?.length === data?.length && approved?.length > 0) ? true : false}
                          onChange={(e) => handleChangeGeneral(e.target.checked)}
                          title={'Seleccionar/De-seleccionar a todos los trabajadores'}
                        />
                        <span className="indicator"></span>
                      </label> :
                      col.label
                    }
                  </th>
                )
              })}
            </tr>
          </thead>
          <tbody className="table-group-divider font-table">
            {resultSearch?.map((row, indexRow) => {
              return (
                <tr key={"row" + indexRow} style={{ fontSize: '11px' }}>
                  {columns.map((column, indexColumn) => {
                    const value = row[column.id];
                    return (
                      <td key={'col' + indexColumn} align={column.align}>
                        {column.id === 'sel' ?
                          stateApprove === 1 &&
                          <div className="checkbox-custom" style={{ display: 'flex', justifyContent: 'center' }}>
                            <input type="checkbox"
                              name="check"
                              id={`checkbox-rect-row` + indexRow}
                              checked={approved?.find((e) => +e.id_asistenciaAprobar === +row.id_asistenciaAprobar) ? true : false}
                              onChange={(e) => handleChangeCheck(e.target.checked, row)}
                            />
                            <label htmlFor={`checkbox-rect-row` + indexRow}></label>
                          </div>
                          : column.format ?
                            column.format(value)
                            : value
                        }
                      </td>
                    );
                  })}

                </tr>
              );
            })}
          </tbody>
        </table>
      </Box>
    </Box >
  );
}

export default BodyAssistanceApprove;