import React, { useEffect, useState, useRef } from 'react';
import SpinnerLoader from '../../components/SpinnerLoad/SpinnerLoader';
import GeneralService from '../../../services/general';
import AssistanceService from '../../../services/assistance';
import ShiftService from '../../../services/shift';
import UserService from '../../../services/user';
import { simpleAlerts } from '../../../utils/alerts';
import { CODES } from '../../../utils/responseCodes';
import { colors } from '../../../assets/colors';
import { getDateOL, signOut } from '../../../utils/function';
import { IconButton, Box, Grow, Grid, Collapse, Divider, Slide, FormControlLabel, FormLabel, FormControl, Checkbox, } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import EditOffIcon from '@mui/icons-material/EditOff';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import ApartmentIcon from '@mui/icons-material/Apartment';
import MainInput from '../../components/General/MainInput';
import MainButton from '../../components/General/MainButton';
import MainSelect from '../../components/General/MainSelect';
import AddAssistance from './AddAssistanceWeb/AddAssistance';
import BodyAssistance from '../../components/Assistance/BodyAssistance/BodyAssistance';
import _ from 'lodash';

const AssistanceWeb = ({ userToken, setLogoClient, pathLogo, isMovil }) => {
    const url = "'" + window.location.pathname + "'";
    const id_usuarioLogin = userToken.user.id_usuario;
    const id_personaLogin = userToken.user.persona_id;
    const nameLogin = userToken.user.trabajador;
    const [openModalLoader, setOpenModalLoader] = useState(true);
    const [titleLoader, setTitleLoader] = useState(null);
    const [open, setOpen] = useState(false)
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [columns, setColumns] = useState(null);
    const [colOptional, setColOptional] = useState(null);

    const [showEditCol, setShowEditCol] = useState(false);
    const changeEditCol = () => setShowEditCol((prev) => !prev);

    const [showGroup, setShowGroup] = useState(true);
    const handleShow = () => setShowGroup((prev) => !prev);

    const [dataAssistanceCondition, setDataAssistanceCondition] = useState(null);
    const [assistanceConditionEditable, setAssistanceConditionEditable] = useState(null);
    const [dataShift, setDataShift] = useState(null);

    const [infoCompany, setInfoCompany] = useState(null);
    const [infoClient, setInfoClient] = useState(null);
    const [infoUnit, setInfoUnit] = useState(null);
    const [infoCampus, setInfoCampus] = useState(null);

    //* variable de las areas seleccionadas
    const [areasSelected, setAreasSelected] = useState([]);

    const [dateInit, setDateInit] = useState(getDateOL());
    const [dateEnd, setDateEnd] = useState(getDateOL('', 'short', 7));

    const [toltipTitle, setToltipTitle] = useState(null);

    const prevDataAssistance = useRef(null);
    const [dataAssistance, setDataAssistance] = useState([]);

    const [empresas, setEmpresas] = useState([]);
    const [clientes, setClientes] = useState([]);
    const [unidades, setUnidades] = useState([]);
    const [sedes, setSedes] = useState([]);
    const [areas, setAreas] = useState([]);
    //* variables para las opciones a mostrar (para evitar los filter directos en los componentes)
    const [clientesOpt, setClientesOpt] = useState([]);
    const [unidadesOpt, setUnidadesOpt] = useState([]);
    const [sedesOpt, setSedesOpt] = useState([]);
    const [areasOpt, setAreasOpt] = useState([]);

    //variable para manejar la vista del modal 
    const [view, setView] = useState(1); //todo: 1: asistencia masiva, 2: lista de aprobadores, 3: lista de aprobadores eliminar
    const [infoDelete, setInfoDelete] = useState(1);

    useEffect(() => {
        init();
    }, []);

    useEffect(() => {
        //* LLenar un toltip del agrupamiento
        setToltipTitle((infoCompany ? 'EMPRESA: ' + infoCompany?.label?.toLowerCase() : '')
            + (infoClient ? ' - CLIENTE: ' + infoClient?.label?.toLowerCase() : '')
            + (infoUnit ? ' - UNIDAD: ' + infoUnit?.label?.toLowerCase() : '')
            + (infoCampus ? ' - SEDE: ' + infoCampus?.label?.toLowerCase() : '')
            + (infoCompany ? '   ||   F. INICIO: ' + dateInit : '')
            + (infoCompany ? ' - F. FIN: ' + dateEnd : '')
        );
    }, [infoCompany, infoClient, infoUnit, infoCampus, dateInit, dateEnd])

    //* LLamar a la función de las asistencias
    useEffect(() => {
        if (areasSelected && areasSelected.length > 0 && JSON.stringify(prevDataAssistance.current) !== JSON.stringify(areasSelected)) {
            setDataAssistance([]);
            getAsistenciaCompleta();
        }
        prevDataAssistance.current = areasSelected;
    }, [areasSelected])

    useEffect(() => {
        setDataAssistance([]);
    }, [dateInit, dateEnd])

    //* inicio limpieza de datos
    useEffect(() => {
        setInfoClient(null);
        setInfoUnit(null);
        setInfoCampus(null);
        setAreasSelected([]);
        setDataAssistance([]);
    }, [infoCompany]);

    useEffect(() => {
        setInfoUnit(null);
        setInfoCampus(null);
        setAreasSelected([]);
        setDataAssistance([]);
    }, [infoClient]);

    useEffect(() => {
        setInfoCampus(null);
        setAreasSelected([]);
        setDataAssistance([]);
    }, [infoUnit]);
    //* fin limpieza de datos

    //* inicio de funciones de inicio
    const init = async () => {
        Promise.all([
            getColumnasAsistencias(),
            getAsistenciaCondicion(),
            getAgrupamientoByUsuario()
        ]).then(() => setOpenModalLoader(false));
    }

    const getColumnasAsistencias = async () => {
        try {
            const result = await AssistanceService.getColumnasAsistencias();
            if (result.status === CODES.SUCCESS_200) {
                result.data?.forEach(element => {
                    element.id = element.desc1
                    element.label = element.desc2
                    //element.width = element.desSede
                    element.align = element.desc3
                    element.view = (isMovil ? (Number(element.imp1) === 1 ? true : false) : (Number(element.nro1) === 1 ? true : false))
                    element.type = (Number(element.nro2) === 1 ? 'edit' : '')
                    element.width = Number(element.nro3)
                })
                setColOptional(result.data?.filter(e => e.view === false));
                let tmp = result.data;
                let item = { id: 'actions', value: 'actions', label: 'Acciones', width: 80, align: 'center', view: true };
                let indexInsert = 1;
                tmp.splice(indexInsert, 0, item);
                //tmp.unshift(item);
                setColumns(tmp);
            } else if (result?.response?.status === CODES.BAD_TOKEN_498) {
                signOut()
            } else {
                simpleAlerts('No hay columnas', 'info');
            }
            return []
        } catch (error) {
            console.log(`Error en getColumnasAsistencias: ${error}`);
        }
    }

    const getAsistenciaCondicion = async () => {
        try {
            const result = await GeneralService.getAsistenciaCondicion();
            if (result.status === CODES.SUCCESS_200) {
                result.data?.forEach(element => {
                    element.value = element.id_asisCond
                    element.label = element.desAsistencia
                })
                setAssistanceConditionEditable(result.data.filter(e => Number(e.editable) === 1))
                setDataAssistanceCondition(result.data);
            } else {
                simpleAlerts('No hay información de tipo de asistencia', 'info');
            }
            return []
        } catch (error) {
            console.log(`Error en getAsistenciaCondicion: ${error}`);
        }
    }
    //* fin de funciones de inicio

    const handleChangeView = (col) => {
        let updColumns = _.cloneDeep(columns);
        let updColOptional = _.cloneDeep(colOptional);
        let foundColumn = updColumns?.find(el => el.id === col.id);
        let foundColumnOptional = updColOptional?.find(el => el.id === col.id);
        foundColumn.view = !col.view;
        foundColumnOptional.view = !col.view;
        return setColOptional(updColOptional), setColumns(updColumns);
    }

    const getAsistenciaCompleta = async () => {
        setOpenModalLoader(true);
        setTitleLoader('Listando asistencias...');
        try {
            let listTemp = [];
            setDataAssistance([]);
            const listPromises = areasSelected.map(async (area) => {
                try {
                    const result = await AssistanceService.getAsistenciaCompleta(url, area, dateInit, dateEnd);
                    result.data?.forEach(element => {
                        element.edit = 0
                    })
                    if (result?.status === CODES.SUCCESS_200) {
                        listTemp = [].concat(listTemp, result.data);
                    }
                    if (result?.response?.status === CODES.BAD_TOKEN_498) {
                        signOut()
                    }
                } catch (error) {
                    console.log(`Error en getAsistenciaCompleta: ${error}`);
                }
            });

            await Promise.all([...listPromises,]);
            return setDataAssistance(listTemp);
        } catch (error) {
            console.log(`Error en getAsistenciaCompleta: ${error}`);
        } finally {
            setOpenModalLoader(false);
            setTitleLoader(null);
        }
    };

    /*** */
    const getAgrupamientoByUsuario = async () => {
        try {
            const result = await UserService.getAgrupamientoByUsuario(url, id_usuarioLogin);
            if (result.status === CODES.SUCCESS_200) {
                if (result.data.areas.length === 0)
                    simpleAlerts('No tiene areas asignadas', "warning");
                setEmpresas(result.data.empresas);
                setClientes(result.data.clientes);
                setUnidades(result.data.unidades);
                setSedes(result.data.sedes);
                result?.data?.areas?.forEach(element => {
                    element.value = Number(element.id_area);
                    element.label = element.desArea;
                })
                setAreas(result.data.areas);
            }
        } catch (error) {
            console.log(`Error en getAgrupamientoByUsuario: ${error}`);
        }
    }
    /*** */

    const saveAssistanceGeneral = async () => {
        const dataEditada = dataAssistance?.filter(e => +e.edit === 1);
        let flag = 0;
        let trabajador = '';
        for (const asistencia of dataEditada) {
            if (+asistencia?.turno === 1) {
                flag = 1;
                trabajador = asistencia.trabajador;
                break;
            }
            const condicion = dataAssistanceCondition?.find(e => +e.id_asisCond === +asistencia?.condicion);
            if (+condicion?.conMarca === 1 && (!asistencia.ingreso || !asistencia.salida)) {
                flag = 2;
                trabajador = asistencia.trabajador;
                break;
            }
            if (+asistencia?.condicion === 17) {
                flag = 3;
                trabajador = asistencia.trabajador;
                break;
            }
        }
        if (flag === 1) {
            simpleAlerts(`Verifique el turno registrado de (${trabajador})!`);
            return;
        }
        if (flag === 2) {
            simpleAlerts(`Verifique el ingreso y la salida registrada de (${trabajador})!`);
            return;
        }
        if (flag === 3) {
            simpleAlerts(`Verifique la condición de (${trabajador})!`);
            return;
        }
        const body = {
            usuario_id: id_usuarioLogin,
            assistances: dataEditada,
        }
        try {
            setOpenModalLoader(true);
            setTitleLoader('Guardando asistencias!')
            const result = await AssistanceService.updateAsistenciaBucle(url, body);
            if (result.status === CODES.SUCCESS_200) {
                simpleAlerts(result?.data?.message || 'Guardado', 'success');
            } else {
                simpleAlerts('Error en el guardado', 'error');
            }
        } catch (err) {
            console.log('Error en updateAsistenciaBucle', err);
        } finally {
            setOpenModalLoader(false);
            setTitleLoader(null)
        }
    };

    const createMassiveAssistance = () => {
        if (infoCampus?.value === 0 || !infoCampus) {
            simpleAlerts('Debe seleccionar una sede!');
            return;
        }
        handleOpen();
    }

    /** Inicio HandleChange*/
    const handleChangeEmpresa = (value) => {
        setInfoCompany(empresas?.find(element => Number(element.value) === Number(value)));
        return setClientesOpt(clientes?.filter(e => Number(e.empresa_id) === Number(value)));
    }
    const handleChangeCliente = (value) => {
        setInfoClient(clientes?.find(element => Number(element.value) === Number(value)));
        return setUnidadesOpt(unidades?.filter(e => Number(e.cliente_id) === Number(value)));
    }
    const handleChangeUnidad = (value) => {
        setInfoUnit(unidades?.find(element => Number(element.value) === Number(value)));
        return setSedesOpt(sedes?.filter(e => Number(e.unidad_id) === Number(value)));
    }
    const handleChangeSede = (value) => {
        setInfoCampus(sedes?.find(element => element.value === value))
        getTurnoBySede(value);
        const areaTmp = areas?.filter(e => e.sede_id === value);
        return setAreasOpt(areaTmp), setAreasSelected(areaTmp.map((element) => element.value));
    }
    const handleChangeArea = (value) => {
        return setAreasSelected(value)
    }
    /** Fin HandleChange*/

    const getTurnoBySede = async (id_sede) => {
        try {
            const result = await ShiftService.getTurnoBySede(url, id_sede);
            if (result.status === CODES.SUCCESS_200) {
                result.data?.forEach(element => {
                    element.value = element.id_turno
                    element.label = element.desTurno
                })
                setDataShift(result.data);
            } else {
                simpleAlerts('No hay información de turnos', 'info');
            }
            return []
        } catch (error) {
            console.log(`Error en getTurnoBySede: ${error}`);
        }
    }

    const deleteAssistance = (info) => {
        setInfoDelete(info);
        setView(3);
        handleOpen();
    }

    return (
        <div>
            <SpinnerLoader open={openModalLoader} title={titleLoader} />
            <AddAssistance
                open={open}
                handleClose={handleClose}
                setOpenModalLoader={setOpenModalLoader}
                setTitleLoader={setTitleLoader}
                id_usuarioLogin={id_usuarioLogin}
                id_personaLogin={id_personaLogin}
                nameLogin={nameLogin}

                infoCompany={infoCompany}
                infoClient={infoClient}
                infoUnit={infoUnit}
                infoCampus={infoCampus}
                areas={areas}
                dataShift={dataShift}
                dataAssistanceCondition={dataAssistanceCondition}

                getAsistenciaCompleta={getAsistenciaCompleta}
                dateInit={dateInit}
                dateEnd={dateEnd}
                view={view}
                setView={setView}
                infoDelete={infoDelete}
            />

            <Collapse in={showGroup} timeout="auto" unmountOnExit
                sx={{
                    border: `solid 0.05rem ${colors.primary.hex}`,
                    //borderRadius: '10px 10px 60px 10px',
                    borderRadius: 5,
                    //paddingBottom: 1,
                    paddingLeft: 1,
                    paddingRight: 1,
                    marginLeft: 1,
                    marginRight: 1,
                    marginBottom: 1,
                }}
            >
                <Grow
                    in={showGroup}
                    style={{ transformOrigin: "0 0 0" }}
                    {...(showGroup ? { timeout: 1000 } : {})}
                >
                    <Grid container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        p={1}>
                        <Grid item xs={11} sm={6} md={2.5}>
                            <MainSelect
                                title={'Empresa'}
                                options={empresas}
                                placeholder={'Selecionar Empresa'}
                                value={infoCompany?.value}
                                onChange={handleChangeEmpresa}
                            />
                        </Grid>
                        <Grid item xs={11} sm={6} md={2.5}>
                            <MainSelect
                                title={'Cliente'}
                                options={clientesOpt}
                                placeholder={'Selecionar Cliente'}
                                value={infoClient?.value}
                                onChange={handleChangeCliente}
                            />
                        </Grid>
                        <Grid item xs={11} sm={4} md={2.5}>
                            <MainSelect
                                title={'Unidad'}
                                options={unidadesOpt}
                                placeholder={'Selecionar Unidad'}
                                value={infoUnit?.value}
                                onChange={handleChangeUnidad}
                            />
                        </Grid>
                        <Grid item xs={11} sm={4} md={2.5}>
                            <MainSelect
                                title={'Sede'}
                                options={sedesOpt}
                                placeholder={'Selecionar Sede'}
                                value={infoCampus?.value}
                                onChange={handleChangeSede}
                            />
                        </Grid>
                        <Grid item xs={11} sm={4} md={2} >
                            <MainSelect
                                mode={"multiple"}
                                title={'Área'}
                                options={areasOpt}
                                placeholder={'Selecionar Área'}
                                value={areasSelected}
                                onChange={handleChangeArea}
                            />
                        </Grid>
                    </Grid>
                </Grow>
            </Collapse>

            <Grid container direction="row" justifyContent="end" alignItems="center">
                <Grid item xs={1}>
                    <Box pt={2} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                        <IconButton color="dark" onClick={handleShow}
                            style={{ fontSize: '1px', border: 'solid 0.02rem' }}
                            size='small'
                            data-toggle="tooltip" data-html="true" title={toltipTitle}
                        >
                            {showGroup ? <KeyboardDoubleArrowUpIcon /> : <ApartmentIcon />}
                        </IconButton>
                    </Box>
                </Grid>
                <Grid item xs={10} sm={5} md={3} style={{ ...(window.innerWidth <= 600 ? { marginRight: '15px' } : {}), }}>
                    <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                        <MainInput
                            title={'Fecha Inicio'}
                            placeholder={'Ingrese Fecha Inicio'}
                            type={'date'}
                            value={dateInit}
                            onChange={(e) => setDateInit(e.target.value)}
                        />
                        <MainInput
                            title={'Fecha Fin'}
                            placeholder={'Ingrese Fecha Fin'}
                            type={'date'}
                            value={dateEnd}
                            onChange={(e) => setDateEnd(e.target.value)}
                        />
                    </Box>
                </Grid>

                <Grid item xs={11} sm={5} md={3} style={{ ...(window.innerWidth <= 600 ? { marginRight: '15px' } : {}), }}>
                    <Box pt={1} display={'flex'}
                        justifyContent={'space-between'}
                        alignItems={'center'}
                    >
                        <MainButton
                            onClick={createMassiveAssistance}
                            title={'Asist. Masiva'}
                            typeStyle={'outlined'}
                        />
                        <MainButton
                            onClick={getAsistenciaCompleta}
                            title={'Cargar'}
                        />
                        <MainButton
                            onClick={saveAssistanceGeneral}
                            title={'Guardar'}
                            color={'success'}
                        />
                    </Box>
                </Grid>
            </Grid>

            <Divider className="divider" sx={{ margin: '0.7rem 0.8rem 0 0.8rem' }} />

            <Grid container mt={1} direction="row" justifyContent="center" alignItems="center" >
                <Grid item
                    xs={showEditCol ? 9 : 0}
                    sm={showEditCol ? 3 : 0}
                    md={showEditCol ? 2 : 0}
                >
                    <Slide direction="right" in={showEditCol} mountOnEnter unmountOnExit>
                        <Box className='page-body-main'>
                            <Box className='page-body-table'>
                                <FormControl component="fieldset" variant="standard">
                                    <FormLabel component="legend">Columnas adicionales</FormLabel>
                                    {colOptional?.map((col, idx) => {
                                        return (
                                            <FormControlLabel
                                                key={col.label + idx + 'optional'}
                                                control={
                                                    <Checkbox size="small"
                                                        checked={col.view}
                                                        onChange={(e) => handleChangeView(col)}
                                                        name={col.label}
                                                    />
                                                }
                                                label={col.label}
                                            />
                                        )
                                    })}
                                </FormControl>
                            </Box>
                        </Box>
                    </Slide>
                </Grid>

                <Grid item
                    xs={showEditCol ? 3 : 12}
                    sm={showEditCol ? 9 : 12}
                    md={showEditCol ? 10 : 12}
                    paddingLeft={'1rem'} paddingRight={'1rem'}
                >
                    <Box position={'relative'}>
                        <button type="button" className="btn btn-danger btn-sm"
                            style={{
                                position: "absolute",
                                top: "0px",
                                left: "0px",
                                zIndex: 1,
                                border: "solid 0.02rem",
                                width: '40px',
                                height: '40px',
                                borderRadius: '40px 25px 100px 30px',
                                fontSize: '5px',
                                textAlign: 'center',
                            }}
                            onClick={changeEditCol}
                        >
                            {showEditCol ? <EditOffIcon /> : <EditIcon />}
                        </button>
                        <BodyAssistance
                            id_usuarioLogin={id_usuarioLogin}
                            dataAssistanceCondition={dataAssistanceCondition}
                            assistanceConditionEditable={assistanceConditionEditable}
                            dataShift={dataShift}
                            columns={columns}
                            setColumns={setColumns}
                            dataAssistance={dataAssistance}
                            setDataAssistance={setDataAssistance}
                            deleteAssistance={deleteAssistance}
                        />
                    </Box>
                </Grid>
            </Grid>
        </div>
    );
};

export default AssistanceWeb;