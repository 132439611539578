import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import MainSelect from '../../../General/MainSelect';
import AreaOrgService from '../../../../../services/areaOrg';
import { CODES } from '../../../../../utils/responseCodes';
import { simpleAlerts } from '../../../../../utils/alerts';
import SpinnerLoader from '../../../../components/SpinnerLoad/SpinnerLoader';

const AssingAreaOrg = (props) => {
  const { areaPadreValue, areaHijoValue, setAreaHijoValue, setAreaPadreValue } = props;

  const [areaOrg, setAreaOrg] = useState(null);
  const [openModalLoader] = useState(false);

  useEffect(() => {
    getAreasOrg();
  }, []);

  const getAreasOrg = async () => {
    try {
      const result = await AreaOrgService.getAreasOrg();
      if (result.status === CODES.SUCCESS_200) {
        result.data?.forEach((element) => {
          element.value = element.id_areaOrg;
          element.label = element.desAreaOrg;
        });
        setAreaOrg(result.data);
      } else {
        simpleAlerts('Hubo un error la relación de áreas organigrama', 'error');
      }
      return [];
    } catch (error) {
      console.log(`Error en getAreasOrg: ${error}`);
    }
  };

  return (
    <>
      <Grid container>
        <Grid item md={12} xs={12} pt={1}>
          <MainSelect
            title={'Area Padre'}
            options={areaOrg}
            value={areaPadreValue}
            onChange={(value, event) => setAreaPadreValue(Number(value))}
          />
        </Grid>
        <Grid item md={12} xs={12} pt={1}>
          <MainSelect
            title={'Area Hijo'}
            options={areaOrg}
            value={areaHijoValue}
            onChange={(value, event) => setAreaHijoValue(Number(value))}
          />
        </Grid>

        <SpinnerLoader open={openModalLoader} />
      </Grid>
    </>
  );
};

export default AssingAreaOrg;
