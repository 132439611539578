import axios from "axios";
import { Global } from '../utils/general';
import headerRequest from "../utils/headers"

const API_URL = Global.url;
const SYS = Global.system;

class MailsService {
    recoveryPassword(usuario) {
        let ruta = `${API_URL}/mails/recovery-password/${usuario}`;
        return axios.post(ruta, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }

    sendAuthorizationRequest(url, body) {
        let ruta = `${API_URL}/mails/send/authorization-request?sys=${SYS}&url=${url}`;
        return axios.post(ruta, body, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }
    
    sendAuthorizationDecision(url, body) {
        let ruta = `${API_URL}/mails/send/authorization-decision?sys=${SYS}&url=${url}`;
        return axios.post(ruta, body, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }
}

export default new MailsService();
