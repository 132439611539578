import axios from "axios";
import { Global } from '../utils/general';
import headerRequest from "../utils/headers"

const API_URL = Global.url;
const SYS = Global.system;


class AssignmentService {
    saveAsignacionArea(url, body) {
        let ruta = `${API_URL}/manage/assignment/area?sys=${SYS}&url=${url}`;
        return axios
            .post(ruta, body, {
                headers: headerRequest(),
            })
            .catch((error) => {
                return error;
            });
    }

    deleteAsignacionArea(url,id) {
        let ruta = `${API_URL}/manage/assignment/areaWorker/${id}?sys=${SYS}&url=${url}`;
        return axios
            .delete(ruta,  {
                headers: headerRequest(),
            })
            .catch((error) => {
                return error;
            });
    }


 
}

export default new AssignmentService();