import React, { useState, useEffect } from 'react';
import { Grid, Box } from '@mui/material';
import MainInput from '../../../General/MainInput';
import SpinnerSmall from '../../../SpinnerLoad/SpinnerSmall';


const InfoAsistenciaPuesto = (props) => {
    const { typeEdition, system, infoAsistenciaPuesto, setInfoAsistenciaPuesto} = props;

    const url = "'" + window.location.pathname + "'";
   
    const [openSmall, setOpenSmall] = useState(true);
    const [screensSystem, setScreensSystem] = useState(null);

    useEffect(() => {
     
    }, [screensSystem])

  

    useEffect(() => {
       
        if (infoAsistenciaPuesto?.id_asistenciaPuesto && typeEdition === 2) {
            setOpenSmall(true);
            //getPantallasByPerfil(infoProfile?.id_perfil);
        } else
            setOpenSmall(false);
    }, [infoAsistenciaPuesto?.id_asistenciaPuesto, typeEdition])


    return (
        <>
            <Grid container>
                <Grid item md={6} xs={12}>
                    <MainInput
                        title={'Nombre de Ubicación'}
                        value={infoAsistenciaPuesto?.ubicacion}
                        name={'ubicacion'}
                        placeholder={"Ingrese ubicación"}
                        onChange={(e) => setInfoAsistenciaPuesto({ ...infoAsistenciaPuesto, ubicacion: e.target.value })}
                    />
                     <MainInput
                        title={'Nombre de Puesto'}
                        value={infoAsistenciaPuesto?.puesto}
                        name={'puesto'}
                        placeholder={"Ingrese Puesto"}
                        onChange={(e) => setInfoAsistenciaPuesto({ ...infoAsistenciaPuesto, puesto: e.target.value })}
                    />
                </Grid>
            </Grid>
            <Box
                display={'flex'}
                overflow={'scroll'}
                padding={'2px 0 0 5px'}
            >
                <SpinnerSmall open={openSmall} />

            </Box>
        </>
    );
};



export default InfoAsistenciaPuesto;