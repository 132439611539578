import React, { useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';
import { CODES } from '../../../../utils/responseCodes';
import { simpleAlerts } from '../../../../utils/alerts';
import SpinnerLoader from '../../../components/SpinnerLoad/SpinnerLoader';
import MainInput from '../../../components/General/MainInput';
import GeneralService from '../../../../services/general';
import ReportService from '../../../../services/report';
import StaffService from '../../../../services/staff';
import MainSelect from '../../../components/General/MainSelect';
import { getDateOL } from '../../../../utils/function';
import { Tabs, Tab } from '@mui/material';
import GroupingSedeByUser from '../../../components/GroupingSedeByUser';

const ReportPersonaActividad = ({ userToken }) => {
  const [openModalLoader, setOpenModalLoader] = useState(false);
  const [titleModalLoader, setTitleModalLoader] = useState(null);

  const [infoRegion, setInfoRegion] = useState(null);
  const [regiones, setRegiones] = useState(null);

  const [dateInit, setDateInit] = useState(getDateOL());
  const [dateEnd, setDateEnd] = useState(getDateOL('', 'short', 7));
  const [type, setType] = useState(1);


  //* inicio variables para el agrupamiento */
  const [infoCompany, setInfoCompany] = useState(null);
  const [infoClient, setInfoClient] = useState(null);
  const [infoUnit, setInfoUnit] = useState(null);
  const [infoCampus, setInfoCampus] = useState(null);
  //* fin variables para el agrupamiento */

  const [infoWorkers, setInfoWorkers] = useState(null);
  console.log("🚀 ~ ReportPersonaActividad ~ infoWorkers:", infoWorkers)
  const [dataWorkers, setDataWorkers] = useState(null);

  const [activeTab, setActiveTab] = useState('persona');

  useEffect(() => {
    init();
  }, [])

  const init = async () => {
    Promise.all([
      getRegiones(),
    ]).then(() => { setTimeout(() => { setOpenModalLoader(false); }, 5000); });
  }

  useEffect(() => {
    if (infoRegion) {
      getSedexRegion();
    }
  }, [infoRegion])

  useEffect(() => {
    if (infoCampus) {
      getPersonBySede();
      setInfoWorkers([]);
    }
  }, [infoCampus])

  const getPersonBySede = async () => {
    const url = "'" + window.location.pathname + "'";
    try {
      setOpenModalLoader(true);
      setDataWorkers([]);
      const result = await StaffService.getPersonBySede(url, { pagination: 1 }, infoCampus?.value);
      if (result.status === CODES.SUCCESS_200) {
        result.data?.forEach(element => {
          element.value = element.id_persona
          element.label = element.trabajador
        })
        setDataWorkers(result.data);
        return []
      } else {
        simpleAlerts('No hay información de Personas en sede', 'info');
      }

    } catch (error) {
      console.log(`Error en getPersonBySede: ${error}`);
    } finally {
      setOpenModalLoader(false);
    }
  }

  const getRegiones = async () => {
    try {
      const result = await GeneralService.getRegiones();
      if (result.status === CODES.SUCCESS_200) {
        result.data?.forEach(element => {
          element.value = element.id_region
          element.label = element.desRegion
        })
        setRegiones(result.data);

      } else {
        simpleAlerts('No hay información de Regiones', 'info');
      }
    } catch (error) {
      console.log(`Error en getRegiones: ${error}`);
    }
  }

  const getSedexRegion = async () => {
    try {
      const result = await GeneralService.getSedexRegion(infoRegion?.value);
      if (result.status === CODES.SUCCESS_200) {
        result.data?.forEach(element => {
          element.value = element.id_sede
          element.label = element.desSede
        })
      } else {
        simpleAlerts('No hay información de Sedes', 'info');
      }
    } catch (error) {
      console.log(`Error en getSedexRegion: ${error}`);
    }
  }

  const exportPersonaActividadExcel = async (isSedeReport = false) => {
  if (!infoCampus?.value) {
    simpleAlerts('Seleccione una Sede', 'warning');
    return;
  }

  // Si es un reporte por sede, se asigna 0, de lo contrario se valida que haya un trabajador seleccionado
  const personaId = isSedeReport ? 0 : infoWorkers?.id_persona;

  // Validación para mostrar alerta si se intenta generar un reporte de trabajador sin seleccionar uno
  if (!isSedeReport && !personaId) {
    simpleAlerts('Seleccione un Trabajador', 'warning');
    return;
  }

  try {
    const reportTitle = isSedeReport ? 'Generando Reporte por Sede...' : 'Generando Reporte de Trabajador...';
    setTitleModalLoader(reportTitle);
    setOpenModalLoader(true);

    // Llama al servicio de reporte con id_persona, que será 0 si no hay trabajador seleccionado
    const result = await ReportService.exportPersonaActividadExcel(
      dateInit,
      dateEnd,
      infoCampus?.value,
      personaId
    );

    if (result.status === CODES.SUCCESS_200) {
      const blob = new Blob([result.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      const fileName = isSedeReport
      ? `REPORTE_ACTIVIDAD_SEDE_${infoCampus?.desSede}_${dateInit}_${dateEnd}.xlsx`
      : `REPORTE_ACTIVIDAD_TRABAJADOR_${infoWorkers?.trabajador}_${infoCampus?.desSede}_${dateInit}_${dateEnd}.xlsx`;

     link.download = fileName;
      link.click();
      URL.revokeObjectURL(url);
      setOpenModalLoader(false);
    } else {
      setOpenModalLoader(false);
      setTitleModalLoader(null);
    }
  } catch (error) {
    console.log(`Error en exportPersonaActividadExcel: ${error}`);
  }
};

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Tabs
        centered
        value={activeTab}
        onChange={(event, newValue) => setActiveTab(newValue)}
      >
        <Tab value="persona" label="Por Persona" />
      </Tabs>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={10} sm={8} md={8}>
          <Grid container justifyContent="center" alignItems="center">
            <Grid item xs={12} sm={6} md={8}>
              <GroupingSedeByUser
                user={userToken.user}
                openModalLoader={openModalLoader} setOpenModalLoader={setOpenModalLoader}
                infoCompany={infoCompany} setInfoCompany={setInfoCompany}
                infoClient={infoClient} setInfoClient={setInfoClient}
                infoUnit={infoUnit} setInfoUnit={setInfoUnit}
                infoCampus={infoCampus} setInfoCampus={setInfoCampus}
              />
            </Grid>
          </Grid>
          <Grid container p={1} justifyContent="center" alignItems="center">
            <Grid item xs={12} sm={6} md={4}>
              <MainInput
                title={"Fecha Inicio"}
                placeholder={"Ingrese Fecha Inicio"}
                type={"date"}
                value={dateInit}
                onChange={(e) => setDateInit(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <MainInput
                title={"Fecha Fin"}
                placeholder={"Ingrese Fecha Fin"}
                type={"date"}
                value={dateEnd}
                onChange={(e) => setDateEnd(e.target.value)}
                propsInput={{ min: dateInit }}
              />
            </Grid>
          </Grid>
          <Grid container p={1} justifyContent="center" alignItems="center">
            {activeTab === "persona" && (
              <Grid
                container
                p={1}
                justifyContent="center"
                alignItems="center"
                style={{ marginTop: "1rem" }}
              >
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={8}
                  sx={{ marginTop: "1rem",marginBottom: "2rem",marginLeft: { md: "2rem", xs: "0" }  }}
                >
                  <button
                    type="button"
                    className="btn btn-success"
                    onClick={() => exportPersonaActividadExcel(true)} // Para reporte por sede
                    style={{ marginRight: "2rem" }}
                  >
                    REPORTE POR SEDE
                  </button>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  sx={{ marginLeft: { md: "2rem", xs: "0" } }}
                >
                  <MainSelect
                    title={"Trabajador"}
                    options={dataWorkers}
                    placeholder={"Seleccionar Trabajador"}
                    value={infoWorkers?.value}
                    onChange={(value, event) =>
                      setInfoWorkers(
                        dataWorkers?.find((element) => element.value == value)
                      )
                    }
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  style={{ marginTop: "1rem" }}
                >
                  <button
                    type="button"
                    className="btn btn-warning"
                    onClick={() => exportPersonaActividadExcel(false)} // Para reporte de trabajador
                    style={{ marginRight: "2rem" }}
                   
                  >
                    REPORTE TRABAJADOR
                  </button>
                </Grid>
              </Grid>
            )}
          </Grid>
          <SpinnerLoader open={openModalLoader} title={titleModalLoader} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ReportPersonaActividad;