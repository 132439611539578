import React, { useState, useEffect } from 'react';
import { Grid, Button, } from '@mui/material'
import SaveIcon from '@mui/icons-material/Save';
import AddBoxIcon from '@mui/icons-material/AddBox';
import ActivityService from '../../../../services/activity';
import UserService from '../../../../services/user';
import ShiftService from '../../../../services/shift';
import ProductivityService from '../../../../services/productivity';
import AddEdition from './Add/AddEdition';
import MainButton from '../../../components/General/MainButton';
import MainSelect from '../../../components/General/MainSelect';
import MainInput from '../../../components/General/MainInput';
import MainInputInfo from '../../../components/General/MainInputInfo';
import GroupingAreaByUser from '../../../components/GroupingAreaByUser';
import BodyEdition from '../../../components/Productivity/Edition/BodyEdition';
import SpinnerLoader from '../../../components/SpinnerLoad/SpinnerLoader';
import { colors } from '../../../../assets/colors';
import { simpleAlerts } from '../../../../utils/alerts';
import { getDateOL, minutesDiff } from '../../../../utils/function';
import { CODES } from '../../../../utils/responseCodes';
import Swal from 'sweetalert2';
import _ from 'lodash';

const ProductivityEdition = ({ setValidationInfoChange, userToken }) => {
    const url = "'" + window.location.pathname + "'";
    const id_usuarioLogin = userToken.user?.id_usuario;
    const id_personaLogin = userToken.user?.persona_id;
    const [titleSpinner, setTitleSpinner] = useState(null);
    const [openModalLoader, setOpenModalLoader] = useState(true);
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    //* inicio variables para el agrupamiento */
    const [empresas, setEmpresas] = useState([]);
    const [clientes, setClientes] = useState([]);
    const [unidades, setUnidades] = useState([]);
    const [sedes, setSedes] = useState([]);
    const [areas, setAreas] = useState([]);

    const [infoCompany, setInfoCompany] = useState(null);
    const [infoClient, setInfoClient] = useState(null);
    const [infoUnit, setInfoUnit] = useState(null);
    const [infoCampus, setInfoCampus] = useState(null);
    const [infoArea, setInfoArea] = useState(null);
    //* fin variables para el agrupamiento */

    const [activities, setActivities] = useState(null);
    const [valueActivities, setValueActivities] = useState(null);
    const [shift, setShift] = useState(null);
    const [valueShift, setValueShift] = useState(null);
    const [dateInit, setDateInit] = useState(getDateOL());
    const [productivityC, setProductivityC] = useState([]);
    const [dataProductivity, setDataProductivity] = useState([]);
    const [productivityCOriginal, setProductivityCOriginal] = useState([]);
    const [dataProductivityOriginal, setDataProductivityOriginal] = useState([]);
    const [deleteWorkerProd, setDeleteWorkerProd] = useState([]);

    const [loadValidation, setLoadValidation] = useState(false);

    useEffect(() => {
        init();
    }, [])

    useEffect(() => {
        validationConfirm()
    }, [productivityC]);

    useEffect(() => {
        setActivities(null);
        setValueActivities(null);
        if (infoArea?.value)
            getActividadesByArea();
    }, [infoArea])

    useEffect(() => {
        setShift(null);
        setValueShift(null);
        if (infoCampus?.value)
            getTurnoBySede();
    }, [infoCampus])

    useEffect(() => {
        setProductivityCOriginal(null);
        setDataProductivityOriginal([]);
        setValidationInfoChange(false)
        setProductivityC(null);
        setDataProductivity([]);
        setDeleteWorkerProd([]);
        setLoadValidation(false);
    }, [infoCompany, infoClient, infoUnit, infoCampus, infoArea, valueShift, valueActivities, dateInit])

    const init = async () => {
        Promise.all([
            getAgrupamientoByUsuario(),
        ]).then(() => setOpenModalLoader(false));
    }

    const getAgrupamientoByUsuario = async () => {
        try {
            const result = await UserService.getAgrupamientoByUsuario(url, id_usuarioLogin);
            if (result.status === CODES.SUCCESS_200) {
                if (result.data.areas.length === 0)
                    simpleAlerts('No tiene areas asignadas', "warning");
                setEmpresas(result.data.empresas);
                setClientes(result.data.clientes);
                setUnidades(result.data.unidades);
                setSedes(result.data.sedes);
                setAreas(result.data.areas);
            }
        } catch (error) {
            console.log(`Error en getAgrupamientoByUsuario: ${error}`);
        }
    }

    const getActividadesByArea = async () => {
        try {
            const result = await await ActivityService.getActividadesByArea(url, infoArea?.value, 1);
            if (result.status === CODES.SUCCESS_200) {
                if (result?.data?.length === 0) {
                    simpleAlerts('No se tiene información de actividades!');
                    return;
                }
                result.data?.forEach(element => {
                    element.id = Number(element.id_actividad)
                    element.value = Number(element.id_actividad)
                    element.label = element.desActividad
                })
            }
            setActivities(result.data);
        } catch (error) {
            console.log(`Error en getActividadesByArea: ${error}`);
        }
    }

    const getTurnoBySede = async () => {
        try {
            const result = await ShiftService.getTurnoBySede(url, infoCampus?.value);
            if (result.status === CODES.SUCCESS_200) {
                if (result?.data?.length === 0) {
                    simpleAlerts('No tiene turnos relacionados!', "info", "colored");
                    return;
                }
                result.data?.forEach(element => {
                    element.value = Number(element.id_turno);
                    element.label = element.desTurno + ' (' + element.ingreso.substring(0, 5) + '-' + element.salida.substring(0, 5) + ')';
                })
                setShift(result.data);
            }
        } catch (error) {
            console.log(`Error en getAreasBySede: ${error}`);
        }
    }

    // * Validación, para no perder los cambios
    const validationConfirm = () => {
        let validationTMP = true;
        if (JSON.stringify(productivityCOriginal) !== JSON.stringify(productivityC) ||
            JSON.stringify(dataProductivityOriginal) !== JSON.stringify(dataProductivity)) {
            setValidationInfoChange(true);
        } else {
            setValidationInfoChange(false);
            validationTMP = false
        }
        return validationTMP
    }
    const confirmReloadProductivity = async () => {
        let resultSwal;
        if (validationConfirm()) {
            resultSwal = await Swal.fire({
                title: `Seguro de volver a cargar la productividad`,
                html: 'Se perderán los cambios realizados',
                showCancelButton: true,
                confirmButtonText: "Si",
                confirmButtonColor: "#CB4335",
                cancelButtonText: "No",
                cancelButtonColor: "#239B56",
            });
        }

        return resultSwal?.isConfirmed;
    };

    // *cargar productividad
    const loadProductivity = async () => {
        const confirmed = await confirmReloadProductivity();
        if (confirmed === false) {
            return;
        }

        if (!infoArea || infoArea?.value === 0 || !infoArea?.value) {
            simpleAlerts('Debe seleccionar un área!', "warning");
            return;
        }
        if (!valueShift || valueShift === 0) {
            simpleAlerts('Debe seleccionar un turno!', "warning");
            return;
        }
        if (!valueActivities || valueActivities === 0) {
            simpleAlerts('Debe seleccionar una actividad!', "warning");
            return;
        }
        if (!dateInit || dateInit === '') {
            simpleAlerts('Debe seleccionar una fecha !', "warning");
            return;
        }
        getProductividadByAreaByTurnoByActividad()
    }

    const getProductividadByAreaByTurnoByActividad = async () => {
        try {
            setTitleSpinner('Buscando productividad...');
            setOpenModalLoader(true);
            const result = await ProductivityService.getProductividadByAreaByTurnoByActividad(url, infoArea?.value, valueShift, valueActivities, dateInit, dateInit);
            if (result.status === CODES.SUCCESS_200) {
                if (result?.data?.length === 0) {
                    simpleAlerts('No hay información de productividad, se creara una NUEVA', "info");
                    const bodyC = {
                        "fecha": dateInit,
                        "area_id": infoArea?.value,
                        "turno_id": valueShift,
                        "supervisor_id": id_personaLogin,
                        "actividad_id": valueActivities,
                        "sumHorasEjecutadas": null,
                        "productividadEspXHra": null,
                        "produccionTotal": null,
                        "comentario": null,
                        "usuario_id": id_usuarioLogin,
                    }
                    setProductivityCOriginal(bodyC);
                    setDataProductivityOriginal([]);

                    setProductivityC(bodyC);
                    setDataProductivity([]);
                } else {
                    let infoTmp = result?.data[0];
                    setDataProductivity(infoTmp?.detalle);
                    setDataProductivityOriginal(infoTmp?.detalle);
                    delete infoTmp?.detalle
                    setProductivityC(infoTmp);
                    setProductivityCOriginal(infoTmp);
                }
                setDeleteWorkerProd([]);
                setLoadValidation(true);
            }
            return []
        } catch (error) {
            console.log(`Error en getProductividadByAreaByTurnoByActividad: ${error}`);
        } finally {
            setTitleSpinner(null);
            setOpenModalLoader(false);
        }
    }

    // *productividad por turno inicio
    useEffect(() => {
        if (Number(infoCampus?.tipoProd) === 1)
            prodTurno(dataProductivity, productivityC);
    }, [productivityC?.produccionTotal])

    const prodTurno = (data, dataC) => {
        let dataProductivityTmp = _.cloneDeep(data);

        const totalHoras = dataProductivityTmp?.reduce((accumulator, item) => accumulator + minutesDiff(item?.horaFin, item?.horaInicio) / 60, 0);

        const indProd = dataC?.produccionTotal / totalHoras

        dataProductivityTmp?.forEach(item => {
            let minutos = minutesDiff(item?.horaFin, item?.horaInicio);
            let horas = minutos / 60;
            item.produccion = parseFloat(indProd * horas).toFixed(4);
        });

        setDataProductivity(dataProductivityTmp);
    }
    // *productividad por turno fin

    // *productividad por persona inicio
    const prodPersona = (data, dataC) => {
        let dataProductivityTmp = _.cloneDeep(data);
        let productivityCTmp = _.cloneDeep(dataC);

        const produccionTotal =
            dataProductivityTmp?.reduce((accumulator, item) => {
                return accumulator + Number(item.produccion);
            }, 0);

        productivityCTmp.produccionTotal = produccionTotal.toFixed(4);

        setDataProductivity(dataProductivityTmp);
        setProductivityC(productivityCTmp);
        setProductivityCOriginal(productivityCTmp);
    }
    // *productividad por persona fin

    const functionAdd = () => {
        if (infoCampus?.value === 0 || !infoCampus) {
            simpleAlerts('Debe seleccionar una sede!');
            return;
        }
        if (infoArea?.value === 0 || !infoArea) {
            simpleAlerts('Debe seleccionar un área!');
            return;
        }
        if (valueShift === 0 || !valueShift) {
            simpleAlerts('Debe seleccionar un turno!');
            return;
        }
        if (valueActivities === 0 || !valueActivities) {
            simpleAlerts('Debe seleccionar una actividad!');
            return;
        }
        if (dateInit === '' || !dateInit) {
            simpleAlerts('Debe seleccionar una fecha!');
            return;
        }

        handleOpen();
    }

    function checkTimeOverlapById(arr) {
        const overlaps = new Map();

        for (let i = 0; i < arr.length; i++) {
            const element = arr[i];
            const id = element.persona_id;
            const start = new Date(element.horaInicio).getTime();
            const end = new Date(element.horaFin).getTime();

            if (!overlaps.has(id)) {
                overlaps.set(id, []);
            }

            const personElements = overlaps.get(id);

            for (let j = 0; j < personElements.length; j++) {
                const existingElement = personElements[j];
                const existingStart = existingElement.start;
                const existingEnd = existingElement.end;

                if (
                    (start >= existingStart && start < existingEnd) ||
                    (end > existingStart && end <= existingEnd) ||
                    (start === existingEnd)
                ) {
                    return true; // Se encontró una superposición de tiempo
                }
            }

            personElements.push({ start, end });
            overlaps.set(id, personElements);
        }

        return false; // No hay superposición de tiempo
    }

    const saveProductivity = async () => {
        if (!loadValidation) {
            simpleAlerts("Debe buscar si ya existe productividad!", "warning");
            return;
        }
        if (checkTimeOverlapById(dataProductivity)) {
            simpleAlerts("Hay trabajadores que tienen horas incorrectas!");
            return
        }
        const body = {
            ...productivityC,
            detalle: dataProductivity,
            deleteProd: deleteWorkerProd,
            supervisor_id: (productivityC?.supervisor_id ? productivityC?.supervisor_id : id_personaLogin),
            usuario_id: id_usuarioLogin,
            fecha: dateInit,
            area_id: infoArea?.value,
            turno_id: valueShift,
            actividad_id: valueActivities,
        }
        Swal.fire({
            title: (body?.id_productividadC ? '¿Seguro de actualizar la productividad?' : '¿Seguro de crear la productividad?'),
            text: (body?.id_productividadC ? 'Se actualizara los calculos de la productividad' : 'Se generaran los calculos de la productividad'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: colors.secondary.hex,
            cancelButtonColor: colors.primary.hex,
            confirmButtonText: (body?.id_productividadC ? 'Si, actualizar' : 'Si, crear'),
        }).then((result) => {
            if (result.isConfirmed) {
                setOpenModalLoader(true);
                setTitleSpinner('Guardando productividad!');
                setTimeout(async () => {
                    try {
                        const result = await ProductivityService.saveProductividad(url, body);
                        if (result.status === CODES.CREATE_201) {
                            simpleAlerts(result?.data?.message?.cabecera?.message, "success");
                            simpleAlerts(result?.data?.message?.detalle, "success");
                            if (result?.data?.message?.delete)
                                simpleAlerts(result?.data?.message?.delete, "info");
                            if (result?.data?.message?.error)
                                simpleAlerts(result?.data?.message?.error, "success");
                            loadProductivity();
                        } else {
                            simpleAlerts(( result?.response?.data?.message?.error || 'Hubo un Error'), "error");
                            if(result?.response?.data?.message?.error_detail)
                                simpleAlerts(result?.response?.data?.message?.error_detail, "error");

                            console.log(result?.response?.data)
                        }
                        return [];
                    } catch (error) {
                        console.log(`Error en saveProductivity: ${error}`);
                    } finally {
                        setOpenModalLoader(false);
                        setTitleSpinner(null);
                        setLoadValidation(false);
                    }
                });
            }
        })
    };

    return (
        <div>
            <AddEdition
                open={open}
                handleClose={handleClose}
                setOpenModalLoader={setOpenModalLoader}

                infoCampus={infoCampus}
                infoArea={infoArea}
                valueActivities={valueActivities}
                valueShift={valueShift}
                shift={shift}
                dateInit={dateInit}

                dataProductivity={dataProductivity}
                setDataProductivity={setDataProductivity}
            />

            <Grid container spacing={1} justifyContent={'center'} alignItems={'center'}>
                <Grid item xs={12} sm={10} md={8}
                    sx={{ border: `solid 0.05rem ${colors.primary.hex}`, borderRadius: '25px', paddingBottom: 1, }}
                >
                    <GroupingAreaByUser
                        user={id_usuarioLogin}
                        empresas={empresas} clientes={clientes} unidades={unidades} sedes={sedes} areas={areas}
                        infoCompany={infoCompany} setInfoCompany={setInfoCompany}
                        infoClient={infoClient} setInfoClient={setInfoClient}
                        infoUnit={infoUnit} setInfoUnit={setInfoUnit}
                        infoCampus={infoCampus} setInfoCampus={setInfoCampus}
                        infoArea={infoArea} setInfoArea={setInfoArea}
                    />
                </Grid>
            </Grid>

            <section aria-label='button-filter' style={{ paddingTop: 8 }}>
                <Grid container spacing={2} justifyContent={'center'} alignItems={'center'}>
                    <Grid item xs={12} sm={10} md={8}>
                        <Grid container style={{ padding: '0.2rem 0.8rem' }}
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="flex-end"
                        >
                            <Grid item xs={12} sm={3}>
                                <MainSelect
                                    title={'Turnos'}
                                    options={shift}
                                    placeholder={'Selecionar Empresa'}
                                    value={+valueShift}
                                    onChange={(value, event) => setValueShift(+value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <MainSelect
                                    title={'Actividades'}
                                    options={activities}
                                    placeholder={'Selecionar Cliente'}
                                    value={+valueActivities}
                                    onChange={(value, event) => setValueActivities(+value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <MainInput
                                    title={'Fecha'}
                                    placeholder={'Ingrese Fecha Inicio'}
                                    type={'date'}
                                    value={dateInit}
                                    onChange={(e) => setDateInit(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={3} pr={1}>
                                <MainButton
                                    title={'Cargar'}
                                    fullWidth={true}
                                    size={'medium'}
                                    onClick={loadProductivity}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </section>

            <section aria-label='body-edition-productivity' style={{ paddingTop: 4 }}>
                <Grid container spacing={2} justifyContent={'center'} alignItems={'center'}>
                    <Grid item xs={12} sm={10} md={8}>
                        <Grid container style={{ padding: '0.2rem 0.8rem' }} direction="row" justifyContent="flex-start" alignItems="flex-end">
                            <Grid item xs={12} sm={3}>
                                <fieldset className='fieldset'>
                                    <legend className='title-legend'>Comentario</legend>
                                    <textarea
                                        className="textarea"
                                        name="textValue"
                                        placeholder='Ingrese comentario'
                                        value={productivityC?.comentario || ''}
                                        onChange={(event) => setProductivityC({ ...productivityC, comentario: event.target.value })}
                                    />
                                </fieldset>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                {Number(infoCampus?.tipoProd) === 1 ?
                                    <MainInput
                                        title={'Producción Total'}
                                        placeholder={'Ingrese Fecha Inicio'}
                                        type={'number'}
                                        propsInput={{ type: 'number', step: 0.0001, min: 0, }}
                                        value={productivityC?.produccionTotal ?? 0}

                                        onChange={(e) => { setProductivityC({ ...productivityC, produccionTotal: e.target.value }) }}
                                    />
                                    : <MainInputInfo
                                        title={'Producción Total'}
                                        value={productivityC?.produccionTotal}
                                    />
                                }
                            </Grid>
                            {loadValidation &&
                                <>
                                    <Grid item xs={12} sm={3} pr={1}>
                                        <Button
                                            startIcon={<AddBoxIcon />}
                                            size={'small'}
                                            variant={'contained'}
                                            color={'primary'}
                                            fullWidth
                                            onClick={functionAdd}
                                        >
                                            Agregar
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} sm={3} pr={1}>
                                        <Button startIcon={<SaveIcon />}
                                            size={'small'}
                                            variant={'contained'}
                                            color={'success'}
                                            fullWidth
                                            onClick={saveProductivity}
                                        >
                                            Guardar
                                        </Button>
                                    </Grid>
                                </>
                            }
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container justifyContent={'center'} alignItems={'center'} pt={1}>
                    <Grid item xs={12} sm={10} md={8}>
                        <BodyEdition
                            infoCampus={infoCampus}
                            productivityC={productivityC}
                            dataProductivity={dataProductivity}
                            deleteWorkerProd={deleteWorkerProd}
                            setDeleteWorkerProd={setDeleteWorkerProd}

                            prodTurno={prodTurno}
                            prodPersona={prodPersona}
                        />
                    </Grid>
                </Grid>
            </section>

            <SpinnerLoader open={openModalLoader} title={titleSpinner} />
        </div>
    );
};

export default ProductivityEdition;