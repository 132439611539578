import React, { } from 'react';
import AssistanceWeb from './AssistanceWeb';
import AssistanceGeneral from './AssistanceGeneralMovil';

const Assistance = ({ setValidationInfoChange, userToken, setLogoClient, pathLogo, isMovil, isDesktop, userAgent, setPageTitle }) => {
    return (
        (isDesktop && !isMovil) ?
            <AssistanceWeb
                userToken={userToken}
                setLogoClient={setLogoClient}
                pathLogo={pathLogo}
                isMovil={isMovil}
                setPageTitle={setPageTitle}
            />
            : <AssistanceGeneral
                setValidationInfoChange={setValidationInfoChange}
                userToken={userToken}
                setPageTitle={setPageTitle}
            />
    );
};

export default Assistance;