import React, { useState, useEffect } from 'react';
import { ButtonGroup, IconButton, Box, TextField, } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import MainWithoutResult from '../../General/MainWithoutResult';
import { colors } from '../../../../assets/colors';
import { formatDate } from '../../../../utils/function';
import { prodTurno, prodPersona } from '../../../pages/Productivity/functionProductivity';
import Swal from 'sweetalert2';
import _ from 'lodash';

const columnsTurno = [
  { id: 'trabajador', label: 'Nombre', width: 220, align: 'left', },
  { id: 'horaInicio', label: 'H. Inicio', width: 120, align: 'center', edit: true, type: 'datetime', },
  { id: 'horaFin', label: 'H. Fin', width: 120, align: 'center', edit: true, type: 'datetime', },
  { id: 'produccion', label: 'Prod. x Turno', width: 120, align: 'center', type: 'number', format: (value) => parseFloat(value)?.toFixed(4), },
  { id: 'sel', label: 'Sel.', width: 50, align: 'center', },
];

const columnsPersona = [
  { id: 'trabajador', label: 'Nombre', width: 220, align: 'left', },
  { id: 'horaInicio', label: 'H. Inicio', width: 120, align: 'center', type: 'datetime', format: (value) => formatDate(new Date(value), 'time', 'short') },
  { id: 'horaFin', label: 'H. Fin', width: 120, align: 'center', type: 'datetime', format: (value) => formatDate(new Date(value), 'time', 'short') },
  { id: 'produccion', label: 'Prod. x Persona', width: 120, align: 'center', edit: true, type: 'number', format: (value) => parseFloat(value)?.toFixed(4), },
  { id: 'sel', label: 'Sel.', width: 50, align: 'center', },
];

const BodyProductivity = (props) => {
  const { productivityC, setProductivityC, dataProductivity, setDataProductivity, tipoProd, workerSelected, setWorkerSelected } = props;

  const [columns, setColumns] = useState([]);

  useEffect(() => {
    if (+tipoProd === 1)
      setColumns(columnsTurno);
    else
      setColumns(columnsPersona);
  }, [tipoProd])

  const changeValue = (idx, key, value) => {
    let updDataProductivity = _.cloneDeep(dataProductivity);
    if (columns?.find(e => e.id === key)?.type === 'datetime') {
      let fechaSinHora = updDataProductivity[idx][key]?.split(' ')[0];
      let nuevaHoraCompleta = `${fechaSinHora} ${value}:00.000`;
      updDataProductivity[idx][key] = nuevaHoraCompleta;
    } else {
      updDataProductivity[idx][key] = value;
    }

    if (+tipoProd === 1)
      setDataProductivity(prodTurno(updDataProductivity, productivityC));
    else {
      let newProductivity = prodPersona(updDataProductivity, productivityC);
      setDataProductivity(newProductivity?.productividad);
      setProductivityC(newProductivity?.cabecera);
    }
  }

  /******/
  const handleChangeGeneral = (checked) => {
    let newArray;
    if (checked) {
      newArray = dataProductivity;
    } else {
      newArray = [];
    }
    return setWorkerSelected(newArray);
  }

  const handleChangeCheck = (checked, info) => {
    let newArray = _.cloneDeep(workerSelected);
    if (checked) {
      newArray.push(info);
    } else {
      newArray = newArray.filter((e) => +e.persona_id !== +info.persona_id);
    }
    return setWorkerSelected(newArray);
  }


  return (
    <Box className='page-body-main'>
      <Box className='page-body-table table-responsive-md'>
        <table className="table table-hover table-striped table-bordered">
          <thead className='sticky-top' style={{ backgroundColor: colors.quaternary.hex, color: '#ffff' }}>
            <tr>
              {columns.map((col) => (
                <th
                  scope="col"
                  key={col.id}
                  style={{
                    textAlign: col.align,
                    minWidth: col.width,
                  }}
                >
                  {col.id === 'sel' ?
                    <label className={`checkbox-title red`}>
                      <input type="checkbox" name="check" id={`checkbox-rect-header` + col.label}
                        checked={(workerSelected?.length === dataProductivity?.length && workerSelected?.length > 0) ? true : false}
                        onChange={(e) => handleChangeGeneral(e.target.checked)}
                        title={`${(workerSelected?.length === dataProductivity?.length && workerSelected?.length > 0) ? 'Retirara' : 'Seleccionara'} a todos los trabajadores`}
                      />
                      <span className="small"></span>
                    </label> :
                    col.label
                  }
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="table-group-divider">
            {dataProductivity?.map((productivity, indexRow) => {
              return (
                <tr key={"row" + indexRow}>
                  {columns.map((column, indexColumn) => {
                    const value = productivity[column.id];
                    return (
                      <td key={'col' + indexColumn} align={column.align}>
                        {column.id === 'sel' ?
                          <div className="checkbox-custom"
                            style={{ display: 'flex', justifyContent: 'center' }}
                          >
                            <input type="checkbox" name="check"
                              id={`checkbox-rect-${productivity.persona_id}`}
                              checked={workerSelected?.find(e => +e.persona_id === +productivity.persona_id) ? true : false}
                              onChange={(e) => handleChangeCheck(e.target.checked, productivity)}
                            />
                            <label htmlFor={`checkbox-rect-${productivity.persona_id}`}></label>
                          </div>
                          : column.id === 'actions' ? (
                            <ButtonGroup disableElevation variant="contained" aria-label="Disabled elevation buttons">
                              <IconButton aria-label="delete" size="small"
                              //onClick={() => deleteWorker(productivity)}
                              >
                                <CancelIcon fontSize='small' color={'secondary'} />
                              </IconButton>
                            </ButtonGroup>
                          ) : column.edit ? (
                            <TextField
                              fullWidth
                              className="input"
                              size='small'
                              type={column.type === 'datetime' ? 'time' : column.type}
                              value={(column.type === 'datetime' ? formatDate(new Date(value), 'time', 'short') : value) || ''}
                              onChange={(e) => changeValue(indexRow, column.id, e.target.value)}
                              inputProps={{
                                type: (column.type === 'datetime' ? 'time' : column.type),
                                min: (column.type === 'datetime' ? '00:00' : 0),
                                step: (column.type === 'datetime' ? '' : 0.0001),
                                style: { fontSize: 12, backgroundColor: 'white' },
                              }}
                              InputLabelProps={{ style: { fontSize: 12 } }} // font size of input label
                            />
                          ) : column.format ? (
                            column.type === 'datetime' ?
                              column.format(value)
                              : (isNaN(column.format(value)) ? '' : column.format(value))
                          ) : (
                            value
                          )}
                      </td>
                    );
                  })}
                </tr>
              );
            })}

            {(dataProductivity?.length === 0 || !dataProductivity) &&
              <MainWithoutResult />
            }
          </tbody>
        </table>
      </Box>
    </Box>
  );
}

export default BodyProductivity;