import { formatDate, minutesDiff } from "../../../utils/function";
import _ from 'lodash';

function checkTimeOverlapById(arr) {
    const overlaps = new Map();

    for (let i = 0; i < arr.length; i++) {
        const element = arr[i];
        const id = element.persona_id;
        const start = new Date(element.horaInicio).getTime();
        const end = new Date(element.horaFin).getTime();

        if (!overlaps.has(id)) {
            overlaps.set(id, []);
        }

        const personElements = overlaps.get(id);

        for (let j = 0; j < personElements.length; j++) {
            const existingElement = personElements[j];
            const existingStart = existingElement.start;
            const existingEnd = existingElement.end;

            if (
                (start >= existingStart && start < existingEnd) ||
                (end > existingStart && end <= existingEnd) ||
                (start === existingEnd)
            ) {
                return true; // Se encontró una superposición de tiempo
            }
        }

        personElements.push({ start, end });
        overlaps.set(id, personElements);
    }

    return false; // No hay superposición de tiempo
}

const bodyItem = (person, turnos, fecha, turno) => {
    let tiempo1 = `${fecha} ${turnos?.find(e => Number(e.id_turno) === Number(turno))?.ingreso?.substring(0, 5)}:00.000`;
    let tiempo2 = `${fecha} ${turnos?.find(e => Number(e.id_turno) === Number(turno))?.salida?.substring(0, 5)}:00.000`;

    const fecha1 = new Date(tiempo1);
    const fecha2 = new Date(tiempo2);
    if (fecha1.getTime() > fecha2.getTime()) {
        fecha2.setDate(fecha2.getDate() + 1);
    }

    let newItem =
    {
        "persona_id": person?.id_persona,
        "horaInicio": `${formatDate(fecha1)}:00.000`,
        "horaFin": `${formatDate(fecha2)}:00.000`,
        "horasEjecutadas": null,
        "productividadEspXHra": '',
        "productividadRealXHra": '',
        "produccion": '',
        "trabajador": person?.trabajador,
        "codSghh": person?.codSghh,
    }

    return newItem;
}

function prodTurno(data, dataC) {
    let dataProductivityTmp = _.cloneDeep(data);

    const totalHoras = dataProductivityTmp?.reduce((accumulator, item) => accumulator + minutesDiff(item?.horaFin, item?.horaInicio) / 60, 0);

    const indProd = dataC?.produccionTotal / totalHoras

    dataProductivityTmp?.forEach(item => {
        let minutos = minutesDiff(item?.horaFin, item?.horaInicio);
        let horas = minutos / 60;
        item.produccion = parseFloat(indProd * horas).toFixed(4);
    });

    return dataProductivityTmp;
}

function prodPersona(data, dataC) {
    let dataProductivityTmp = _.cloneDeep(data);
    let productivityCTmp = _.cloneDeep(dataC);

    const produccionTotal = dataProductivityTmp?.reduce((accumulator, item) => {
        return accumulator + Number(item.produccion);
    }, 0);

    if (productivityCTmp?.produccionTotal) {
        productivityCTmp.produccionTotal = produccionTotal.toFixed(4);
    }

    return { 'productividad': dataProductivityTmp, 'cabecera': productivityCTmp };
}

export { checkTimeOverlapById, bodyItem, prodTurno, prodPersona };
