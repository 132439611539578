import React, { useState, useEffect, } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import ApartmentIcon from '@mui/icons-material/Apartment';
import GroupIcon from '@mui/icons-material/Group';
import CarpenterIcon from '@mui/icons-material/Carpenter';
import AddAlarmIcon from '@mui/icons-material/AddAlarm';
import EngineeringIcon from '@mui/icons-material/Engineering';
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import { colors } from '../../assets/colors';
import { navigatePages } from '../../utils/function';
import _ from 'lodash';
import { Layout, } from "antd";
const { Sider } = Layout;

const SideMenu = ({ validationInfoChange = true, setValidationInfoChange, collapsed, setCollapsed, menu, setPageTitle }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [menuTmp, setMenuTmp] = useState(null);
    const [categories, setCategories] = useState(null);

    useEffect(() => {
        let updMenu = _.cloneDeep(menu);
        updMenu = updMenu?.filter(e => Number(e.ubicacionMenu) !== 1);

        updMenu?.forEach(element => {
            element.key = element?.link
            element.icon = searchIcon(element?.link)
            element.label = element?.desPagina
        })

        let categorias = updMenu?.reduce((acc, item) => { //itera cada elemento del tmp
            if (!acc.includes(item.categoria)) { //esta condicion valida que no este antes
                acc.push(item.categoria); //agrega al acc temporal
            }
            return acc;
        }, []);
        categorias?.sort();

        return setCategories(categorias), setMenuTmp(updMenu), setCollapsed(true);
    }, [menu])

    const searchIcon = (icon) => {
        switch (icon) {
            case '/user':
                return <PersonAddAltIcon />;
            case '/profiles':
                return <RecentActorsIcon />;
            case '/assistance':
                return <AssignmentTurnedInIcon />;
            case '/areas':
                return <ApartmentIcon />;
            case '/user/areas':
                return <GroupIcon />;
            case '/shifts':
                return <AddAlarmIcon />;
            case '/sede/shift':
                return <CarpenterIcon />;
            default:
                return <EngineeringIcon />;
        }
    }

    return (
        <Sider
            trigger={null}
            collapsible
            collapsed={collapsed}
            collapsedWidth="0"
            className='sider-menu'
            style={{
                background: colors.tertiary.hex,
                margin: (collapsed ? '1rem 1.5rem 1rem .8rem' : '1rem 2.3rem 1rem .8rem')
            }}
        >
            {categories?.map((category) => {
                return (
                    <ElementMenu key={category + 'oculto'} info={category} menu={menuTmp}
                        navigate={navigate}
                        location={location}
                        setPageTitle={setPageTitle}
                        validationInfoChange={validationInfoChange}
                        setValidationInfoChange={setValidationInfoChange}
                        navigatePages={navigatePages} />
                )
            })}
        </Sider>
    );
};

const ElementMenu = (props) => {
    const { info, menu,
        navigate, location,
        setPageTitle, validationInfoChange, setValidationInfoChange, navigatePages,
    } = props;
    const [open, setOpen] = useState(false);
    const menuTmp = menu?.filter(e => e.categoria === info && Number(e.ubicacionMenu) !== 1);
    const desPaginas = menuTmp?.map(m => m.shortName).join(', ');

    return (
        <>
            <ListItemButton
                alignItems="flex-start"
                onClick={() => setOpen(!open)}
                sx={{
                    pt: 1.5,
                    pb: open ? .5 : 1.5,
                    "&:hover, &:focus": { "& svg": { opacity: open ? 1 : 0 } },
                    "&:hover": { "backgroundColor": `#fdd530`, "color": "#000000" },
                    backgroundColor: colors.primary.hex,
                    color: '#ffffff'
                }}
            >
                <ListItemText
                    primary={info}
                    primaryTypographyProps={{
                        fontSize: 15,
                        fontWeight: "medium",
                        lineHeight: "20px",
                        mb: "0.125rem"
                    }}
                    secondary={desPaginas}
                    secondaryTypographyProps={{
                        noWrap: true,
                        fontSize: 12,
                        lineHeight: "16px",
                        //color: open ? "rgba(0,0,0,0)" : "rgba(255,255,255,0.5)"
                        color: open ? colors.quaternary.hex : "rgba(255,255,255,0.5)"
                    }}
                    sx={{ my: 0 }}
                />
                <KeyboardArrowDown
                    sx={{
                        mr: -1,
                        opacity: 0,
                        transform: open ? "rotate(-180deg)" : "rotate(0)",
                        transition: "0.2s"
                    }}
                />
            </ListItemButton>
            {open &&
                menuTmp?.map((item) => {
                    return (
                        <ListItemButton
                            key={item.label}
                            sx={{
                                py: 0, minHeight: 32, color: "rgba(255,255,255,.8)",
                                backgroundColor: `rgba(255, 255, 255,.2)`
                            }}
                            onClick={() => navigatePages(location, navigate, setPageTitle, item.desPagina, item.link, validationInfoChange, setValidationInfoChange)}
                        >
                            <ListItemIcon sx={{ color: "inherit" }}>
                                {item.icon}
                            </ListItemIcon>
                            <ListItemText
                                primary={item.label}
                                primaryTypographyProps={{
                                    fontSize: 14,
                                    fontWeight: "medium"
                                }}
                            />
                        </ListItemButton>
                    )
                })
            }
        </>
    )
}

export default SideMenu;