import React, { useState, useEffect } from 'react';
import { Grid, Box } from '@mui/material';
import { OrderedListOutlined, } from '@ant-design/icons/lib/icons';
import MainSelect from '../../General/MainSelect';
import MainInput from '../../General/MainInput';
import MainWithoutResult from '../../General/MainWithoutResult';
import SpinnerSmall from '../../SpinnerLoad/SpinnerSmall';
import StaffService from '../../../../services/staff';
import { sortDataListSimple, filterData } from '../../../../utils/function';
import { CODES } from '../../../../utils/responseCodes';
import _ from 'lodash';

const columns = [
    { id: 'id_persona', label: '#', width: 10, align: 'center', },
    { id: 'codSghh', label: 'Código', width: 100, align: 'left', },
    { id: 'trabajador', label: 'Nombres', width: 100, align: 'left', },
    { id: 'sel', label: 'Sel.', width: 10, align: 'center', },
];

const MassiveAssistance = (props) => {
    const { infoCampus, areas, dataShift, dataAssistanceCondition,
        shift, setShift, valueArea, setValueArea, typeAssistance, setTypeAssistance,
        markIn, setMarkIn, markOut, setMarkOut, breakLunch, setBreakLunch,
        dateInitMassive, setDateInitMassive, dateEndMassive, setDateEndMassive,
        workersAssistance, setWorkersAssistance, dateInit, dateEnd, areasOpt,
    } = props;

    const url = "'" + window.location.pathname + "'";
    const [openSmall, setOpenSmall] = useState(true);
    const [dataWorkers, setDataWorkers] = useState(null);
    const [search, setSearch] = useState('');

    let resultSearch = filterData(dataWorkers, search, ['codSghh', 'numdoc', 'apePaterno', 'trabajador']);

    /** inicio ordenamiento de columnas */
    const [sortConfigTable, setSortConfigTable] = useState({ key: null, direction: "ascending", });
    const handleSortTable = (key) => {
        let direction = "ascending";
        if (sortConfigTable && sortConfigTable.key === key && sortConfigTable.direction === "ascending") {
            direction = "descending";
        }
        setSortConfigTable({ key, direction });
    };
    const sortedData = sortDataListSimple(resultSearch, sortConfigTable); //sortDataListSimple(dataWorkers, sortConfigTable);
    /** fin ordenamiento de columnas */

    useEffect(() => {
        getPersonBySede();
    }, [])

    useEffect(() => {
        let timeIn;
        let timeOut;
        let brunchTmp;
        if (+shift > 0) {
            let shiftTmp = dataShift?.find((e) => +e.value === +shift);
            timeIn = shiftTmp.ingreso;
            timeOut = shiftTmp.salida;
            brunchTmp = shiftTmp.refrigerio;
        }
        if (+typeAssistance > 0) {
            let indoCond = dataAssistanceCondition?.find(e => +e.value === +typeAssistance);
            if (+indoCond?.conMarca === 0) {
                timeIn = null;
                timeOut = null;
                brunchTmp = null;
            }
        }
        setBreakLunch(brunchTmp);
        setMarkIn(timeIn?.substr(0, 5));
        setMarkOut(timeOut?.substr(0, 5));
    }, [shift, typeAssistance]);

    useEffect(() => {
        setDateInitMassive(dateInit);
        setDateEndMassive(dateEnd);
    }, [dateInit, dateEnd])

    const getPersonBySede = async () => {
        try {
            setOpenSmall(true);
            const result = await StaffService.getPersonBySede(url, { pagination: 1 }, infoCampus?.value);
            if (result.status === CODES.SUCCESS_200) {
                setDataWorkers(result.data);
            }
            return []
        } catch (error) {
            console.log(`Error en getPersonBySede: ${error}`);
        } finally {
            setOpenSmall(false);
        }
    }

    const handleChangeCheckGeneral = (e) => {
        let updWorkersAssistance = _.cloneDeep(workersAssistance);

        if (e.target.checked === false) {
            updWorkersAssistance = [];
        } else {
            updWorkersAssistance = dataWorkers;
        }
        return setWorkersAssistance(updWorkersAssistance);
    }

    const handleChangeCheck = (value, p_person) => {
        let updWorkersAssistance = _.cloneDeep(workersAssistance);

        if (value === false)
            updWorkersAssistance = updWorkersAssistance?.filter((e) => Number(e.id_persona) !== Number(p_person.id_persona));
        else
            updWorkersAssistance.push(p_person);

        return setWorkersAssistance(updWorkersAssistance);
    }

    const searcher = (e) => {
        setSearch(e.target.value);
    }

    return (
        <>
            <Grid container>
                <Grid item md={3} xs={6} mt={'5px'}>
                    <MainSelect
                        title={'Áreas'}
                        placeholder={'Selecionar área'}
                        options={areasOpt}
                        value={Number(valueArea)}
                        onChange={(value, e) => { setValueArea(value) }}
                        name='area'
                    />
                </Grid>
                <Grid item md={3} xs={6} mt={'5px'}>
                    <MainSelect
                        title={'Turnos'}
                        placeholder={'Selecionar turno'}
                        options={dataShift}
                        value={shift}
                        onChange={(value, e) => { setShift(value) }}
                        name='turno'
                    />
                </Grid>
                <Grid item md={3} xs={6} mt={'5px'}>
                    <MainSelect
                        title={'Tipo de asistencia'}
                        placeholder={'Selecionar'}
                        options={dataAssistanceCondition}
                        value={typeAssistance}
                        onChange={(value, e) => { setTypeAssistance(value) }}
                        name='tipoasis'
                    />
                </Grid>
                <Grid item md={3} xs={6} mt={'5px'}>
                    <MainInput
                        title={'Refrigerio'}
                        type={'Number'}
                        placeholder={'Min. de Refrigerio'}
                        value={breakLunch}
                        propsInput={{
                            step: 1,
                            min: 0,
                            max: 100,
                            type: 'number',
                        }}
                        onChange={(e) => setBreakLunch(e.target.value)}
                        name='refrigerio'
                        required={+dataAssistanceCondition?.find(e => Number(e.value) === Number(typeAssistance))?.conMarca === 1 ?
                            true :
                            false}
                        readonly={+dataAssistanceCondition?.find(e => Number(e.value) === Number(typeAssistance))?.conMarca === 1 ?
                            false :
                            true}
                    />
                </Grid>
                <Grid item md={3} xs={6} mt={'5px'}>
                    <MainInput
                        title={'Fecha inicio'}
                        type={'date'}
                        value={dateInitMassive}
                        onChange={(e) => {
                            setDateInitMassive(e.target.value);
                            /*if (e.target.value > dateInit) {
                                setDateEndMassive(e.target.value);
                            }*/
                        }}
                        name='inicio'
                        required={true}
                        propsInput={{
                            // min: dateInit,
                            // max: dateEnd,
                            type: 'date',
                        }}
                    />
                </Grid>
                <Grid item md={3} xs={6} mt={'5px'}>
                    <MainInput
                        title={'Fecha fin'}
                        type={'date'}
                        value={dateEndMassive}
                        onChange={(e) => setDateEndMassive(e.target.value)}
                        name='fin'
                        required={true}
                        propsInput={{
                            // min: dateInitMassive,
                            // max: dateEnd,
                            type: 'date',
                        }}
                    />
                </Grid>
                <Grid item md={3} xs={6} mt={'5px'}>
                    <MainInput
                        title={'Hora Entrada'}
                        type={'time'}
                        value={markIn}
                        onChange={(e) => setMarkIn(e.target.value)}
                        name='ingreso'
                        required={+dataAssistanceCondition?.find(e => Number(e.value) === Number(typeAssistance))?.conMarca === 1 ?
                            true :
                            false}
                        readonly={+dataAssistanceCondition?.find(e => Number(e.value) === Number(typeAssistance))?.conMarca === 1 ?
                            false :
                            true}
                    />
                </Grid>
                <Grid item md={3} xs={6} mt={'5px'}>
                    <MainInput
                        title={'Hora Salida'}
                        type={'time'}
                        value={markOut}
                        onChange={(e) => setMarkOut(e.target.value)}
                        name='salida'
                        required={+dataAssistanceCondition?.find(e => Number(e.value) === Number(typeAssistance))?.conMarca === 1 ?
                            true :
                            false}
                        readonly={+dataAssistanceCondition?.find(e => Number(e.value) === Number(typeAssistance))?.conMarca === 1 ?
                            false :
                            true}
                    />
                </Grid>
                <Grid item xs={12} mt={2}>
                    <SpinnerSmall open={openSmall} />
                    <MainInput
                        placeholder={'Busqueda por Nombres / Ape.Paternos / Ape.Maternos / Nro.Doc.'}
                        value={search}
                        onChange={searcher}
                    />
                    <Box className='page-body-main'>
                        <Box className='page-body-table page-body-table__modal table-responsive-md'>
                            <table className="table table-hover table-striped table-bordered table-sm">
                                <thead className='sticky-top table-warning'>
                                    <tr>
                                        {columns.map((column) => (
                                            <th
                                                scope="col"
                                                key={column.id}
                                                style={{
                                                    textAlign: column.align,
                                                    width: column.width,
                                                    maxWidth: column.width,
                                                    cursor: 'pointer',
                                                }}
                                                onClick={() => handleSortTable(column.id)}
                                            >
                                                {column.id === 'sel' ?
                                                    <input type="checkbox"
                                                        name="check"
                                                        id={`checkbox-rect-header`}
                                                        className={'checkbox-rect-header'}
                                                        onChange={handleChangeCheckGeneral}
                                                    />
                                                    : <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                        <div style={{ marginRight: 'auto' }}>{column.label}</div>
                                                        <div><OrderedListOutlined style={{ paddingBottom: 2 }} /></div>
                                                    </div>}
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody className="table-group-divider">
                                    {sortedData?.map((person, indexRow) => {
                                        return (
                                            <tr key={"row" + indexRow}>
                                                {columns.map((column, indexColumn) => {
                                                    const value = person[column.id];
                                                    return (
                                                        <td key={'col' + indexColumn} align={column.align}>
                                                            {column.id === 'sel' ?
                                                                <div className="checkbox-custom"
                                                                    style={{ display: 'flex', justifyContent: 'center' }}
                                                                >
                                                                    <input type="checkbox"
                                                                        name="check"
                                                                        id={`checkbox-rect` + person.id_persona}
                                                                        checked={workersAssistance?.find(e => Number(e.id_persona) === Number(person.id_persona)) ? true : false}
                                                                        onChange={(e) => handleChangeCheck(e.target.checked, person)}
                                                                    />
                                                                    <label htmlFor={`checkbox-rect` + person.id_persona}></label>
                                                                </div>
                                                                : value}
                                                        </td>
                                                    );
                                                })}
                                            </tr>
                                        );
                                    })}
                                    {(dataWorkers?.length === 0 || !dataWorkers) &&
                                        <MainWithoutResult />
                                    }
                                </tbody>
                            </table>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </>
    );
};

export default MassiveAssistance;