import React, { useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';
import { CODES } from '../../../../utils/responseCodes';
import { simpleAlerts } from '../../../../utils/alerts';
import SpinnerLoader from '../../../components/SpinnerLoad/SpinnerLoader';
import MainInput from '../../../components/General/MainInput';
import GeneralService from '../../../../services/general';
import ReportService from '../../../../services/report';
import StaffService from '../../../../services/staff';
import MainSelect from '../../../components/General/MainSelect';
import { getDateOL } from '../../../../utils/function';
import { Tabs, Tab } from '@mui/material';
import GroupingAreaReport from '../../../components/GroupingAreaReport';

const AssistanceDetail = ({ userToken }) => {
    const [openModalLoader, setOpenModalLoader] = useState(false);
    const [titleModalLoader, setTitleModalLoader] = useState(null);


    
    const [infoRegion, setInfoRegion] = useState(null);
    const [regiones, setRegiones] = useState(null);
    const [campus, setCampus] = useState(null);

    const [dateInit, setDateInit] = useState(getDateOL());
    const [dateEnd, setDateEnd] = useState(getDateOL('', 'short', 7));
    const [type, setType] = useState(1);


    const [infoCompany, setInfoCompany] = useState(null);
    const [infoClient, setInfoClient] = useState(null);
    const [infoUnit, setInfoUnit] = useState(null);
    const [infoCampus, setInfoCampus] = useState(null);
    const [infoArea, setInfoArea] = useState(null);

    const [activeTab, setActiveTab] = useState('sede');
   
    const generateAsistenciaDetalladaPDF = async () => {
      if (!infoArea?.value) {
          simpleAlerts('Seleccione una Area', 'warning');
      } else {
          try {
              setTitleModalLoader('Generando Reporte de Asistencia...');
              setOpenModalLoader(true);
  
              const result = await ReportService.generateAsistenciaDetalladaPDF(dateInit, dateEnd, infoArea?.value, infoArea?.label, infoCompany.label,infoCompany.ruc); // Cambia la función del servicio para generar PDF
  
              if (result.status === CODES.SUCCESS_200) {
                  const blob = new Blob([result.data], { type: 'application/pdf' }); // Cambia el tipo de contenido a PDF
                  const url = URL.createObjectURL(blob);
                  const link = document.createElement('a');
                  link.href = url;
                  link.download = 'ASISTENCIA_DETALLADA_' + infoArea?.desArea + "_" + dateInit + '_' + dateEnd + '.pdf'; // Cambia la extensión del archivo
                  link.click();
                  URL.revokeObjectURL(url);
                  setOpenModalLoader(false);
              } else {
                  setOpenModalLoader(false);
                  setTitleModalLoader(null);
              }
          } catch (error) {
              console.log(`Error en generateActividad: ${error}`);
          }
      }
  };

    return (
        <Box sx={{ flexGrow: 1 }}>
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <Grid item xs={10} sm={8} md={8}>
            <Grid container p={1} justifyContent="center" alignItems="center">
              <Grid item xs={12} sm={6} md={2}>
                <Tabs value={activeTab} onChange={(event, newValue) => setActiveTab(newValue)}>
                  <Tab value="sede" label="Asistencia" />
                 
                </Tabs>
              </Grid>
            </Grid>
            <Grid container justifyContent="center" alignItems="center">
              {activeTab === 'region' ? (
                <Grid item xs={12} sm={6} md={6}>
                  <MainSelect
                    title={'Region'}
                    options={regiones}
                    placeholder={'Seleccionar Región'}
                    value={infoRegion?.value}
                    onChange={(value, event) => setInfoRegion(regiones?.find(element => element.value == value))}
                  />
                </Grid>
              ) : (
                <Grid item xs={12} sm={6} md={8}>
                  <GroupingAreaReport
                    infoCompany={infoCompany} setInfoCompany={setInfoCompany}
                    infoClient={infoClient} setInfoClient={setInfoClient}
                    infoUnit={infoUnit} setInfoUnit={setInfoUnit}
                    infoCampus={infoCampus} setInfoCampus={setInfoCampus}
                    infoArea  ={infoArea} setInfoArea={setInfoArea}
                  />
                </Grid>
              )}
            </Grid>
            <Grid container p={1} justifyContent="center" alignItems="center">
              <Grid item xs={12} sm={6} md={4}>
                <MainInput
                  title={'Fecha Inicio'}
                  placeholder={'Ingrese Fecha Inicio'}
                  type={'date'}
                  value={dateInit}
                  onChange={(e) => setDateInit(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <MainInput
                  title={'Fecha Fin'}
                  placeholder={'Ingrese Fecha Fin'}
                  type={'date'}
                  value={dateEnd}
                  onChange={(e) => setDateEnd(e.target.value)}
                  propsInput={{ min: dateInit }}
                />
              </Grid>
            </Grid>
            <Grid container p={1} marginTop={'1rem'} justifyContent="center" alignItems="center">
              <Grid item xs={12} sm={6} md={8}>
                {activeTab === 'sede' && (
                  <>
                    <button
                      type='button'
                      className='btn btn-success'
                     onClick={generateAsistenciaDetalladaPDF}
                      style={{ marginRight: "2rem" }}
                    >
                      REPORTE ASISTENCIA
                    </button>
                  </>
                )}
              </Grid>
            </Grid>
            <SpinnerLoader open={openModalLoader} title={titleModalLoader} />
          </Grid>
        </Grid>
      </Box>
    );
};
export default AssistanceDetail;



