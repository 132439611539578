import React, { useState, useEffect } from 'react';
import { Grid, Box, TextField, InputAdornment } from '@mui/material';
import MainInput from '../../../General/MainInput';
import MainInputInfo from '../../../General/MainInputInfo';
import BodyStaffs from '../../../../components/Maintenance/Organigrama/BodyStaffs/BodyStaffs';
import StaffService from '../../../../../services/staff';
import { CODES } from '../../../../../utils/responseCodes';
import { simpleAlerts } from '../../../../../utils/alerts';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import SpinnerLoader from '../../../../components/SpinnerLoad/SpinnerLoader';
const InfoAreaOrg = (props) => {
    const { infoAreaOrg, setInfoAreaOrg, id_usuarioLogin } = props;

    const [dataStaffs, setDataStaffs] = useState(null);
    const [open, setOpen] = useState(false)
    const [search, setSearch] = useState('');
    const handleOpen = () => setOpen(true);
    const url = "'" + window.location.pathname + "'";
    const [searchStaff, setSearchStaff] = useState("");
    const [pageStaff, setPageStaff] = useState(1);
    const pageSizeStaff = 7;
    const [totalPagesStaff, setTotalPagesStaff] = useState(1);

    const [openModalLoader, setOpenModalLoader] = useState(true);

    useEffect(() => {
        getStaff();
    }, [searchStaff, pageStaff])

    const getStaff = async () => {
        try {
            const result = await StaffService.getStaff(url, { search: searchStaff, page: pageStaff, pageSize: pageSizeStaff, pagination: 0 });
            if (result.status === CODES.SUCCESS_200) {
                setDataStaffs(result.data.data);
                setTotalPagesStaff(result.data.last_page);
                setOpenModalLoader(false);
            } else {
                simpleAlerts(result.response.data.message, 'error');
            }
            return [];
        } catch (error) {
            console.log(`Error en getStaff: ${error}`);
        }
    }

    return (
        <>
            <Grid container>
                <Grid item md={6} xs={12} pt={1}>
                    <MainInput
                        title={'Area:'}
                        value={infoAreaOrg?.Area}
                        onChange={(e) => setInfoAreaOrg({ ...infoAreaOrg, Area: e.target.value })}
                        required={true}
                    />
                </Grid>
                <Grid item md={6} xs={12} pt={1}>
                    <MainInputInfo
                        title={'Jefe:'}
                        value={infoAreaOrg?.Jefe || " "}
                    />
                </Grid>
                <Grid item md={12} xs={12} pt={1} paddingTop={"1rem"} paddingBottom={"1rem"}>
                    <Box width={'100%'}>
                        <TextField
                            id="input-with-icon-textfield"
                            label="Búsqueda"
                            placeholder="Buscar personal"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <TravelExploreIcon color='tertiary' />
                                    </InputAdornment>
                                )
                            }}
                            variant="standard"
                            style={{ width: '100%' }}
                            onChange={(e) => setSearchStaff(e.target.value)}
                        />
                    </Box>
                </Grid>
                <Grid item md={12} xs={12} >
                    <BodyStaffs
                        dataStaffs={dataStaffs}
                        setDataStaffs={setDataStaffs}
                        totalPagesStaff={totalPagesStaff}
                        pageStaff={pageStaff}
                        setPageStaff={setPageStaff}
                        search={search}
                        infoAreaOrg={infoAreaOrg}
                        setInfoAreaOrg={setInfoAreaOrg}
                        id_usuarioLogin={id_usuarioLogin}

                        handleOpen={handleOpen}

                    />
                </Grid>
                <SpinnerLoader open={openModalLoader} />
            </Grid>
        </>
    );
};

export default InfoAreaOrg;