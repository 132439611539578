import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import { CODES } from "../../../utils/responseCodes";
import SpinnerLoader from "../../components/SpinnerLoad/SpinnerLoader";
import MainInput from "../../components/General/MainInput";
import { getDateOL } from "../../../utils/function";
import ComplementaryService from "../../../services/complementary";


const RecursoRRHH = ({ userToken }) => {
  const [openModalLoader, setOpenModalLoader] = useState(false);
  const [titleModalLoader, setTitleModalLoader] = useState(null);
  const [dateInit, setDateInit] = useState(getDateOL());
  const [dateEnd, setDateEnd] = useState(getDateOL("", "short", 7));
  const [infoCampus, setInfoCampus] = useState(null);
  const [infoWorkers, setInfoWorkers] = useState(null);




  useEffect(() => {
    if (infoCampus) {
      setInfoWorkers([]);
    }
  }, [infoCampus]);

  const generateReporteLA = async () => {
      try {
        setTitleModalLoader("Generando Reporte de Lavoro Assistance...");
        setOpenModalLoader(true);
        const result = await ComplementaryService.generateReporteLA(
          dateInit,
          dateEnd
        );
        if (result.status === CODES.SUCCESS_200) {
          const blob = new Blob([result.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const url = URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.download =
            "REPORTE LA"+
            "_" +
            dateInit +
            "_" +
            dateEnd +
            ".xlsx";
          link.click();
          URL.revokeObjectURL(url);
          //getPeople();
          setOpenModalLoader(false);
        } else {
          setOpenModalLoader(false);
          setTitleModalLoader(null);
        }
        return [];
      } catch (error) {
        console.log(`Error en generateReporteLA: ${error}`);
      }
    
  };


  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid padding={2} item xs={10} sm={12} md={8}>
          <div
            style={{
              fontSize: "1.5rem",
              fontWeight: "bold",
              marginBottom: "1rem",
            }}
          >
            REPORTE DE LA{" "}
          </div>
          <Grid container>
            <Grid item xs={12} sm={6} md={2}>
              <MainInput
                title={"Fecha Inicio"}
                placeholder={"Ingrese Fecha Inicio"}
                type={"date"}
                value={dateInit}
                onChange={(e) => setDateInit(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <MainInput
                title={"Fecha Fin"}
                placeholder={"Ingrese Fecha Fin"}
                type={"date"}
                value={dateEnd}
                onChange={(e) => setDateEnd(e.target.value)}
                propsInput={{ min: dateInit }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2} style={{ marginTop: "1.3%" }}>
              <button
                type="button"
                className="btn btn-success"
                onClick={generateReporteLA}
              >
                REPORTE LA
              </button>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <textarea
                value={"REPORTE DE LAVORO ASSISTANCE"}
                readOnly
                style={{
                  marginTop: "6px",
                  border: "1px solid #ccc",
                  padding: "5px",
                  width: "100%",
                  resize: "none",
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <SpinnerLoader open={openModalLoader} title={titleModalLoader} />
      </Grid>
    </Box>
  );
};
export default RecursoRRHH;
