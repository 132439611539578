import React from 'react';
import mainImg from '../../assets/img/home/main.png';
import { YouTube } from '@mui/icons-material';

const Home = () => {
    return (
        <div className="flex-container-home">
            <div className="column-home">
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    <h1 className='text-center'>
                        Bienvenido <br />al sistema de Lavoro Peru
                    </h1>
                    <br />
                    <h3>Manuales</h3>
                    <button
                        className='btn shakeFix-button-main btn-home'
                        type='button'
                    >
                        Manual Lavoro-Assistance
                    </button>

                    <button
                        className='btn shakeFix-button-main btn-home btn-home__yellow'
                        type='button'
                    >
                        Manual ASISPROD
                    </button>
                    <a href='#'><YouTube color='secondary' fontSize='large' /> Capacitación Guiada</a>
                </div>
            </div>
            <div className="column-home">
                <img
                    alt='main image'
                    src={mainImg}
                    style={{
                        maxWidth: '70%',
                        height: 'auto',
                        borderRadius: '50% 10% 50% 50%',
                    }}
                />
            </div>
        </div>
    );
};

export default Home;