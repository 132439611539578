import React, { useState, useEffect } from 'react';
import { Grid, Box } from '@mui/material';
import MainInputInfo from '../../../General/MainInputInfo';
import MainInput from '../../../General/MainInput';
import MainSelect from '../../../General/MainSelect';
import { minutesDiff } from '../../../../../utils/function';
import _ from 'lodash';

const InfoShift = (props) => {
    const { weekDays, regimes, infoTurno, setInfoTurno, } = props;

    const [weekDaysCol, setWeekDaysCol] = useState(null);

    useEffect(() => {
        let cantXcol = 4;
        let toltalCol = weekDays?.length / cantXcol;
        let arrayTemp = [];
        for (let i = 0; i < toltalCol; i++) {
            arrayTemp.push(weekDays.slice((i * cantXcol), (i * cantXcol) + cantXcol));
        }
        setWeekDaysCol(arrayTemp);
    }, [weekDays])

    useEffect(() => {
        let updInfoTurno = _.cloneDeep(infoTurno);
        const hrNormales = (Math.abs(minutesDiff(infoTurno?.ingreso, infoTurno?.salida, 'time')) - infoTurno?.refrigerio || 0) / 60;
        if (!updInfoTurno.Hnormales || hrNormales < +updInfoTurno.Hnormales) {
            updInfoTurno.Hnormales = hrNormales;
            setInfoTurno(updInfoTurno);
        }
    }, [infoTurno.ingreso, infoTurno.salida, infoTurno.refrigerio])

    const handleChangeCheck = (value, p_day) => {
        let updInfoTurno = _.cloneDeep(infoTurno);
        if (value === true)
            updInfoTurno[p_day?.id] = 1;
        else
            updInfoTurno[p_day?.id] = 0;
        return setInfoTurno(updInfoTurno)
    }

    return (
        <Grid container>
            <Grid item md={6} xs={12} pt={1}>
                <MainInput
                    title={'Descripción'}
                    value={infoTurno?.desTurno}
                    name={'desTurno'}
                    placeholder={"Ingrese descripción"}
                    onChange={(e) => setInfoTurno({ ...infoTurno, desTurno: e.target.value })}
                    required={true}
                />
            </Grid>
            <Grid item md={6} xs={12} pt={1}>
                <MainInput
                    title={'Código SGHH'}
                    value={infoTurno?.codsghh}
                    name={'codsghh'}
                    placeholder={"Ingrese código sghh"}
                    onChange={(e) => setInfoTurno({ ...infoTurno, codsghh: e.target.value })}
                    required={true}
                />
            </Grid>
            <Grid item md={6} xs={12} pt={1}>
                <MainInput
                    title={'Hora Inicio'}
                    value={infoTurno?.ingreso?.substring(0, 5)}
                    name={'ingreso'}
                    placeholder={"Ingrese hr. inicio"}
                    onChange={(e) => setInfoTurno({ ...infoTurno, ingreso: e.target.value })}
                    required={true}
                    type={'time'}
                />

                <MainInput styleTitle={{ marginTop: 1 }}
                    title={'Hora Salida'}
                    value={infoTurno?.salida?.substring(0, 5)}
                    name={'salida'}
                    placeholder={"Ingrese hr. salida"}
                    onChange={(e) => setInfoTurno({ ...infoTurno, salida: e.target.value })}
                    required={true}
                    type={'time'}
                />
            </Grid>
            {/* SEMANAS */}
            <Grid item md={6} xs={12} pt={1}>
                <Box
                    padding={'2px 0 0 5px'}
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'space-evenly'}
                    flexDirection={'row'}
                    flexWrap={'wrap'}
                >
                    {weekDaysCol?.map((week, idx) => {
                        return (
                            <Box key={'box-check-' + idx}
                                minWidth={'40%'}
                                sx={{ marginRight: '5px', padding: 1, }}
                            >
                                <div className="checkbox-custom">
                                    {week.map((day, idxDay) => {
                                        return (
                                            <ElementCheck
                                                key={'day' + idxDay}
                                                info={infoTurno}
                                                data={day}
                                                handleChangeCheck={handleChangeCheck}
                                            />
                                        )
                                    })}
                                </div>
                            </Box>
                        )
                    })}
                </Box>
            </Grid>
            <Grid item md={6} xs={12} pt={1}>
                <MainInput
                    title={'Inicio Nocturno'}
                    value={infoTurno?.ingresoNoc?.substring(0, 5)}
                    name={'ingresoNoc'}
                    placeholder={"Ingrese hr. inicio nocturna"}
                    onChange={(e) => setInfoTurno({ ...infoTurno, ingresoNoc: e.target.value })}
                    type={'time'}
                />
            </Grid>
            <Grid item md={6} xs={12} pt={1}>
                <MainInput
                    title={'Salida Nocturna'}
                    value={infoTurno?.salidaNoc?.substring(0, 5)}
                    name={'salidaNoc'}
                    placeholder={"Ingrese hr. salida nocturna"}
                    onChange={(e) => setInfoTurno({ ...infoTurno, salidaNoc: e.target.value })}
                    type={'time'}
                />
            </Grid>
            <Grid item md={6} xs={12} pt={1}>
                <MainInput
                    title={'Min. Refrigerio'}
                    value={infoTurno?.refrigerio}
                    name={'refrigerio'}
                    placeholder={"minutos de refrigerio"}
                    onChange={(e) => setInfoTurno({ ...infoTurno, refrigerio: e.target.value })}
                    required={true}
                    propsInput={{
                        step: 1,
                        min: 0,
                        max: 180,
                        type: 'number',
                    }}
                />
            </Grid>
            <Grid item md={6} xs={12} pt={1}>
                <MainSelect
                    title='Régimen'
                    placeholder='Seleccionar régimen'
                    options={regimes}
                    value={Number(infoTurno?.regimen_id)}
                    name={'servicio'}
                    onChange={(value, event) => setInfoTurno({ ...infoTurno, regimen_id: value })}
                />
            </Grid>
            <Grid item xs={10} pt={1}>
                <MainInput
                    title={'Nota'}
                    value={infoTurno?.nota}
                    name={'nota'}
                    placeholder={"Ingrese nota (Opcional....)"}
                    onChange={(e) => setInfoTurno({ ...infoTurno, nota: e.target.value })}
                />
            </Grid>
            <Grid item xs={2} pt={1}>
                <MainInput
                    title={'Horas Normales'}
                    value={infoTurno?.Hnormales}
                    name={'Hnormales'}
                    placeholder={"Ingrese Hnormales (Opcional....)"}
                    onChange={(e) => setInfoTurno({ ...infoTurno, Hnormales: e.target.value })}
                    propsInput={{
                        min: 0,
                        step: 0.01,
                        type: 'number',
                    }}
                />
            </Grid>
        </Grid>
    );
};

const ElementCheck = (props) => {
    const { info, data, handleChangeCheck } = props;
    return (
        <>
            <input type="checkbox" id={`checkbox-rect` + data?.label} name="check"
                checked={Number(info[data?.id]) === 1 ? true : false}
                onChange={(e) => handleChangeCheck(e.target.checked, data)}
            />
            <label htmlFor={`checkbox-rect` + data?.label} >{data?.label}</label>
        </>
    )
}

export default InfoShift;