import React, { useState } from 'react';
import { ButtonGroup, IconButton, Box } from '@mui/material';
import { OrderedListOutlined, } from '@ant-design/icons/lib/icons';
import EditIcon from '@mui/icons-material/Edit';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import ShiftService from '../../../../../services/shift';
import { colors } from '../../../../../assets/colors';
import { CODES } from '../../../../../utils/responseCodes';
import { simpleAlerts } from '../../../../../utils/alerts';
import { sortDataListSimple } from '../../../../../utils/function';
import _ from 'lodash';
import Swal from 'sweetalert2';


const columns = [
  { id: 'id_turno', label: '#', width: 50, align: 'left', },
  { id: 'desTurno', label: 'Descripción', width: 220, align: 'left', },
  { id: 'ingreso', label: 'Ingreso', width: 120, align: 'center', },
  { id: 'salida', label: 'Salida', width: 120, align: 'center', },
  { id: 'ingresoNoc', label: 'Ing. Nocturno', width: 120, align: 'center', },
  { id: 'salidaNoc', label: 'Sal. Nocturno', width: 120, align: 'center', },
  { id: 'refrigerio', label: 'Refrigerio', width: 120, align: 'center', },
  { id: 'dayOff', label: 'Descanso', width: 120, align: 'center', },
  { id: 'regimen_id', label: 'Régimen', width: 120, align: 'center', },
  { id: 'estado', label: 'Estado', width: 120, align: 'center', },
  { id: 'actions', label: 'Acciones', width: 80, align: 'center', },
];

const BodyShifts = (props) => {
  const { handleOpen, id_usuarioLogin, weekDays, states, regimes, dataTurnos, setDataTurnos, setInfoTurno, setOpenModalLoader, setTitleSpinner } = props;

  const url = "'" + window.location.pathname + "'";

  // /** inicio ordenamiento de columnas */
  const [sortConfigTable, setSortConfigTable] = useState({ key: null, direction: "ascending", });

  const handleSortTable = (key) => {
    let direction = "ascending";
    if (sortConfigTable && sortConfigTable.key === key && sortConfigTable.direction === "ascending") {
      direction = "descending";
    }
    setSortConfigTable({ key, direction });
  };

  const sortedData = sortDataListSimple(dataTurnos, sortConfigTable);
  // /** fin ordenamiento de columnas */

  const editShift = async (idx, key, value) => {
    let updDataTurnos = _.cloneDeep(dataTurnos);
    updDataTurnos[idx][key] = value;

    updDataTurnos[idx].ingreso = (updDataTurnos[idx]?.ingreso?.substring(0, 5) || null);
    updDataTurnos[idx].salida = (updDataTurnos[idx]?.salida?.substring(0, 5) || null);
    updDataTurnos[idx].ingresoNoc = (updDataTurnos[idx]?.ingresoNoc?.substring(0, 5) || null);
    updDataTurnos[idx].salidaNoc = (updDataTurnos[idx]?.salidaNoc?.substring(0, 5) || null);
    updDataTurnos[idx].usuario_id = id_usuarioLogin;

    try {
      Swal.fire({
        title: (value === 2 ? '¿Seguro de desactivar el Turno?' : '¿Seguro de activar el Turno?'),
        text: (value === 2 ? 'El turno quedara inactivo' : 'Activar turno!'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: (value === 2 ? colors.secondary.hex : '#28A745'),
        cancelButtonColor: (value === 2 ? colors.primary.hex : colors.secondary.hex),
        confirmButtonText: (value === 2 ? 'Si, desactivar' : 'Si, sactivar')
      }).then((result) => {
        if (result.isConfirmed) {
          setOpenModalLoader(true);
          setTitleSpinner((value === 2 ? 'Desactivando ...' : 'Activando ...'));
          setTimeout(async () => {
            const result = await ShiftService.saveTurno(url, updDataTurnos[idx]);
            if (result.status === CODES.CREATE_201) {

              simpleAlerts(result.data.message, 'success');
              setOpenModalLoader(false);
              setTitleSpinner(null);
              return setDataTurnos(updDataTurnos);
            } else {
              simpleAlerts('Error al guardar el turno', 'error');
            }
          });
        }
      })
    } catch (err) {
      console.log(`Error en editTurno ${err}`);
    }

  }

  return (
    <Box className='page-body-main'>
      <Box className='page-body-table table-responsive-md'>
        <table className="table table-hover table-striped table-bordered">
          <thead className='sticky-top' style={{ backgroundColor: colors.quaternary.hex, color: '#ffff' }}>
            <tr>
              {columns.map((column) => (
                <th
                  scope="col"
                  key={column.id}
                  style={{
                    textAlign: column.align,
                    width: column.width,
                    maxWidth: column.width,
                    cursor: 'pointer',
                  }}
                  onClick={() => ((column.id === 'id_turno' || column.id === 'desTurno') && handleSortTable(column.id))}
                >
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div style={{ marginRight: 'auto' }}>{column.label}</div>
                    {(column.id === 'id_turno' || column.id === 'desTurno') && <div><OrderedListOutlined style={{ paddingBottom: 2 }} /></div>}
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="table-group-divider">
            {sortedData?.map((shift, indexRow) => {
              return (
                <tr key={"row" + indexRow}>
                  {columns.map((column, indexColumn) => {
                    const value = shift[column.id];
                    return (
                      <td key={'col' + indexColumn} align={column.align}>
                        {column.id === 'estado' ?
                          states?.find(e => Number(e.value) === Number(value))?.label
                          : column.id === 'actions' ?
                            <ButtonGroup
                              disableElevation
                              variant="contained"
                              aria-label="Disabled elevation buttons"
                            >
                              <IconButton aria-label="delete" size="small"
                                onClick={() => { setInfoTurno(shift); handleOpen() }} title='Editar'
                              >
                                <EditIcon fontSize='small' color={'primary'} />
                              </IconButton>
                              <IconButton aria-label="delete" size="small"
                                onClick={() => { editShift(indexRow, 'estado', (Number(shift?.estado) === 1 ? 2 : 1)); }} title={Number(shift?.estado) === 1 ? 'Desactivar' : 'Activar'}
                              >
                                {Number(shift?.estado) === 1 ?
                                  <ToggleOffIcon fontSize='small' color={'secondary'} /> :
                                  <VolunteerActivismIcon fontSize='small' color={'success'} />
                                }
                              </IconButton>
                            </ButtonGroup> :
                            column.id === 'dayOff' ?
                              (
                                (Number(shift.lunes) === 0 ? weekDays?.find(e => e.id === 'lunes')?.label + ', ' : '') +
                                (Number(shift.martes) === 0 ? weekDays?.find(e => e.id === 'martes')?.label + ', ' : '') +
                                (Number(shift.miercoles) === 0 ? weekDays?.find(e => e.id === 'miercoles')?.label + ', ' : '') +
                                (Number(shift.jueves) === 0 ? weekDays?.find(e => e.id === 'jueves')?.label + ', ' : '') +
                                (Number(shift.viernes) === 0 ? weekDays?.find(e => e.id === 'viernes')?.label + ', ' : '') +
                                (Number(shift.sabado) === 0 ? weekDays?.find(e => e.id === 'sabado')?.label + ', ' : '') +
                                (Number(shift.domingo) === 0 ? weekDays?.find(e => e.id === 'domingo')?.label : '')
                              ) :
                              column.id === 'ingreso' || column.id === 'salida' || column.id === 'ingresoNoc' || column.id === 'salidaNoc' ?
                                value?.substring(0, 5) :
                                column.id === 'regimen_id' ?
                                  regimes?.find(e => Number(e.id_regimen) === Number(value))?.desRegimen
                                  : value}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </Box>
    </Box >
  );
}

export default BodyShifts;