import React, { useState, useEffect } from 'react';
import MainModal from '../../../../components/General/MainModal';
import MainButton from '../../../../components/General/MainButton';
import InfoPlanning from '../../../../components/Manage/Planning/AddPlanning/InfoPlanning';
import Planning from '../../../../components/Manage/Planning/AddPlanning/Planning';
import Incidence from '../../../../components/Manage/Planning/AddPlanning/Incidence';
import PlanningByExcel from '../../../../components/Manage/Planning/AddPlanning/PlanningByExcel';
import PlanningService from '../../../../../services/planning';
import StaffService from '../../../../../services/staff';
import { CODES } from '../../../../../utils/responseCodes';
import { simpleAlerts } from '../../../../../utils/alerts';
import { formatDate, getDateOL, minutesDiff, nombreDia, nombreMes } from '../../../../../utils/function';
import Swal from 'sweetalert2';
import _ from 'lodash';

const AddPlanning = (props) => {
    const { open, handleClose, id_usuarioLogin, id_personaLogin, setOpenModalLoader, setTitleSpinner,
        typeModal, setTypeModal, buscarPlanificacion, infoPlan, setInfoPlan, planningType, shift,
        fechaDesde, setFechaDesde, fechaHasta, setFechaHasta, infoCampus, infoArea,
        primerDiaG, ultimoDiaG
    } = props;

    const url = "'" + window.location.pathname + "'";
    const [shiftPlan, setShiftPlan] = useState(null);
    const [workerPlan, setWorkerPlan] = useState([]);
    const [workerPlanDesc, setWorkerPlanDesc] = useState([]);
    const [dataWorkers, setDataWorkers] = useState(null);
    const [openSmall, setOpenSmall] = useState(true);
    const [fechaIncidencia, setFechaIncidencia] = useState(fechaDesde);
    const [valuePlanType, setValuePlanType] = useState(null);
    const [semana, setsemana] = useState([]);
    const [diaDescanso, setDiaDescanso] = useState(null);

    const [fechaIniExcel, setFechaIniExcel] = useState(getDateOL());
    const [fechaFinExcel, setFechaFinExcel] = useState(getDateOL());
    const [templatePlanning, setTemplatePlanning] = useState({ file: null, nameFile: null });

    useEffect(() => {
        if (infoArea?.value)
            getPersonByArea();
    }, [infoArea])

    useEffect(() => {
        let semana = [];
        for (let fecha = new Date(`${fechaDesde}T00:00:00`); fecha <= new Date(`${fechaHasta}T00:00:00`); fecha.setDate(fecha.getDate() + 1)) {
            let fechaString = formatDate(fecha, 'date');
            const diaSemana = nombreDia(fechaString, 's');
            const mes = nombreMes(fechaString, 's');
            const dia = fecha.getDate();
            semana.push({ id: fechaString, value: fechaString, label: `${diaSemana} ${dia} (${mes})` });
        }
        setsemana(semana);
    }, [fechaDesde, fechaHasta])

    const getPersonByArea = async () => {
        try {
            setOpenSmall(true);
            const result = await StaffService.getPersonByArea(url, {}, infoArea?.value);
            if (result.status === CODES.SUCCESS_200) {
                setDataWorkers(result.data);
            }
            return []
        } catch (error) {
            console.log(`Error en getPersonByArea: ${error}`);
        } finally {
            setOpenSmall(false);
        }
    }

    const savePlanning = async (e) => {
        e.preventDefault();

        if (Number(infoPlan?.turno_id) === 0) {
            simpleAlerts('Debe seleccionar turno!');
            return;
        }
        if (Number(infoPlan?.tipPla_id) === 0) {
            simpleAlerts('Debe seleccionar un tipo de planificación!');
            return;
        }

        try {
            setOpenModalLoader(true);
            setTitleSpinner('Guardando planificación');

            const result = await PlanningService.savePlanning(url, infoPlan);
            if (result.status === CODES.CREATE_201) {
                cleanInfo();
                buscarPlanificacion(0);
                simpleAlerts(result.data.message, "success");
            } else {
                simpleAlerts(result.response.data.message, "error");
                setOpenModalLoader(false);
                setTitleSpinner(null);
            }
            return [];
        } catch (error) {
            console.log(`Error en savePlanning: ${error}`);
        }
    }

    const savePlanningMassive = async (e) => {
        e.preventDefault();
        let dataPlan = _.cloneDeep(workerPlan);
        let dataPlanDesc = _.cloneDeep(workerPlanDesc);
        if (dataPlan?.length === 0) {
            simpleAlerts('Debe seleccionar un trabajador!');
            return;
        }
        dataPlanDesc?.forEach(item => {
            let idx = dataPlan.findIndex(e => +e.persona_id === +item.persona_id);
            if (idx === -1) {
                item.onlyDesc = 1;
                dataPlan.push(item);
            } else {
                dataPlan[idx].marcaDesc = 1;
            }
        });

        let minutes = minutesDiff(fechaHasta, fechaDesde);
        let hours = Math.round(minutes / 60);
        let days = Math.round(hours / 24);
        if (days > 7) {
            simpleAlerts('El maximo de dias es 7, para una planificación masiva!');
            return;
        }
        if (+shiftPlan === 0 || !shiftPlan) {
            simpleAlerts('Se debe seleccionar turno para la planificación!');
            return;
        }
        if ((+diaDescanso === 0 || !diaDescanso) && workerPlanDesc?.length > 0) {
            simpleAlerts('Se debe seleccionar un día de descanso!');
            return;
        }

        setOpenModalLoader(true);
        setTitleSpinner('Guardando planificación!');
        try {
            let body = {
                "tipPla_id": 1,
                "area_id": infoArea?.value,
                "turno_id": shiftPlan,
                "fechaDesde": fechaDesde,
                "fechaHasta": fechaHasta,
                "fechaDesc": diaDescanso,
                "supervisor_id": id_personaLogin,
                "usuario_id": id_usuarioLogin,
                "people": dataPlan,
            };
            const result = await PlanningService.savePlanningMassive(url, body);
            if (result.status === CODES.CREATE_201) {
                simpleAlerts(result?.data?.message || 'Guardado Exitoso', 'succes');
            } else {
                simpleAlerts(result?.response?.data?.message?.error || 'Error en planificar', 'error');
            }
            cleanInfo();
            buscarPlanificacion(0);
        } catch (error) {
            console.log(`Error en saveAssociateAreas: ${error}`);
        }
    }

    const savePlanningIncidence = async (e) => {
        e.preventDefault();

        if (fechaIncidencia < fechaDesde || fechaIncidencia > fechaHasta) {
            simpleAlerts('La fecha para la incidencia esta fuera de los 7 dias permitidos!', 'warning');
            return;
        }
        if (Number(shiftPlan) === 0 || !shiftPlan) {
            simpleAlerts('Se debe seleccionar turno para la planificación!', 'warning');
            return;
        }
        if (Number(valuePlanType) === 0 || !valuePlanType) {
            simpleAlerts('Se debe seleccionar un tipo de incidencia!', 'warning');
            return;
        }

        try {
            setOpenModalLoader(true);
            setTitleSpinner('Guardando incidencia!');
            const savePromises = workerPlan.map(async (worker) => {
                worker['fecha'] = fechaIncidencia;
                worker['turno_id'] = Number(shiftPlan);
                worker['area_id'] = Number(infoArea?.value);
                worker['tipPla_id'] = Number(valuePlanType);
                try {
                    const result = await PlanningService.savePlanning(url, worker);
                    if (result.status === CODES.CREATE_201) {
                        simpleAlerts(result?.data?.message, 'success');
                    }
                    await Promise.all([result]);
                } catch (error) {
                    console.log(`Error en savePlanning: ${error}`);
                }
            });

            await Promise.all([...savePromises]);
            setOpenModalLoader(false);
            setTitleSpinner(null);
            cleanInfo();
            buscarPlanificacion(0);
            simpleAlerts("Fin de planificación", "success");
        } catch (error) {
            console.log(`Error en saveAssociateAreas: ${error}`);
        }
    }

    const sendTemplatePlanning = async (e) => {
        e.preventDefault();
        if(!templatePlanning.file || templatePlanning.nameFile === ''){
            simpleAlerts('Primero debe cargar un archivo de planificación!', "warning");
            return;
        }

        const minutesMes = minutesDiff(getDateOL(), fechaIniExcel);
        const hoursMes = Math.round(minutesMes / 60);
        const daysMes = Math.round(hoursMes / 24);
        if (daysMes > 30) {
            simpleAlerts('El máximo de días futuros son 30 días!', "warning");
            return;
        }

        if (infoCampus?.value === 0 || !infoCampus) {
            simpleAlerts('Debe selecciónar un sede!', 'warning');
            return;
        }

        const fechaIniObj = new Date(`${fechaIniExcel}T00:00:00`);
        const fechaFinObj = new Date(`${fechaFinExcel}T00:00:00`);
        const hoyObj = new Date(`${getDateOL()}T00:00:00`);
        if (fechaIniObj < hoyObj) {
            simpleAlerts('La fecha inicial no puede ser menor a la del dia actual!');
            return;
        }
        if (fechaFinObj < hoyObj) {
            simpleAlerts('La fecha final no puede ser menor a la del dia actual!');
            return;
        }
        const minutes = minutesDiff(fechaIniExcel, fechaFinExcel);
        const hours = Math.round(minutes / 60);
        const days = Math.round(hours / 24);
        if (days > 7) {
            simpleAlerts('El maximo de dias es 7, para una planificación masiva!');
            return;
        }
        const mark = getDateOL(null, 'large').replace(/[-\s:]/g, '');//yyyymmddhhmmss
        const array = templatePlanning.nameFile.split('.');
        const ext = array[array?.length - 1];
        const nameFile = `${infoCampus.desSede}_${fechaIniExcel.replace(/[-\s:]/g, '')}_${fechaFinExcel.replace(/[-\s:]/g, '')}_${mark}.${ext}`;
        if (ext !== 'xlsx') {
            simpleAlerts('Formato de archivo no permitido!', "warning");
            return;
        }

        const dataTemplate = new FormData();
        const modTemplateFile = new File([templatePlanning.file], nameFile, { type: templatePlanning.file.type });
        dataTemplate.append("templatePlanning", modTemplateFile);
        dataTemplate.append("fechaIni", fechaIniExcel);
        dataTemplate.append("fechaFin", fechaFinExcel);
        dataTemplate.append("id_sede", infoCampus.id_sede);
        dataTemplate.append("desSede", infoCampus.desSede);
        dataTemplate.append("nameFile", nameFile);
        dataTemplate.append("validation", "V");
        dataTemplate.append("id_usuario", id_usuarioLogin);
        dataTemplate.append("id_supervisor", id_personaLogin);
        // for (const [clave, valor] of dataTemplate.entries()) {
        //     console.log(clave + ": " + valor);
        // }

        const resultValidation = await savePlaningByExcel(dataTemplate, "Validando la información ...");
        if (resultValidation.data.status === 'E') {
            const datosFaltantes = resultValidation.data.datosFaltantes;
            const datosNoPermitidos = resultValidation.data.datosNoPermitidos;

            const columnsInfo = [{ id: "numdoc", label: "Documento" }, { id: "trabajador", label: "Trabajador" }]
            let htmlSubtitle = ''
            if (datosFaltantes) {
                htmlSubtitle += `
                    <caption>Trabajadores sin planificación</caption>
                    <table class="table table-striped table-sm table-bordered" style="font-size:small;">
                        <thead>
                            <tr>
                                ${columnsInfo?.map((col) => {
                    return (`<th scope="col">${col.label}</th>`)
                })?.join("")}
                            </tr>
                        </thead>
                        <tbody>
                            ${datosFaltantes?.map((row) => {
                    return (
                        `<tr>
                                    ${columnsInfo?.map((col) => {
                            const value = row[col.id];
                            return (`<td>${value}</td>`)
                        })?.join("")}
                                </tr>`)
                })?.join("")}
                        </tbody>
                    </table>`;
            }
            if (datosNoPermitidos) {
                htmlSubtitle += `
                    <caption>Trabajadores no pemitidos</caption>
                    <table class="table table-striped table-sm table-bordered" style="font-size:small;">
                        <thead>
                            <tr>
                                ${columnsInfo?.map((col) => {
                    return (`<th scope="col">${col.label}</th>`)
                })?.join("")}
                            </tr>
                        </thead>
                        <tbody>
                            ${datosNoPermitidos?.map((row) => {
                    return (`<tr>
                                            ${columnsInfo?.map((col, idxCol) => {
                        const value = row[col.id];
                        return (`<td>${value}</td>`)
                    })?.join("")}
                                        </tr>`)
                })?.join("")}
                        </tbody>
                    </table>`;
            }

            const resultSwal = await Swal.fire({
                title: `No se pudo planificar por que la plantilla presenta estas inconcistencias`,
                html: htmlSubtitle,
                showConfirmButton: false,
                showCancelButton: true,
                cancelButtonText: "Cancelar",
            });

        } else if (resultValidation.data.status === 'W') {
            const datosFaltantes = resultValidation.data.datosFaltantes;

            const columnsInfo = [{ id: "numdoc", label: "Documento" }, { id: "trabajador", label: "Trabajador" }]
            let htmlSubtitle = 'Seguro de continuar con la planificación <br/><br/>';
            if (datosFaltantes) {
                htmlSubtitle += `
                    <caption>Trabajadores sin planificación</caption>
                    <table class="table table-striped table-sm table-bordered" style="font-size:small;">
                        <thead>
                            <tr>
                                ${columnsInfo?.map((col) => {
                    return (`<th scope="col">${col.label}</th>`)
                })?.join("")}
                            </tr>
                        </thead>
                        <tbody>
                            ${datosFaltantes?.map((row) => {
                    return (
                        `<tr>
                                    ${columnsInfo?.map((col) => {
                            const value = row[col.id];
                            return (`<td>${value}</td>`)
                        })?.join("")}
                                </tr>`)
                })?.join("")}
                        </tbody>
                    </table>`;
            }

            const resultSwal = await Swal.fire({
                title: 'Trabajadores sin información',
                html: htmlSubtitle,
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: "Si",
                cancelButtonText: "Cancelar",
            });

            if (resultSwal?.isConfirmed) {
                dataTemplate.set("validation", "S");
                // for (const [clave, valor] of dataTemplate.entries()) {
                //     console.log(clave + ": " + valor);
                // }
                const resultSave = await savePlaningByExcel(dataTemplate, "Guardando planifiación");
                if (resultSave.status === CODES.SUCCESS_200) {
                    const resultFile = resultSave.data.resultFile;
                    const responses = resultSave.data.responses;
                    const inconsistencias = resultSave.data.inconsistencias;
                    const errors = resultSave.data.errors;
                    const columnsInfo = [{ id: "numdoc", label: "Documento" }, { id: "trabajador", label: "Trabajador" }, { id: "area", label: "Área" }, { id: "turno", label: "Turno" }]

                    let htmlSubtitle = `<span>Se hicieron ${responses.length} planificacion(es)</span><br/><br/>`;
                    if (inconsistencias.length) {
                        htmlSubtitle += `
                            <caption>Trabajadores con inconsistencia de turno</caption>
                            <table class="table table-striped table-sm table-bordered" style="font-size:small;">
                                <thead>
                                    <tr>
                                        ${columnsInfo?.map((col) => {
                            return (`<th scope="col">${col.label}</th>`)
                        })?.join("")}
                                    </tr>
                                </thead>
                                <tbody>
                                    ${inconsistencias?.map((row) => {
                            return (
                                `<tr>
                                            ${columnsInfo?.map((col) => {
                                    const value = row[col.id];
                                    return (`<td>${value}</td>`)
                                })?.join("")}
                                        </tr>`)
                        })?.join("")}
                                </tbody>
                            </table>`;
                    }
                    if (errors.length) {
                        htmlSubtitle += `
                            <caption>Trabajadores no planificados por errores</caption>
                            <table class="table table-striped table-sm table-bordered" style="font-size:small;">
                                <thead>
                                    <tr>
                                        ${columnsInfo?.map((col) => {
                            return (`<th scope="col">${col.label}</th>`)
                        })?.join("")}
                                    </tr>
                                </thead>
                                <tbody>
                                    ${errors?.map((row) => {
                            return (
                                `<tr>
                                            ${columnsInfo?.map((col) => {
                                    const value = row[col.id];
                                    return (`<td>${value}</td>`)
                                })?.join("")}
                                        </tr>`)
                        })?.join("")}
                                </tbody>
                            </table>`;
                    }

                    htmlSubtitle += `<br/><span>${resultFile}</span>`;

                    Swal.fire({
                        icon: "success",
                        title: "Guardado exitoso!",
                        html: htmlSubtitle,
                    });

                    simpleAlerts('Planificación realizada', 'success');
                    cleanInfo();
                }
            }
        } else {
            simpleAlerts(resultValidation?.data?.message, 'success');
            if (resultValidation.status === CODES.SUCCESS_200) {
                const resultFile = resultValidation.data.resultFile;
                const responses = resultValidation.data.responses;
                const inconsistencias = resultValidation.data.inconsistencias;
                const errors = resultValidation.data.errors;
                const columnsInfo = [{ id: "numdoc", label: "Documento" }, { id: "trabajador", label: "Trabajador" }, { id: "area", label: "Área" }, { id: "turno", label: "Turno" }]

                let htmlSubtitle = `<span>Se hicieron ${responses.length} planificacion(es)</span><br/><br/>`;
                if (inconsistencias.length) {
                    htmlSubtitle += `
                        <caption>Trabajadores con inconsistencia de turno</caption>
                        <table class="table table-striped table-sm table-bordered" style="font-size:small;">
                            <thead>
                                <tr>
                                    ${columnsInfo?.map((col) => {
                        return (`<th scope="col">${col.label}</th>`)
                    })?.join("")}
                                </tr>
                            </thead>
                            <tbody>
                                ${inconsistencias?.map((row) => {
                        return (
                            `<tr>
                                        ${columnsInfo?.map((col) => {
                                const value = row[col.id];
                                return (`<td>${value}</td>`)
                            })?.join("")}
                                    </tr>`)
                    })?.join("")}
                            </tbody>
                        </table>`;
                }
                if (errors.length) {
                    htmlSubtitle += `
                        <caption>Trabajadores no planificados por errores</caption>
                        <table class="table table-striped table-sm table-bordered" style="font-size:small;">
                            <thead>
                                <tr>
                                    ${columnsInfo?.map((col) => {
                        return (`<th scope="col">${col.label}</th>`)
                    })?.join("")}
                                </tr>
                            </thead>
                            <tbody>
                                ${errors?.map((row) => {
                        return (
                            `<tr>
                                        ${columnsInfo?.map((col) => {
                                const value = row[col.id];
                                return (`<td>${value}</td>`)
                            })?.join("")}
                                    </tr>`)
                    })?.join("")}
                            </tbody>
                        </table>`;
                }

                htmlSubtitle += `<br/><span>${resultFile}</span>`;

                Swal.fire({
                    icon: "success",
                    title: "Guardado exitoso!",
                    html: htmlSubtitle,
                });

                simpleAlerts('Planificación realizada', 'success');
                cleanInfo();
            }
        }
    }

    const savePlaningByExcel = async (body, spinner = 'Guardando ...') => {
        try {
            setOpenModalLoader(true);
            setTitleSpinner(spinner);

            const result = await PlanningService.savePlaningByExcel(url, body);
            return result;
        } catch (error) {
            console.log(`Error en saveAssociateAreas: ${error}`);
        } finally {
            setOpenModalLoader(false);
            setTitleSpinner(null);
        }
    }

    const cleanInfo = () => {
        setInfoPlan(null);
        handleClose();
        setTypeModal(0);
        setWorkerPlan([]);
        setWorkerPlanDesc([]);
        setDiaDescanso(null);
        setFechaIniExcel(getDateOL());
        setFechaFinExcel(getDateOL());
        setTemplatePlanning({ file: null, nameFile: null });
    }

    return (
        <MainModal
            open={open}
            size={(typeModal === 0 || typeModal === 3) ? 'sm' : 'md'}
            handleClose={cleanInfo}
            formSubmit={
                typeModal === 0 ?
                    savePlanning
                    : typeModal === 1 ?
                        savePlanningMassive
                        : typeModal === 2 ?
                            savePlanningIncidence
                            : typeModal === 3 &&
                            sendTemplatePlanning
            }
            title={'Mantenimiento de planificación'}
            subTitle={'Edición de planificación'}
            bodyModal=
            {typeModal === 0 ? // para la planificación personal
                <InfoPlanning
                    infoPlan={infoPlan}
                    setInfoPlan={setInfoPlan}
                    planningType={planningType}
                    shift={shift}
                />
                : typeModal === 1 ?
                    <Planning // para planificación masiva
                        openSmall={openSmall}
                        shift={shift}
                        id_usuarioLogin={id_usuarioLogin}
                        id_personaLogin={id_personaLogin}
                        dataWorkers={dataWorkers}

                        fechaDesde={fechaDesde}
                        setFechaDesde={setFechaDesde}
                        fechaHasta={fechaHasta}
                        setFechaHasta={setFechaHasta}

                        shiftPlan={shiftPlan}
                        setShiftPlan={setShiftPlan}
                        workerPlan={workerPlan}
                        setWorkerPlan={setWorkerPlan}
                        workerPlanDesc={workerPlanDesc}
                        setWorkerPlanDesc={setWorkerPlanDesc}
                        infoArea={infoArea}

                        semana={semana}
                        diaDescanso={diaDescanso}
                        setDiaDescanso={setDiaDescanso}
                        primerDiaG={primerDiaG}
                        ultimoDiaG={ultimoDiaG}
                    />
                    : typeModal === 2 ?
                        <Incidence // para incidencias masiva
                            openSmall={openSmall}
                            shift={shift}
                            id_usuarioLogin={id_usuarioLogin}
                            id_personaLogin={id_personaLogin}
                            planningType={planningType}
                            dataWorkers={dataWorkers}

                            fechaDesde={fechaDesde}
                            fechaHasta={fechaHasta}
                            fechaIncidencia={fechaIncidencia}
                            setFechaIncidencia={setFechaIncidencia}
                            valuePlanType={valuePlanType}
                            setValuePlanType={setValuePlanType}

                            shiftPlan={shiftPlan}
                            setShiftPlan={setShiftPlan}
                            workerPlan={workerPlan}
                            setWorkerPlan={setWorkerPlan}
                            infoArea={infoArea}
                        />
                        : typeModal === 3 &&
                        <PlanningByExcel // Planificar por excel
                            fechaIniExcel={fechaIniExcel}
                            setFechaIniExcel={setFechaIniExcel}
                            fechaFinExcel={fechaFinExcel}
                            setFechaFinExcel={setFechaFinExcel}
                            templatePlanning={templatePlanning}
                            setTemplatePlanning={setTemplatePlanning}
                        />
            }
            buttonActions={<MainButton
                title={'Guardar'}
                typeStyle={'outlined'}
                color={'primary'}
                type={'submit'}
            />}
        />
    );
};

export default AddPlanning;