import React, { } from 'react';
import { Box } from '@mui/material';
import { colors } from '../../../../assets/colors';

import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
const columns = [
  { id: 'desEmpresa', label: 'Empresa', width: 120, align: 'left', },
  { id: 'desUnidad', label: 'Unidad', width: 120, align: 'left', },
  { id: 'desSede', label: 'Sede', width: 90, align: 'left', },
  { id: 'desArea', label: 'Area', width: 90, align: 'left', },
  { id: 'desEstado', label: 'Estado', width: 90, align: 'center', },
  { id: 'fechaCese', label: 'Fecha Cese', width: 80, align: 'center', },
  { id: 'obsCese', label: 'Obs. Cese', width: 80, align: 'center', },
];

const BodyDetailStaffs = (props) => {
  const { states, dataStaffs } = props;

  return (
    <Box className='page-body-main'>
      <Box className='page-body-table page-body-table__modal table-responsive-md'>
        <table className="table table-hover table-striped table-bordered table-sm">
          <thead className='sticky-top' style={{ backgroundColor: colors.quaternary.hex, color: '#ffff' }}>
            <tr>
              {columns.map((column) => (
                <th
                  scope="col"
                  key={column.id}
                  style={{
                    textAlign: column.align,
                    width: column.width,
                    maxWidth: column.width
                  }}
                >
                  {column.label}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="table-group-divider">
            {dataStaffs?.map((staff, indexRow) => {
              return (
                <tr key={"row" + indexRow}>
                  {columns.map((column, indexColumn) => {
                    const value = staff[column.id];
                    return (
                      <td key={'col' + indexColumn} align={column.align} style={{ fontSize: "11px" }}>
                        {column.id === 'codSituacion' ?
                          // console.log(
                          //   states,
                          //   value,
                          //   states?.find(e => Number(e.value) === Number(value)),
                          //   staff,
                          //   )
                          states?.find(e => Number(e.value) === Number(value))
                          : value
                        }
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </Box>


    </Box>
  );
}

export default BodyDetailStaffs;