import React, { useState, } from 'react';
import './style.scss';
import SpinnerLoader from '../../components/SpinnerLoad/SpinnerLoader';
import AuthService from '../../../services/auth';
import MailsService from '../../../services/mails';
import { CODES } from '../../../utils/responseCodes';
import { simpleAlerts } from '../../../utils/alerts';
import logoLP from '../../../assets/img/LP.png'
import { clearBrowserCache } from '../../../utils/function';

const Login = ({ setUserToken }) => {
    const [usuario, setUsuario] = useState(null);
    const [openModalLoader, setOpenModalLoader] = useState(false);
    const [titleSpinner, setTitleSpinner] = useState(null);

    const [isLoginVisible, setLoginVisible] = useState(true);
    const [isSignupVisible, setSignupVisible] = useState(false);

    const handleVisualLogin = () => {
        setLoginVisible(false);
        setSignupVisible(true);
    };

    const handleVisualSignup = () => {
        setLoginVisible(true);
        setSignupVisible(false);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        // const { usuario, clave } = event.target;
        try {
            let data = new FormData(event.currentTarget);
            data.append("sistema", 'asisprod');

            setOpenModalLoader(true);
            setTitleSpinner('Iniciando ...');

            const result = await AuthService.signIn(data)
            if (result?.status === CODES.SUCCESS_200) {
                localStorage.setItem('infoAssisProd', JSON.stringify(result?.data));
                setUserToken(result?.data);
                clearBrowserCache()
            } else if (result === undefined) {
                simpleAlerts('¡Error: El servidor no esta disponible, comuniquese con TI!', 'error');
            } else {
                simpleAlerts(result.data.message, 'error', 'colored');
            }
            return [];
        } catch (error) {
            console.log(`Error en getEstados: ${error}`);
        } finally {
            setOpenModalLoader(false);
            setTitleSpinner(null);
        }
    };

    const handleRecuperarContraseña = async () => {
        if (usuario === '' || !usuario || !isNaN(usuario)) {
            simpleAlerts('Debe ingresar un usuario valido', 'warning');
            return;
        }

        setOpenModalLoader(true);
        setTitleSpinner('Solicitando recuperación...');
        try {
            const result = await MailsService.recoveryPassword(usuario);
            if (result.status === CODES.SUCCESS_200) {
                simpleAlerts(result.data.message, 'success');
            } else {
                simpleAlerts(result?.response?.data?.message || 'Error al solicitar', 'error', 'colored');
            }
            return [];
        } catch (error) {
            console.log(`Error en getUsuarioAreas: ${error}`);
        } finally {
            setOpenModalLoader(false);
            setTitleSpinner(null);
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className="login-body">
                <div className="login-form-structor">
                    <div className={`signup${isSignupVisible ? ' slide-up' : ''}`}>
                        <div className='form-title' onClick={handleVisualSignup}>
                            <img height={50} src={logoLP} />
                        </div>
                        <div className="form-holder" style={{ backgroundColor: 'transparent' }}>
                            <span className='span h2'>Bienvenido<br />A</span>
                            <span className='span h1'>ASISPROD</span>
                            <span className='span h3'>El sistema de gestion de asistencia y productividad de Lavoro Peru.</span>
                        </div>
                    </div>
                    <div className={`login${isLoginVisible ? ' slide-up' : ''}`}>
                        <div className="center">
                            <h2 className="form-title" onClick={handleVisualLogin}>Log in</h2>
                            <div className="form-holder">
                                <input type="text" name="usuario" className="input" placeholder="Usuario"
                                    value={usuario || ''} onChange={(event) => setUsuario(event.target.value)}
                                    required />
                                <input type="password" name="clave" className="input" placeholder="Password" required />
                            </div>
                            <button type='submit' className="submit-btn">Log in</button>
                            <span style={{ padding: '8px 15px', fontSize: '12px', opacity: '0.8', color: 'white' }}>
                                Recuperar contraseña{" - "}
                                <button
                                    type="button"
                                    onClick={handleRecuperarContraseña}
                                    style={{
                                        backgroundColor: "transparent",
                                        border: "none",
                                        textDecoration: "underline",
                                        color: 'white'
                                    }}
                                >
                                    Solicitar
                                </button>
                            </span>
                        </div>
                    </div>
                </div>
            </div >
            <SpinnerLoader open={openModalLoader} title={titleSpinner} />
        </form >
    );
};

export default Login;