import React, { useState, useEffect } from 'react';
import { Box, } from '@mui/material';

import { OrderedListOutlined, } from '@ant-design/icons/lib/icons';

import { colors } from '../../../../assets/colors';
import { sortDataListSimple, filterData } from '../../../../utils/function';
import _ from 'lodash';

const columns = [
  { id: 'selA', label: 'Sel.', width: 10, align: 'center', },
  { id: 'trabajador', label: 'Trabajador', width: 200, align: 'left', },
  { id: 'tt', label: 'Sit.', width: 100, align: 'left', },
  { id: 'yy', label: 'Lid.', width: 100, align: 'left', },
];

const BodyAssignedWorker = (props) => {
  const { DataWorkersAssigned, dataWorkersAssignedArea, setDataWorkersAssignedArea, searchA } = props;
  
  let resultSearch = filterData(DataWorkersAssigned, searchA, ['codSghh', 'trabajador']);
  /** inicio ordenamiento de columnas */
  const [sortConfigTable, setSortConfigTable] = useState({ key: null, direction: "ascending", });

  const handleSortTable = (key) => {
    let direction = "ascending";
    if (sortConfigTable && sortConfigTable.key === key && sortConfigTable.direction === "ascending") {
      direction = "descending";
    }
    setSortConfigTable({ key, direction });
  };
  const sortedDataWorkersAssigned = sortDataListSimple(resultSearch, sortConfigTable);

  const handleChangeCheckA = (valueA, p_workerA) => {
    let updAssignedWorkerCopy = _.cloneDeep(dataWorkersAssignedArea);
    if (valueA === false) {
      let index = updAssignedWorkerCopy?.indexOf(p_workerA.id_persona);
      if (index !== -1) {
        updAssignedWorkerCopy.splice(index, 1);
      }
    } else {
      let index = updAssignedWorkerCopy?.indexOf(p_workerA.id_persona);
      if (index === -1) {
        updAssignedWorkerCopy.push(p_workerA.id_persona);
      }
    }

    return setDataWorkersAssignedArea(updAssignedWorkerCopy);
  }


  const handleChangeCheckGeneralA = (e) => {

    let updAssignedWorkerCopy = [];//contenedor

    if (e.target.checked === false) {
      updAssignedWorkerCopy = [];
    } else {
      DataWorkersAssigned?.forEach((element) => {
        updAssignedWorkerCopy.push(element.id_persona);

      });
    }

    return setDataWorkersAssignedArea(updAssignedWorkerCopy);
  }

  return (
    <>


      <Box className='page-body-main'>
        <Box className='page-body-table table-responsive-md' sx={{ height: "20rem", maxHeight: "30rem" }}>
          <table className="table table-hover table-bordered">
            <thead className='sticky-top' style={{ backgroundColor: colors.quaternary.hex, color: '#ffff' }}>
              <tr>
                {columns.map((column) => {
                  return (
                    <th
                      scope="col"
                      key={column.id + "BodyTotalWorker"}
                      style={{
                        textAlign: column.align,
                        width: column.width,
                        cursor: 'pointer',
                      }}
                      onClick={() => handleSortTable(column.id)}
                    >
                      {column.id === 'selA' ?
                        <input type="checkbox"
                          name="check"
                          id={`checkbox-rect-header`}
                          className={'checkbox-rect-header'}
                          onChange={handleChangeCheckGeneralA}
                        />
                        : <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                          <div style={{ marginRight: 'auto' }}>{column.label}</div>
                          <div><OrderedListOutlined style={{ paddingBottom: "" }} /></div>
                        </div>}
                    </th>)
                })}
              </tr>
            </thead>
            <tbody className="table-group-divider">
              {sortedDataWorkersAssigned?.map((workerassigned, indexRow) => {
                return (
                  <tr key={"row" + indexRow}>
                    {columns.map((column, indexColumn) => {
                      const value = workerassigned[column.id];
                      return (
                        <td key={'col' + indexColumn} align={column.align}>
                          {
                            column.id === 'selA' ?
                              <div className="checkbox-custom"
                                style={{ display: 'flex', justifyContent: 'center' }}
                              >
                                <input type="checkbox"
                                  name="check"
                                  id={`checkbox-rect` + workerassigned.id_persona + "Assigned"}
                                  checked={dataWorkersAssignedArea?.find(e => Number(e) === Number(workerassigned.id_persona)) ? true : false}
                                  onChange={(e) => handleChangeCheckA(e.target.checked, workerassigned)}
                                />
                                <label htmlFor={`checkbox-rect` + workerassigned.id_persona + "Assigned"}></label>
                              </div> :
                              value}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Box>
      </Box >
    </>
  );
};

export default BodyAssignedWorker;