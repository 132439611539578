import React, { useEffect, useState } from 'react';
import { Box, Grid, } from '@mui/material';
import AddAreaOrg from '../Organigrama/AddAreaOrg/AddAreaOrg';
import BodyAreaOrganigrama from '../../../components/Maintenance/Organigrama/BodyAreaOrganigrama/BodyAreaOrganigrama';
import MainInput from '../../../components/General/MainInput';
import MainButton from '../../../components/General/MainButton';
import AreaOrgService from '../../../../services/areaOrg';
import { CODES } from '../../../../utils/responseCodes';
import SpinnerLoader from '../../../components/SpinnerLoad/SpinnerLoader';

const newArea = {
    'Area': '',
    'Jefe': '',
    'usuarioMod': ''
}

const AreaOrganigrama = ({ userToken }) => {
    const id_usuarioLogin = userToken.user.id_usuario;
    const [openModalLoader, setOpenModalLoader] = useState(true);
    const [titleSpinner, setTitleSpinner] = useState(null);
    const [open, setOpen] = useState(false)
    const [search, setSearch] = useState('');
    const [dataAreaOrg, setDataAreaOrg] = useState(null);
    const [infoAreaOrg, setInfoAreaOrg] = useState(null);
    const [tipoModal, setTipoModal] = useState(1);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    useEffect(() => {
        getAreaOrganigrama();
    }, [])

    const getAreaOrganigrama = async () => {
        try {
            const result = await AreaOrgService.getAreaOrganigrama();
            if (result.status === CODES.SUCCESS_200) {
                setDataAreaOrg(result.data);
                setOpenModalLoader(false);
            } else {
                console.log('No hay areas organigrama');
            }
        } catch (error) {
            console.log(`Error en getAreasBySede: ${error}`);
        }
    }

    const saveAgregarAreaOrg = () => {
        setInfoAreaOrg(newArea)
        setTipoModal(1);
        handleOpen();
    };

    const saveAsignacionAreaOrg = () => {
        setTipoModal(2);
        handleOpen();
    };

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AddAreaOrg
                open={open}
                handleClose={handleClose}
                setOpenModalLoader={setOpenModalLoader}
                id_usuarioLogin={id_usuarioLogin}
                infoAreaOrg={infoAreaOrg}
                setInfoAreaOrg={setInfoAreaOrg}
                getAreaOrganigrama={getAreaOrganigrama}
                tipoModal={tipoModal}
                setTitleSpinner={setTitleSpinner}

            />

            <Grid container direction="row" justifyContent="center" alignItems="center">
                <Grid item xs={12} sm={10} md={8} m={2} style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                    <Box width={'50%'}>
                        <MainInput
                            placeholder={'Buscar por Area o codigo de Area'}
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}

                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={10} md={8} paddingLeft={'0.2rem'} paddingRight={'0.2rem'}>
                    <BodyAreaOrganigrama
                        setTipoModal={setTipoModal}
                        handleOpen={handleOpen}
                        id_usuarioLogin={id_usuarioLogin}
                        setOpenModalLoader={setOpenModalLoader}
                        setTitleSpinner={setTitleSpinner}
                        dataAreaOrg={dataAreaOrg}
                        setDataAreaOrg={setDataAreaOrg}
                        setInfoAreaOrg={setInfoAreaOrg}
                        search={search}
                        getAreaOrganigrama={getAreaOrganigrama}
                        infoAreaOrg={infoAreaOrg}

                    />
                </Grid>
                <Grid item xs={12} sm={10} md={8} style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", paddingTop: "0.5rem" }}>
                    <MainButton
                        onClick={saveAsignacionAreaOrg}
                        title={'Asignar Sub-Area'}
                        color={'info'}
                    />
                    &nbsp;&nbsp;
                    <MainButton
                        onClick={saveAgregarAreaOrg}
                        title={'Agregar Area'}
                        color={'info'}
                    />
                </Grid>

            </Grid>

            <SpinnerLoader open={openModalLoader} title={titleSpinner} />
        </Box>
    );
};
export default AreaOrganigrama;