import React, { useState, useEffect } from 'react';
import { Grid, } from '@mui/material'
import DownloadIcon from '@mui/icons-material/Download';
import UploadIcon from '@mui/icons-material/Upload';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import NoteAddIcon from '@mui/icons-material/NoteAdd';

import GeneralService from '../../../../services/general';
import UserService from '../../../../services/user';
import ShiftService from '../../../../services/shift';
import PlanningService from '../../../../services/planning';

import MainButton from '../../../components/General/MainButton';
import MainInput from '../../../components/General/MainInput';
import GroupingAreaByUser from '../../../components/GroupingAreaByUser';

import AddPlanning from './AddPlanning/AddPlanning';
import BodyPlanByShift from '../../../components/Manage/Planning/BodyPlanByShift';
import BodyPlanByWorker from '../../../components/Manage/Planning/BodyPlanByWorker';

import SpinnerLoader from '../../../components/SpinnerLoad/SpinnerLoader';
import { simpleAlerts } from '../../../../utils/alerts';
import { getDateOL, calculateWeek, formatDate } from '../../../../utils/function';
import { CODES } from '../../../../utils/responseCodes';

const EditPlanning = ({ userToken }) => {
    const url = "'" + window.location.pathname + "'";
    const id_usuarioLogin = userToken.user?.id_usuario;
    const id_personaLogin = userToken.user?.persona_id;
    const [titleSpinner, setTitleSpinner] = useState(null);
    const [openModalLoader, setOpenModalLoader] = useState(true);
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    //* inicio variables para el agrupamiento */
    const [empresas, setEmpresas] = useState([]);
    const [clientes, setClientes] = useState([]);
    const [unidades, setUnidades] = useState([]);
    const [sedes, setSedes] = useState([]);
    const [areas, setAreas] = useState([]);

    const [infoCompany, setInfoCompany] = useState(null);
    const [infoClient, setInfoClient] = useState(null);
    const [infoUnit, setInfoUnit] = useState(null);
    const [infoCampus, setInfoCampus] = useState(null);
    const [infoArea, setInfoArea] = useState(null);
    //* fin variables para el agrupamiento */

    const [shift, setShift] = useState(null);
    const [dateInit, setDateInit] = useState(getDateOL());
    const [planningType, setPlanningType] = useState(null); //descripcion tipo de planificación
    const [dataPlanning, setDataPlanning] = useState(null); //planificación
    const [type, setType] = useState(0); //0: por turno - 1: por trabajador
    const [typeModal, setTypeModal] = useState(0); //0: edición - 1: planificación masiva
    // const { primerDiaG, ultimoDiaG } = calculateWeek(dateInit); //obtener el primer y ultimo dia de semana
    const primerDiaG = calculateWeek(dateInit)?.primerDia; //obtener el primer y ultimo dia de semana
    const ultimoDiaG = calculateWeek(dateInit)?.ultimoDia; //obtener el primer y ultimo dia de semana
    // const [primerDiaG, setPrimerDiaG] = useState(null);
    // const [ultimoDiaG, setUltimoDiaG] = useState(null);
    const [fechaDesde, setFechaDesde] = useState(null); //fecha inicio para planificación masiva
    const [fechaHasta, setFechaHasta] = useState(null); //fecha fin para planificación masiva
    const [infoPlan, setInfoPlan] = useState(null); //info de la planificación a editar

    useEffect(() => {
        init();
    }, [])

    useEffect(() => {
        setShift(null);
        if (infoCampus?.value)
            getTurnoBySede();
    }, [infoCampus])

    useEffect(() => {
        setDataPlanning(null);
    }, [infoArea, dateInit])

    useEffect(() => {
        let { primerDia, ultimoDia } = calculateWeek(dateInit);
        setFechaDesde(primerDia);
        setFechaHasta(ultimoDia);
    }, [dateInit])

    const init = async () => {
        Promise.all([
            getTipoPlanificacion(),
            getAgrupamientoByUsuario(),
        ]).then(() => setOpenModalLoader(false));
    }

    const getTipoPlanificacion = async () => {
        try {
            const result = await GeneralService.getTipoPlanificacion();
            if (result.status === CODES.SUCCESS_200) {
                if (result?.data?.length === 0) {
                    simpleAlerts('No hay tipos de planificación!', "info", "colored");
                    return;
                }
                result.data?.forEach(element => {
                    element.value = Number(element.id_tipoPlani);
                    element.label = element.descripcion;
                })
                setPlanningType(result.data);
            }
        } catch (error) {
            console.log(`Error en getTipoPlanificacion: ${error}`);
        }
    }

    const getAgrupamientoByUsuario = async () => {
        try {
            const result = await UserService.getAgrupamientoByUsuario(url, id_usuarioLogin);
            if (result.status === CODES.SUCCESS_200) {
                if (result.data.areas.length === 0)
                    simpleAlerts('No tiene areas asignadas', "warning");
                setEmpresas(result.data.empresas);
                setClientes(result.data.clientes);
                setUnidades(result.data.unidades);
                setSedes(result.data.sedes);
                setAreas(result.data.areas);
            }
        } catch (error) {
            console.log(`Error en getAgrupamientoByUsuario: ${error}`);
        }
    }

    const getTurnoBySede = async () => {
        try {
            const result = await ShiftService.getTurnoBySede(url, infoCampus?.value);
            if (result.status === CODES.SUCCESS_200) {
                if (result?.data?.length === 0) {
                    simpleAlerts('No tiene turnos relacionados!', "info", "colored");
                    return;
                }
                result.data?.forEach(element => {
                    element.value = Number(element.id_turno);
                    element.label = element.desTurno;
                })
                let tmp = result.data;
                tmp.push(
                    {
                        "id_sede": "107",
                        "sede_id": "107",
                        "turno_id": 0,
                        "id_turno": 0,
                        "desTurno": "Sin turno",
                        "ingreso": "",
                        "salida": "",
                        "value": 0,
                        "label": "Sin turno",
                    }
                );
                setShift(tmp);
            }
        } catch (error) {
            console.log(`Error en getAreasBySede: ${error}`);
        }
    }

    const buscarPlanificacion = (typeModal) => {
        if (typeModal === 3) {
            if (infoCampus?.value === 0 || !infoCampus) {
                simpleAlerts('Debe selecciónar un sede!', 'warning');
                return;
            }
        } else {
            if (infoArea?.value === 0 || !infoArea) {
                simpleAlerts('Debe selecciónar un área!', 'warning');
                return;
            }
            if (dateInit === '' || !dateInit) {
                simpleAlerts('Debe selecciónar un fecha!', 'warning');
                return;
            }
        }
        setTypeModal(typeModal);
        if (typeModal === 0) {
            getPlanificacionByArea();
        }
        if (typeModal === 1 || typeModal === 2 || typeModal === 3) {
            handleOpen();
        }
    }

    const getPlanificacionByArea = async () => {
        try {
            setTitleSpinner('Buscando planificación');
            setOpenModalLoader(true);
            setDataPlanning(null);
            const result = await PlanningService.getPlanificacionByArea(url, infoArea?.value, primerDiaG, ultimoDiaG);
            if (result.status === CODES.SUCCESS_200) {
                if (result?.data?.length === 0) {
                    simpleAlerts('No hay planificación!', "info", "colored");
                }
                setDataPlanning(result.data);
            } else {
                simpleAlerts('Hubo un problema en la consulta!', "error", "colored");
            }
            return [];
        } catch (error) {
            console.log(`Error en getAreasBySede: ${error}`);
        } finally {
            setTitleSpinner(null);
            setOpenModalLoader(false);
        }
    }

    const editPlanning = (date, worker, shift, plan) => {
        if (/^\d{4}-\d{2}-\d{2}$/.test(date)) {
            date = date;
        } else {
            for (let fecha = new Date(`${primerDiaG}T00:00:00`); fecha <= new Date(`${ultimoDiaG}T00:00:00`); fecha.setDate(fecha.getDate() + 1)) {
                let fechaString = formatDate(fecha, 'date');
                let numDay = new Date(`${fechaString}T00:00:00`)?.getDay();
                if (Number(date) === Number(numDay)) {
                    date = fechaString;
                    break;
                }
            }
        }

        let info = {
            'fecha': date,
            'numdoc': worker?.numdoc,
            'codSghh': worker?.codSghh,
            'persona_id': worker?.id_persona,
            'trabajador': worker?.trabajador,
            'turno_id': shift?.id_turno,
            'area_id': infoArea?.id_area,
            'supervisor_id': id_personaLogin,
            'tipPla_id': plan,
            'usuario_id': id_usuarioLogin,
        };
        setTypeModal(0)
        setInfoPlan(info)
        handleOpen();
    }

    const processPlanningData = (data, tipo) => {
        return data?.reduce((acc, item) => {
            const existingItem = acc.find(e => +e.id_persona === +item.id_persona);
            const key = tipo === 0 ? item.fecha : new Date(`${item.fecha}T00:00:00`).getDay();
            const value = item.tipPla_id + '/' + item.descripcion + '/' + item.codColor + '/' + item.turno_id + '/' + item.desTurno + '/' + item.ingreso + ' - ' + item.salida + '/' + item.fecha;

            if (existingItem) {
                existingItem[key] = value;
            } else {
                const newItem = {
                    numdoc: item.numdoc,
                    codSghh: item.codSghh,
                    trabajador: item.trabajador,
                    id_persona: item.id_persona,
                    [key]: value
                };
                acc.push(newItem);
            }

            return acc;
        }, []);
    }

    const copyPlanning = async () => {
        if (!infoArea?.value || infoArea?.value === 0 || infoArea?.value === '') {
            simpleAlerts('Debe seleccionar un área!', "warning", "colored");
            return;
        }
        try {
            let body = {
                fecIni: primerDiaG,
                fecFin: ultimoDiaG,
                area_id: +infoArea?.value,
                supervisor_id: +id_personaLogin,
                usuario_id: id_usuarioLogin
            }
            setTitleSpinner('Copiando planificación');
            setOpenModalLoader(true);
            const result = await PlanningService.copyPlanning(url, body);
            if (result.status === CODES.SUCCESS_200) {
                if (+result?.data?.status === 1) {
                    simpleAlerts(result?.data?.message, "success", "colored");
                    simpleAlerts(result?.data?.detalle, "success", "colored");
                    getPlanificacionByArea()
                } else {
                    simpleAlerts(result?.data?.message, "error", "colored");
                    setTitleSpinner(null);
                    setOpenModalLoader(false);
                }
            } else {
                simpleAlerts('Hubo un problema en la copia!', "error", "colored");
                setTitleSpinner(null);
                setOpenModalLoader(false);
            }
            return [];
        } catch (error) {
            setTitleSpinner(null);
            setOpenModalLoader(false);
            console.log(`Error en getAreasBySede: ${error}`);
        }
    }

    const downloadTemplate = async () => {
        try {
            const body = {
                id_sede: infoCampus.id_sede,
                id_usuario: id_usuarioLogin
            }

            setTitleSpinner('Generando Plantilla de Planificación ...');
            setOpenModalLoader(true);
            const result = await PlanningService.downloadTemplate(url, body);
            if (result.status === CODES.SUCCESS_200) {
                const nameFile = `Plantilla_${infoCampus.desSede}.xlsx`;
                const blob = new Blob([result.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                const url = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.download = nameFile;
                link.click();
                URL.revokeObjectURL(url);
                simpleAlerts('Plantilla Generada correctamente!', 'success');
            } else {
                simpleAlerts('Sin información para la plantilla!', 'warning');
            }
            return []
        } catch (error) {
            simpleAlerts('No se pudo generar la plantilla!', 'error');
        } finally {
            setOpenModalLoader(false);
            setTitleSpinner(null);
        }
    }

    return (
        <div>
            <AddPlanning
                open={open}
                handleClose={handleClose}
                id_usuarioLogin={id_usuarioLogin}
                id_personaLogin={id_personaLogin}
                setOpenModalLoader={setOpenModalLoader}
                setTitleSpinner={setTitleSpinner}
                typeModal={typeModal}
                setTypeModal={setTypeModal}
                buscarPlanificacion={buscarPlanificacion}

                infoPlan={infoPlan}
                setInfoPlan={setInfoPlan}
                planningType={planningType}
                shift={shift}

                fechaDesde={fechaDesde}
                setFechaDesde={setFechaDesde}
                fechaHasta={fechaHasta}
                setFechaHasta={setFechaHasta}
                infoCampus={infoCampus}
                infoArea={infoArea}

                primerDiaG={primerDiaG}
                ultimoDiaG={ultimoDiaG}
            />

            <Grid container justifyContent={'center'} alignItems={'center'}>
                <Grid item xs={11}>
                    <GroupingAreaByUser
                        user={id_usuarioLogin}
                        empresas={empresas} clientes={clientes} unidades={unidades} sedes={sedes} areas={areas}
                        infoCompany={infoCompany} setInfoCompany={setInfoCompany}
                        infoClient={infoClient} setInfoClient={setInfoClient}
                        infoUnit={infoUnit} setInfoUnit={setInfoUnit}
                        infoCampus={infoCampus} setInfoCampus={setInfoCampus}
                        infoArea={infoArea} setInfoArea={setInfoArea}
                    />
                </Grid>
            </Grid>

            <Grid container justifyContent={'center'} alignItems={'center'}>
                <Grid item xs={11}>
                    <Grid container style={{ padding: '0.2rem 0.8rem' }}
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="flex-end"
                        spacing={0.5}
                    >
                        <Grid item xs={6} sm={5} md={3}
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}
                        >
                            <span className="badge text-bg-secondary">Vista:</span>&nbsp;&nbsp;&nbsp;
                            <div>
                                <div>
                                    <input
                                        type="radio"
                                        id={"byShift"}
                                        name="typeBody"
                                        value={0}
                                        checked={type === 0}
                                        onChange={(e) => setType(Number(e.target.value))}
                                    />
                                    <label htmlFor={"byShift"} style={{ padding: '0.2rem 0.5rem' }}>Por Turno</label><br />
                                </div>
                                <div>
                                    <input
                                        type="radio"
                                        id={"typeBody"}
                                        name="typeBody"
                                        value={1}
                                        checked={type === 1}
                                        onChange={(e) => setType(Number(e.target.value))}
                                    />
                                    <label htmlFor={"typeBody"} style={{ padding: '0.2rem 0.5rem' }}>Por Trabajador</label>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={6} sm={5} md={2}>
                            <MainInput
                                title={'Fecha'}
                                placeholder={'Ingrese Fecha Inicio'}
                                type={'date'}
                                value={dateInit}
                                onChange={(e) => setDateInit(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={10} md={4}
                            style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'end', gap: 3 }}
                        >
                            <MainButton
                                title={'Cargar'}
                                size={'medium'}
                                onClick={() => buscarPlanificacion(0)}
                                typeStyle={'outlined'}
                                color={'primary'}
                                fullWidth={true}
                            />
                            <MainButton
                                title={'Planificar'}
                                size={'medium'}
                                onClick={() => buscarPlanificacion(1)}
                                typeStyle={'outlined'}
                                color={'secondary'}
                                fullWidth={true}
                            />
                            <MainButton
                                title={'Incidencia'}
                                size={'medium'}
                                onClick={() => buscarPlanificacion(2)}
                                typeStyle={'outlined'}
                                color={'quaternary'}
                                fullWidth={true}
                            />
                            <MainButton
                                title={'Cop. Plan.'}
                                size={'medium'}
                                onClick={copyPlanning}
                                typeStyle={'outlined'}
                                color={'warning'}
                                fullWidth={true}
                            />
                            <button
                                type="button"
                                className="btn btn-outline-primary"
                                title='Descargar Plantilla Planificación'
                                onClick={downloadTemplate}
                                style={{ display: 'flex', justifyContent: 'space-between' }}
                            >
                                <FormatListNumberedIcon fontSize='inherit' />
                                <DownloadIcon fontSize='inherit' />
                            </button>
                            <button
                                type="button"
                                className="btn btn-outline-success"
                                title='Enviar Plantilla Planificación'
                                onClick={() => buscarPlanificacion(3)}
                                style={{ display: 'flex', justifyContent: 'space-between' }}
                            >
                                <UploadIcon fontSize='inherit' />
                                <NoteAddIcon fontSize='inherit' />
                            </button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <section aria-label='body-edition-productivity' style={{ paddingTop: 4 }}>
                <Grid container justifyContent={'center'} alignItems={'center'}>
                    <Grid item xs={11}>
                        {type === 0 ?
                            <BodyPlanByShift
                                dateInit={dateInit}
                                primerDiaG={primerDiaG}
                                ultimoDiaG={ultimoDiaG}

                                shift={shift}
                                dataPlanning={dataPlanning}
                                editPlanning={editPlanning}

                                type={type}
                                processPlanningData={processPlanningData}
                            /> :
                            <BodyPlanByWorker
                                dateInit={dateInit}
                                primerDiaG={primerDiaG}
                                ultimoDiaG={ultimoDiaG}

                                shift={shift}
                                dataPlanning={dataPlanning}
                                editPlanning={editPlanning}
                                type={type}
                                processPlanningData={processPlanningData}
                            />
                        }
                    </Grid>
                </Grid>
            </section>

            <SpinnerLoader open={openModalLoader} title={titleSpinner} />
        </div>
    );
};

export default EditPlanning;