import React, { useEffect, useState } from 'react';
import { Box, Grid, } from '@mui/material';
import BodyRegions from '../../../components/Maintenance/Regions/BodyRegions/BodyRegions';
import AddRegion from './AddRegions/AddRegions';
import MainButton from '../../../components/General/MainButton';
import RegionService from '../../../../services/region';
import { CODES } from '../../../../utils/responseCodes';
import { simpleAlerts } from '../../../../utils/alerts';
import SpinnerLoader from '../../../components/SpinnerLoad/SpinnerLoader';


const newRegion = {
    'desRegion': '',
    'usuarioMod': ''
}

const Regions = ({ userToken }) => {
    const id_usuarioLogin = userToken.user.id_usuario;
    const [openModalLoader, setOpenModalLoader] = useState(true);
    const [titleSpinner, setTitleSpinner] = useState(null);
    const [open, setOpen] = useState(false)
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

  
  
    const [infoRegion, setInfoRegion] = useState(null);


    const [region, setRegion] = useState(null);

    useEffect(() => {
        init();
    }, [])

    const init = async () => {
        Promise.all([
            getRegion(),
        ]).then(() => setOpenModalLoader(false));
    }

 

    const getRegion = async () => {
        try {
            const result = await RegionService.getRegion();
            if (result.status === CODES.SUCCESS_200) {
                result.data?.forEach(element => {
                    element.value = element.id_region;
                    element.label = element.desRegion;
                })
                setRegion(result.data);
               
            } else {
                simpleAlerts('Hubo un error la relacion usuarios areas', 'error');
            }
            return [];
        } catch (error) {
            console.log(`Error en getUsuarioAreas: ${error}`);
        }
    }


    const createRegion = () => {
      
        setInfoRegion(newRegion)
        handleOpen();
    };

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AddRegion
                open={open}
                handleClose={handleClose}
                id_usuarioLogin={id_usuarioLogin}
                setOpenModalLoader={setOpenModalLoader}
                setTitleSpinner={setTitleSpinner}
                infoRegion={infoRegion}
                setInfoRegion={setInfoRegion}
                getRegion={getRegion}
            />

            <Grid container
                direction="row"
                justifyContent="center"
                alignItems="center"
            >
                <Grid item
                    xs={10}
                    display={'flex'}
                    justifyContent={'center'}
                    m={1}
                >
                    <Box width={'500px'}>
                        <MainButton
                            onClick={createRegion}
                            title={'Crear región'}
                            color={'primary'}
                            fullWidth={true}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={8} md={8} paddingLeft={'0.2rem'} paddingRight={'0.2rem'}>
                    <BodyRegions
                        handleOpen={handleOpen}
                        region={region}
                        setRegion={setRegion}
                        setInfoRegion={setInfoRegion}
                        id_usuarioLogin={id_usuarioLogin}
                        setOpenModalLoader={setOpenModalLoader}
                        setTitleSpinner={setTitleSpinner}
                        getRegion={getRegion}
                    />
                </Grid>

            </Grid>

            <SpinnerLoader open={openModalLoader} title={titleSpinner} />
        </Box>
    );
};

export default Regions;