import React, { useEffect, useState } from 'react';
import MainModal from '../../../../components/General/MainModal';
import MainButton from '../../../../components/General/MainButton';
import { CODES } from '../../../../../utils/responseCodes';
import { simpleAlerts } from '../../../../../utils/alerts';
import { colors } from '../../../../../assets/colors';
import ProfileService from '../../../../../services/profile';
import InfoProfile from '../../../../components/Maintenance/Profiles/AddProfile/InfoProfile';
import Swal from 'sweetalert2';

const optTemp = [
    { value: 'ASISPROD', label: 'ASISTENCIA Y PRODUCTIVIDAD' },
    { value: 'LA', label: 'LAVORO ASSISTANCE' },
]

const AddProfile = (props) => {
    const { open, handleClose, id_usuarioLogin, setOpenModalLoader, setTitleSpinner,
        screens, typeEdition, setTypeEdition, infoProfile, setInfoProfile, getPerfiles } = props;

    const url = "'" + window.location.pathname + "'";
    let newProfile = { "desPerfil": "", "codPerfil": "", "usuario_id": id_usuarioLogin }
    const [screensProfile, setScreensProfile] = useState([]);
    const [screensDelete, setScreensDelete] = useState([]);
    const [system, setSystem] = useState('ASISPROD');

    useEffect(() => {
        if (typeEdition === 1) { //crear perfil
            setInfoProfile(newProfile);
        }
    }, [])

    const saveProfile = async () => {
        if (!infoProfile?.desPerfil?.trim() || infoProfile?.desPerfil?.trim() === '') {
            simpleAlerts('Debe ingresar una descripción del perfil', 'warning');
            return;
        }
        if (!infoProfile?.codPerfil?.trim() || infoProfile?.codPerfil?.trim() === '') {
            simpleAlerts('Debe ingresar un código para el perfil', 'warning');
            return;
        }
        if (screensProfile?.length === 0) {
            Swal.fire({
                title: '¿Seguro de no marcar ninguna pantalla?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#28A745', //colors.secondary.hex,
                cancelButtonColor: colors.secondary.hex, //colors.primary.hex,
                confirmButtonText: 'Si, guardar'
            }).then((result) => {
                if (result.isConfirmed) {
                    saveGeneral();
                }
            })
            return;
        }
        saveGeneral();
    }

    const saveGeneral = async () => {
        setOpenModalLoader(true);
        setTitleSpinner('Guardando ...');
        let profileTmp;
        try {
            infoProfile.usuario_id = id_usuarioLogin;
            const result = await ProfileService.savePerfil(url, infoProfile);
            if (result.status === CODES.CREATE_201) {
                simpleAlerts(result.data.message, 'success');
                profileTmp = result.data;
            } else {
                simpleAlerts(result?.response?.data?.message, 'error');
                setOpenModalLoader(false);
                setTitleSpinner(null);
                return;
            }
        } catch (err) {
            console.log('Error en saveProfile', err);
        }

        if (profileTmp) {
            const deletePromises = screensDelete.map(async (screen) => {
                try {
                    const result = await ProfileService.deletePerfilPantalla(url, profileTmp.id_perfil, screen.id_pantalla);
                    if (result.status !== CODES.DELETE_204) {
                        simpleAlerts('No se logro eliminar la asociación', 'info');
                    }
                } catch (error) {
                    console.log(`Error en deletePerfilPantalla: ${error}`);
                }
            });
            await Promise.all([...deletePromises]);
            const savePromises = screensProfile.map(async (screen) => {
                try {
                    let item = {};
                    item.perfil_id = profileTmp.id_perfil;
                    item.pantalla_id = screen.id_pantalla;
                    const result = await ProfileService.savePerfilPantalla(url, item);
                    if (result.status !== CODES.CREATE_201) {
                        simpleAlerts("Error al asociar la pantalla", 'error');
                    }
                } catch (error) {
                    console.log(`Error en savePerfilPantalla: ${error}`);
                }
            });

            await Promise.all([...savePromises]);
            getPerfiles();
            handleClose();
            simpleAlerts("Perfil guardado correctamente!", 'success');
        }
    }

    const cleanInfo = () => {
        console.log('limpiando')
        return setInfoProfile(null),
            setTypeEdition(null),
            setScreensProfile([]),
            setScreensDelete([]);
    };

    return (
        <>
            <MainModal
                open={open}
                handleClose={() => { handleClose(); cleanInfo() }}
                centrado={'centrado'}
                title={'Mantenimiento de Perfiles'}
                subTitle={'Creación y edición de perfiles'}
                bodyModal={
                    <>
                        <span className="badge text-bg-secondary">Tipo:</span>
                        {optTemp?.map((opt) => {
                            return (
                                <div key={opt.label}>
                                    <input type="radio" id={opt.label} name="typeUser" value={opt.value}
                                        checked={system === opt.value}
                                        onChange={(e) => setSystem(e.target.value)}
                                    />
                                    <label htmlFor={opt.label} style={{ padding: '0.2rem 0.5rem' }}>{opt.label}</label><br />
                                </div>
                            )
                        })}

                        <InfoProfile
                            typeEdition={typeEdition}
                            system={system}
                            infoProfile={infoProfile}
                            setInfoProfile={setInfoProfile}

                            screens={screens}

                            screensProfile={screensProfile}
                            setScreensProfile={setScreensProfile}
                            screensDelete={screensDelete}
                            setScreensDelete={setScreensDelete}
                        />
                    </>
                }
                buttonActions={[
                    <MainButton
                        key='btn-modal-user-save1'
                        title={'Guardar'}
                        typeStyle={'outlined'}
                        color={'primary'}
                        onClick={saveProfile}
                    />
                ]}
            />
        </>
    );
};

export default AddProfile;