import React, { useState, } from 'react';
import FaultsWorker from '../../../components/Assistance/Movil/AddAssistanceMovil/FaultsWorker';
import HoursTime from '../../../components/Assistance/Movil/AddAssistanceMovil/HoursTime';
import AddWorkers from '../../../components/Assistance/Movil/AddAssistanceMovil/AddWorkers';
import Workstation from '../../../components/Assistance/Movil/AddAssistanceMovil/Workstation';
import MainCustomModal from '../../../components/General/MainCustomModal';
import AssistanceService from '../../../../services/assistance';
import { formatDate, getDateOL } from '../../../../utils/function';
import { CODES } from '../../../../utils/responseCodes';
import { simpleAlerts } from '../../../../utils/alerts';
import _ from 'lodash';
import { bodyItem, prodPersona, prodTurno, } from '../../Productivity/functionProductivity';

const validationMessages = {
    fault: 'Debe seleccionar un incidencia o falta!',
    workStation: 'Debe seleccionar un puesto de trabajo!',
};

const AddAssistance = (props) => {
    const { setOpenModalLoader, setTitleLoader, isModalOpen, handleCloseModal, typeModal, titleModal, pageOri,
        id_usuarioLogin, id_personaLogin,
        workStation, assistanceCondition, dataAssistance,
        dateSelected, sedes, areas, areasSelected, shiftSelected, hourIn, setHourIn, hourOut, setHourOut, workerSelected,
        getAsistenciaSimple, lstSedes, dataShift,
        tipoProd, productivityC, setProductivityC, dataProductivity, setDataProductivity,
    } = props;


    const url = "'" + window.location.pathname + "'";
    const [workerNew, setWorkerNew] = useState([]);
    const [faultSelected, setFaultSelected] = useState(null);
    const [workStationSel, setWorkStationSel] = useState(null);

    const handleClose = () => {
        handleCloseModal();
        setHourIn(null);
        setHourOut(null);
        setWorkerNew([]);
        setFaultSelected(null);
        setWorkStationSel(null);
    }

    const saveAssistanceHours = async () => {
        const currentDate = getDateOL();
        const previousDate = getDateOL('', 'short', -1);

        if (dateSelected !== currentDate && dateSelected !== previousDate) {
            simpleAlerts('La fecha seleccionada debe ser igual a la del día hoy o la del día ayer.', 'warning');
            return;
        }

        const assistanceArray = workerSelected.map(item => {
            let ingfecha = new Date(dateSelected + ' ' + hourIn + ':00.000');
            let salFecha = new Date(dateSelected + ' ' + hourOut + ':00.000');
            if (salFecha.valueOf() < ingfecha.valueOf())
                salFecha.setDate(salFecha.getDate() + 1);

            return {
                "id_Asistencia": item.id_Asistencia,
                "fecha": item.fecha,
                "area_id": item.area_id,
                "turno_id": item.turno_id,
                "supervisor_id": id_personaLogin,
                "ingreso": formatDate(ingfecha),
                "salida": formatDate(salFecha),
                "asisCond_id": 1,
                "usuario_id": id_usuarioLogin,
            };
        });
        handleClose();
        setOpenModalLoader(true);
        setTitleLoader('Guardando asistencias ...');
        try {
            const result = await AssistanceService.saveAssistanceHours(url, { 'trabajadores': assistanceArray });
            if (result.status === CODES.SUCCESS_200) {
                simpleAlerts(result.data.message, "success");
            } else {
                simpleAlerts(result.response.data.message, "error");
            }
            return [];
        } catch (error) {
            console.log(`Error en updateAddAsistenciaMovil: ${error}`);
        } finally {
            getAsistenciaSimple();
        }
    }

    const saveNewAssistanceWorker = async () => {
        try {
            setOpenModalLoader(true);
            setTitleLoader('Agregando trabajador ...');
            handleClose();
            const savePromises = workerNew.map(async (worker) => {
                let body = {
                    "persona_id": worker.id_persona,
                    "fecha": dateSelected,
                    "ingreso": null,
                    "salida": null,
                    "asisCond_id": null,
                    "area_id": areasSelected,
                    "turno_id": shiftSelected,
                    "supervisor_id": id_personaLogin,
                    "usuario_id": id_usuarioLogin
                };
                try {
                    const result = await AssistanceService.updateAddAsistenciaMovil(url, body);
                    if (result.status !== CODES.SUCCESS_200) {
                        simpleAlerts('Error al agregar trabajadores', "error");
                    }
                } catch (error) {
                    console.log(`Error en updateAddAsistenciaMovil: ${error}`);
                }
            });

            await Promise.all([...savePromises,]);
            simpleAlerts("Trabajadores agregados", "success");
            getAsistenciaSimple();
        } catch (error) {
            console.log(`Error en updateAddAsistenciaMovil: ${error}`);
        }
    };

    const saveWorkStation = async () => {
        if (!workStationSel || +workStationSel === 0) {
            simpleAlerts(validationMessages.workStation, 'error');
            return;
        }

        try {
            handleClose();
            setOpenModalLoader(true);
            setTitleLoader(`Grabando Puestos ....`);
            const savePromises = workerSelected.map(async (worker) => {
                worker.asistenciaPuesto_id = workStationSel;
                worker.supervisor_id = id_personaLogin;
                worker.usuario_id = id_usuarioLogin;
                try {
                    const result = await AssistanceService.updateAddAsistenciaMovil(url, worker);
                    if (result.status !== CODES.SUCCESS_200) {
                        simpleAlerts('Error al guardar la fatla', "error");
                    }
                } catch (error) {
                    console.log(`Error en updateAddAsistenciaMovil: ${error}`);
                }
            });

            await Promise.all([...savePromises,]);
            simpleAlerts("Puesto grabado", "success");
            getAsistenciaSimple();
        } catch (error) {
            console.log(`Error en updateAddAsistenciaMovil: ${error}`);
        }
    };

    const saveFaultsWorker = async () => {
        if (!faultSelected || +faultSelected === 0) {
            simpleAlerts(validationMessages.fault, 'error');
            return;
        }

        try {
            handleClose();
            setOpenModalLoader(true);
            setTitleLoader(`Grabando ${assistanceCondition?.find(e => +e.value === faultSelected)?.label} ....`);
            const savePromises = workerSelected.map(async (worker) => {
                let body = {
                    "persona_id": worker.persona_id,
                    "fecha": dateSelected,
                    "area_id": areasSelected,
                    "turno_id": shiftSelected,
                    "supervisor_id": id_personaLogin,
                    "usuario_id": id_usuarioLogin,
                    "ingreso": null,
                    "salida": null,
                    "asisCond_id": faultSelected
                };
                try {
                    const result = await AssistanceService.updateAddAsistenciaMovil(url, body);
                    if (result.status !== CODES.SUCCESS_200) {
                        simpleAlerts('Error al guardar la fatla', "error");
                    }
                } catch (error) {
                    console.log(`Error en updateAddAsistenciaMovil: ${error}`);
                }
            });

            await Promise.all([...savePromises,]);
            simpleAlerts("Faltas grabadas", "success");
            getAsistenciaSimple();
        } catch (error) {
            console.log(`Error en updateAddAsistenciaMovil: ${error}`);
        }
    };

    /**********************/
    const addHoursProductivity = async () => {
        let updData = _.cloneDeep(dataProductivity);
        let ingfecha = new Date(dateSelected + ' ' + hourIn + ':00.000');
        let salFecha = new Date(dateSelected + ' ' + hourOut + ':00.000');
        if (salFecha.valueOf() < ingfecha.valueOf()) {
            salFecha.setDate(salFecha.getDate() + 1);
        }
        updData?.forEach((e) => {
            e.horaFin = formatDate(salFecha, 'date-time', 'long');
            e.horaInicio = formatDate(ingfecha, 'date-time', 'long');
        });
        if (+tipoProd === 1) {
            setDataProductivity(prodTurno(updData, productivityC));
        } else {
            let newProductivity = prodPersona(updData, productivityC);
            setDataProductivity(newProductivity?.productividad);
            setProductivityC(newProductivity?.cabecera);
        }
        handleClose();
    };

    const acceptProductivityWorker = async () => {
        const savePromises = workerNew.map(async (worker) => {
            return bodyItem(worker, dataShift, dateSelected, shiftSelected);
        });

        const results = await Promise.all(savePromises);
        const resumProductivity = [...dataProductivity, ...results];
        if (+tipoProd === 1) {
            setDataProductivity(prodTurno(resumProductivity, productivityC));
        } else {
            let newProductivity = prodPersona(resumProductivity, productivityC);
            setDataProductivity(newProductivity?.productividad);
            setProductivityC(newProductivity?.cabecera);
        }
        return handleClose();
    };

    return (
        <MainCustomModal
            isOpen={isModalOpen}
            onClose={handleClose}
            sizeModal={(typeModal === 1 || typeModal === 3) ? 'small' : ''}
            title={titleModal || "Espacion modal"}
            buttons={
                pageOri === 'ASIS' ?
                    (typeModal === 0 ?
                        [{ label: 'Agregar', color: 'success', onClick: saveNewAssistanceWorker },] :
                        typeModal === 1 ?
                            [{ label: 'Guardar', color: 'primary', onClick: saveAssistanceHours },] :
                            typeModal === 2 ?
                                [{ label: 'Guardar', color: 'primary', onClick: saveWorkStation },] :
                                typeModal === 3 ?
                                    [{ label: 'Guardar', color: 'danger', onClick: saveFaultsWorker },] : null)
                    : pageOri === 'PROD' ?
                        (typeModal === 0 ?
                            [{ label: 'Agregar', color: 'success', onClick: acceptProductivityWorker },] :
                            typeModal === 1 ?
                                [{ label: 'Guardar', color: 'primary', onClick: addHoursProductivity },] : null)
                        : null
            }
        >
            {pageOri === 'ASIS' ?
                (typeModal === 0 ?
                    <AddWorkers
                        pageOri={pageOri}
                        dataAssistance={dataAssistance}
                        lstSedes={lstSedes}
                        workerNew={workerNew}
                        setWorkerNew={setWorkerNew}
                        sedes={sedes}
                        areas={areas}
                    /> :
                    typeModal === 1 ?
                        <HoursTime
                            hourIn={hourIn}
                            setHourIn={setHourIn}
                            hourOut={hourOut}
                            setHourOut={setHourOut}
                        /> :
                        typeModal === 2 ?
                            <Workstation
                                workStation={workStation}
                                workStationSel={workStationSel}
                                setWorkStationSel={setWorkStationSel}
                            /> :
                            typeModal === 3 ?
                                <FaultsWorker
                                    assistanceCondition={assistanceCondition}
                                    faultSelected={faultSelected}
                                    setFaultSelected={setFaultSelected}
                                /> : '')
                : pageOri === 'PROD' ?
                    (typeModal === 0 ?
                        <AddWorkers
                            pageOri={pageOri}
                            dataAssistance={dataProductivity}
                            lstSedes={lstSedes}
                            sedes={sedes}
                            areas={areas}
                            areasSelected={areasSelected}
                            shiftSelected={shiftSelected}
                            workerNew={workerNew}
                            setWorkerNew={setWorkerNew}
                            dateSelected={dateSelected}
                        /> : typeModal === 1 ?
                            <HoursTime
                                hourIn={hourIn}
                                setHourIn={setHourIn}
                                hourOut={hourOut}
                                setHourOut={setHourOut}
                            /> : '')
                    : ''
            }
        </MainCustomModal >
    );
};

export default AddAssistance;