import React, { useState, } from 'react';
import MainModal from '../../../components/General/MainModal';
import MainButton from '../../../components/General/MainButton';
import AddWorkerAreaOrg from '../../../components/OrganizationalChart/AddaAreaOrg/AddWorkerAreaOrg';
import AreaOrgService from '../../../../services/areaOrg';
import { CODES } from '../../../../utils/responseCodes';
import { simpleAlerts } from '../../../../utils/alerts';

const AddaAreaOrg = (props) => {
    const { open, handleClose, setOpenModalLoader, setTitleSpinner, organigramaArea, infoAreaOrg, getOrganigramaArea, } = props;

    const url = "'" + window.location.pathname + "'";
    const [newWorkers, setNewWorkers] = useState([]);

    const cleanInfo = async () => {
        setNewWorkers([]);
    }

    const saveTrabajadorArea = async () => {
        try {
            setOpenModalLoader(true);
            setTitleSpinner('Guardando...');

            const savePromises = newWorkers.map(async (area) => {
                try {
                    const result = await AreaOrgService.saveTrabajadorArea(url, area);
                    if (result.status === CODES.CREATE_201 && result.data.id === 0) {
                        simpleAlerts(result.data.message, "success");
                    }
                } catch (error) {
                    console.log(`Error en saveTrabajadorArea: ${error}`);
                }
            });
            await Promise.all([...savePromises]);
            simpleAlerts("Fin de asociaciónes", "success");
            getOrganigramaArea();
            handleClose();
        } catch (error) {
            console.log(`Error en saveAssociateAreas: ${error}`);
        }
    }

    return (
        <>
            <MainModal
                open={open}
                handleOpen={cleanInfo}
                handleClose={() => { handleClose(); }}
                title={'Edición de productividad'}
                subTitle={'Agregar trabajador'}
                bodyModal={
                    <AddWorkerAreaOrg
                        organigramaArea={organigramaArea}
                        infoAreaOrg={infoAreaOrg}
                        newWorkers={newWorkers}
                        setNewWorkers={setNewWorkers}
                    />
                }
                buttonActions={[
                    <MainButton
                        key='btn-modal-user-save1'
                        title={'Aceptar'}
                        color={'success'}
                        onClick={saveTrabajadorArea}
                    />
                ]}
            />
        </>
    );
};

export default AddaAreaOrg;