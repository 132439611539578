import React, { useState, } from 'react';
import AsistenciaPuestoService from '../../../../../services/AsistenciaPuesto';
import MainButton from '../../../../components/General/MainButton';
import { CODES } from '../../../../../utils/responseCodes';
import { simpleAlerts } from '../../../../../utils/alerts';
import AssociateAreas from '../../../../components/AsistenciaPuesto/AddAreaAsistenciaPuesto/AssociateAreas';
import MainModal from '../../../../components/General/MainModal';

const AddAreaAsistenciaPuesto = (props) => {
    const { open, handleClose, setOpenModalLoader, setTitleSpinner, dataAsistenciaPuesto, dataAreaAsistenciaPuestos, getAreaAsistenciaPuestos } = props;
    const url = "'" + window.location.pathname + "'";

    const [areaAsistenciaPuestosDelete, setAreaAsistenciaPuestosDelete] = useState([]);
    const [areaAsistenciaPuestosCopy, setAreaAsistenciaPuestosCopy] = useState(null);

    const saveAssociateAreas = async (e) => {
        e.preventDefault();
        setOpenModalLoader(true);
        setTitleSpinner('Guardando ...');
        try {
            const savePromises = areaAsistenciaPuestosCopy.map(async (area) => {
                try {
                    const result = await AsistenciaPuestoService.saveAreaAsistenciaPuestos(url, area);
                    if (result.status === CODES.CREATE_201 && result.data.id === 0) {
                        simpleAlerts(result.data.message, "success");
                    }
                } catch (error) {
                    console.log(`Error en saveAssociateAreas: ${error}`);
                }
            });

            const deletePromises = areaAsistenciaPuestosDelete.map(async (area) => {
                try {
                    const result = await AsistenciaPuestoService.deleteAreaAsistenciaPuestos(url, area);
                    if (result.status === CODES.DELETE_204) {
                        simpleAlerts("Relación eliminada", "success");
                    }
                } catch (error) {
                    console.log(`Error en deleteAreaAsistenciaPuestos: ${error}`);
                }
            });

            await Promise.all([...savePromises, ...deletePromises]);

            simpleAlerts("Fin de asociaciónes", "success");
            getAreaAsistenciaPuestos();
            closeModal();
        } catch (error) {
            console.log(`Error en saveAssociateAreas: ${error}`);
        }
    };

    const closeModal = () => {
        return handleClose(), setAreaAsistenciaPuestosDelete([]), setAreaAsistenciaPuestosCopy([]);
    }

    return (
        <>
            <MainModal
                size={'lg'}
                open={open}
                handleClose={closeModal}
                formSubmit={saveAssociateAreas}
                title={'Asociar Areas'}
                subTitle={'Asociar nuevas areas'}
                bodyModal={
                    <AssociateAreas
                        dataAreaAsistenciaPuestos={dataAreaAsistenciaPuestos}
                        areaAsistenciaPuestosCopy={areaAsistenciaPuestosCopy}
                        setAreaAsistenciaPuestosCopy={setAreaAsistenciaPuestosCopy}
                        areaAsistenciaPuestosDelete={areaAsistenciaPuestosDelete}
                        setAreaAsistenciaPuestosDelete={setAreaAsistenciaPuestosDelete}
                        dataAsistenciaPuesto={dataAsistenciaPuesto}


                    />
                }
                buttonActions={[
                    <MainButton
                        key='btn-modal-user-save1'
                        title={'Guardar'}
                        typeStyle={'outlined'}
                        color={'primary'}
                        type={'submit'}
                    />
                ]}
            />
        </>
    );
};

export default AddAreaAsistenciaPuesto;