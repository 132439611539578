import React, { useEffect, useState } from 'react';
import { Box, Grid, Tabs, Tab } from '@mui/material';
import { CODES } from '../../../../utils/responseCodes';
import { simpleAlerts } from '../../../../utils/alerts';
import SpinnerLoader from '../../../components/SpinnerLoad/SpinnerLoader';
import MainInput from '../../../components/General/MainInput';
import GeneralService from '../../../../services/general';
import ReportService from '../../../../services/report';
import MainSelect from '../../../components/General/MainSelect';
import { getDateOL } from '../../../../utils/function';
import GroupingSedeByUser from '../../../components/GroupingSedeByUser';

const ReportProductivity = ({ userToken }) => {
  const [openModalLoader, setOpenModalLoader] = useState(true);
  const [titleModalLoader, setTitleModalLoader] = useState(null);

  const [infoRegion, setInfoRegion] = useState(null);
  const [regiones, setRegiones] = useState(null);
  const [dateInit, setDateInit] = useState(getDateOL());
  const [dateEnd, setDateEnd] = useState(getDateOL('', 'short', 7));

  const [infoCompany, setInfoCompany] = useState(null);
  const [infoClient, setInfoClient] = useState(null);
  const [infoUnit, setInfoUnit] = useState(null);
  const [infoCampus, setInfoCampus] = useState(null);

  const [activeTab, setActiveTab] = useState('sede');

  useEffect(() => {
    init();
  }, [])

  const init = async () => {
    Promise.all([
      getRegiones(),
    ]).then(() => { setTimeout(() => { setOpenModalLoader(false); }, 5000); });
  }

  useEffect(() => {
    if (infoRegion)
      getSedexRegion();
  }, [infoRegion])

  const getRegiones = async () => {
    try {
      const result = await GeneralService.getRegiones();
      if (result.status === CODES.SUCCESS_200) {
        result.data?.forEach(element => {
          element.value = element.id_region
          element.label = element.desRegion
        })
        setRegiones(result.data);

      } else {
        simpleAlerts('No hay información de Regiones', 'info');
      }
    } catch (error) {
      console.log(`Error en getAgrupamientoByUsuario: ${error}`);
    }
  }

  const getSedexRegion = async () => {
    try {
      setOpenModalLoader(true);
      setTitleModalLoader('Buscando Cliente ...');
      const result = await GeneralService.getSedexRegion(infoRegion?.value);
      if (result.status === CODES.SUCCESS_200) {
        result.data?.forEach(element => {
          element.value = element.id_sede
          element.label = element.desSede
        })
      } else {
        simpleAlerts('No hay información de Sedes', 'info');
      }
    } catch (error) {
      console.log(`Error en getSedexRegion: ${error}`);
    } finally {
      setOpenModalLoader(false);
      setTitleModalLoader(null);
    }
  }

  const generateActividad = async () => {
    if (!infoCampus || +infoCampus?.value === 0) {
      simpleAlerts('Seleccione una Sede', 'warning');
      return;
    }
    if (!dateInit || dateInit === '') {
      simpleAlerts('Debe seleccionar fecha de inicio', 'warning');
      return;
    }
    if (!dateEnd || dateEnd === '') {
      simpleAlerts('Debe seleccionar fecha de fin', 'warning');
      return;
    }
    if (dateInit > dateEnd) {
      simpleAlerts('Rangos de fechas incorrectos', 'warning');
      return;
    }
    try {
      setTitleModalLoader('Generando Reporte por Actividad ...');
      setOpenModalLoader(true);

      const result = await ReportService.generateActividad(dateInit, dateEnd, infoCampus?.value);

      if (result.status === CODES.SUCCESS_200) {
        const blob = new Blob([result.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'PORDUCTIVIDAD_ACTIVIDAD_' + infoCampus?.desSede + "_" + dateInit + '_' + dateEnd + '.xlsx';
        link.click();
        URL.revokeObjectURL(url);
        simpleAlerts('Reporte Generado correctamente!', 'success');
      } else {
        simpleAlerts('Sin información para el reporte!', 'warning');
      }
      return []
    } catch (error) {
      simpleAlerts('No se pudo generar reporte!', 'error');
      console.log(`Error en generateActividad: ${error}`);
    } finally {
      setOpenModalLoader(false);
      setTitleModalLoader(null);
    }
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container direction="row" justifyContent="center" alignItems="center">
        <Grid item xs={10} sm={8} md={8}>
          <Grid container p={1} justifyContent="center" alignItems="center">
            <Grid item xs={12} sm={6} md={2}>
              <Tabs value={activeTab} onChange={(event, newValue) => setActiveTab(newValue)}>
                <Tab value="sede" label="Actividad" />
              </Tabs>
            </Grid>
          </Grid>
          <Grid container justifyContent="center" alignItems="center">
            {activeTab === 'region' ? (
              <Grid item xs={12} sm={6} md={6}>
                <MainSelect
                  title={'Region'}
                  options={regiones}
                  placeholder={'Seleccionar Región'}
                  value={infoRegion?.value}
                  onChange={(value, event) => setInfoRegion(regiones?.find(element => element.value == value))}
                />
              </Grid>
            ) : (
              <Grid item xs={12} sm={12} md={8}>
                <GroupingSedeByUser
                  user={userToken.user}
                  openModalLoader={openModalLoader} setOpenModalLoader={setOpenModalLoader}
                  infoCompany={infoCompany} setInfoCompany={setInfoCompany}
                  infoClient={infoClient} setInfoClient={setInfoClient}
                  infoUnit={infoUnit} setInfoUnit={setInfoUnit}
                  infoCampus={infoCampus} setInfoCampus={setInfoCampus}
                />
              </Grid>
            )}
          </Grid>
          <Grid container p={1} justifyContent="center" alignItems="center">
            <Grid item xs={12} sm={6} md={4}>
              <MainInput
                title={'Fecha Inicio'}
                placeholder={'Ingrese Fecha Inicio'}
                type={'date'}
                value={dateInit}
                onChange={(e) => setDateInit(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <MainInput
                title={'Fecha Fin'}
                placeholder={'Ingrese Fecha Fin'}
                type={'date'}
                value={dateEnd}
                onChange={(e) => setDateEnd(e.target.value)}
                propsInput={{ min: dateInit }}
              />
            </Grid>
          </Grid>
          <Grid container p={1} marginTop={'1rem'} justifyContent="center" alignItems="center">
            <Grid item xs={12} sm={6} md={8}>
              {activeTab === 'sede' && (
                <>
                  <button
                    type='button'
                    className='btn btn-success'
                    onClick={generateActividad}
                    style={{ marginRight: "2rem" }}
                  >
                    REPORTE ACTIVIDAD
                  </button>
                </>
              )}
            </Grid>
          </Grid>
          <SpinnerLoader open={openModalLoader} title={titleModalLoader} />
        </Grid>
      </Grid>
    </Box>
  );
};
export default ReportProductivity;



