import React, { useEffect, useState } from 'react';

import { Grid } from "@mui/material";

import GeneralService from '../../services/general';
import { CODES } from '../../utils/responseCodes';
import { simpleAlerts } from '../../utils/alerts';
import MainSelect from './General/MainSelect';

const GroupingSede = ({
    infoCompany, setInfoCompany,
    infoClient, setInfoClient,
    infoUnit, setInfoUnit,
    infoCampus, setInfoCampus,
}) => {

    const [companies, setCompanies] = useState(null);
    const [clients, setClients] = useState(null);
    const [units, setUnits] = useState(null);
    const [campus, setCampus] = useState(null);

    useEffect(() => {
        getEmpresas();
    }, [])

    useEffect(() => {
        setClients(null);
        setUnits(null);
        setCampus(null);

        setInfoClient(null);
        setInfoUnit(null);
        setInfoCampus(null);

        if (infoCompany)
            getClientesByEmpresa();
    }, [infoCompany])

    useEffect(() => {
        setUnits(null);
        setCampus(null);

        setInfoUnit(null);
        setInfoCampus(null);
        if (infoClient)
            getUnidadesByCliente();
    }, [infoClient])

    useEffect(() => {
        if (infoUnit)
            getSedesByUnidad();
        setInfoCampus(null);
        setCampus(null);
    }, [infoUnit])

    const getEmpresas = async () => {
        await GeneralService.getEmpresas()
            .then(result => {
                if (result.status === CODES.SUCCESS_200) {
                    result.data?.forEach(element => {
                        element.value = element.id_empresa
                        element.label = element.desEmpresa
                    })
                    setCompanies(result.data);
                } else {
                    simpleAlerts('No hay información de empresas', 'info');
                }
            })
            .catch(err => {
                console.log('getEmpresas', err)
            })

    }

    const getClientesByEmpresa = async () => {
        await GeneralService.getClientesByEmpresa(infoCompany?.value).then(result => {
            if (result.status === CODES.SUCCESS_200) {
                result.data?.forEach(element => {
                    element.value = element.id_cliente
                    element.label = element.desCliente
                })
                setClients(result.data);
            } else {
                simpleAlerts('No hay información de clientes', 'info');
            }
        })
            .catch(err => {
                console.log('getClientesByEmpresa', err)
            })
    }

    const getUnidadesByCliente = async () => {
        await GeneralService.getUnidadesByCliente(infoClient?.value)
            .then(result => {
                if (result.status === CODES.SUCCESS_200) {
                    result.data?.forEach(element => {
                        element.value = element.id_unidad
                        element.label = element.desUnidad
                    })
                    setUnits(result.data);
                } else {
                    simpleAlerts('No hay información de unidades', 'info');
                }
            })
            .catch(err => {
                console.log('getEmpresas', err)
            })
    }

    const getSedesByUnidad = async () => {
        await GeneralService.getSedesByUnidad(infoUnit?.value)
            .then(result => {
                if (result.status === CODES.SUCCESS_200) {
                    result.data?.forEach(element => {
                        element.value = element.id_sede
                        element.label = element.desSede
                    })
                    setCampus(result.data);
                } else {
                    simpleAlerts('No hay información de sedes', 'info');
                }
            })
            .catch(err => {
                console.log('getEmpresas', err)
            })

    }

    return (
        <Grid container p={1} spacing={1.2}>
            <Grid item xs={12} sm={6} md={6}>
                <MainSelect
                    title={'Empresa'}
                    options={companies}
                    placeholder={'Selecionar Empresa'}
                    value={Number(infoCompany?.value)}
                    onChange={(value, event) => setInfoCompany(companies?.find(element => element.value === Number(value)))}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
                <MainSelect
                    title={'Cliente'}
                    options={clients}
                    placeholder={'Selecionar Cliente'}
                    value={Number(infoClient?.value)}
                    onChange={(value, event) => setInfoClient(clients?.find(element => element.value === Number(value)))}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={6} paddingTop="1%">
                <MainSelect
                    title={'Unidad'}
                    options={units}
                    placeholder={'Selecionar Unidad'}
                    value={Number(infoUnit?.value)}
                    onChange={(value, event) => setInfoUnit(units?.find(element => element.value === Number(value)))}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={6} paddingTop="1%">
                <MainSelect
                    title={'Sede'}
                    options={campus}
                    placeholder={'Selecionar Sede'}
                    value={Number(infoCampus?.value)}
                    onChange={(value, event) => setInfoCampus(campus?.find(element => element.value === Number(value)))}
                />
            </Grid>
        </Grid>
    );
};

export default GroupingSede;