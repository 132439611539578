import React, { useState, useEffect } from 'react';
import { Grid, Box } from '@mui/material';
import MainInput from '../../../General/MainInput';
import SpinnerSmall from '../../../SpinnerLoad/SpinnerSmall';


const InfoRegion = (props) => {
    const { typeEdition, system, infoRegion, setInfoRegion} = props;

    const url = "'" + window.location.pathname + "'";
   
    const [openSmall, setOpenSmall] = useState(true);
    const [screensSystem, setScreensSystem] = useState(null);

    useEffect(() => {
     
    }, [screensSystem])

  

    useEffect(() => {
       
        if (infoRegion?.id_region && typeEdition === 2) {
            setOpenSmall(true);
            //getPantallasByPerfil(infoProfile?.id_perfil);
        } else
            setOpenSmall(false);
    }, [infoRegion?.id_region, typeEdition])


    return (
        <>
            <Grid container>
                <Grid item md={6} xs={12}>
                    <MainInput
                        title={'Nombre de Región'}
                        value={infoRegion?.desRegion}
                        name={'desRegion'}
                        placeholder={"Ingrese descripción"}
                        onChange={(e) => setInfoRegion({ ...infoRegion, desRegion: e.target.value })}
                    />
                </Grid>
            </Grid>
            <Box
                display={'flex'}
                overflow={'scroll'}
                padding={'2px 0 0 5px'}
            >
                <SpinnerSmall open={openSmall} />

            </Box>
        </>
    );
};



export default InfoRegion;