import React, { useState } from 'react';
import MainModal from '../../../../components/General/MainModal';
import MainButton from '../../../../components/General/MainButton';
import { CODES } from '../../../../../utils/responseCodes';
import { simpleAlerts } from '../../../../../utils/alerts';
import AreaOrgService from '../../../../../services/areaOrg';
import InfoAreaOrg from '../../../../components/Maintenance/Organigrama/AddAreaOrg/InfoAreaOrg';
import AssingAreaOrg from '../../../../components/Maintenance/Organigrama/AddAreaOrg/AssingAreaOrg';

const AddAreaOrg = (props) => {
    const { open, handleClose, setOpenModalLoader, id_usuarioLogin, infoAreaOrg, setInfoAreaOrg, getAreaOrganigrama, tipoModal, setTitleSpinner } = props;
    const url = "'" + window.location.pathname + "'";
    const [areaPadreValue, setAreaPadreValue] = useState(null);
    const [areaHijoValue, setAreaHijoValue] = useState(null);

    const cleanInfo = () => {
        return setInfoAreaOrg(null), setAreaPadreValue(null), setAreaHijoValue(null);
    };

    const saveAreaOrg = async (e) => {
        infoAreaOrg.usuarioMod = id_usuarioLogin;
        if (infoAreaOrg?.Area === '') {
            simpleAlerts('Debe poner una descripcion en el Área!', 'warning');
            return;
        }

        setOpenModalLoader(true);
        setTitleSpinner("Creando  Area");
        try {
            const result = await AreaOrgService.saveAreaOrg(url, infoAreaOrg);
            if (result.status === CODES.CREATE_201) {
                simpleAlerts(result.data.message, 'success');
                cleanInfo();
                handleClose();
                getAreaOrganigrama();
            } else {
                simpleAlerts(result.response.data.message, 'error');
            }
        } catch (err) {
            console.log('Error en saveProfile', err);
        } finally {
            setOpenModalLoader(false);
        }
    }

    const saveAssingAreaOrg = async (e) => {
        let body = {
            'areaOrg_id': areaPadreValue,
            'subAreaOrg_id': areaHijoValue
        }
        if (areaPadreValue === 0 || !areaPadreValue) {
            simpleAlerts('Debe seleccionar un Area padre', 'warning');
            return;
        }

        if (areaHijoValue === 0 || !areaHijoValue) {
            simpleAlerts('Debe seleccionar un Area hijo', 'warning');
            return;
        }
        setOpenModalLoader(true);
        setTitleSpinner("Creando sub Area");

        try {
            const result = await AreaOrgService.saveAssingAreaOrg(url, body);
            if (result.status === CODES.CREATE_201) {
                simpleAlerts(result.data.message, 'success');
                cleanInfo();
                handleClose();
            } else {
                simpleAlerts(result.response.data.message, 'error');
            }
        } catch (err) {
            console.log('Error en saveProfile', err);
        } finally {
            setOpenModalLoader(false);
        }
    }

    return (
        <>
            <MainModal
                open={open}
                handleClose={() => { cleanInfo(); handleClose() }}
                title={'Mantenimiento de áreas y jefes'}
                subTitle={'Edición de áreas y jefes'}
                bodyModal={
                    tipoModal === 1 ?
                        <InfoAreaOrg
                            infoAreaOrg={infoAreaOrg}
                            setInfoAreaOrg={setInfoAreaOrg}
                            id_usuarioLogin={id_usuarioLogin}

                        /> :
                        <AssingAreaOrg
                            areaPadreValue={areaPadreValue}
                            areaHijoValue={areaHijoValue}
                            setAreaHijoValue={setAreaHijoValue}
                            setAreaPadreValue={setAreaPadreValue}
                            id_usuarioLogin={id_usuarioLogin}
                        />
                }
                buttonActions={[
                    <MainButton
                        key='btn-modal-user-save1'
                        title={'Guardar'}
                        typeStyle={'outlined'}
                        color={'primary'}
                        onClick={tipoModal === 1 ? saveAreaOrg : saveAssingAreaOrg}
                    />
                ]}
            />
        </>
    );
};

export default AddAreaOrg;