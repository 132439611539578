import React, { } from 'react';
import UserService from '../../../../services/user';
import InfoUser from '../../../components/Users/AddUser/InfoUser';
import MainModal from '../../../components/General/MainModal';
import MainButton from '../../../components/General/MainButton';
import { CODES } from '../../../../utils/responseCodes';
import { simpleAlerts } from '../../../../utils/alerts';
import { validatePassword } from '../../../../utils/function';
import { colors } from '../../../../assets/colors';
import Swal from 'sweetalert2';

const optTemp = [
    { value: 1, label: 'Lavoro' },
    { value: 2, label: 'Externo' },
]

const AddUser = (props) => {
    const { open, handleClose, id_usuarioLogin, setOpenModalLoader, setTitleSpinner, systems, infoUser, setInfoUser, profiles, typeDocument, getUsuarios,
        typeRegister, setTypeRegister } = props;
    const url = "'" + window.location.pathname + "'";

    const cleanInfo = () => {
        setTypeRegister(null);
        setInfoUser(null);
        handleClose();
    }

    const onChangeTypeUser = (e) => {
        let updInfo = JSON.parse(JSON.stringify(infoUser));
        updInfo.isExternal = +e.target.value
        if (+e.target.value === 2) {
            updInfo.persona_id = null;
        }
        return setInfoUser(updInfo);
    }

    const registerUser = async (e) => {
        e.preventDefault();
        if (typeRegister === 1) {
            saveUser(e);
        } else {
            duplicateUser(e);
        }
    }

    const saveUser = async (e) => {
        e.preventDefault();
        Swal.fire({
            title: (infoUser.id_usuario ? '¿Seguro de editar el usuario?' : '¿Seguro de crear el usuario?'),
            text: `Se ${infoUser.id_usuario ? 'ediatara' : 'creara'} el usuario ${infoUser?.usuario} !`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#28A745',
            cancelButtonColor: colors.secondary.hex,
            confirmButtonText: 'Si, crear'
        }).then((result) => {
            if (result.isConfirmed) {
                setTimeout(async () => {
                    let data = new FormData(e.target);
                    data.append("usuario_id", id_usuarioLogin);
                    data.append("id_Perfil1", infoUser.id_Perfil1 || 0);
                    data.append("id_Perfil2", infoUser.id_Perfil2 || 0);
                    data.append("documento_id", infoUser.documento_id);
                    data.append("estado", infoUser.estado);

                    // data.forEach((value, key) => {
                    //     console.log(key, value);
                    // });

                    if (data.get('id_usuario') === '' || !data.get('id_usuario')) {
                        if (!validatePassword(data.get('clave'))) {
                            simpleAlerts('Error: La clave debe tener una longitud de 6 a 10 caracteres, debe tener al menos una mayúscula, un número y no debe tener espacios en blanco ni /, \\!', 'error');
                            return;
                        }
                    }

                    setOpenModalLoader(true);
                    setTitleSpinner('Guardando...');
                    try {
                        const result = await UserService.saveUsuario(url, data);
                        if (result.status === CODES.CREATE_201) {
                            simpleAlerts(result.data.message, 'success');
                            getUsuarios();
                            handleClose();
                            setInfoUser(null);
                        } else {
                            simpleAlerts(result?.response?.data?.message || 'Error al guardar el usuario', 'error');
                            setOpenModalLoader(false);
                            setTitleSpinner(null);
                        }
                        return [];
                    } catch (error) {
                        setOpenModalLoader(false);
                        setTitleSpinner(null);
                        console.log(`Error en getUsuarioAreas: ${error}`);
                    }
                });
            }
        })
    }

    const duplicateUser = async (e) => {
        Swal.fire({
            title: '¿Seguro de duplicar el usuario?',
            text: 'Se copiaran todos los accesos del usuario!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#28A745',
            cancelButtonColor: colors.secondary.hex,
            confirmButtonText: 'Si, duplicar'
        }).then((result) => {
            if (result.isConfirmed) {
                setOpenModalLoader(true);
                setTitleSpinner('Duplicando...');
                setTimeout(async () => {
                    try {
                        const result = await UserService.duplicateUsuario(url, infoUser);
                        if (result.status === CODES.SUCCESS_200) {
                            simpleAlerts(result?.data?.message, (+result?.data?.status === 1 ? 'success' : 'error'));
                            getUsuarios();
                        } else {
                            simpleAlerts('Error al guardar el usuario', 'error')
                        }
                        return [];
                    } catch (err) {
                        console.log(`Error en editUser ${err}`);
                    } finally {
                        setOpenModalLoader(false);
                        setTitleSpinner(null);
                        cleanInfo();
                    }
                });
            }
        })
    }

    return (
        <>
            <MainModal
                open={open}
                handleClose={cleanInfo}
                formSubmit={registerUser}
                title={'Mantenimiento de Usuarios'}
                subTitle={'Creación y edición de usuarios'}
                bodyModal={
                    <>
                        <span className="badge text-bg-secondary">Tipo:</span>
                        {optTemp?.map((opt) => {
                            return (
                                <div key={opt.label}>
                                    <input type="radio" id={opt.label} name="typeUser" value={opt.value}
                                        checked={Number(infoUser?.isExternal) === opt.value}
                                        onChange={onChangeTypeUser}
                                    />
                                    <label htmlFor={opt.label} style={{ padding: '0.2rem 0.5rem' }}>{opt.label}</label><br />
                                </div>
                            )
                        })}

                        <InfoUser
                            setOpenModalLoader={setOpenModalLoader}
                            systems={systems}
                            infoUser={infoUser}
                            setInfoUser={setInfoUser}
                            profiles={profiles}
                            typeDocument={typeDocument}
                            setTitleSpinner={setTitleSpinner}
                        />
                    </>
                }
                buttonActions={[
                    <MainButton
                        key='btn-modal-user-save1'
                        title={'Guardar'}
                        typeStyle={'outlined'}
                        color={'primary'}
                        type={'submit'}
                    />
                ]}
            />
        </>
    );
};

export default AddUser;