import React, { useState, } from 'react';
import { ButtonGroup, IconButton, Box } from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { OrderedListOutlined, } from '@ant-design/icons/lib/icons';
import { colors } from '../../../../assets/colors';
import { CODES } from '../../../../utils/responseCodes';
import { simpleAlerts } from '../../../../utils/alerts';
import { sortDataListSimple } from '../../../../utils/function';
import ShiftService from '../../../../services/shift';
import Swal from 'sweetalert2';
import _ from 'lodash';

const columns = [
  { id: 'id_sede', label: 'Codigo', width: 50, align: 'left', },
  { id: 'desEmpresa', label: 'Empresa', width: 150, align: 'left', },
  { id: 'desCliente', label: 'Cliente', width: 150, align: 'center', },
  { id: 'desUnidad', label: 'Unidad', width: 150, align: 'center', },
  { id: 'desSede', label: 'Sede', width: 150, align: 'center', },
  { id: 'delete', label: 'Eliminar', width: 80, align: 'center', },
];

const BodyShiftSede = (props) => {
  const { setTitleSpinner, setOpenModalLoader, dataShiftSedes, setDataShiftSedes } = props;
  const url = "'" + window.location.pathname + "'";

  const deleteSede = async (info) => {
    let updDataShiftSedes = _.cloneDeep(dataShiftSedes);
    updDataShiftSedes = updDataShiftSedes.filter(e => e.id_sede !== info?.id_sede);

    Swal.fire({
      title: '¿Seguro de eliminar la relación?',
      text: 'Se perdera la relación turno - sede',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: colors.secondary.hex,
      cancelButtonColor: colors.primary.hex,
      confirmButtonText: 'Si, eliminar'
    }).then((result) => {
      if (result.isConfirmed) {
        setOpenModalLoader(true);
        setTitleSpinner('Eliminando...');
        setTimeout(async () => {
          try {
            const result = await ShiftService.deleteTurnoSedes(url, info);
            if (result.status === CODES.DELETE_204) {
              simpleAlerts("Relación eliminada", "success");
              setDataShiftSedes(updDataShiftSedes);
            }
            return [];
          } catch (error) {
            console.log(`Error en saveAssociateAreas: ${error}`);
          } finally {
            setOpenModalLoader(false);
            setTitleSpinner(null);
          }
        });
      }
    })
  };

  // /** inicio ordenamiento de columnas */
  const [sortConfigTable, setSortConfigTable] = useState({ key: null, direction: "ascending" });

  const handleSortTable = (key) => {
    let direction = "ascending";
    if (sortConfigTable && sortConfigTable.key === key && sortConfigTable.direction === "ascending") {
      direction = "descending";
    }
    setSortConfigTable({ key, direction });
  };

  const sortedDataShiftSedes = sortDataListSimple(dataShiftSedes, sortConfigTable);
  // /** fin ordenamiento de columnas */

  return (
    <>
      <Box className='page-body-main'>
        <Box className='page-body-table table-responsive-md'>
          <table className="table table-hover table-striped table-bordered">
            <thead className='sticky-top' style={{ backgroundColor: colors.quaternary.hex, color: '#ffff' }}>
              <tr>
                {columns.map((column) => (
                  <th
                    scope="col"
                    key={column.id}
                    style={{
                      textAlign: column.align,
                      width: column.width,
                      maxWidth: column.width,
                      cursor: 'pointer',
                    }}
                    onClick={() => handleSortTable(column.id)}
                  >
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <div style={{ marginRight: 'auto' }}>{column.label}</div>
                      <div><OrderedListOutlined style={{ paddingBottom: 2 }} /></div>
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="table-group-divider">
              {sortedDataShiftSedes?.map((sede, indexRow) => {

                return (
                  <tr key={"row" + indexRow}>
                    {columns.map((column, indexColumn) => {
                      const value = sede[column.id];
                      return (
                        <td key={'col' + indexColumn} align={column.align}>
                          {
                            column.id === 'delete' ?
                              <ButtonGroup
                                disableElevation
                                variant="contained"
                                aria-label="Disabled elevation buttons"
                              >
                                <IconButton aria-label="delete" size="small" title='Eliminar'
                                  onClick={() => { deleteSede(sede) }}
                                >
                                  <DeleteForeverIcon fontSize='small' color={'secondary'} />
                                </IconButton>
                              </ButtonGroup> :
                              value}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Box>
      </Box>
    </>
  );
}

export default BodyShiftSede;