import React, { } from 'react';
import { Grid, } from '@mui/material';
import MainSelect from '../../../General/MainSelect';
import MainInput from '../../../General/MainInput';
import MainInputInfo from '../../../General/MainInputInfo';


const InfoPlanning = (props) => {
    const { infoPlan, setInfoPlan, planningType, shift, } = props;

    return (
        <>
            <Grid container marginTop={'1rem'}>
                <Grid item xs={4}>
                    <MainInputInfo
                        title={'Código'}
                        value={infoPlan?.codSghh}
                    />
                </Grid>
                <Grid item xs={8}>
                    <MainInputInfo
                        title={'Trabajador'}
                        value={infoPlan?.trabajador}
                    />
                </Grid>
            </Grid>
            <Grid container marginTop={'1rem'}>
                <Grid item xs={4}>
                    <MainInputInfo
                        title={'Fecha'}
                        value={infoPlan?.fecha}
                    />
                </Grid>
                <Grid item xs={4}>
                    <MainSelect
                        title={'Turno'}
                        placeholder={'Selecionar turno'}
                        options={shift}
                        value={Number(infoPlan?.turno_id)}
                        onChange={(value, e) => { setInfoPlan({ ...infoPlan, turno_id: value }) }}
                        name='turno'
                    />
                </Grid>
                <Grid item xs={4}>
                    <MainSelect
                        title={'Tipo planificación'}
                        placeholder={'Selecionar tipo'}
                        options={planningType}
                        value={Number(infoPlan?.tipPla_id)}
                        onChange={(value, e) => { setInfoPlan({ ...infoPlan, tipPla_id: value }) }}
                        name='tipPla'
                    />
                </Grid>
            </Grid>
        </>
    );
};

export default InfoPlanning;