import React, { } from 'react';
import { ButtonGroup, IconButton, Box } from '@mui/material';
import MainPagination from '../../General/MainPagination'
import { colors } from '../../../../assets/colors';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
const columns = [
  { id: 'id_persona', label: 'Cód.', width: 40, align: 'left', },
  { id: 'numdoc', label: 'Documento', width: 100, align: 'left', },
  { id: 'nombres', label: 'Nombres', width: 120, align: 'left', },
  { id: 'apePaterno', label: 'Apellido Paterno', width: 120, align: 'left', },
  { id: 'apeMaterno', label: 'Apellido Materno', width: 120, align: 'left', },
  { id: 'actions', label: 'Detalle', width: 80, align: 'center', },
];

const BodyStaffs = (props) => {
  const { dataStaffs, totalPagesStaff, pageStaff, setPageStaff, setInfoStaff, handleOpen } = props;

  return (
    <Box className='page-body-main'>
      <Box className='page-body-table table-responsive-md'>
        <table className="table table-hover table-striped table-bordered">
          <thead className='sticky-top' style={{ backgroundColor: colors.quaternary.hex, color: '#ffff' }}>
            <tr>
              {columns.map((column) => (
                <th
                  scope="col"
                  key={column.id}
                  style={{
                    textAlign: column.align,
                    width: column.width,
                    maxWidth: column.width
                  }}
                >
                  {column.label}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="table-group-divider">
            {dataStaffs?.map((user, indexRow) => {
              return (
                <tr key={"row" + indexRow}>
                  {columns.map((column, indexColumn) => {
                    const value = user[column.id];
                    return (
                      <td key={'col' + indexColumn} align={column.align}>
                        {column.id === 'nombres' ?
                          (user?.nombres) :
                          column.id === 'actions' ?
                            <ButtonGroup
                              disableElevation
                              variant="contained"
                              aria-label="Disabled elevation buttons"
                            >
                              <IconButton aria-label="delete" size="small" title='Ver detalle'
                                onClick={() => { setInfoStaff(user); handleOpen() }}
                              >
                                <RemoveRedEyeIcon fontSize='small' color={'primary'} />
                              </IconButton>
                            </ButtonGroup> :
                            value}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </Box>

      {totalPagesStaff > 1 &&
        <MainPagination
          size={'small'}
          totalPages={totalPagesStaff}
          page={pageStaff}
          setPage={setPageStaff}
        />
      }
    </Box>
  );
}

export default BodyStaffs;