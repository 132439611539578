import axios from "axios";
import { Global } from '../utils/general';
import headerRequest from "../utils/headers"

const API_URL = Global.url;
const SYS = Global.system;

class ProfileService {
    getPerfiles() {
        let ruta = `${API_URL}/profiles`;
        return axios
            .get(ruta, {
                headers: headerRequest(),
            })
            .catch((error) => {
                return error;
            });
    }

    getPantallasByPerfil(sys, url, id) {
        let ruta = `${API_URL}/profiles/screen/${id}?sys=${sys}&url=${url}`;
        return axios.get(ruta, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }

    savePerfil(url, body) {
        let ruta = `${API_URL}/profile?sys=${SYS}&url=${url}`;
        return axios
            .post(ruta, body, {
                headers: headerRequest(),
            })
            .catch((error) => {
                return error;
            });
    }

    deletePerfilPantalla(url, perfil_id, pantalla_id) {
        let ruta = `${API_URL}/profile-screen?sys=${SYS}&url=${url}&perfil_id=${perfil_id}&pantalla_id=${pantalla_id}`;
        return axios.delete(ruta, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }

    savePerfilPantalla(url, body) {
        let ruta = `${API_URL}/profile-screen?sys=${SYS}&url=${url}`;
        return axios
            .post(ruta, body, {
                headers: headerRequest(),
            })
            .catch((error) => {
                return error;
            });
    }
}

export default new ProfileService();
