import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import { CODES } from "../../../utils/responseCodes";
import { simpleAlerts } from "../../../utils/alerts";
import SpinnerLoader from "../../components/SpinnerLoad/SpinnerLoader";
import MainInput from "../../components/General/MainInput";
import ReportService from "../../../services/report";
import { getDateOL } from "../../../utils/function";
import GroupingSede from "../../components/GroupingSede";
import ComplementaryService from "../../../services/complementary";


const TecnologiaInformacion = ({ userToken }) => {
  const [openModalLoader, setOpenModalLoader] = useState(false);
  const [titleModalLoader, setTitleModalLoader] = useState(null);

  const [fechaAP, setFechaAP] = useState(getDateOL());
  const [infoCampus, setInfoCampus] = useState(null);
  const [infoWorkers, setInfoWorkers] = useState(null);
 



  useEffect(() => {
    if (infoCampus) {
      setInfoWorkers([]);
    }
  }, [infoCampus]);

  

  const AsistencaPlanificacion = async () => {
    setTitleModalLoader("Ejecutando procedimientos...");
    setOpenModalLoader(true);
    try {
      const result = await ComplementaryService.AsistencaPlanificacion(fechaAP);
      if (result.status === CODES.SUCCESS_200) {
        simpleAlerts(result.data.message, 'success');
        setOpenModalLoader(false);
        setTitleModalLoader(null);
      } else {
        simpleAlerts("No se puedo ejecutar el procedimiento", "error");
      }
      return [];
    } catch (error) {
      console.log(`Error en AsistencaPlanificacion: ${error}`);
    }
  };

  const SincronizadorTrabajador = async () => {
    try {
        const result = await ComplementaryService.SincronizadorTrabajador();
        if (result && result.status === CODES.SUCCESS_200) {
            if (result.data.message.includes('El JOB está en ejecución')) {
                simpleAlerts(result.data.message, 'warning');
            } else {
                simpleAlerts(result.data.message, 'success');
            }
        } else if (result && result.data && result.data.error) {
            simpleAlerts(result.data.error, 'error');
        } else {
            simpleAlerts('No se pudo ejecutar el procedimiento', 'error');
        }

        return result && result.data; // Puedes devolver datos adicionales si es necesario
    } catch (error) {
        console.log(`Error en SincronizadorTrabajador: ${error}`);
    } finally {
        // ...
    }
};

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid padding={2} item xs={10} sm={12} md={8}>
          <div
            style={{
              fontSize: "1.5rem",
              fontWeight: "bold",
              marginBottom: "1rem",
              marginTop: "2rem",

            }}
          >
            SOLUCIONAR ASISTENCIA Y PLANIFICACIÓN
          </div>
          <Grid container>
            <Grid item xs={12} sm={6} md={4}>
              <MainInput
                title={"Fecha Unica"}
                placeholder={"Ingrese Fecha"}
                type={"date"}
                value={fechaAP}
                onChange={(e) => setFechaAP(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2} style={{ marginTop: "1.3%" }}>
              <button
                type="button"
                className="btn btn-warning"
                onClick={AsistencaPlanificacion}
              >
                SOLUCIONAR
              </button>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <textarea
                value={"INSERTA ASISTENCIA Y PLANIFICACION"}
                readOnly
                style={{
                  marginTop: "6px",
                  border: "1px solid #ccc",
                  padding: "5px",
                  width: "100%",
                  resize: "none",
                }}
              />
            </Grid>
          </Grid>
          <div
            style={{ marginTop: "2rem", borderBottom: "1.5px solid #000000" }}
          ></div>
          <div
            style={{
              fontSize: "1.5rem",
              fontWeight: "bold",
              marginBottom: "1rem",
              marginTop: "2rem",
            }}
          >
            SINCRONIZADOR TRABAJADORES
          </div>
          <Grid container>
  
            <Grid item xs={12} sm={6} md={6} style={{ marginTop: "1.3%" }}>
              <button
                  type="button"
                  className="btn btn-info"
                  onClick={SincronizadorTrabajador}
                >
                SINCRONIZAR TRABAJADORES
              </button>
          
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <textarea
                value={"SINCRONIZA LOS TRABAJADORES"}
                readOnly
                style={{
                  marginTop: "6px",
                  border: "1px solid #ccc",
                  padding: "5px",
                  width: "100%",
                  resize: "none",
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <SpinnerLoader open={openModalLoader} title={titleModalLoader} />
      </Grid>
    </Box>
  );
};
export default TecnologiaInformacion;
