import React, { useState, } from 'react';
import MainModal from '../../../../components/General/MainModal';
import MainButton from '../../../../components/General/MainButton';
import AddWorker from '../../../../components/Productivity/Edition/AddWorker';

const AddEdition = (props) => {
    const { open, handleClose, infoCampus, infoArea, valueActivities, valueShift, shift, dateInit,
        dataProductivity, setDataProductivity, } = props;

    const [newWorkers, setNewWorkers] = useState([]);

    const cleanInfo = () => {
        return setNewWorkers([]);
    }

    const acceptAssociation = () => {
        return setDataProductivity([...dataProductivity, ...newWorkers]), handleClose();
    }

    return (
        <>
            <MainModal
                open={open}
                handleOpen={cleanInfo}
                handleClose={() => { handleClose(); }}
                title={'Edición de productividad'}
                subTitle={'Agregar trabajador'}
                bodyModal={
                    <AddWorker
                        infoCampus={infoCampus}
                        infoArea={infoArea}
                        valueActivities={valueActivities}
                        valueShift={valueShift}
                        shift={shift}
                        dateInit={dateInit}

                        newWorkers={newWorkers}
                        setNewWorkers={setNewWorkers}
                    />
                }
                buttonActions={[
                    <MainButton
                        key='btn-modal-user-save1'
                        title={'Aceptar'}
                        color={'success'}
                        onClick={acceptAssociation}
                    />
                ]}
            />
        </>
    );
};

export default AddEdition;