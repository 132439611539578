import React, { useEffect, useState } from 'react';
import { Box, Grid, } from '@mui/material';
import BodyProductivityLock from '../../components/Productivity/Lock/BodyProductivityLock';
import MainButton from '../../components/General/MainButton';
import UserService from '../../../services/user';
import ProductivityService from '../../../services/productivity';
import { CODES } from '../../../utils/responseCodes';
import { simpleAlerts } from '../../../utils/alerts';
import SpinnerLoader from '../../components/SpinnerLoad/SpinnerLoader';
import MainInput from '../../components/General/MainInput';
import { getDateOL } from '../../../utils/function';
import GroupingSede from '../../components/GroupingSede';

const ProductivityLock = ({ userToken }) => {
    const url = "'" + window.location.pathname + "'";
    const id_usuarioLogin = userToken.user?.id_usuario;
    const [openModalLoader, setOpenModalLoader] = useState(true);
    const [titleSpinner, setTitleSpinner] = useState(null);

    //* inicio variables para el agrupamiento */
    const [empresas, setEmpresas] = useState([]);
    const [clientes, setClientes] = useState([]);
    const [unidades, setUnidades] = useState([]);
    const [sedes, setSedes] = useState([]);
    //const [areas, setAreas] = useState([]);

    const [infoCompany, setInfoCompany] = useState(null);
    const [infoClient, setInfoClient] = useState(null);
    const [infoUnit, setInfoUnit] = useState(null);
    const [infoCampus, setInfoCampus] = useState(null);
    //const [infoArea, setInfoArea] = useState(null);

    //* fin variables para el agrupamiento */

    const [dataProductivity, setDataProductivity] = useState(null);
    const [dataBloqueada, setDataBloqueada] = useState([]);
    const [dateInit, setDateInit] = useState(getDateOL());
    const [dateEnd, setDateEnd] = useState(getDateOL('', 'short', 7));

    useEffect(() => {
        init();
    }, [])

    const init = async () => {
        Promise.all([
            getAgrupamientoByUsuario(),
        ]).then(() => setOpenModalLoader(false));
    }

    //* inicio limpieza de datos
    useEffect(() => {
        setInfoClient(null);
        setInfoUnit(null);
        setInfoCampus(null);
        setDataProductivity(null);
    }, [infoCompany]);

    useEffect(() => {
        setInfoUnit(null);
        setInfoCampus(null);
        setDataProductivity(null);
    }, [infoClient]);

    useEffect(() => {
        setInfoCampus(null);
        setDataProductivity(null);
    }, [infoUnit]);

    useEffect(() => {
        setDataProductivity(null);
    }, [infoCampus, dateInit, dateEnd]);

    const getAgrupamientoByUsuario = async () => {
        try {
            const result = await UserService.getAgrupamientoByUsuario(url, id_usuarioLogin);
            if (result.status === CODES.SUCCESS_200) {
                if (result.data.areas.length === 0)
                    simpleAlerts('No tiene areas asignadas', "warning");
                setEmpresas(result.data.empresas);
                setClientes(result.data.clientes);
                setUnidades(result.data.unidades);
                setSedes(result.data.sedes);
                //setAreas(result.data.areas);
            }
        } catch (error) {
            console.log(`Error en getAgrupamientoByUsuario: ${error}`);
        }
    }

    const getProductividadBySede = async () => {
        if (!infoCampus || infoCampus?.value === 0) {
            simpleAlerts('Debe seleccionar una sede!', 'warning');
            return;
        }
        if (!dateInit || dateInit === '') {
            simpleAlerts('Debe seleccionar una fecha de inicial!', 'warning');
            return;
        }
        if (!dateEnd || dateEnd === '') {
            simpleAlerts('Debe seleccionar una fecha de final!', 'warning');
            return;
        }
        try {
            setOpenModalLoader(true);
            setTitleSpinner('Buscando ...');
            const result = await ProductivityService.getProductividadBySede(url, infoCampus?.value, dateInit, dateEnd);
            if (result.status === CODES.SUCCESS_200) {
                setDataProductivity(result.data);
            }
        } catch (error) {
            console.log(`Error en getProductividadBySede: ${error}`);
        } finally {
            setOpenModalLoader(false);
            setTitleSpinner(null);
        }
    }

    const saveBloquearProductividad = async (lock) => {
        if (!infoCampus || infoCampus?.value === 0) {
            simpleAlerts('Debe seleccionar una sede!', 'warning');
            return;
        }
        if (!dateInit || dateInit === '') {
            simpleAlerts('Debe seleccionar una fecha de inicial!', 'warning');
            return;
        }
        if (!dateEnd || dateEnd === '') {
            simpleAlerts('Debe seleccionar una fecha de final!', 'warning');
            return;
        }
        if (!dataBloqueada || dataBloqueada?.length === 0) {
            simpleAlerts('No selecciono ninguna productividad!', 'error');
            return;
        }

        try {
            setOpenModalLoader(true);
            setTitleSpinner('Guardando productividad ...');
            const savePromises = dataBloqueada.map(async (data) => {
                data.bloqueoProd = lock;
                try {
                    const result = await ProductivityService.saveBloquearProductividad(url, data);
                    if (result.status !== CODES.CREATE_201) {
                        simpleAlerts('No se actualizo productividad.', 'error');
                    }
                } catch (error) {
                    console.log(`Error en saveBloquearProductividad: ${error}`);
                }
            });

            await Promise.all([...savePromises,]);

            simpleAlerts("Fin de actualizaciones", "success");
            getProductividadBySede();
        } catch (error) {
            console.log(`Error en saveAssociateAreas: ${error}`);
        }
    };


    return (
        <Box sx={{ flexGrow: 1 }} >
            <Grid container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={1}
                p={1}
            >
                <Grid container spacing={0} justifyContent={'center'} alignItems={'center'} pt={1}>
                    <Grid item xs={12} sm={10} md={8}
                        sx={{
                           
                            borderRadius: '25px',
                            paddingBottom: 1,
                        }}
                    >
                        <GroupingSede 
                            infoCompany={infoCompany} setInfoCompany={setInfoCompany}
                            infoClient={infoClient} setInfoClient={setInfoClient}
                            infoUnit={infoUnit} setInfoUnit={setInfoUnit}
                            infoCampus={infoCampus} setInfoCampus={setInfoCampus}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Grid container spacing={2}
                        direction="row"
                        justifyContent="center"
                        alignItems="end"
                    >
                        <Grid item xs={6}>
                            <MainInput
                                title={'Fecha'}
                                placeholder={'Ingrese Fecha Inicio'}
                                type={'date'}
                                value={dateInit}
                                onChange={(e) => setDateInit(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <MainInput
                                title={'Fecha'}
                                placeholder={'Ingrese Fecha Fin'}
                                type={'date'}
                                value={dateEnd}
                                onChange={(e) => setDateEnd(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <MainButton
                                onClick={getProductividadBySede}
                                title={'Cargar'}
                                typeStyle={'outlined'}
                            />
                            <MainButton
                                onClick={(e) => saveBloquearProductividad(1)}
                                title={'Bloquear'}
                                color={'secondary'}
                            />
                            <MainButton
                                onClick={(e) => saveBloquearProductividad(0)}
                                title={'Aperturar'}
                                color={'info'}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container
                direction="row"
                justifyContent="center"
                alignItems="center"
            >
                <Grid item xs={12} sm={8} paddingLeft={'0.2rem'} paddingRight={'0.2rem'}>
                    <BodyProductivityLock
                        id_usuarioLogin={id_usuarioLogin}
                        dataProductivity={dataProductivity}
                        dataBloqueada={dataBloqueada}
                        setDataBloqueada={setDataBloqueada}
                    />
                </Grid>
            </Grid>

            <SpinnerLoader open={openModalLoader} title={titleSpinner} />
        </Box>
    );
};

export default ProductivityLock;