import axios from "axios";
import { Global } from '../utils/general';
import headerRequest from "../utils/headers"

const API_URL = Global.url;
class ReportService {
    exportRegionExcel(dateInit, dateEnd, id_region, type) {
        let ruta = `${API_URL}/report/region?dateInit=${dateInit}&dateEnd=${dateEnd}&id_region=${id_region}&type=${type}`;
        return ruta;
    }

    exportSedeExcel(dateInit, dateEnd, id_sede, type) {
        let ruta = `${API_URL}/report/sede?dateInit=${dateInit}&dateEnd=${dateEnd}&id_sede=${id_sede}&type=${type}`;
        return ruta;
    }

    exportTramaSctrExcel(infoEmpresa, infoCliente, infoUnidad, infoSede, dateInit, dateEnd, id_usuarioLogin, masivo) {
        let ruta = `${API_URL}/report/trama-sctr?rucEmpresa=${infoEmpresa}&rucCliente=${infoCliente}&pnCodUnidad=${infoUnidad}&pnCodSede=${infoSede}&dateInit=${dateInit}&dateEnd=${dateEnd}&id_usuarioLogin=${id_usuarioLogin}&masivo=${masivo}`;
        return ruta;
    }

    exportTramaVidaExcel(infoEmpresa, infoCliente, infoUnidad, infoSede, dateInit, dateEnd, id_usuarioLogin, usuario, masivo) {
        let ruta = `${API_URL}/report/trama-vida-ley?rucEmpresa=${infoEmpresa}&rucCliente=${infoCliente}&pnCodUnidad=${infoUnidad}&pnCodSede=${infoSede}&dateInit=${dateInit}&dateEnd=${dateEnd}&id_usuarioLogin=${id_usuarioLogin}&usuario=${usuario}&masivo=${masivo}`;
        return ruta;
    }

    generateSede(dateInit, dateEnd, id_sede, type) {
        const ruta = `${API_URL}/report/sede?dateInit=${dateInit}&dateEnd=${dateEnd}&id_sede=${id_sede}&type=${type}`;
        return axios.post(ruta, null, {
            headers: headerRequest(),
            responseType: 'blob'
        }).catch((error) => {
            return error;
        });
    }

    generateRegion(dateInit, dateEnd, id_region, type) {
        const ruta = `${API_URL}/report/region?dateInit=${dateInit}&dateEnd=${dateEnd}&id_region=${id_region}&type=${type}`;
        return axios.post(ruta, null, {
            headers: headerRequest(),
            responseType: 'blob'
        }).catch((error) => {
            return error;
        });
    }

    generateActividad(dateInit, dateEnd, id_sede) {
        const ruta = `${API_URL}/report/actividad?dateInit=${dateInit}&dateEnd=${dateEnd}&id_sede=${id_sede}`;
        return axios.post(ruta, null, {
            headers: headerRequest(),
            responseType: 'blob'
        }).catch((error) => {
            return error;
        });
    }

    exportPersonaTareosExcel(dateInit, dateEnd, id_sede, codSghh) {
        const ruta = `${API_URL}/report/persona-tareos?dateInit=${dateInit}&dateEnd=${dateEnd}&id_sede=${id_sede}&codSghh=${codSghh}`;
        return axios.post(ruta, null, {
            headers: headerRequest(),
            responseType: 'blob'
        }).catch((error) => {
            return error;
        });
    }

    generateAsistenciaDetalladaPDF(dateInit, dateEnd, id_area, desArea, desEmpresa, rucEmpresa) {
        const ruta = `${API_URL}/report/asistencia-detallada?dateInit=${dateInit}&dateEnd=${dateEnd}&id_area=${id_area}&desArea=${desArea}&desEmpresa=${desEmpresa}&rucEmpresa=${rucEmpresa}`;
        return axios.post(ruta, null, {
            headers: headerRequest(),
            responseType: 'blob'
        }).catch((error) => {
            return error;
        });
    }

    exportReportMarksBySedeExcel(dateInit, dateEnd, id_sede, desSede) {
        const ruta = `${API_URL}/report/marks/sede?dateInit=${dateInit}&dateEnd=${dateEnd}&id_sede=${id_sede}&desSede=${desSede}`;
        return axios.post(ruta, null, {
            headers: headerRequest(),
            responseType: 'blob'
        }).catch((error) => {
            return error;
        });
    }

    exportReportMarksByRegionExcel(dateInit, dateEnd, id_region, desRegion) {
        const ruta = `${API_URL}/report/marks/region?dateInit=${dateInit}&dateEnd=${dateEnd}&id_region=${id_region}&desRegion=${desRegion}`;
        return axios.post(ruta, null, {
            headers: headerRequest(),
            responseType: 'blob'
        }).catch((error) => {
            return error;
        });
    }

    exportPersonaActividadExcel(dateInit, dateEnd,id_sede,personaId) {
        const ruta = `${API_URL}/report/persona-actividad?dateInit=${dateInit}&dateEnd=${dateEnd}&id_sede=${id_sede}&id_persona=${personaId}`;
        return axios.post(ruta, null, {
            headers: headerRequest(),
            responseType: 'blob'
        }).catch((error) => {
            return error;
        });
    }

}

export default new ReportService();
