import React, { useState, useEffect } from 'react';
import { OrderedListOutlined, } from '@ant-design/icons/lib/icons';
import { Box, } from '@mui/material';
import MainInput from '../../General/MainInput';
import ManageService from '../../../../services/manage';
import JobImg from '../../../../assets/icons/proceso.png';
import { colors } from '../../../../assets/colors';
import { filterData, sortDataListSimple, formatDate } from '../../../../utils/function';
import { simpleAlerts } from '../../../../utils/alerts';
import { CODES } from '../../../../utils/responseCodes';

const columns = [
  { id: '#', label: '#', width: 50, align: 'center', }, //id_tareosExportados
  { id: 'desEmpresa', label: 'Empresa', width: 150, align: 'left', },
  { id: 'desCliente', label: 'Cliente', width: 150, align: 'left', },
  { id: 'desUnidad', label: 'Unidad', width: 150, align: 'left', },
  { id: 'desSede', label: 'Sede', width: 150, align: 'left', },
  { id: 'estado', label: 'Estado', width: 100, align: 'left', },
  { id: 'fechaDesde', label: 'Desde', width: 100, align: 'left', },
  { id: 'fechaHasta', label: 'Hasta', width: 100, align: 'left', },
  { id: 'fechaCierre', label: 'Programa', width: 120, align: 'left', format: (value) => formatDate(new Date(value)) },
  { id: 'usuario', label: 'Usuario', width: 120, align: 'left', },
];

const columnsJobs = [
  { id: 'name', label: 'Job', width: 100, align: 'center', },
  { id: 'next_run_date', label: 'Fecha', width: 100, align: 'center', format: (value) => (value?.substring(0, 4) + '-' + value?.substring(4, 6) + '-' + value?.substring(6, value?.length)) },
  { id: 'next_run_time', label: 'Hora', width: 100, align: 'center', format: (value) => (value?.slice(0, value?.length > 5 ? 2 : 1) + ':' + value?.slice(value?.length > 5 ? 2 : 1, value?.length > 5 ? 4 : 3) + ':' + value?.slice(value?.length > 5 ? 4 : 3, value?.length > 5 ? 6 : 5)) },
];

const SeeClosures = (props) => {
  const { setOpenModalLoader, getSeeClosing, dataClosures, setDataClosures, infoCompany } = props;

  const url = "'" + window.location.pathname + "'";

  const [dataJobs, setDataJobs] = useState(null);

  const [search, setSearch] = useState('');
  let resultSearch = filterData(dataClosures, search, ['desEmpresa', 'desCliente', 'desUnidad', 'desSede', 'estado', 'usuario']);
  /** inicio ordenamiento de columnas */
  const [sortConfigTable, setSortConfigTable] = useState({ key: null, direction: "ascending", });
  const handleSortTable = (key) => {
    let direction = "ascending";
    if (sortConfigTable && sortConfigTable.key === key && sortConfigTable.direction === "ascending") {
      direction = "descending";
    }
    setSortConfigTable({ key, direction });
  };
  const sortedData = sortDataListSimple(resultSearch, sortConfigTable); //sortDataListSimple(dataWorkers, sortConfigTable);
  /** fin ordenamiento de columnas */

  useEffect(() => {
    init();
  }, [])

  useEffect(() => {
    setDataClosures(null);
  }, [infoCompany])

  const init = async () => {
    setOpenModalLoader(true);
    const promisesResult = await Promise.all([getSeeClosing(), getSeeJobs()]);
    setOpenModalLoader(false);
  }

  const getSeeJobs = async () => {
    try {
      setDataJobs(null);
      const result = await ManageService.getSeeJobs(url);
      if (result.status === CODES.SUCCESS_200) {
        setDataJobs(result.data);
      }
    } catch (error) {
      console.log(`Error en getSeeJobs: ${error}`);
    }
  }

  /** */
  const [mostrarJob, setMostrarJob] = useState(false);
  const toggleJob = () => { getSeeJobs(); setMostrarJob(!mostrarJob); }

  return (
    <>
      <MainInput
        placeholder={'Busqueda Empresa / Cliente / Unidad / Sede / Estado / Usuario'}
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />
      <Box className='page-body-main' mt={1} position="relative">
        <div className="circle" onClick={toggleJob}
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', }}
        >
          <img src={JobImg} alt='Imagen job' width={23} />
        </div>
        {mostrarJob &&
          <div className="mensaje">
            <table className="table table-hover table-striped table-bordered table-sm">
              <thead className='sticky-top' style={{ backgroundColor: '#633974', color: '#ffff' }}>
                <tr>
                  {columnsJobs.map((col) => (
                    <th
                      scope="col"
                      key={col.id}
                      style={{
                        textAlign: col.align,
                        width: col.width,
                        maxWidth: col.width,
                      }}
                    >
                      {col.label}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody style={{ fontSize: '12px' }}>
                {dataJobs?.map((row, indexRow) => {
                  return (
                    <tr key={"row" + indexRow}>
                      {columnsJobs.map((column, indexColumn) => {
                        const value = row[column.id];
                        return (
                          <td key={'col' + indexColumn}
                            style={{
                              textAlign: column.align,
                              width: column.width,
                              maxWidth: column.width,
                            }}
                          >
                            {column.id === '#' ?
                              (indexRow + 1)
                              : column.format ?
                                column.format(value)
                                : value}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>}
        <Box className='page-body-table table-responsive-md' sx={{ height: "25rem", }}>
          <table className="table table-hover table-striped table-bordered table-sm">
            <thead className='sticky-top' style={{ backgroundColor: colors.quaternary.hex, color: '#ffff' }}>
              <tr>
                {columns.map((column) => (
                  <th
                    scope="col"
                    key={column.id}
                    style={{
                      textAlign: column.align,
                      width: column.width,
                      maxWidth: column.width,
                      cursor: 'pointer',
                    }}
                    onClick={() => handleSortTable(column.id)}
                  >
                    {<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <div style={{ marginRight: 'auto' }}>{column.label}</div>
                      {(column.id !== '#') && <div><OrderedListOutlined style={{ paddingBottom: 2 }} /></div>}
                    </div>}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="table-group-divider" style={{ fontSize: '12px' }}>
              {sortedData?.map((sede, indexRow) => {
                return (
                  <tr key={"row" + indexRow}>
                    {columns.map((column, indexColumn) => {
                      const value = sede[column.id];
                      return (
                        <td key={'col' + indexColumn}
                          style={{
                            textAlign: column.align,
                            width: column.width,
                            maxWidth: column.width,
                          }}
                        >
                          {column.id === '#' ?
                            (indexRow + 1)
                            : column.format ?
                              column.format(value)
                              : value}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Box>
      </Box>
    </>
  );
}

export default SeeClosures;