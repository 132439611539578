import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import { CODES } from "../../../utils/responseCodes";
import { simpleAlerts } from "../../../utils/alerts";
import SpinnerLoader from "../../components/SpinnerLoad/SpinnerLoader";
import MainInput from "../../components/General/MainInput";
import ReportService from "../../../services/report";
import { getDateOL } from "../../../utils/function";
import GroupingSede from "../../components/GroupingSede";
import ComplementaryService from "../../../services/complementary";
import { Select } from "antd";
const { Option } = Select;

const Contabilidad = ({ userToken }) => {
  const [openModalLoader, setOpenModalLoader] = useState(false);
  const [titleModalLoader, setTitleModalLoader] = useState(null);
  const [jobEjecutandose, setJobEjecutandose] = useState(false);
  const [campus, setCampus] = useState(null);

  const [dateInit, setDateInit] = useState(getDateOL());
  const [dateEnd, setDateEnd] = useState(getDateOL("", "short", 7));
  const [fechaAP, setFechaAP] = useState(getDateOL());
  const [selectedOption, setSelectedOption] = useState("OLA");

  const [infoCampus, setInfoCampus] = useState(null);

  const [infoWorkers, setInfoWorkers] = useState(null);
  const [dataWorkers, setDataWorkers] = useState(null);

  const [fechaPlanilla, setfechaPlanilla] = useState(getDateOL());



  useEffect(() => {
    if (infoCampus) {
      setInfoWorkers([]);
    }
  }, [infoCampus]);

  const exportPlanilla = async () => {
    try {
      setTitleModalLoader("Generando Reporte de Planilla...");
      setOpenModalLoader(true);
      const result = await ComplementaryService.exportPlanilla();
      if (result.status === CODES.SUCCESS_200) {
        const blob = new Blob([result.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = "REPORTE PLANILLA" + "_" + selectedOption + "_" + fechaAP + ".xlsx";
        link.click();
        URL.revokeObjectURL(url);
        //getPeople();
        setOpenModalLoader(false);
      } else {
        setOpenModalLoader(false);
        setTitleModalLoader(null);
      }
      return [];
    } catch (error) {
      console.log(`Error en exportPlanilla: ${error}`);
    }

  };

  /*const exportExcelPlanilla = async () => {
    setTitleModalLoader("Ejecutando procedimientos...");
    setOpenModalLoader(true);
    try {
      const result = await ComplementaryService.exportExcelPlanilla(fechaPlanilla);
      if (result.status === CODES.SUCCESS_200) {
        simpleAlerts(result.data.message, 'success');
        setOpenModalLoader(false);
        setTitleModalLoader(null);
      } else {
        simpleAlerts("No se puedo ejecutar el procedimiento", "error");
      }
      return [];
    } catch (error) {
      console.log(`Error en AsistencaPlanificacion: ${error}`);
    }
  };*/


  const exportExcelPlanilla = async () => {
    try {
      setTitleModalLoader("Generando Reporte de Planilla...");
      setOpenModalLoader(true);
      const result = await ComplementaryService.exportExcelPlanilla(fechaPlanilla);
      if (result.status === CODES.SUCCESS_200) {
        const blob = new Blob([result.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download ="REPORTE PLANILLA_"+fechaPlanilla+".xlsx";
        link.click();
        URL.revokeObjectURL(url);
        //getPeople();
        setOpenModalLoader(false);
      } else {
        setOpenModalLoader(false);
        setTitleModalLoader(null);
      }
      return [];
    } catch (error) {
      console.log(`Error en exportExcelPlanilla: ${error}`);
    }
  
};


  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid padding={2} item xs={10} sm={12} md={8}>
          <div
            style={{
              fontSize: "1.5rem",
              fontWeight: "bold",
              marginBottom: "1rem",
              marginTop: "2rem",

            }}
          >
            EXPORTAR PLANILLA OLA/OLP
          </div>
          <Grid container>
            {/*<Grid item xs={12} sm={6} md={4}>
              <MainInput
                title={"Fecha Unica"}
                placeholder={"Ingrese Fecha"}
                type={"date"}
                value={fechaAP}
                onChange={(e) => setFechaAP(e.target.value)}
                readOnly={true}
              />
          </Grid>*/}
            <Grid item xs={12} sm={6} md={4} paddingRight={'1rem'}>
              <div>Seleccionar</div>
              <Select
                mode={"single"}
                className="select"
                size={'middle'}
                placeholder={'Seleccionar'}
                value={selectedOption}
                onChange={(value, e) => setSelectedOption(value)}
              >
                <Option value="OLA">OLA</Option>
                <Option value="OLP">OLP</Option>
              </Select>
            </Grid>
            <Grid item xs={12} sm={6} md={2} style={{ marginTop: "1.3%" }}>
              <button
                type="button"
                className="btn btn-warning"
                onClick={exportPlanilla}
              >
                Exportar Planilla
              </button>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <textarea
                value={"EXPORTA PLANILLA OLA / OLP"}
                readOnly
                style={{
                  marginTop: "6px",
                  border: "1px solid #ccc",
                  padding: "5px",
                  width: "100%",
                  resize: "none",
                }}
              />
            </Grid>
          </Grid>
          <div
            style={{ marginTop: "2rem", borderBottom: "1.5px solid #000000" }}
          ></div>
            <div
            style={{
              fontSize: "1.5rem",
              fontWeight: "bold",
              marginBottom: "1rem",
              marginTop: "2rem",
            }}
          >
            EXPORTAR  EXCEL PLANILLA OLA/OLP
          </div>
          <Grid container>
            <Grid item xs={12} sm={6} md={4} paddingRight={'1rem'}>
              <MainInput
                title={"Fecha Unica"}
                placeholder={"Ingrese Fecha"}
                type={"date"}
                value={fechaPlanilla}
                onChange={(e) => setfechaPlanilla(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2} style={{ marginTop: "1.3%" }}>
              <button
                type="button"
                className="btn btn-success"
                onClick={exportExcelPlanilla}
              >
                Exportar Planilla
              </button>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <textarea
                value={"EXPORTA EXCEL PLANILLA"}
                readOnly
                style={{
                  marginTop: "6px",
                  border: "1px solid #ccc",
                  padding: "5px",
                  width: "100%",
                  resize: "none",
                }}
              />
            </Grid>

          </Grid>
        </Grid>
        <SpinnerLoader open={openModalLoader} title={titleModalLoader} />
      </Grid>
    </Box>
  );
};
export default Contabilidad;
