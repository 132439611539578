import React, { useState, useEffect } from 'react';
import { Box, Grid, Tabs, Tab, } from '@mui/material';
import SpinnerLoader from '../../../components/SpinnerLoad/SpinnerLoader';
import MainInput from '../../../components/General/MainInput';
import MainSelect from '../../../components/General/MainSelect';
import MainButton from '../../../components/General/MainButton';
import BodyTrama from './Body/BodyTrama';
import AuxiliaryService from '../../../../services/auxiliary';
import ConsejeroService from '../../../../services/consejero';
import { CODES } from '../../../../utils/responseCodes';
import { simpleAlerts } from '../../../../utils/alerts';
import { colors } from '../../../../assets/colors';

const months =
    [
        { id: 1, label: 'Enero', value: 1 },
        { id: 2, label: 'Febrero', value: 2 },
        { id: 3, label: 'Marzo', value: 3 },
        { id: 4, label: 'Abril', value: 4 },
        { id: 5, label: 'Mayo', value: 5 },
        { id: 6, label: 'Junio', value: 6 },
        { id: 7, label: 'Julio', value: 7 },
        { id: 8, label: 'Agosto', value: 8 },
        { id: 9, label: 'Septiembre', value: 9 },
        { id: 10, label: 'Octubre', value: 10 },
        { id: 11, label: 'Noviembre', value: 11 },
        { id: 12, label: 'Diciembre', value: 12 },
    ];
const validationMessages = {
    infoSede: 'Debe seleccionar una sede!',
    year: 'Debe seleccionar un año correcto!',
    month: 'Debe seleccionar un mes!',
    data: 'Debe selecionar al menos un trabajador!',
};

const ReportTrama = ({ userToken }) => {
    const url = "'" + window.location.pathname + "'";
    const id_usuarioLogin = userToken.user.id_usuario;
    const usuario = userToken.user.usuario;
    const [openModalLoader, setOpenModalLoader] = useState(false);
    const [titleModalLoader, setTitleModalLoader] = useState('Carga inicial ...');

    const [empresas, setEmpresas] = useState(null);
    const [infoEmpresa, setInfoEmpresa] = useState(null);
    const [clientes, setClientes] = useState(null);
    const [infoCliente, setInfoCliente] = useState(null);
    const [unidades, setUnidades] = useState(null);
    const [infoUnidad, setInfoUnidad] = useState(null);
    const [sedes, setSedes] = useState(null);
    const [infoSede, setInfoSede] = useState(null);

    const [search, setSearch] = useState('');
    const [typeReport, setTypeReport] = useState(1);
    const [title, setTitle] = useState('Lista de trabajadores - ');
    const [dataWorker, setDataWorker] = useState(null);
    const [dataReport, setDataReport] = useState(null);
    const [year, setYear] = useState(new Date().getFullYear());
    const [month, setMonth] = useState(new Date().getMonth() + 1);
    const dateRep = `${year}-${(month).toString().padStart(2, '0')}-01`;
    const [view, setView] = useState(0);
    const handleChangeView = (event, newValue) => setView(newValue);

    useEffect(() => {
        Promise.all([
            getEmpresas()
        ]).then(() => setOpenModalLoader(false));
    }, []);
    useEffect(() => {
        setDataWorker(null);
        setDataReport(null);
        setClientes(null);
        setInfoCliente(null);
        setUnidades(null);
        setInfoUnidad(null);
        setSedes(null);
        setInfoSede(null);
        if (infoEmpresa) {
            getClientes();
        }
    }, [infoEmpresa]);
    useEffect(() => {
        setDataWorker(null);
        setDataReport(null);
        setUnidades(null);
        setInfoUnidad(null);
        setSedes(null);
        setInfoSede(null);
        if (infoCliente) {
            getUnidades();
        }
    }, [infoCliente]);
    useEffect(() => {
        setDataWorker(null);
        setDataReport(null);
        setSedes(null);
        setInfoSede(null);
        if (infoUnidad) {
            getSedes()
        }
    }, [infoUnidad]);
    useEffect(() => {
        setDataWorker(null);
        setDataReport(null);
    }, [typeReport, dateRep, view]);

    const getEmpresas = async () => {
        try {
            setEmpresas(null)
            const result = await AuxiliaryService.getEmpresas();
            if (result.status === CODES.SUCCESS_200) {
                result.data?.forEach(element => {
                    element.id = element.dcRucEmpresa
                    element.label = element.dcDesEmpresa
                    element.value = element.dcRucEmpresa
                })
                setEmpresas(result.data);
            }
            return []
        } catch (error) {
            console.log(`Error en getEmpresas: ${error}`);
        }
    }
    const getClientes = async () => {
        try {
            setOpenModalLoader(true);
            setTitleModalLoader(null);
            setClientes(null)
            const result = await AuxiliaryService.getClientes(infoEmpresa);
            if (result.status === CODES.SUCCESS_200) {
                result.data?.forEach(element => {
                    element.id = element.dcRucCliente
                    element.label = element.dcDesCliente
                    element.value = element.dcRucCliente
                })
                setClientes(result.data);
            }
            return []
        } catch (error) {
            console.log(`Error en getClientes: ${error}`);
        } finally {
            setOpenModalLoader(false);
        }
    }
    const getUnidades = async () => {
        try {
            setOpenModalLoader(true);
            setTitleModalLoader(null);
            setUnidades(null);
            const result = await AuxiliaryService.getUnidades(infoEmpresa, infoCliente);
            if (result.status === CODES.SUCCESS_200) {
                result.data?.forEach(element => {
                    element.id = element.pnCodUnidad
                    element.label = element.dcDesUnidad
                    element.value = element.pnCodUnidad
                })
                setUnidades(result.data);
            }
            return []
        } catch (error) {
            console.log(`Error en getUnidades: ${error}`);
        } finally {
            setOpenModalLoader(false);
        }
    }
    const getSedes = async () => {
        try {
            setOpenModalLoader(true);
            setTitleModalLoader(null);
            setSedes(null);
            const result = await AuxiliaryService.getSedes(infoEmpresa, infoCliente, infoUnidad);
            if (result.status === CODES.SUCCESS_200) {
                result.data?.forEach(element => {
                    element.id = element.pnCodSede
                    element.label = element.dcDesSede
                    element.value = element.pnCodSede
                })
                setSedes(result.data);
            }
            return []
        } catch (error) {
            console.log(`Error en getSedes: ${error}`);
        } finally {
            setOpenModalLoader(false);
        }
    }
    const generateBody = (typeWorker = 'X') => {
        let lstTrabajadores
        if (typeWorker?.toUpperCase() === 'X') {
            lstTrabajadores = dataReport;
        } else if (typeWorker?.toUpperCase() === 'O') {
            lstTrabajadores = dataReport?.filter(e => e.TipoTrab !== 'E');
        } else {
            lstTrabajadores = dataReport?.filter(e => e.TipoTrab === typeWorker);
        }
        
        let empresa = empresas?.find(e => e.id === infoEmpresa);
        let unidad = unidades?.find(e => e.pnCodUnidad === infoUnidad);
        let body = {
            trabajadores: lstTrabajadores, //dataReport,
            id_usuario: id_usuarioLogin,
            usuario: usuario,
            fecha: dateRep,
            unidad,
            empresa,
            tipoTrama: +unidad?.tipoTrama,
            tipoTrabajador: typeWorker || 'X'
        }
        return body;
    }

    return (
        <Box sx={{ flexGrow: 1 }} >
            <Grid container direction="row" justifyContent="center" alignItems="center" p={1}>
                <Grid item xs={11} sm={6} md={4}>
                    <MainSelect
                        title={'Empresa'}
                        options={empresas}
                        placeholder={'Selecionar Empresa'}
                        value={infoEmpresa}
                        onChange={(value, e) => { setInfoEmpresa(value); }}
                    />
                </Grid>
                <Grid item xs={11} sm={6} md={4}>
                    <MainSelect
                        title={'Cliente'}
                        options={clientes}
                        placeholder={'Selecionar Cliente'}
                        value={infoCliente}
                        onChange={(value, e) => { setInfoCliente(value); }}
                    />
                </Grid>
            </Grid>
            <Grid container direction="row" justifyContent="center" alignItems="center" p={1}>
                <Grid item xs={11} sm={6} md={4}>
                    <MainSelect
                        title={'Unidad'}
                        options={unidades}
                        placeholder={'Selecionar Unidad'}
                        value={infoUnidad}
                        onChange={(value, e) => { setInfoUnidad(value); }}
                    />
                </Grid>
                <Grid item xs={11} sm={6} md={4}>
                    <MainSelect
                        title={'Sede'}
                        options={sedes}
                        placeholder={'Selecionar Sede'}
                        value={infoSede}
                        onChange={(value, e) => { setInfoSede(value); }}
                    />
                </Grid>
            </Grid>
            <Grid container direction="row" justifyContent="center">
                <Grid item xs={11} sm={6} md={5}>
                    <fieldset className='fieldset'>
                        <legend className='title-legend'
                            style={{ fontSize: '15px', letterSpacing: '0.03rem', margin: '3px 0px 5px 20px', fontStyle: 'italic', }}
                        >{title}
                            <input
                                type='text'
                                placeholder='Buscar trabajador'
                                style={{
                                    backgroundColor: 'transparent',
                                    border: `solid 1px ${colors.primary.hex}`,
                                    borderRadius: 10,
                                    paddingLeft: 10,
                                    marginRight: 25,
                                    fontStyle: 'italic',
                                    width: '50%',
                                    float: 'right',
                                }}
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                            />
                        </legend>
                        <BodyTrama
                            dataWorker={dataWorker}
                            dataReport={dataReport}
                            setDataReport={setDataReport}
                            search={search}
                        />
                    </fieldset>
                </Grid>
                <Grid item xs={11} sm={6} md={3}>
                    <Box sx={{ width: '100%' }}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={view} onChange={handleChangeView} aria-label="basic tabs example" centered>
                                <Tab label="REPORTES" />
                                <Tab label="REIMPRESIÓN" />
                            </Tabs>
                        </Box>
                    </Box>
                    <Grid container direction={'column'} pt={5} spacing={3}>
                        <Grid item xs={6} md={3} sm={2}
                            style={{
                                display: 'grid',
                                gridTemplateColumns: '1fr',
                                gap: '5px',
                                justifyItems: 'center',
                            }}
                        >
                            <span className="badge text-bg-secondary" style={{ fontWeight: 'bold', letterSpacing: 1 }}>Tipo de reporte:</span>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'space-evenly'
                                }}
                            >
                                <div
                                    style={{
                                        ...typeReport === 1 &&
                                        {
                                            backgroundColor: colors.primary.hex,
                                            borderRadius: 10,
                                            padding: '0 8px',
                                            margin: '0 5px',
                                            color: 'white',
                                        },
                                        transition: 'background-color 1s, color 1s',
                                    }}
                                >
                                    <input
                                        type="radio"
                                        id={"repSCTR"}
                                        name="typeBody"
                                        value={1}
                                        checked={typeReport === 1}
                                        onChange={(e) => setTypeReport(+e.target.value)}
                                    />
                                    <label htmlFor={"repSCTR"} style={{ padding: '0.2rem 0.5rem', color: colors.primary, fontWeight: 400 }}>SCTR</label><br />
                                </div>
                                <div
                                    style={{
                                        ...typeReport === 2 &&
                                        {
                                            backgroundColor: colors.primary.hex,
                                            borderRadius: 10,
                                            padding: '0 8px',
                                            margin: '0 5px',
                                            color: 'white',
                                        },
                                        transition: 'background-color 1s, color 1s',
                                    }}
                                >
                                    <input
                                        type="radio"
                                        id={"repVidaLey"}
                                        name="typeBody"
                                        value={2}
                                        checked={typeReport === 2}
                                        onChange={(e) => setTypeReport(+e.target.value)}
                                    />
                                    <label htmlFor={"repVidaLey"} style={{ padding: '0.2rem 0.5rem', color: colors.primary, fontWeight: 400 }}>Vida Ley</label>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={3} display={'flex'} justifyContent={'center'}>
                            <div style={{ width: '5.5rem' }}>
                                <MainInput
                                    title={'Año'}
                                    placeholder={'Año'}
                                    type={'Number'}
                                    value={year}
                                    onChange={(e) => setYear(e.target.value)}
                                />
                            </div>
                            <MainSelect
                                title={'Seleccionar mes'}
                                options={months}
                                value={month}
                                onChange={(value, e) => { setMonth(value); }}
                            />
                        </Grid>
                        {view === 0 ?
                            <Grid item xs={3} p={3}>
                                <ButtonsReport
                                    setOpenModalLoader={setOpenModalLoader}
                                    setTitleModalLoader={setTitleModalLoader}
                                    setTitle={setTitle}
                                    infoEmpresa={infoEmpresa}
                                    infoCliente={infoCliente}
                                    infoUnidad={infoUnidad}
                                    infoSede={infoSede}
                                    dateRep={dateRep}
                                    year={year}
                                    month={month}
                                    typeReport={typeReport}
                                    dataReport={dataReport}
                                    setDataWorker={setDataWorker}
                                    setDataReport={setDataReport}
                                    generateBody={generateBody}
                                />
                            </Grid> :
                            <Grid item xs={3} p={3}>
                                <ButtonsReprint
                                    url={url}
                                    setOpenModalLoader={setOpenModalLoader}
                                    setTitleModalLoader={setTitleModalLoader}
                                    setTitle={setTitle}
                                    infoEmpresa={infoEmpresa}
                                    infoCliente={infoCliente}
                                    infoUnidad={infoUnidad}
                                    infoSede={infoSede}
                                    dateRep={dateRep}
                                    year={year}
                                    month={month}
                                    typeReport={typeReport}
                                    dataReport={dataReport}
                                    setDataWorker={setDataWorker}
                                    setDataReport={setDataReport}
                                    generateBody={generateBody}
                                />
                            </Grid>
                        }
                    </Grid>
                </Grid>
            </Grid>
            <SpinnerLoader open={openModalLoader} title={titleModalLoader} />
        </Box >
    );
};

const ButtonsReport = (
    { setOpenModalLoader, setTitleModalLoader, setTitle,
        infoEmpresa, infoCliente, infoUnidad, infoSede,
        dateRep, year, month, typeReport,
        dataReport, setDataReport, setDataWorker, generateBody, }) => {
    useEffect(() => {
        setDataWorker(null);
        setDataReport(null);
        // if (!infoSede && +infoSede !== 0)
        //     getPeople();
    }, [infoSede]);
    const getPeople = () => {
        if (!infoSede || +infoSede <= 0) {
            simpleAlerts(validationMessages.infoSede);
            return;
        }
        if (!year || year < 2020) {
            simpleAlerts(validationMessages.year);
            return;
        }
        if (!month || month === 0) {
            simpleAlerts(validationMessages.month);
            return;
        }
        switch (typeReport) {
            case 1:
                getSCTRPeople();
                break;
            default:
                getVidaLeyPeople();
        }
    }
    const getSCTRPeople = async () => {
        try {
            setOpenModalLoader(true);
            setTitleModalLoader('Generando lista ...');
            setTitle('Lista trabajadores - SCTR');
            setDataWorker(null);
            setDataReport(null);
            const result = await AuxiliaryService.getSCTRPeople(dateRep, infoEmpresa, infoCliente, infoUnidad, infoSede)
            console.log("🚀🥵 ~ getSCTRPeople ~ result:", result)
            if (result.status === CODES.SUCCESS_200) {
                setDataWorker(result.data);
                setDataReport(result.data);
            } else {
                simpleAlerts('No se encontro información!')
            }
            return []
        } catch (error) {
            console.log(`Error en getSCTRPeople: ${error}`);
        } finally {
            setOpenModalLoader(false);
            setTitleModalLoader(null);
        }
    }
    const getVidaLeyPeople = async () => {
        try {
            setTitle('Lista trabajadores - Vida Ley');
            setTitleModalLoader('Generando lista ...');
            setOpenModalLoader(true);
            setDataWorker(null);
            setDataReport(null);
            const result = await AuxiliaryService.getVidaLeyPeople(dateRep, infoEmpresa, infoCliente, infoUnidad, infoSede)
            if (result.status === CODES.SUCCESS_200) {
                setDataWorker(result.data);
                setDataReport(result.data);
            } else {
                simpleAlerts('No se encontro información!')
            }
            return []
        } catch (error) {
            console.log(`Error en getVidaLeyPeople: ${error}`);
        } finally {
            setOpenModalLoader(false);
            setTitleModalLoader(null);
        }
    }
    const generarReporte = async (typeWorker) => {
        if (!infoSede || +infoSede <= 0) {
            simpleAlerts(validationMessages.infoSede);
            return;
        }
        if (!year || year < 2020) {
            simpleAlerts(validationMessages.year);
            return;
        }
        if (!month || month === 0) {
            simpleAlerts(validationMessages.month);
            return;
        }
        if (!dataReport || dataReport?.length === 0) {
            simpleAlerts(validationMessages.data);
            return;
        }
        switch (typeReport) {
            case 1:
                generateSCTR();
                break;
            default:
                generateVidaLey(typeWorker);
        }
    }
    const generateSCTR = async () => {
        try {
            setTitleModalLoader('Generando Reporte ...');
            setOpenModalLoader(true);
            let body = generateBody();
            const result = await AuxiliaryService.generateSCTR(body);
            if (result.status === CODES.SUCCESS_200) {
                if (body.tipoTrama === 1) {
                    const archivoUrl = result.data;
                    const link = document.createElement('a');
                    link.href = archivoUrl;
                    link.download = 'trama.xlsx';
                    link.click();
                } else {
                    const blob = new Blob([result.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                    const url = URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = url;
                    link.download = 'TRAMA-SCTR-PENSION.xlsx';
                    link.click();
                    URL.revokeObjectURL(url);
                }
                getPeople();
            } else {
                simpleAlerts('No se pudo generar repote', 'error');
                setOpenModalLoader(false);
                setTitleModalLoader(null);
            }
            return []
        } catch (error) {
            console.log(`Error en generateSCTR: ${error}`);
        }
    }
    const generateVidaLey = async (typeWorker) => {
        try {
            setTitleModalLoader('Generando Reporte ...');
            setOpenModalLoader(true);
            let body = generateBody(typeWorker);
            const result = await AuxiliaryService.generateVidaLey(body);
            if (result.status === CODES.SUCCESS_200) {
                const blob = new Blob([result.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                const url = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.download = 'TRAMA-VIDA-LEY.xlsx';
                link.click();
                URL.revokeObjectURL(url);
                getPeople();
            } else {
                setOpenModalLoader(false);
                setTitleModalLoader(null);
            }
            return []
        } catch (error) {
            console.log(`Error en generateVidaLey: ${error}`);
        }
    }

    return (
        <div
            style={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr',
                gap: '10px',
                justifyItems: 'center',
                alignItems: 'start'
            }}
        >
            <MainButton
                onClick={getPeople}
                title={'List. trabajadores'}
                color={'primary'}
            />
            <div
                style={{
                    display: 'grid',
                    gridTemplateColumns: '1fr',
                    gap: '10px',
                }}
            >
                {typeReport !== 1 &&
                    <>
                        <MainButton
                            onClick={() => generarReporte('E')}
                            title={'Reporte Empleados'}
                            color={'warning'}
                        />
                        <MainButton
                            onClick={() => generarReporte('O')}
                            title={'Reporte Otros'}
                            color={'tertiary'}
                        />
                    </>
                }
                <MainButton
                    onClick={() => generarReporte('X')}
                    title={'Generar Reporte'}
                    color={'success'}
                />
            </div>
        </div>
    );
}

const ButtonsReprint = ({ url, setOpenModalLoader, setTitleModalLoader, setTitle, infoEmpresa, infoCliente, infoUnidad, infoSede, dateRep, year, month, typeReport, dataReport, setDataWorker, setDataReport, generateBody }) => {
    useEffect(() => {
        setDataWorker(null);
        setDataReport(null);
        // if (!infoSede && +infoSede !== 0)
        //     getExportedPeople();
    }, [infoSede]);
    const getExportedPeople = () => {
        if (!infoSede || +infoSede <= 0) {
            simpleAlerts(validationMessages.infoSede);
            return;
        }
        if (!year || year < 2020) {
            simpleAlerts(validationMessages.year);
            return;
        }
        if (!month || month === 0) {
            simpleAlerts(validationMessages.month);
            return;
        }
        switch (typeReport) {
            case 1:
                getConsejeroTramaSCTRC();
                break;
            default:
                getConsejeroTramaVida();
        }
    }
    const getConsejeroTramaSCTRC = async () => {
        try {
            setOpenModalLoader(true);
            setTitleModalLoader('Generando lista ...');
            setTitle('Lista trabajadores - SCTR');
            setDataWorker(null);
            setDataReport(null);
            const result = await ConsejeroService.getConsejeroTramaSCTRC(url, infoEmpresa, infoCliente, infoUnidad, infoSede, dateRep);
            if (result.status === CODES.SUCCESS_200) {
                setDataWorker(result.data);
                setDataReport(result.data);
            } else {
                simpleAlerts('No se encontro información!')
            }
            return []
        } catch (error) {
            console.log(`Error en getConsejeroTramaSCTRC: ${error}`);
        } finally {
            setOpenModalLoader(false);
            setTitleModalLoader(null);
        }
    }
    const getConsejeroTramaVida = async () => {
        try {
            setTitle('Lista trabajadores - Vida Ley');
            setTitleModalLoader('Generando lista ...');
            setOpenModalLoader(true);
            setDataWorker(null);
            setDataReport(null);
            const result = await ConsejeroService.getConsejeroTramaVida(url, infoEmpresa, infoCliente, infoUnidad, infoSede, dateRep);
            if (result.status === CODES.SUCCESS_200) {
                setDataWorker(result.data);
                setDataReport(result.data);
            } else {
                simpleAlerts('No se encontro información!')
            }
            return []
        } catch (error) {
            console.log(`Error en getConsejeroTramaVida: ${error}`);
        } finally {
            setOpenModalLoader(false);
            setTitleModalLoader(null);
        }
    }
    const generarReporte = async (typeWorker) => {
        if (!infoSede || +infoSede <= 0) {
            simpleAlerts(validationMessages.infoSede);
            return;
        }
        if (!year || year < 2020) {
            simpleAlerts(validationMessages.year);
            return;
        }
        if (!month || month === 0) {
            simpleAlerts(validationMessages.month);
            return;
        }
        if (!dataReport || dataReport?.length === 0) {
            simpleAlerts(validationMessages.data);
            return;
        }

        switch (typeReport) {
            case 1:
                generateSCTR();
                break;
            default:
                generateVidaLey(typeWorker);
        }
    }
    const generateSCTR = async () => {
        try {
            setTitleModalLoader('Generando Reporte ...');
            setOpenModalLoader(true);
            let body = generateBody();
            const result = await AuxiliaryService.generateSCTR(body);
            if (result.status === CODES.SUCCESS_200) {
                if (body.tipoTrama === 1) {
                    const archivoUrl = result.data;
                    const link = document.createElement('a');
                    link.href = archivoUrl;
                    link.download = 'trama.xlsx';
                    link.click();
                } else {
                    const blob = new Blob([result.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                    const url = URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = url;
                    link.download = 'TRAMA-SCTR-PENSION.xlsx';
                    link.click();
                    URL.revokeObjectURL(url);
                }
                setOpenModalLoader(false);
            } else {
                setOpenModalLoader(false);
                setTitleModalLoader(null);
            }
            return []
        } catch (error) {
            console.log(`Error en generateSCTR: ${error}`);
        }
    }
    const generateVidaLey = async (typeWorker) => {
        try {
            setTitleModalLoader('Generando Reporte ...');
            setOpenModalLoader(true);
            let body = generateBody(typeWorker);
            const result = await AuxiliaryService.generateVidaLey(body);
            if (result.status === CODES.SUCCESS_200) {
                const blob = new Blob([result.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                const url = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.download = 'TRAMA-VIDA-LEY.xlsx';
                link.click();
                URL.revokeObjectURL(url);
                setOpenModalLoader(false);
            } else {
                setOpenModalLoader(false);
                setTitleModalLoader(null);
            }
            return []
        } catch (error) {
            console.log(`Error en generateVidaLey: ${error}`);
        }
    }

    return (
        <div
            style={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr',
                gap: '10px',
                justifyItems: 'center',
                alignItems: 'start'
            }}
        >
            <MainButton
                onClick={getExportedPeople}
                title={'Cargar'}
                color={'primary'}
                style={{
                    paddingLeft: '1.5rem !important',
                    paddingRight: '1.5rem !important',
                }}
            />
            <div
                style={{
                    display: 'grid',
                    gridTemplateColumns: '1fr',
                    gap: '10px',
                }}
            >
                {typeReport !== 1 &&
                    <>
                        <MainButton
                            onClick={() => generarReporte('E')}
                            title={'Reimp. Empleados'}
                            color={'warning'}
                        />
                        <MainButton
                            onClick={() => generarReporte('O')}
                            title={'Reimp. Otros'}
                            color={'tertiary'}
                        />
                    </>
                }
                <MainButton
                    onClick={() => generarReporte('X')}
                    title={'Reimprimir'}
                    color={'success'}
                    style={{
                        paddingLeft: '1.5rem !important',
                        paddingRight: '1.5rem !important',
                    }}
                />
            </div>
        </div>
    );
}

export default ReportTrama;



