import React, { useEffect, useState } from 'react';
import { Box, Grid, TextField, InputAdornment, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import BodyUsers from '../../components/Users/BodyUsers/BodyUsers';
import AddUser from './AddUser/AddUser';
import MainButton from '../../components/General/MainButton';
import UserService from '../../../services/user';
import ProfileService from '../../../services/profile';
import GeneralService from '../../../services/general';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import { CODES } from '../../../utils/responseCodes';
import { simpleAlerts } from '../../../utils/alerts';
import SpinnerLoader from '../../components/SpinnerLoad/SpinnerLoader';

const newUser = {
    numdoc: '',
    usuario: '',
    id_Perfil1: 0,
    id_Perfil2: 0,
    email: '',
    clave: '',
    nombres: '',
    apellidos: '',
    documento_id: 1,
    isExternal: 1,
    persona_id: '',
    usuario_id: '',
    id_usuario: '',
    estado: 1
}

const UserMaintenance = ({ userToken }) => {
    const url = "'" + window.location.pathname + "'";
    const id_usuarioLogin = userToken.user.id_usuario;
    const [titleSpinner, setTitleSpinner] = useState(null);
    const [openModalLoader, setOpenModalLoader] = useState(true);
    const [open, setOpen] = useState(false)
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [profiles, setProfiles] = useState(null);
    const [typeDocument, setTypeDocument] = useState(null);
    const [status, setStatus] = useState(null);
    const [systems, setSystems] = useState(null);

    const [dataUsers, setDataUsers] = useState(null);
    const [infoUser, setInfoUser] = useState(newUser);

    const [searchUser, setSearchUser] = useState("");
    const [pageUser, setPageUser] = useState(1);
    const pageSizeUser = 20;
    const [totalPagesUser, setTotalPagesUser] = useState(1);

    const [typeRegister, setTypeRegister] = useState(1); //TODO: 1: nuevo usuario, 2: duplicado de usuario

    useEffect(() => {
        init();
    }, [])

    useEffect(() => {
        getUsuarios();
    }, [pageUser])

    useEffect(() => {
        if (!searchUser) {
            getUsuarios();
        }
    }, [searchUser])

    const init = async () => {
        Promise.all([
            getUsuarios(),
            getPerfiles(),
            getTipoDocumento(),
            getEstados(),
            getSistemas(),
        ]).then(() => setOpenModalLoader(false));
    }

    const getSistemas = async () => {
        try {
            const result = await GeneralService.getSistemas();
            if (result.status === CODES.SUCCESS_200) {
                result.data?.forEach(element => {
                    element.id = Number(element.desc2)
                    element.Value = Number(element.desc2)
                    element.label = element.desc1
                })
                setSystems(result.data);
            }
            return []
        } catch (error) {
            console.log(`Error en getUsuarios: ${error}`);
        }
    }

    const getUsuarios = async () => {
        try {
            setDataUsers(null);
            setTitleSpinner('Buscando Usuarios...');
            setOpenModalLoader(true);
            const result = await UserService.getUsuarios(url, { search: searchUser, page: pageUser, pageSize: pageSizeUser, pagination: 0 });
            if (result.status === CODES.SUCCESS_200) {
                setDataUsers(result?.data?.data);
                setTotalPagesUser(result.data.last_page);
            } else {
                simpleAlerts(result.response.data.message, 'error');
            }
            return []
        } catch (error) {
            console.log(`Error en getUsuarios: ${error}`);
        } finally {
            setOpenModalLoader(false);
            setTitleSpinner(null);
        }
    }

    const getPerfiles = async () => {
        try {
            const result = await ProfileService.getPerfiles();
            if (result.status === CODES.SUCCESS_200) {
                result.data?.forEach(element => {
                    element.value = element.id_perfil
                    element.label = element.desPerfil
                })
                setProfiles(result.data);
            }
            return []
        } catch (error) {
            console.log(`Error en getPerfiles: ${error}`);
        }
    }

    const getTipoDocumento = async () => {
        try {
            const result = await GeneralService.getTipoDocumento();
            if (result.status === CODES.SUCCESS_200) {
                result.data?.forEach(element => {
                    element.value = element.id_Documento
                    element.label = element.codDocumento + ' - ' + element.Documento
                })
                setTypeDocument(result.data);
            }
            return []
        } catch (error) {
            console.log(`Error en getPerfiles: ${error}`);
        }
    }

    const getEstados = async () => {
        try {
            const result = await GeneralService.getEstados();
            if (result.status === CODES.SUCCESS_200) {
                result.data?.forEach(element => {
                    element.id = Number(element.corr3)
                    element.Value = Number(element.corr3)
                    element.label = element.desc1
                })
                setStatus(result.data);
            }
            return []
        } catch (error) {
            console.log(`Error en getColumnasAsistencias: ${error}`);
        }
    }

    const editUser = (info) => {
        setInfoUser(info);
        setTypeRegister(1);
        handleOpen();
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            getUsuarios();
        }
    };
    
    return (
        <Box sx={{ flexGrow: 1 }}>
            <AddUser
                open={open}
                handleClose={handleClose}
                id_usuarioLogin={id_usuarioLogin}
                setOpenModalLoader={setOpenModalLoader}
                setTitleSpinner={setTitleSpinner}

                systems={systems}
                infoUser={infoUser}
                setInfoUser={setInfoUser}

                profiles={profiles}
                typeDocument={typeDocument}
                getUsuarios={getUsuarios}

                typeRegister={typeRegister}
                setTypeRegister={setTypeRegister}
            />
            <Grid container direction="row" justifyContent="center" alignItems="center" mt={2}>
                <Grid item xs={8} sm={6} md={7}>
                    <TextField
                        id="input-with-icon-textfield"
                        label="Buscador"
                        placeholder="Buscar usuario"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon color='tertiary' />
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={getUsuarios}
                                        color='quaternary'
                                    >
                                        <TravelExploreIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        variant="standard"
                        style={{ width: '100%' }}
                        onChange={(e) => setSearchUser(e.target.value)}
                        onKeyPress={handleKeyPress}
                    />
                </Grid>
                <Grid item xs={2} sx={{ paddingTop: "1rem", paddingLeft: "0.5rem" }}>
                    <MainButton
                        onClick={()=>editUser(newUser)}
                        title={'Crear'}
                        typeStyle={'outlined'}
                        color={'primary'}
                    />
                </Grid>
            </Grid>

            <Grid container direction="row" justifyContent="center" alignItems="center" mt={2}>
                <Grid item xs={12} sm={10} md={10} paddingLeft={'1rem'} paddingRight={'1rem'}>
                    <BodyUsers
                        id_usuarioLogin={id_usuarioLogin}

                        setTitleSpinner={setTitleSpinner}
                        setOpenModalLoader={setOpenModalLoader}

                        status={status}

                        dataUsers={dataUsers}
                        setDataUsers={setDataUsers}
                        totalPagesUser={totalPagesUser}
                        pageUser={pageUser}
                        setPageUser={setPageUser}

                        setInfoUser={setInfoUser}
                        handleOpen={handleOpen}

                        getUsuarios={getUsuarios}

                        editUser={editUser}
                        setTypeRegister={setTypeRegister}
                    />
                </Grid>
            </Grid>

            <SpinnerLoader open={openModalLoader} title={titleSpinner} />
        </Box>
    );
};

export default UserMaintenance;