import React, { useState, } from 'react';
import { ButtonGroup, IconButton, } from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { OrderedListOutlined, } from '@ant-design/icons/lib/icons';
import { colors } from '../../../../assets/colors';
import { CODES } from '../../../../utils/responseCodes';
import { simpleAlerts } from '../../../../utils/alerts';
import { sortDataListSimple } from '../../../../utils/function';
import AreaOrgService from '../../../../services/areaOrg';
import Swal from 'sweetalert2';
import _ from 'lodash';

const columns = [
  { id: '#', label: '#', width: 20, align: 'center', },
  { id: 'nombreJefe', label: 'Jefe Directo', width: 150, align: 'left', },
  { id: 'numdoc', label: 'Documento', width: 100, align: 'center', },
  { id: 'trabajador', label: 'Trabajador', width: 150, align: 'center', },
  { id: 'delete', label: 'Retirar', width: 50, align: 'center', },
];

const BodyWorker = (props) => {
  const { titleBody, setTitleSpinner, setOpenModalLoader, organigramaArea, setOrganigramaArea, } = props;
  const url = "'" + window.location.pathname + "'";

  const deleteWorkerAreaOrg = async (info) => {
    info.subAreaOrg_id = info.id_areaOrg
    info.persona_id = info.id_Trabajador
    let UpdOrganigramaArea = _.cloneDeep(organigramaArea);
    UpdOrganigramaArea = UpdOrganigramaArea.filter(e => !(Number(e.id_areaOrg) === Number(info?.id_areaOrg) && Number(e.id_Trabajador) === Number(info?.id_Trabajador)));

    Swal.fire({
      title: '¿Seguro de eliminar la relación?',
      text: 'Se perdera la asignacion trabajador - jefe',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: colors.secondary.hex,
      cancelButtonColor: colors.primary.hex,
      confirmButtonText: 'Si, eliminar'
    }).then((result) => {
      if (result.isConfirmed) {
        setTimeout(async () => {
          try {
            setTitleSpinner('Retirando ...');
            setOpenModalLoader(true);
            const result = await AreaOrgService.deleteWorkerAreaOrg(url, info);
            if (result.status === CODES.DELETE_204) {
              simpleAlerts("Relación eliminada", "success");
              setTitleSpinner(null);
              setOpenModalLoader(false);
              setOrganigramaArea(UpdOrganigramaArea);
            }
            return [];
          } catch (error) {
            console.log(`Error en deleteWorkerAreaOrg: ${error}`);
          }
        });
      }
    })
  };

  // /** inicio ordenamiento de columnas */
  const [sortConfigTable, setSortConfigTable] = useState({ key: null, direction: "ascending" });

  const handleSortTable = (key) => {
    let direction = "ascending";
    if (sortConfigTable && sortConfigTable.key === key && sortConfigTable.direction === "ascending") {
      direction = "descending";
    }
    setSortConfigTable({ key, direction });
  };

  const sortedData = sortDataListSimple(organigramaArea, sortConfigTable);
  // /** fin ordenamiento de columnas */

  return (
    <div className='group'>
      <div className='contenido'>
        <label
          className="label"
          style={{
            fontSize: '22px',
            top: '-1%',
            left: '3rem',
            fontFamily: 'Coffe-Cake',
          }}
        >
          {titleBody}
        </label>
        <div className='input' style={{ marginTop: '5px' }} >
          <div className='page-body-main'>
            <div className='page-body-table table-responsive-md' style={{ height: '55vh' }}>
              <table className="table table-hover table-striped table-bordered">
                <thead className='sticky-top' style={{ backgroundColor: colors.quaternary.hex, color: '#ffff' }}>
                  <tr>
                    {columns.map((column) => (
                      <th
                        scope="col"
                        key={column.id}
                        style={{
                          textAlign: column.align,
                          width: column.width,
                          maxWidth: column.width,
                          cursor: 'pointer',
                        }}
                        onClick={() => handleSortTable(column.id)}
                      >
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                          <div style={{ marginRight: 'auto' }}>{column.label}</div>
                          <div><OrderedListOutlined style={{ paddingBottom: 2 }} /></div>
                        </div>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="table-group-divider">
                  {sortedData?.map((row, indexRow) => {
                    return (
                      <tr key={"row" + indexRow}>
                        {columns.map((column, indexColumn) => {
                          const value = row[column.id];
                          return (
                            <td key={'col' + indexColumn} align={column.align}>
                              {column.id === '#' ?
                                (indexRow + 1)
                                : column.id === 'delete' ?
                                  <ButtonGroup
                                    disableElevation
                                    variant="contained"
                                    aria-label="Disabled elevation buttons"
                                  >
                                    <IconButton aria-label="delete" size="small" title='Eliminar'
                                      onClick={() => { deleteWorkerAreaOrg(row) }}
                                    >
                                      <DeleteForeverIcon fontSize='small' color={'secondary'} />
                                    </IconButton>
                                  </ButtonGroup>
                                  : value}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BodyWorker;