import React from 'react';
import ProductivityEdition from './Edit/Edition';
import AssistanceGeneral from '../Assistance/AssistanceGeneralMovil';

const Productivity = ({ setValidationInfoChange, userToken, isMovil, isDesktop, userAgent, setPageTitle }) => {
    return (
        (isDesktop && !isMovil) ?
            <ProductivityEdition
                setValidationInfoChange={setValidationInfoChange}
                userToken={userToken}
            />
            : <AssistanceGeneral
                setValidationInfoChange={setValidationInfoChange}
                userToken={userToken}
                setPageTitle={setPageTitle}
            />
    );
};

export default Productivity;