import React, { useEffect, useState } from 'react';
import { Box, Grid, TextField, InputAdornment } from '@mui/material';
import BodyOpen from '../../../components/Manage/OpenAndClose/BodyOpen';
import SeeClosures from '../../../components/Manage/OpenAndClose/SeeClosures';
import GroupingUnitByUser from '../../../components/GroupingUnitByUser';
import MainButton from '../../../components/General/MainButton';
import UserService from '../../../../services/user';
import GeneralService from '../../../../services/general';
import ManageService from '../../../../services/manage';
import DetailSede from './Detail/DetailSede';
import { CODES } from '../../../../utils/responseCodes';
import { simpleAlerts } from '../../../../utils/alerts';
import { getDateOL, formatDate } from '../../../../utils/function';
import { colors } from '../../../../assets/colors';
import SpinnerLoader from '../../../components/SpinnerLoad/SpinnerLoader';
import imgUbication from '../../../../assets/icons/ubication.png';
import { Select } from "antd";
const { Option } = Select;

const validationMessages = {
    company: 'Debe seleccionar una empresa!'
};

const OpenSede = ({ userToken }) => {
    const url = "'" + window.location.pathname + "'";
    const id_usuarioLogin = userToken.user.id_usuario;
    const [titleSpinner, setTitleSpinner] = useState(null);
    const [openModalLoader, setOpenModalLoader] = useState(true);
    const [open, setOpen] = useState(false)
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [infoCompany, setInfoCompany] = useState(null);
    const [infoClient, setInfoClient] = useState(null);
    const [infoUnit, setInfoUnit] = useState(null);
    const [empresas, setEmpresas] = useState([]);
    const [clientes, setClientes] = useState([]);
    const [unidades, setUnidades] = useState([]);
    const [sedes, setSedes] = useState([]);
    const [sedesOpen, setSedesOpen] = useState([]);
    const [dateInit, setDateInit] = useState(getDateOL());
    const [dateEnd, setDateEnd] = useState(getDateOL());
    const [dateProgram, setDateProgram] = useState(null);
    const [hoursProgram, setHoursProgram] = useState(null);
    const [minutesProgram, setMinutesProgram] = useState(null);
    const [selectMinutes, setSelectMinutes] = useState([
        { value: 0, label: '0 min.' },
        { value: 15, label: '15 min.' },
        { value: 30, label: '30 min.' },
        { value: 45, label: '45 min.' },
    ]);
    const [infoSede, setInfoSede] = useState(null);
    const [showMsgSedes, setShowMsgSedes] = useState(false);
    const [checkGeneral, setCheckGeneral] = useState(false);

    const [viewClosures, setViewClosures] = useState(false);
    const [dataClosures, setDataClosures] = useState(null);

    useEffect(() => {
        init();
    }, [])

    useEffect(() => {
        setSedes([]);
    }, [infoCompany, infoClient])

    useEffect(() => {
        setSedes([]);
    }, [infoUnit, dateInit, dateEnd])

    const init = async () => {
        Promise.all([
            getAgrupamientoByUsuario(),
        ]).then(() => setOpenModalLoader(false));
    }

    const getAgrupamientoByUsuario = async () => {
        try {
            const result = await UserService.getAgrupamientoByUsuario(url, id_usuarioLogin);
            if (result.status === CODES.SUCCESS_200) {
                if (result?.data?.sedes?.length === 0)
                    simpleAlerts('No tiene areas asignadas', "warning");
                setEmpresas(result.data.empresas);
                setClientes(result.data.clientes);
                setUnidades(result.data.unidades);
            }
        } catch (error) {
            console.log(`Error en getAgrupamientoByUsuario: ${error}`);
        }
    }

    const getSedesGeneral = () => {
        if (viewClosures) {
            getSeeClosing();
        } else {
            if (!infoUnit || infoUnit?.value === 0 || !infoUnit?.value) {
                simpleAlerts('Debe seleccionar una unidad!', 'warning');
                return;
            }
            if (!dateInit || dateInit === '') {
                simpleAlerts('Debe seleccionar una fecha de inicio!', 'warning');
                return;
            }
            if (!dateEnd || dateEnd === '') {
                simpleAlerts('Debe seleccionar una fecha de fin!', 'warning');
                return;
            }

            setOpenModalLoader(true);
            setTitleSpinner('Buscando sedes ...');
            Promise.all([
                getSedesByUnidad(),
            ]).then(() => { setOpenModalLoader(false); setTitleSpinner(null); });
        }
    }

    const getSedesByUnidad = async () => {
        try {
            //const result = await GeneralService.getSedesByUnidad(infoUnit?.value);
            const result = await GeneralService.getSedesByUnidadPersonalActivo(infoUnit?.value);
            if (result.status === CODES.SUCCESS_200) {
                result?.data?.forEach(element => {
                    element.FecDesde = getDateOL();
                    element.FecHasta = getDateOL('', 'short', 7)
                })
                setSedes(result?.data);
            }
            setCheckGeneral(false);
            return [];
        } catch (error) {
            console.log(`Error en getSedesByUnidad: ${error}`);
        }
    }

    const closePlanning = async (type) => {
        if (!infoUnit?.value) {
            simpleAlerts('Seleccione una Unidad', 'warning');
            return;
        }
        if (!sedesOpen || sedesOpen?.length === 0) {
            simpleAlerts('No hay sedes seleccionadas', 'error');
            return;
        }
        if ((!dateProgram || dateProgram === '') && type === 1) {
            simpleAlerts('No hay fecha de programación', 'error');
            return;
        }
        if ((!hoursProgram || hoursProgram === 0 || hoursProgram < 0 || hoursProgram > 23) && type === 1) {
            simpleAlerts('La hora de programación debe ser entre 0 - 23 hrs.', 'error');
            return;
        }
        if (!selectMinutes?.find(e => Number(e.value) === Number(minutesProgram)) && type === 1) {
            simpleAlerts('Debe seleccionar los minutos', 'error');
            return;
        }

        const arraySedes = sedesOpen.map((sede) => ({
            sede_id: sede?.id_sede,
            FecDesde: sede?.FecDesde,
            FecHasta: sede?.FecHasta,
        }));

        const body = {
            usuCierre_id: id_usuarioLogin,
            type: type,
            FecProgram: (type === 1 ? formatDate(new Date(dateProgram + ' ' + hoursProgram + ':' + (minutesProgram < 9 ? ('0' + minutesProgram) : minutesProgram)), 'date-time', 'long') : null),
            arraySedes: arraySedes,
        }
        setTitleSpinner('ejecutando');
        setOpenModalLoader(true);
        try {
            const result = await ManageService.updateCloseSede(url, body);
            if (result.status === CODES.CREATE_201) {
                simpleAlerts((type === 0 ? 'Sedes aparturadas' : 'Cierre enviado') + ' - ' + result?.data?.message, 'success');
                if (type === 1) {
                    scheduleClosing();
                }else{
                    setOpenModalLoader(false);
                    setTitleSpinner(null);
                    setSedesOpen([]);            
                }
            } else {
                simpleAlerts(result?.response?.data?.message ||'Error en el proceso', 'error');
                setOpenModalLoader(false);
                setTitleSpinner(null);
                setSedesOpen([]);
            }
        } catch (error) {
            console.log(`Error en updateCloseSede: ${error}`);
            setOpenModalLoader(false);
            setTitleSpinner(null);
        }
    }

    const scheduleClosing = async () => {
        try {
            setOpenModalLoader(true);
            setTitleSpinner('Programando el cierre');
            let body = {
                'FecProgram': formatDate(new Date(dateProgram + ' ' + hoursProgram + ':' + (minutesProgram < 9 ? ('0' + minutesProgram) : minutesProgram)), 'date-time', 'long')
            }
            const result = await ManageService.scheduleClosing(url, body);
            if (result.status === CODES.CREATE_201) {
                simpleAlerts(result?.data?.message?.msg || 'Cierre programado', 'success')
            } else {
                simpleAlerts('Hubo un error comuniquese con TI', 'error')
            }
        } catch (error) {
            console.log(`Error en scheduleClosing: ${error}`);
        } finally {
            setOpenModalLoader(false);
            setTitleSpinner(null);
            setSedesOpen([]);
        }
    }

    const changeViewClosures = (valor) => {
        if (valor) {
            if (!infoCompany || infoCompany.value === 0) {
                simpleAlerts(validationMessages.company, 'warning')
                return
            }
        }
        setViewClosures(valor);
    }

    const getSeeClosing = async () => {
        try {
            setOpenModalLoader(true);
            setDataClosures(null);
            const result = await ManageService.getSeeClosing(url, infoCompany.value);
            if (result.status === CODES.SUCCESS_200) {
                if (result?.data?.sedes?.length === 0)
                    simpleAlerts('No se encontro cierres', "warning");
                setDataClosures(result.data);
            }
        } catch (error) {
            console.log(`Error en getSeeClosing: ${error}`);
        } finally {
            setOpenModalLoader(false);
        }
    }

    return (
        <Box sx={{ flexGrow: 1 }}>
            <DetailSede
                open={open}
                handleClose={handleClose}
                setTitleSpinner={setTitleSpinner}
                setOpenModalLoader={setOpenModalLoader}
                infoSede={infoSede}
                dateInit={dateInit}
                dateEnd={dateEnd}
            />

            <Grid container spacing={0} justifyContent={'center'} alignItems={'center'} pt={1}>
                <Grid item xs={12} sm={10} md={9}
                    sx={{
                        border: `solid 0.05rem ${colors.primary.hex}`,
                        borderRadius: '25px',
                        paddingBottom: 1,
                    }}
                >
                    <GroupingUnitByUser
                        user={id_usuarioLogin}
                        empresas={empresas} clientes={clientes} unidades={unidades}

                        infoCompany={infoCompany} setInfoCompany={setInfoCompany}
                        infoClient={infoClient} setInfoClient={setInfoClient}
                        infoUnit={infoUnit} setInfoUnit={setInfoUnit}
                    />
                </Grid>
            </Grid>

            <Grid container spacing={3} pt={1} justifyContent={'center'} alignItems={'center'} sx={{ paddingTop: { xs: "1.5rem", md: "0.5rem" } }}>
                <Grid item xs={6} sm={2} md={2}
                    style={{
                        gap: 10,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'end',
                    }} >
                    <MainButton
                        size={'large'}
                        onClick={getSedesGeneral}
                        title={'Cargar'}
                    />
                    <MainButton
                        onClick={() => changeViewClosures(!viewClosures)}
                        title={viewClosures ? 'Volver a cierres' : 'Ver Cierres'}
                        color={'warning'}
                        typeStyle={viewClosures ? 'outlined' : 'contained'}

                    />
                </Grid>
                <Grid item xs={12} sm={5} md={3} >
                    <fieldset className='fieldset'>
                        <legend className='title-legend'>Fecha y hora de cierre</legend>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'space-evenly',
                            alignItems: 'end',
                            gap: 10,
                            border: 'solid 1px #13005A',
                            padding: '2px 5px',
                        }}>
                            <TextField
                                className="text"
                                fullWidth
                                size='small'
                                type={'date'}
                                value={dateProgram || ''}
                                onChange={(e) => setDateProgram(e.target.value)}
                                style={{ borderRadius: '10px' }}
                                inputProps={{
                                    style: {
                                        fontSize: 12,  // font size of input text
                                        backgroundColor: 'white',
                                        borderRadius: '10px'
                                    },
                                }}
                                InputLabelProps={{ style: { fontSize: 12 } }} // font size of input label
                            />
                            <TextField
                                id="outlined-start-adornment"
                                className="text"
                                fullWidth
                                size='small'
                                value={hoursProgram || ''}
                                onChange={(e) => setHoursProgram(e.target.value)}
                                style={{ borderRadius: '10px', width: '80%' }}
                                InputProps={{
                                    inputProps: {
                                        step: 1,
                                        min: 0,
                                        max: 23,
                                        type: 'number',
                                    },
                                    style: {
                                        fontSize: 12,
                                        backgroundColor: 'white',
                                        borderRadius: '10px'
                                    },
                                    endAdornment: <InputAdornment position="start" sx={{ fontSize: '2rem' }}>Hrs.</InputAdornment>,
                                }}
                                InputLabelProps={{ style: { fontSize: 12 } }}
                            />
                            <Select
                                mode={"single"}
                                className="select"
                                size={'middle'}
                                placeholder={'Seleccionar'}
                                optionFilterProp="children"
                                showSearch
                                popupClassName='my-drop-down'
                                value={(minutesProgram !== null && minutesProgram !== undefined) ? minutesProgram : -1}
                                onChange={(value, e) => { setMinutesProgram(value) }}
                                style={{ width: '80%' }}
                            >
                                <Option value={-1}><em>Seleccionar</em></Option>
                                {selectMinutes?.map((opt, idx) => {
                                    return (
                                        <Option key={opt?.value?.toString() + opt?.label?.replace(/\s+/g, '')} value={opt?.value}>
                                            {opt?.label}
                                        </Option>
                                    )
                                })}
                            </Select>
                        </div>
                    </fieldset>
                </Grid>
                <Grid item xs={12} sm={3} md={3}
                    style={{
                        gap: 10,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'end',
                    }}
                >
                    <MainButton
                        onClick={() => closePlanning(0)}
                        title={'Aperturar'}
                        color={'success'}
                    />
                    <MainButton
                        onClick={() => closePlanning(1)}
                        title={'Cerrar'}
                        color={'warning'}
                    />
                </Grid>
            </Grid>

            <Grid container justifyContent="center" pt={1}>
                <Grid item xs={12} sm={10} md={9}>
                    {viewClosures ?
                        <SeeClosures
                            setOpenModalLoader={setOpenModalLoader}
                            getSeeClosing={getSeeClosing}
                            dataClosures={dataClosures}
                            setDataClosures={setDataClosures}
                            infoCompany={infoCompany}
                        />
                        : <BodyOpen
                            handleOpen={handleOpen}
                            checkGeneral={checkGeneral}
                            setCheckGeneral={setCheckGeneral}
                            sedes={sedes}
                            setSedes={setSedes}
                            sedesOpen={sedesOpen}
                            setSedesOpen={setSedesOpen}
                            setInfoSede={setInfoSede}
                        />
                    }
                </Grid>
            </Grid>

            <div id="floatingButton" className="floating-button" onClick={() => setShowMsgSedes(!showMsgSedes)}
                style={{
                    position: 'fixed',
                    bottom: '20px',
                    right: '20px',
                    width: '50px',
                    height: '50px',
                    borderRadius: '50%',
                    backgroundColor: '#FFC107',
                    color: '#fff',
                    fontSize: '30px',
                    textAlign: 'center',
                    lineHeight: '50px',
                    cursor: 'pointer',
                    zIndex: '9999',
                }}
            >
                <span className="plus-sign"
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%',
                    }}
                >+</span>
                {sedesOpen?.length > 0 &&
                    <div className="contenedor"
                        style={{
                            position: 'relative',
                            display: 'inline-block',
                            textAlign: 'center',
                            top: '-5.4rem',
                        }}>
                        <img src={imgUbication} width={40} />
                        <div className="centrado"
                            style={{
                                position: 'absolute',
                                top: '60%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                fontSize: '20px',
                                fontWeight: 'bold',
                                color: 'black'
                            }}
                        >{sedesOpen?.length}</div>
                    </div>
                }
            </div>
            {showMsgSedes &&
                <div id="messageBox" className="message-box"
                    style={{
                        position: 'fixed',
                        bottom: '3.5rem', //'12%', //'75px',
                        right: '4.5rem', //'9%', //'75px',
                        width: '20rem', //'200px',
                        height: '10rem', //'100px',
                        backgroundColor: '#fff',
                        border: '1px solid #ccc',
                        padding: '10px',
                        zIndex: '9998',
                    }}
                >
                    <span className="badge text-bg-dark message-text" style={{ fontWeight: 'bold', }}>Sedes seleccionadas:</span><br />
                    <div style={{ height: '90%', overflow: 'scroll' }}>
                        <ul className='lista-msg-sedes' style={{ marginLeft: '-1rem' }}>
                            {sedesOpen?.map((sede) => {
                                return (
                                    <li key={sede?.id_sede} style={{ fontSize: '12px' }}>{sede?.desUnidad + ' / ' + ' (' + sede?.id_sede + ') ' + sede?.desSede}</li>
                                )
                            })}
                        </ul>
                    </div>
                </div>
            }

            <SpinnerLoader open={openModalLoader} title={titleSpinner} />
        </Box >
    );
};

export default OpenSede;