import React, { useState } from 'react';
import { IconButton, Grid, TextField } from '@mui/material';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import StaffService from '../../../../services/staff';
import ExternosService from '../../../../services/externos';
import MainSelect from '../../General/MainSelect';
import MainInput from '../../General/MainInput';
import { CODES } from '../../../../utils/responseCodes';
import { colors } from '../../../../assets/colors';
import { simpleAlerts } from '../../../../utils/alerts';
import _ from 'lodash';

const InfoUser = (props) => {
    const { setOpenModalLoader, infoUser, setInfoUser, profiles, typeDocument,setTitleSpinner } = props;

    const url = "'" + window.location.pathname + "'";
    const regex = /^(?=.*\d)(?=.*[A-Z])(?=.*[@$!%*&])(?!.*[\s\\\/]).{6,10}$/;

    const [viewPassword, setViewPassword] = useState(false);
    const [dataStaffs, setDataStaffs] = useState(null);
    const [searchStaff, setSearchStaff] = useState("");
    const [pageStaff, setPageStaff] = useState(1);
    const pageSizeStaff = 10;
    const [totalPagesStaff, setTotalPagesStaff] = useState(1);
   

    const getStaff = async () => {
        setOpenModalLoader(true);
        try {
            const result = await StaffService.getStaff(url, { search: searchStaff, page: pageStaff, pageSize: pageSizeStaff, pagination: 0 });
            //!se puede usar esto para un modal esperar solicitud. por el momento se puso para q tome siempre el primero del array
            if (result.status === CODES.SUCCESS_200) {
                if (result.data.data.length === 0) {
                    simpleAlerts('No se encontro información con la palabra clave!', 'error', 'colored');
                }
                let updInfoUser = _.cloneDeep(infoUser);
                updInfoUser.numdoc = result?.data?.data[0]?.numdoc;
                updInfoUser.email = result?.data?.data[0]?.email;
                updInfoUser.nombres = result?.data?.data[0]?.nombres;
                updInfoUser.apellidos = result?.data?.data[0]?.apePaterno + ' ' + result?.data?.data[0]?.apeMaterno;
                updInfoUser.documento_id = result?.data?.data[0]?.documento_id;
                updInfoUser.persona_id = result?.data?.data[0]?.id_persona;
                updInfoUser.nombreCompleto = result?.data?.data[0]?.apePaterno + ' ' + result?.data?.data[0]?.apeMaterno + ', ' + result?.data?.data[0]?.nombres;

                setInfoUser(updInfoUser);
                setDataStaffs(result?.data?.data[0]);
                setTotalPagesStaff(result.data.last_page);
            } else {
                simpleAlerts(result.response.data.message, 'error');
            }
            return [];
        } catch (error) {
            console.log(`Error en getStaff: ${error}`);
        } finally {
            setOpenModalLoader(false);
        }
    }

    const getPersonaReniec = async () => {
        try {
            setOpenModalLoader(true);
            setTitleSpinner("Buscando Dni");
            if(infoUser?.numdoc===""){
                simpleAlerts('Debe ingresar un DNI!', 'error', 'colored');
                return;
            } 
            const result = await ExternosService.getPersonaReniec(url, infoUser?.numdoc);
            if (result.status === CODES.SUCCESS_200) {
                let updInfoUser = _.cloneDeep(infoUser);
                updInfoUser.numdoc = result?.data?.numeroDocumento;
                updInfoUser.nombres = result?.data?.nombres;
                updInfoUser.apellidos = result?.data?.apellidoPaterno + ' ' + result?.data?.apellidoMaterno;
                setInfoUser(updInfoUser);

               
            } else {
                simpleAlerts(result.response.data.message, 'error');
            }
               
            return [];
        } catch (error) {
            console.log(`Error en getPersonaReniec: ${error}`);
        } finally {
            setOpenModalLoader(false);
        }
    }

    
    return (
        <>
            {Number(infoUser?.isExternal) === 1 ?
                <>
                    <Grid container>
                        <Grid item md={6} xs={12} display='flex' alignItems={'center'} pt={1}>
                            <MainInput
                                title={'Busqueda'}
                                placeholder={'Ingrese documento / código'}
                                value={searchStaff}
                                setInfo={setSearchStaff}
                                onChange={(e) => setSearchStaff(e.target.value)}
                            />
                            <IconButton aria-label="trabajador" color="primary" size='small' sx={{ marginTop: 2, border: `solid 0.05rem ${colors.secondary.hex}` }}
                                onClick={getStaff}
                            >
                                <PersonSearchIcon fontSize={'small'} />
                            </IconButton>
                        </Grid>
                        <Grid item md={6} xs={12} pt={1}>
                            <MainInput
                                title={'Documento'}
                                placeholder={'Ingrese documento'}
                                value={infoUser?.numdoc}
                                readonly={true}
                                name={'numdoc'}
                                onChange={(e) => setInfoUser({ ...infoUser, numdoc: e.target.value })}
                            />
                        </Grid>
                    </Grid>
                    <span className="badge text-bg-dark">{infoUser?.nombreCompleto}</span>
                </> :
                <Grid container>
                    <Grid item md={6} xs={12} pt={1}>
                        <MainSelect
                            title={'Tipos de documento'}
                            options={typeDocument}
                            value={Number(infoUser?.documento_id)}
                            name={'documento_id'}
                            onChange={(value, event) => setInfoUser({ ...infoUser, documento_id: value })}
                        />
                    </Grid>

                    <Grid item md={6} xs={12} pt={1}
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            //flexWrap: 'wrap',
                            justifyContent: 'left',
                            alignItems: 'end',
                        }}
                    >
                        <MainInput
                            title={'Documento'}
                            placeholder={'Ingrese documento'}
                            value={infoUser?.numdoc}
                            name={'numdoc'}
                            onChange={(e) => setInfoUser({ ...infoUser, numdoc: e.target.value })}
                            required={true}
                        />
                        <IconButton onClick={getPersonaReniec} aria-label="trabajador" color="primary" size='small' sx={{ marginTop: 2, border: `solid 0.05rem ${colors.secondary.hex}` }}>
                            <PersonSearchIcon fontSize={'small'} />
                        </IconButton>
                    </Grid>

                    <Grid item md={6} xs={12} pt={1}>
                        <MainInput
                            title={'Nombres'}
                            value={infoUser?.nombres}
                            placeholder={'Ingrese nombres'}
                            name={'nombres'}
                            onChange={(e) => setInfoUser({ ...infoUser, nombres: e.target.value })}
                            required={true}
                        />
                    </Grid>
                    <Grid item md={6} xs={12} pt={1}>
                        <MainInput
                            title={'Apellidos'}
                            value={infoUser?.apellidos}
                            placeholder={'Ingrese apellidos'}
                            name={'apellidos'}
                            onChange={(e) => setInfoUser({ ...infoUser, apellidos: e.target.value })}
                            required={true}
                        />
                    </Grid>
                </Grid>
            }

            <input type={'hidden'} value={infoUser?.persona_id || ''} name={'persona_id'} />
            <input type={'hidden'} value={infoUser?.id_usuario || ''} name={'id_usuario'} />
            <div style={{ marginTop: 10 }}><label className="badge text-bg-light">Registro de usuario</label></div>
            <Grid container>
                <Grid item md={6} xs={12} pt={1}>
                    <MainInput
                        title={'Usuario'}
                        value={infoUser?.usuario}
                        //readonly={Number(infoUser?.id_usuario) ? true : false}
                        readonly={Number(infoUser?.id_usuario) ? (infoUser?.nombreCompleto?.trim() === ',' ? false : true) : false}
                        name={'usuario'}
                        onChange={(e) => setInfoUser({ ...infoUser, usuario: e.target.value })}
                        required={true}
                    />
                </Grid>
                <Grid item md={6} xs={12} display='flex' alignItems={'center'} pt={1}>
                    <fieldset className='fieldset'>
                        <legend className='title-legend'>Contraseña</legend>
                        <TextField
                            className="text"
                            fullWidth
                            //required={infoUser?.id_usuario ? false : true}
                            required={(infoUser?.id_usuario && (infoUser?.numdoc || infoUser?.persona_id)) ? false : true}

                            size='small'
                            type={viewPassword ? 'text' : 'password'}
                            value={infoUser?.clave || ''}
                            onChange={(e) => setInfoUser({ ...infoUser, clave: e.target.value })}
                            name={'clave'}

                            style={{ borderRadius: '10px' }}
                            inputProps={{
                                style: {
                                    fontSize: 12,
                                    backgroundColor: 'white',
                                    borderRadius: '10px'
                                },
                                pattern: regex?.toString().slice(1, -1),
                                title: "La clave debe tener una longitud de 6 a 10 caracteres, debe tener al menos una mayúscula, un número, un caracter especial(@$!%*?&) y no debe tener espacios en blanco ni /, \\"
                            }}
                            InputLabelProps={{ style: { fontSize: 12 } }} // font size of input label
                        />
                    </fieldset>

                    <IconButton aria-label="trabajador" color="primary" size='small'
                        sx={{ marginTop: 2, }}
                        onClick={() => setViewPassword(!viewPassword)}
                    >
                        {viewPassword ?
                            <RemoveRedEyeIcon fontSize={'small'} /> :
                            <VisibilityOffIcon fontSize={'small'} />
                        }
                    </IconButton>
                </Grid>
                <Grid item md={6} xs={12} pt={1}>
                    <MainSelect
                        title={'Perfiles Lavoro-Assistance'}
                        options={profiles}
                        value={Number(infoUser?.id_Perfil1)}
                        name={'perfil_id1'}
                        onChange={(value, event) => setInfoUser({ ...infoUser, id_Perfil1: value })}
                    />
                </Grid>
                <Grid item md={6} xs={12} pt={1}>
                    <MainSelect
                        title={'Perfiles ASISPROD'}
                        options={profiles}
                        value={Number(infoUser?.id_Perfil2)}
                        name={'perfil_id2'}
                        onChange={(value, event) => setInfoUser({ ...infoUser, id_Perfil2: value })}
                    />
                </Grid>
                <Grid item md={6} xs={12} pt={1}>
                    <MainInput
                        title={'Correo'}
                        type={'email'}
                        value={infoUser?.email}
                        placeholder={"Ingrese correo"}
                        name={'email'}
                        onChange={(e) => setInfoUser({ ...infoUser, email: e.target.value })}
                        required={true}
                    />
                </Grid>
            </Grid>
        </>
    );
};

export default InfoUser;