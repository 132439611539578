import React, { useState } from 'react';
import { ButtonGroup, IconButton, Box, TextField } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import MainWithoutResult from '../../General/MainWithoutResult';
import { OrderedListOutlined, } from '@ant-design/icons/lib/icons';
import { colors } from '../../../../assets/colors';
import { CODES } from '../../../../utils/responseCodes';
import { simpleAlerts } from '../../../../utils/alerts';
import { sortDataListSimple } from '../../../../utils/function';
import ActivityService from '../../../../services/activity';
import { Select } from "antd";
import _ from 'lodash';
import Swal from 'sweetalert2';

const columns = [
  { id: 'id_actividad', label: 'Cód.', width: 50, align: 'center', },
  { id: 'tipoActividad_id', label: 'Tipo de Actividad', width: 200, align: 'left', edit: true, },
  { id: 'desActividad', label: 'Descripción', width: 200, align: 'left', edit: true, },
  { id: 'metaProduccion', label: 'Meta', width: 120, align: 'center', format: (value) => value.toFixed(3), edit: true, },
  { id: 'estado', label: 'Estado', width: 120, align: 'center', edit: true, },
  { id: 'actions', label: 'Acciones', width: 80, align: 'center', edit: true, },
];

const BodyActivities = (props) => {
  const { states, typeActivity, activities, setActivities, setOpenModalLoader, setTitleSpinner } = props;

  const url = "'" + window.location.pathname + "'";

  /** inicio ordenamiento de columnas */
  const [sortConfigTable, setSortConfigTable] = useState({ key: null, direction: "ascending", });

  const handleSortTable = (key) => {
    let direction = "ascending";
    if (sortConfigTable && sortConfigTable.key === key && sortConfigTable.direction === "ascending") {
      direction = "descending";
    }
    setSortConfigTable({ key, direction });
  };

  const sortedData = sortDataListSimple(activities, sortConfigTable);
  /** fin ordenamiento de columnas */

  const changeValue = async (idx, key, value) => {
    let updActivities = _.cloneDeep(activities);
    updActivities[idx][key] = value;
    if (key === 'estado') {
      saveActivity(updActivities[idx], idx, value)
    } else {
      setActivities(updActivities);
    }
  }

  const saveActivity = async (info, idx, value) => {
    let updActivities = _.cloneDeep(activities);

    Swal.fire({
      title: (value === 2 ? '¿Seguro de desactivar la Actividad?' : '¿Seguro de activar la Actividad?'),
      text: (value === 2 ? 'Perdera todos los datos de la Actividad!' : 'Se repondran todos los datos de la Actividad!'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: (value === 2 ? colors.secondary.hex : '#28A745'),
      cancelButtonColor: (value === 2 ? colors.primary.hex : colors.secondary.hex),
      confirmButtonText: (value === 2 ? 'Si, desactivar' : 'Si, sactivar')
    }).then((result) => {
      if (result.isConfirmed) {
        setOpenModalLoader(true);
        setTitleSpinner(value === 2 ? 'Desactivando' : 'Activando');
        setTimeout(async () => {
          try {
            const result = await ActivityService.saveActivity(url, info);
            if (result.status === CODES.CREATE_201) {
              simpleAlerts(result.data.message, 'success');
              setOpenModalLoader(false);
              setTitleSpinner(null);
              simpleAlerts(result.data.message, 'success');
              updActivities[idx] = result.data;
              updActivities[idx]['edit'] = 1;
              return setActivities(updActivities);
            } else {
              simpleAlerts('Error al guardar el perfil', 'error');
            }
          } catch (err) {
            console.log(`Error en editUser ${err}`);
          }
        });
      }
    })
  }

  return (
    <>
      <Box className='page-body-main'>
        <Box className='page-body-table table-responsive-md'>
          <table className="table table-hover table-striped table-bordered">
            <thead className='sticky-top' style={{ backgroundColor: colors.quaternary.hex, color: '#ffff' }}>
              <tr>
                {columns.map((column) => (
                  <th
                    scope="col"
                    key={column.id}
                    style={{
                      textAlign: column.align,
                      width: column.width,
                      maxWidth: column.width,
                      cursor: 'pointer',
                    }}
                    onClick={() => (column.id !== 'actions' && handleSortTable(column.id))}
                  >
                    {<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <div style={{ marginRight: 'auto' }}>{column.label}</div>
                      {column.id !== 'actions' && <div><OrderedListOutlined style={{ paddingBottom: 2 }} /></div>}
                    </div>}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="table-group-divider">
              {sortedData?.map((activity, indexRow) => {
                return (
                  <tr key={"row" + indexRow}>
                    {columns.map((column, indexColumn) => {
                      const value = activity[column.id];
                      return (
                        <td key={'col' + indexColumn} align={column.align}>
                          {
                            column.id === 'actions' ?
                              Number(activity.edit) === 1 ?
                                <ButtonGroup
                                  disableElevation
                                  variant="contained"
                                  aria-label="Disabled elevation buttons"
                                >
                                  <IconButton aria-label="delete" size="small" title='Editar'
                                    onClick={() => changeValue(indexRow, 'edit', 0)}
                                  >
                                    <EditIcon fontSize='small' color={'primary'} />
                                  </IconButton>

                                  <IconButton aria-label="delete" size="small"
                                    onClick={() => changeValue(indexRow, 'estado', (Number(activity?.estado) === 1 ? 2 : 1))} title={Number(activity?.estado) === 1 ? 'Desactivar' : 'Activar'}
                                  >
                                    {Number(activity?.estado) === 1 ?
                                      <ToggleOffIcon fontSize='small' color={'secondary'} /> :
                                      <VolunteerActivismIcon fontSize='small' color={'success'} />
                                    }
                                  </IconButton>
                                </ButtonGroup>
                                : <ButtonGroup
                                  disableElevation
                                  variant="contained"
                                  aria-label="Disabled elevation buttons"
                                >
                                  <IconButton aria-label="delete" size="small"
                                    onClick={() => saveActivity(activity, indexRow)}
                                  >
                                    <SaveIcon fontSize='small' color={'success'} />
                                  </IconButton>
                                  <IconButton aria-label="delete" size="small"
                                    onClick={() => changeValue(indexRow, 'edit', 1)}
                                  >
                                    <CancelIcon fontSize='small' color={'secondary'} />
                                  </IconButton>
                                </ButtonGroup>
                              : Number(activity.edit) === 1 ?
                                column.format ?
                                  column.format(parseFloat(value))
                                  : column.id === 'tipoActividad_id' ?
                                    typeActivity?.find(e => Number(e.value) === Number(value))?.label
                                    : column.id === 'estado' ?
                                      states?.find(e => Number(e.value) === Number(value))?.label
                                      : value
                                :
                                column.id === 'tipoActividad_id' ?
                                  <Select
                                    size={'middle'}
                                    placeholder={'Seleccionar tipo'}
                                    optionFilterProp="children"
                                    showSearch
                                    name={'condicion'}
                                    popupClassName='my-drop-down'
                                    value={Number(activity?.tipoActividad_id)}
                                    onChange={(value, event) => changeValue(indexRow, column.id, value)}
                                    style={{ width: '100%' }}
                                    options={typeActivity}
                                  />
                                  :
                                  column.id === 'estado' ?
                                    <Select
                                      size={'middle'}
                                      placeholder={'Seleccionar tipo'}
                                      optionFilterProp="children"
                                      showSearch
                                      name={'state'}
                                      popupClassName='my-drop-down'
                                      value={Number(activity?.estado)}
                                      onChange={(value, event) => changeValue(indexRow, column.id, value)}
                                      style={{ width: '100%' }}
                                      options={states}
                                    />
                                    :
                                    column.edit ?
                                      <TextField fullWidth
                                        className="input"
                                        size='small'
                                        value={value || ''}
                                        onChange={(e) => changeValue(indexRow, column.id, e.target.value)}
                                        inputProps={{
                                          //step: (column.id === 'metaProduccion' && 1),
                                          min: 0,
                                          type: (column.id === 'metaProduccion' ? 'number' : 'text'),
                                          style: { fontSize: 12, backgroundColor: 'white' },
                                        }}
                                        InputLabelProps={{ style: { fontSize: 12 } }} // font size of input label
                                      />
                                      : value
                          }
                        </td>
                      );
                    })}
                  </tr>
                );
              })}

              {activities?.length === 0 || !activities &&
                <MainWithoutResult />
              }
            </tbody>
          </table>
        </Box>
      </Box>
    </>
  );
}

export default BodyActivities;