import axios from "axios";
import { Global } from '../utils/general';
import headerRequest from "../utils/headers"

const API_URL = Global.url;
const SYS = Global.system;

class AreaService {
    saveArea(url, body) {
        let ruta = `${API_URL}/area?sys=${SYS}&url=${url}`;
        return axios
            .post(ruta, body, {
                headers: headerRequest(),
            })
            .catch((error) => {
                return error;
            });
    }
    
    deleteArea(url, id) {
        let ruta = `${API_URL}/area/${id}?sys=${SYS}&url=${url}`;
        return axios.delete(ruta, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }
}

export default new AreaService();
