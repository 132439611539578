import React, { useState, useEffect } from 'react';
import { Box, } from '@mui/material';

import { OrderedListOutlined, } from '@ant-design/icons/lib/icons';
import SpinnerSmall from '../../SpinnerLoad/SpinnerSmall';
import GroupingUnit from '../../GroupingUnit';
import GeneralService from '../../../../services/general';
import { CODES } from '../../../../utils/responseCodes'
import { simpleAlerts } from '../../../../utils/alerts';
import _ from 'lodash';

const columns = [
    { id: 'sel', label: 'Sel.', width: 10, align: 'center', },
    { id: 'id_sede', label: 'Cód.', width: 30, align: 'left', },
    { id: 'desSede', label: 'Sede', width: 50, align: 'left', },
];

const AssociateSedes = (props) => {
    const { dataRegionSedes,regionSedesCopy,setRegionSedesCopy,regionSedesDelete,setRegiontSedesDelete,dataRegion} = props;
    const [openSmall, setOpenSmall] = useState(false);
    const [infoCompanyMdl, setInfoCompanyMdl] = useState(null);
    const [infoClientMdl, setInfoClientMdl] = useState(null);
    const [infoUnitMdl, setInfoUnitMdl] = useState(null);    
    const [sedes, setSedes] = useState(null);

    useEffect(() => {
        if (infoUnitMdl?.value)
        getSedesByUnidad()
    }, [infoUnitMdl])

    useEffect(() => {
        setRegionSedesCopy(dataRegionSedes)
    }, [dataRegionSedes])


    /** inicio ordenamiento de columnas */
    const [sortConfigTable, setSortConfigTable] = useState({ key: null, direction: "ascending", });

    const handleSortTable = (key) => {
        let direction = "ascending";
        if (sortConfigTable && sortConfigTable.key === key && sortConfigTable.direction === "ascending") {
            direction = "descending";
        }
        setSortConfigTable({ key, direction });
    };

    //const sortedDataAreasCampus = dataSedesCampus?.sort((a, b) => {
        sedes?.sort((a, b) => {
        if (a[sortConfigTable.key] < b[sortConfigTable.key]) {
            return sortConfigTable.direction === "ascending" ? -1 : 1;
        }
        if (a[sortConfigTable.key] > b[sortConfigTable.key]) {
            return sortConfigTable.direction === "ascending" ? 1 : -1;
        }
        return 0;
    });
    /** fin ordenamiento de columnas */

    const handleChangeCheck = (value, p_sede) => {
        let upregionSedesCopy = _.cloneDeep(regionSedesCopy);
        
        let updRegionSedesDelete = _.cloneDeep(regionSedesDelete);
        if (value === false) {
            updRegionSedesDelete.push(upregionSedesCopy?.find(e => Number(e.id_sede) === Number(p_sede.id_sede)));
            upregionSedesCopy = upregionSedesCopy?.filter(e => Number(e.id_sede) !== Number(p_sede.id_sede));
        } else {
            let newItem = {
                "sede_id": Number(p_sede.id_sede),
                "region_id":dataRegion?.id_region,
                "id_sede": Number(p_sede.id_sede),
            }
            //updShiftSedesDelete = updShiftSedesDelete?.filter(e => Number(e.area_id) !== Number(p_sede.id_area));
            upregionSedesCopy.push(newItem);
           
        }

        return setRegionSedesCopy(upregionSedesCopy), setRegiontSedesDelete(updRegionSedesDelete);
    }

    const handleChangeCheckGeneral = (e) => {
        let upregionSedesCopy = _.cloneDeep(regionSedesCopy);
        let updRegionSedesDelete = _.cloneDeep(regionSedesDelete);
        upregionSedesCopy = [];
        updRegionSedesDelete = [];
    
        if (e.target.checked === false) {
            sedes?.forEach((element) => {
                let newItem = {
                    "sede_id": Number(element.id_sede),
                    "region_id":dataRegion?.id_region,
                     "id_sede": element.id_sede
                }
                updRegionSedesDelete.push(newItem)
            });
        } else {
            sedes?.forEach((element) => {
                let newItem = {
                    "sede_id": Number(element.id_sede),
                    "region_id":dataRegion?.id_region,
                    "id_sede": element.id_sede
                }
                upregionSedesCopy.push(newItem)
            });
        }
        return setRegionSedesCopy(upregionSedesCopy), setRegiontSedesDelete(updRegionSedesDelete);
    }

    const getSedesByUnidad = async () => {
        setOpenSmall(true);
        await GeneralService.getSedesByUnidad(infoUnitMdl?.value)
            .then(result => {
           
                if (result.status === CODES.SUCCESS_200) {
                    setSedes(result.data);
                } else {
                    simpleAlerts('No hay información de sedes', 'info');
                }
                setOpenSmall(false);
            })
            .catch(err => {
                console.log('Error en getEmpresas', err)
            })

    }

    return (
        <>
            <GroupingUnit
                infoCompany={infoCompanyMdl} setInfoCompany={setInfoCompanyMdl}
                infoClient={infoClientMdl} setInfoClient={setInfoClientMdl}
                infoUnit={infoUnitMdl} setInfoUnit={setInfoUnitMdl}
            />
            <SpinnerSmall open={openSmall} />
            <Box className='page-body-main'>
                <Box className='page-body-table page-body-table__modal table-responsive-md'>
                    <table className="table table-hover table-striped table-bordered table-sm">
                        <thead className='sticky-top table-warning'>
                            <tr>
                                {columns.map((column) => (
                                    <th
                                        scope="col"
                                        key={column.id}
                                        style={{
                                            textAlign: column.align,
                                            width: column.width,
                                            maxWidth: column.width,
                                            // backgroundColor: 'red',
                                            // color: 'red',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => handleSortTable(column.id)}
                                    >
                                        {column.id === 'sel' ?
                                            <input type="checkbox"
                                                name="check"
                                                id={`checkbox-rect-header`}
                                                className={'checkbox-rect-header'}
                                                onChange={handleChangeCheckGeneral}
                                            />
                                            : <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <div style={{ marginRight: 'auto' }}>{column.label}</div>
                                                <div><OrderedListOutlined style={{ paddingBottom: 2 }} /></div>
                                            </div>}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody className="table-group-divider">
                            {sedes?.map((sede, indexRow) => {
                                return (
                                    <tr key={"row" + indexRow}>
                                        {columns.map((column, indexColumn) => {
                                            const value = sede[column.id];
                                            return (
                                                <td key={'col' + indexColumn} align={column.align}>
                                                    {column.id === 'desSede' ?
                                                        (sede?.desSede) :
                                                        column.id === 'sel' ?
                                                            <div className="checkbox-custom"
                                                                style={{ display: 'flex', justifyContent: 'center' }}
                                                            >
                                                                <input type="checkbox"
                                                                    name="check"
                                                                    id={`checkbox-rect` + sede.id_sede}
                                                                    checked={regionSedesCopy?.find(e => Number(e.id_sede) === Number(sede.id_sede)) ? true : false}
                                                                    onChange={(e) => handleChangeCheck(e.target.checked, sede)}
                                                                />
                                                                <label htmlFor={`checkbox-rect` + sede.id_sede}></label>
                                                            </div>
                                                            : value}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </Box>
            </Box >
        </>
    );
};

export default AssociateSedes;