import React, { useEffect, useState, } from 'react';
import SpinnerLoader from '../../components/SpinnerLoad/SpinnerLoader';
import GeneralService from '../../../services/general';
import AssistanceService from '../../../services/assistance';
import MailsService from '../../../services/mails';
import { simpleAlerts } from '../../../utils/alerts';
import { CODES } from '../../../utils/responseCodes';
import { getDateOL, } from '../../../utils/function';
import { TextareaAutosize, ButtonGroup, IconButton, Box, Grid, Divider, Tabs, Tab } from "@mui/material";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import SaveIcon from '@mui/icons-material/Save';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import AddTaskIcon from '@mui/icons-material/AddTask';
import MainInput from '../../components/General/MainInput';
import MainButton from '../../components/General/MainButton';
import MainSelect from '../../components/General/MainSelect';
import BodyAssistanceApprove from '../../components/Assistance/BodyAssistance/BodyAssistanceApprove';
import BodyAssistanceDelete from '../../components/Assistance/BodyAssistance/BodyAssistanceDelete';

const validationMessages = {
    motive: 'Para denegar debe escribir un motivo!',
    approveLength: 'Debe seleccionar alguna asistencia!',
    stateApprove: 'Debe seleccionar un estado!',
    dateInit: 'Debe seleccionar una fecha inicio!',
    dateEnd: 'Debe seleccionar una fecha fin!',
};

const AssistanceApprove = ({ userToken, }) => {
    const url = "'" + window.location.pathname + "'";
    const id_personaLogin = userToken.user.persona_id;
    const [openModalLoader, setOpenModalLoader] = useState(true);
    const [titleLoader, setTitleLoader] = useState(null);

    const [typeApprove, setTypeApprove] = useState(0);
    const handleChangeTypeApprove = (event, newValue) => setTypeApprove(newValue);

    const [dateInit, setDateInit] = useState(getDateOL('', 'short', -15));
    const [dateEnd, setDateEnd] = useState(getDateOL('', 'short', 15));

    const [search, setSearch] = useState('');
    const [dataStateApprove, setDataStateApprove] = useState(null);
    const [stateApprove, setStateApprove] = useState(1);
    const [listAssistance, setListAssistance] = useState(null);
    const [approved, setApproved] = useState([]);
    const [motive, setMotive] = useState(null);
    const [deny, setDeny] = useState(false);

    useEffect(() => {
        init();
    }, []);

    useEffect(() => {
        setListAssistance([]);
    }, [dateInit, dateEnd, stateApprove, typeApprove])

    const init = async () => {
        Promise.all([
            getEstadoAprobar(),
            searchAssistances(),
        ]).then(() => setOpenModalLoader(false));
    }

    const getEstadoAprobar = async () => {
        try {
            const result = await GeneralService.getEstadoAprobar();
            if (result.status === CODES.SUCCESS_200) {
                result.data?.forEach(element => {
                    element.value = element.id_estadoAprobar
                    element.label = element.desEstadoAprobar
                })
                setDataStateApprove(result.data);
            }
        } catch (error) {
            console.log(`Error en getEstadoAprobar: ${error}`);
        }
    }

    const getAsistenciaByAprobar = async () => {
        if (!stateApprove || stateApprove === 0) {
            simpleAlerts(validationMessages.stateApprove, 'warning')
            return;
        }
        if (!dateInit || dateInit === '') {
            simpleAlerts(validationMessages.dateInit, 'warning')
            return;
        }
        if (!dateEnd || dateEnd === '') {
            simpleAlerts(validationMessages.dateEnd, 'warning')
            return;
        }
        setOpenModalLoader(true);
        setListAssistance(null);
        setApproved([]);
        try {
            const result = await AssistanceService.getAsistenciaByAprobar(url, id_personaLogin, stateApprove, dateInit, dateEnd);
            if (result.status === CODES.SUCCESS_200) {
                setListAssistance(result.data);
            }
        } catch (error) {
            console.log(`Error en getAsistenciaByAprobar: ${error}`);
        } finally {
            setOpenModalLoader(false);
        }
    }

    const saveApproveAssistance = async (estadoAprobar) => {
        if (estadoAprobar === 3 && (motive === '' || !motive)) {
            simpleAlerts(validationMessages.motive, 'warning')
            return;
        }
        if (approved.length === 0) {
            simpleAlerts(validationMessages.approveLength, 'warning')
            return;
        }

        let body = {
            estadoAprobar_id: estadoAprobar,
            motivo: motive,
            asistencias: approved
        }
        setOpenModalLoader(true);
        setTitleLoader('Ejecutando aprobación');
        try {
            const result = await AssistanceService.saveApproveAssistance(url, body);
            if (result.status === CODES.SUCCESS_200) {
                simpleAlerts(result?.data?.message || 'Guardado', 'success');
                sendRequest(estadoAprobar);
            } else {
                setOpenModalLoader(false);
                setTitleLoader(null);
            }
        } catch (err) {
            console.log('Error en updateAsistencia', err);
        }
    };

    const searchAssistances = () => {
        if (typeApprove === 0) {
            getAsistenciaByAprobar();
        } else {
            getAsistenciaToEliminar();
        }
    };

    const getAsistenciaToEliminar = async () => {
        if (!stateApprove || stateApprove === 0) {
            simpleAlerts(validationMessages.stateApprove, 'warning')
            return;
        }
        if (!dateInit || dateInit === '') {
            simpleAlerts(validationMessages.dateInit, 'warning')
            return;
        }
        if (!dateEnd || dateEnd === '') {
            simpleAlerts(validationMessages.dateEnd, 'warning')
            return;
        }
        setOpenModalLoader(true);
        setListAssistance(null);
        setApproved([]);
        try {
            const result = await AssistanceService.getAsistenciaToEliminar(url, id_personaLogin, stateApprove, dateInit, dateEnd);
            if (result.status === CODES.SUCCESS_200) {
                setListAssistance(result.data);
            }
        } catch (error) {
            console.log(`Error en getAsistenciaToEliminar: ${error}`);
        } finally {
            setOpenModalLoader(false);
        }
    };

    const saveDeleteAssistance = async (estadoAprobar) => {
        if (estadoAprobar === 3 && (motive === '' || !motive)) {
            simpleAlerts(validationMessages.motive, 'warning')
            return;
        }
        if (approved.length === 0) {
            simpleAlerts(validationMessages.approveLength, 'warning')
            return;
        }
        let body = {
            estadoAprobar_id: estadoAprobar,
            motivo: motive,
            asistencias: approved
        }
        setOpenModalLoader(true);
        setTitleLoader('Ejecutando eliminación');
        try {
            const result = await AssistanceService.saveDeleteAssistance(url, body);
            if (result.status === CODES.SUCCESS_200) {
                simpleAlerts(result?.data?.message || 'Guardado', 'success');
                sendRequest(estadoAprobar);
            } else {
                setOpenModalLoader(false);
                setTitleLoader(null);
            }
        } catch (err) {
            console.log('Error en updateAsistencia', err);
        }
    };

    const saveDecisionAssistance = (estadoAprobar) => {
        if (typeApprove === 0) {
            saveApproveAssistance(estadoAprobar);
        } else {
            saveDeleteAssistance(estadoAprobar);
        }
    };

    const sendRequest = async (estadoAprobar) => {
        const body = {
            decision: dataStateApprove.find(e => +e.value === +estadoAprobar)?.label,
            motivo: motive,
            resume: ordenarInfo(approved, 'id_solicitante'),
        }
        try {
            setOpenModalLoader(true);
            setTitleLoader('Enviando solicitud!');
            const result = await MailsService.sendAuthorizationDecision(url, body);
            if (result.status === CODES.SUCCESS_200) {
                simpleAlerts(result?.data?.message || 'Enviado!', 'success');
            } else {
                simpleAlerts('No se pudo enviar correo al aprobador, comuniquese con él!', 'error');
            }
            return []
        } catch (error) {
            console.log(`Error en sendRequest: ${error}`);
        } finally {
            setOpenModalLoader(false);
            setTitleLoader(null);

            searchAssistances();
            setMotive(null);
            setDeny(false);
        }
    }

    const ordenarInfo = (datos, propiedad) => {
        const resultado = [];
        const mapaElementos = new Map();

        datos.forEach((item) => {
            const clave = item[propiedad];

            if (!mapaElementos.has(clave)) {
                mapaElementos.set(clave, []);
            }

            mapaElementos.get(clave).push(item);
        });

        mapaElementos.forEach((elemento) => {
            resultado.push(elemento);
        });

        return resultado;
    };

    return (
        <div>
            <SpinnerLoader open={openModalLoader} title={titleLoader} />
            <Grid container direction="row" justifyContent="center" alignItems="end" spacing={2}>
                <Grid item xs={11} sm={6} md={3} display={'flex'} justifyContent={'center'} alignItems={'end'}>
                    <MainInput
                        placeholder={'Busqueda solicitante / trabajador'}
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                    <MainSelect
                        title={'Estado'}
                        options={dataStateApprove}
                        value={+stateApprove}
                        onChange={(value, event) => setStateApprove(+value)}
                    />
                </Grid>
                <Grid item xs={8} sm={6} md={3}>
                    <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                        <MainInput
                            title={'Fecha Inicio'}
                            placeholder={'Ingrese Fecha Inicio'}
                            type={'date'}
                            value={dateInit}
                            onChange={(e) => setDateInit(e.target.value)}
                        />
                        <MainInput
                            title={'Fecha Fin'}
                            placeholder={'Ingrese Fecha Fin'}
                            type={'date'}
                            value={dateEnd}
                            onChange={(e) => setDateEnd(e.target.value)}
                        />
                    </Box>
                </Grid>
                {!deny ?
                    <Grid item xs={2} sm={3} display={'flex'} justifyContent={'space-evenly'}>
                        <MainButton
                            onClick={searchAssistances}
                            title={'Buscar'}
                        />
                        <ButtonGroup disableElevation variant="contained" aria-label="Disabled elevation buttons">
                            <IconButton color='secondary' aria-label="Rechazar" title="Rechazar" size="small" style={{ border: `solid 1px` }}
                                onClick={() => setDeny(!deny)}
                            >
                                <DoDisturbIcon fontSize={'12px'} />
                            </IconButton>
                            &nbsp;&nbsp;
                            <IconButton color='success' aria-label="Aprobar" title="Aprobar" size="small" style={{ border: `solid 1px` }}
                                onClick={() => { saveDecisionAssistance(2); }}
                            >
                                <AddTaskIcon fontSize={'12px'} />
                            </IconButton>
                        </ButtonGroup>
                    </Grid>
                    : <Grid item xs={2} sm={3}>
                        <div style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }} >
                            <TextareaAutosize
                                aria-label="Motivo de rechazo"
                                minRows={1}
                                maxRows={2}
                                placeholder="Motivo (100 Max.)"
                                style={{ padding: '0px 5px 3px 5px', fontStyle: 'italic', }}
                                value={motive || ''}
                                maxLength={100}
                                onChange={(e) => setMotive(e.target.value)}
                            />
                            <IconButton color='success' aria-label="Guardar" title="Guardar" size="small" style={{ border: `solid 1px` }}
                                onClick={() => { saveDecisionAssistance(3); }}
                            >
                                <SaveIcon fontSize={'12px'} />
                            </IconButton>
                            <IconButton aria-label="Cancelar" title="Cancelar" size="small" style={{ border: `solid 1px` }}
                                onClick={() => setDeny(!deny)}
                                color='secondary'
                            >
                                <HighlightOffIcon fontSize={'12px'} />
                            </IconButton>
                        </div>
                    </Grid>
                }
            </Grid>

            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={typeApprove} onChange={handleChangeTypeApprove} aria-label="basic tabs example" centered>
                        <Tab label="Futuras" />
                        <Tab label="Eliminación" />
                    </Tabs>
                </Box>
            </Box>
            <Divider className="divider" sx={{ margin: '0.7rem 0.8rem 0 0.8rem' }} />

            <Grid container mt={1} direction="row" justifyContent="center" alignItems="center" >
                <Grid item xs={12} paddingLeft={'1rem'} paddingRight={'1rem'}>
                    {typeApprove === 0 ?
                        <BodyAssistanceApprove
                            stateApprove={stateApprove}
                            search={search}
                            data={listAssistance}
                            approved={approved}
                            setApproved={setApproved}
                        />
                        : <BodyAssistanceDelete
                            stateApprove={stateApprove}
                            search={search}
                            data={listAssistance}
                            approved={approved}
                            setApproved={setApproved}
                        />
                    }
                </Grid>
            </Grid>
        </div >
    );
};

export default AssistanceApprove;