import React, { useEffect, } from 'react';
import RefreshIcon from '@mui/icons-material/Refresh';
import AssistanceService from '../../../services/assistance';
import { simpleAlerts } from '../../../utils/alerts';
import { CODES } from '../../../utils/responseCodes';
import { Box, Grid, Divider, IconButton } from "@mui/material";
import MainButton from '../../components/General/MainButton';
import BodyAssistance from '../../components/Assistance/Movil/BodyAssistance';

const AssistanceMovil = ({
    userToken, setOpenModalLoader, setTitleLoader, getAsistenciaSimple,
    processInformation, validationMessages, areasSelected, shiftSelected,
    dateSelected, dataAssistance, workerSelected, setWorkerSelected, }) => {
    const url = "'" + window.location.pathname + "'";
    const id_usuarioLogin = userToken.user.id_usuario;
    const id_personaLogin = userToken.user.persona_id;

    useEffect(() => {
        if (areasSelected && shiftSelected && +areasSelected > 0 && +shiftSelected > 0) {
            getAsistenciaSimple();
        }
    }, [areasSelected, shiftSelected]);

    const saveDeleteAssistances = async () => {
        if (!workerSelected || workerSelected?.length === 0) {
            simpleAlerts(validationMessages.worker);
            return;
        }
        try {
            setOpenModalLoader(true);
            setTitleLoader(`Borrando asistencias ....`);
            const savePromises = workerSelected.map(async (worker) => {
                let body = {
                    "persona_id": worker.persona_id,
                    "fecha": dateSelected,
                    "area_id": areasSelected,
                    "turno_id": shiftSelected,
                    "supervisor_id": id_personaLogin,
                    "usuario_id": id_usuarioLogin,
                    "ingreso": null,
                    "salida": null,
                    "asisCond_id": null
                };
                try {
                    const result = await AssistanceService.updateAddAsistenciaMovil(url, body);
                    if (result.status !== CODES.SUCCESS_200) {
                        simpleAlerts('Error al guardar la fatla', "error");
                    }
                } catch (error) {
                    console.log(`Error en updateAddAsistenciaMovil: ${error}`);
                }
            });

            await Promise.all([...savePromises,]);
            simpleAlerts("Asistencias borradas", "success");
            getAsistenciaSimple();
        } catch (error) {
            console.log(`Error en updateAddAsistenciaMovil: ${error}`);
        }
    };

    return (
        <div>
            <Grid container direction="row" justifyContent="center">
                <Grid item xs={11} md={6}>
                    <Box
                        sx={{
                            display: 'grid',
                            gridTemplateColumns: '1fr 1fr 1fr',
                            gap: '10px',
                            flexWrap: 'wrap',
                            justifyContent: 'center',
                        }}
                    >
                        <MainButton
                            onClick={() => processInformation(0, 'Agregar trabajador', 'ASIS')}
                            typeStyle={'outlined'}
                            color={'primary'}
                            title={'Agregar'}
                        />
                        <MainButton
                            onClick={() => processInformation(1, 'Horario de trabajo', 'ASIS')}
                            typeStyle={'outlined'}
                            color={'success'}
                            title={'Horario'}
                        />
                        <MainButton
                            onClick={() => processInformation(3, 'Ausecnia de trabajadores', 'ASIS')}
                            typeStyle={'outlined'}
                            color={'warning'}
                            title={'Ausencia'}
                        />
                        <MainButton
                            onClick={saveDeleteAssistances}
                            typeStyle={'outlined'}
                            color={'secondary'}
                            title={'Borrar'}
                        />
                        <MainButton
                            onClick={() => processInformation(2, 'Puesto de trabajo', 'ASIS')}
                            typeStyle={'outlined'}
                            color={'tertiary'}
                            title={'Puesto'}
                        />
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}
                        >
                            <IconButton aria-label="refresh" color='primary' style={{ border: '1px solid' }} size='small' onClick={getAsistenciaSimple}>
                                <RefreshIcon fontSize='1px' />
                            </IconButton>
                        </div>
                    </Box>
                </Grid>
            </Grid>
            <Divider className="divider" sx={{ margin: '0.7rem 0.8rem 0 0.8rem' }} />

            <Grid container direction="row" justifyContent="center" mt={1}>
                <Grid item xs={12} md={6}>
                    <BodyAssistance
                        dataAssistance={dataAssistance}
                        workerSelected={workerSelected}
                        setWorkerSelected={setWorkerSelected}
                    />
                </Grid>
            </Grid>
        </div>
    );
};

export default AssistanceMovil;