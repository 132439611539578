import React, { useState, } from 'react';
import { OrderedListOutlined, } from '@ant-design/icons/lib/icons';
import { Box, ButtonGroup, IconButton } from '@mui/material';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import MainInput from '../../General/MainInput';
import MainWithoutResult from '../../General/MainWithoutResult';
import { colors } from '../../../../assets/colors';
import { filterData, sortDataListSimple } from '../../../../utils/function';
import _ from 'lodash';

const columns = [
  { id: 'sel', label: 'sel', width: 50, align: 'center', },
  { id: 'id_sede', label: '#', width: 50, align: 'center', },
  { id: 'codSede', label: 'Código', width: 100, align: 'left', },
  { id: 'desSede', label: 'Descripción', width: 200, align: 'left', },
  { id: 'FecDesde', label: 'Fecha Inicio', width:135, align: 'center', edit: true, },
  { id: 'FecHasta', label: 'Fecha Fin', width: 135, align: 'center', edit: true, },
  { id: 'show', label: 'Detalle', width: 90, align: 'center', },
];

const BodyOpen = (props) => {
  const { handleOpen, checkGeneral, setCheckGeneral, sedes, setSedes, sedesOpen, setSedesOpen, setInfoSede } = props;

  const [search, setSearch] = useState('');

  let resultSearch = filterData(sedes, search, ['codSede', 'desSede']);
  /** inicio ordenamiento de columnas */
  const [sortConfigTable, setSortConfigTable] = useState({ key: null, direction: "ascending", });
  const handleSortTable = (key) => {
    let direction = "ascending";
    if (sortConfigTable && sortConfigTable.key === key && sortConfigTable.direction === "ascending") {
      direction = "descending";
    }
    setSortConfigTable({ key, direction });
  };
  const sortedData = sortDataListSimple(resultSearch, sortConfigTable); //sortDataListSimple(dataWorkers, sortConfigTable);
  /** fin ordenamiento de columnas */

  /** */
  const handleChangeCheckGeneral = (e) => {
    let updSedesOpen = _.cloneDeep(sedesOpen);

    if (e.target.checked === true) {
      sedes?.forEach((element) => {
        if (!updSedesOpen?.find(e => Number(e.id_sede) === Number(element.id_sede))) {
          updSedesOpen.push(element)
        }
      });
    } else {
      sedes?.forEach((element) => {
        updSedesOpen = updSedesOpen?.filter(e => Number(e.id_sede) !== Number(element.id_sede));
      });
    }

    return setSedesOpen(updSedesOpen), setCheckGeneral(e.target.checked);
  }

  const handleChangeCheck = (value, p_sede) => {
    let updSedesOpen = _.cloneDeep(sedesOpen);

    if (value === false) {
      updSedesOpen = updSedesOpen?.filter(e => Number(e.id_sede) !== Number(p_sede.id_sede));
    } else {
      updSedesOpen.push(p_sede);
    }

    return setSedesOpen(updSedesOpen);
  }

  const changeValue = (idx, key, value) => {
    let updData = _.cloneDeep(sedes);
    updData[idx][key] = value;
    return setSedes(updData);
  }

  return (
    <>
      <MainInput
        placeholder={'Busqueda descripcion / código'}
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />
      <Box className='page-body-main' mt={1}>
        <Box className='page-body-table table-responsive-md' sx={{ height: "25rem", }}>
          <table className="table table-hover table-striped table-bordered">
            <thead className='sticky-top' style={{ backgroundColor: colors.quaternary.hex, color: '#ffff',whiteSpace:'nowrap' }}>
              <tr>
                {columns.map((column) => (
                  <th
                    scope="col"
                    key={column.id}
                    style={{
                      textAlign: column.align,
                      width: column.width,
                      maxWidth: column.width,
                      cursor: 'pointer',
                    }}
                    onClick={() => handleSortTable(column.id)}
                  >
                    {column.id === 'sel' ?
                      <input type="checkbox"
                        name="check"
                        id={`checkbox-rect-header`}
                        className={'checkbox-rect-header'}
                        checked={checkGeneral}
                        onChange={handleChangeCheckGeneral}
                      /> :
                      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div style={{ marginRight: 'auto' }}>{column.label}</div>
                        {(column.id !== 'id_sede' && column.id !== 'fechas') && <div><OrderedListOutlined style={{ paddingBottom: 2 }} /></div>}
                      </div>}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="table-group-divider">
              {sortedData?.map((sede, indexRow) => {
                return (
                  <tr key={"row" + indexRow} style={{ whiteSpace:'nowrap' }}>
                    {columns.map((column, indexColumn) => {
                      const value = sede[column.id];
                      return (
                        <td key={'col' + indexColumn}
                          style={{
                            textAlign: column.align,
                            width: column.width,
                            maxWidth: column.width,
                          }}
                        >
                          {column.id === 'sel' ?
                            <div className="checkbox-custom"
                              style={{ display: 'flex', justifyContent: 'center' }}
                            >
                              <input type="checkbox"
                                name="check"
                                id={`checkbox-rect` + sede.id_sede}
                                checked={sedesOpen?.find(e => Number(e.id_sede) === Number(sede.id_sede)) ? true : false}
                                onChange={(e) => handleChangeCheck(e.target.checked, sede)}
                              />
                              <label htmlFor={`checkbox-rect` + sede.id_sede}></label>
                            </div> :
                            column.id === 'show' ?
                              <ButtonGroup
                                disableElevation
                                variant="contained"
                                aria-label="Disabled elevation buttons"
                              >
                                <IconButton aria-label="delete" size="small" title='Ver detalle'
                                  onClick={() => { setInfoSede(sede); handleOpen() }}
                                >
                                  <RemoveRedEyeIcon fontSize='small' color={'primary'} />
                                </IconButton>
                              </ButtonGroup>
                              : column.edit ?
                                <MainInput
                                  type={'date'}
                                  value={value}
                                  onChange={(e) => changeValue(indexRow, column.id, e.target.value)}
                                  name='inicio'
                                  required={true}
                                />
                                : value}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
              {(sortedData?.length === 0 || !sortedData) &&
                <MainWithoutResult />
              }
            </tbody>
          </table>
        </Box>
      </Box>
    </>
  );
}

export default BodyOpen;