import React, { useState, useEffect } from 'react';
import { Box, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { OrderedListOutlined, } from '@ant-design/icons/lib/icons';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { sortDataList, sortDataListSimple } from '../../../../utils/function';
import _ from 'lodash';
import { simpleAlerts } from '../../../../utils/alerts';
import AssignmentService from '../../../../services/assignment';
import { CODES } from '../../../../utils/responseCodes';
import SpinnerLoader from '../../../components/SpinnerLoad/SpinnerLoader';

const columns = [
    { id: 'numdoc', label: 'Cód.', width: 20, align: 'left', },
    { id: 'trabajador', label: 'Trabajador', width: 80, align: 'left', },
    { id: 'agregar', label: 'Agregar', width: 50, align: 'center', },
];

const AssociateAreas = (props) => {
    const { dataWorkerExt, infoArea, id_usuarioLogin } = props;

    const url = "'" + window.location.pathname + "'";
    const [openModalLoader, setOpenModalLoader] = useState(false);
    const [titleModalLoader, setTitleModalLoader] = useState(null);

    /** inicio ordenamiento de columnas */
    const [sortConfigTable, setSortConfigTable] = useState({ key: null, direction: "ascending", });

    const handleSortTable = (key) => {
        let direction = "ascending";
        if (sortConfigTable && sortConfigTable.key === key && sortConfigTable.direction === "ascending") {
            direction = "descending";
        }
        setSortConfigTable({ key, direction });
    };

    const sortedData = sortDataListSimple(dataWorkerExt, sortConfigTable);
    /** fin ordenamiento de columnas */

    const saveWorkerAreaAsignacion = async (worker) => {
        if (infoArea) {
            try {
                let body = {
                    'numdoc': worker.numdoc,
                    'trabajador': worker.trabajador,
                    'id_persona': worker.id_persona,
                    'id_area': infoArea?.id_area,
                    'id_usuario': id_usuarioLogin
                }
                try {
                    setTitleModalLoader("Guardando Trabajador");
                    setOpenModalLoader(true);
                    const result = await AssignmentService.saveAsignacionArea(url, body);
                    if (result.status === CODES.SUCCESS_200) {
                        simpleAlerts(result.data.message, (Number(result.data.status) === 1 ? "success" : "error"));
                    }
                } catch (error) {
                    console.log(`Error en saveAsignacionArea: ${error}`);
                }
                setOpenModalLoader(false);
                setTitleModalLoader(null);
                simpleAlerts("Fin de Asignación", "success");

            } catch (error) {
                console.log(`Error en saveWorkerAreaAsignacion: ${error}`);
            }
        } else {
            simpleAlerts("Debes seleccionar Area", "warning");
        }

    }

    return (
        <>

            <Box className='page-body-main'>
                <Box className='page-body-table page-body-table__modal table-responsive-md'>
                    <table className="table table-hover table-striped table-bordered table-sm">
                        <thead className='sticky-top table-warning'>
                            <tr>
                                {columns.map((column) => (
                                    <th
                                        scope="col"
                                        key={column.id}
                                        style={{
                                            textAlign: column.align,
                                            width: column.width,
                                            maxWidth: column.width,
                                            // backgroundColor: 'red',
                                            // color: 'red',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => handleSortTable(column.id)}
                                    >
                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <div style={{ marginRight: 'auto' }}>{column.label}</div>
                                            <div><OrderedListOutlined style={{ paddingBottom: 2 }} /></div>
                                        </div>
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody className="table-group-divider">
                            {sortedData?.map((worker, indexRow) => {

                                return (
                                    <tr key={"row" + indexRow}>
                                        {columns.map((column, indexColumn) => {
                                            const value = worker[column.id];
                                            return (
                                                <td key={'col' + indexColumn} align={column.align}>
                                                    {column.id === 'agregar' ?
                                                        <IconButton aria-label="delete" size="small" title='Editar'
                                                            onClick={() => saveWorkerAreaAsignacion(worker)}

                                                        >
                                                            <CheckCircleIcon fontSize='medium' color={'success'} />
                                                        </IconButton>
                                                        : value}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </Box>
                <SpinnerLoader open={openModalLoader} title={titleModalLoader} />
            </Box >
        </>
    );
};

export default AssociateAreas;