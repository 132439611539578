import React, { } from 'react';
import { ButtonGroup, IconButton, Box, TextField } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import AssistanceService from '../../../../services/assistance';
import { formatDate, minutesDiff } from '../../../../utils/function';
import { colors } from '../../../../assets/colors';
import { CODES } from '../../../../utils/responseCodes';
import { simpleAlerts } from '../../../../utils/alerts';
import { Select } from "antd";
import _ from 'lodash';

const BodyAssistance = (props) => {
  const { id_usuarioLogin, dataAssistanceCondition, assistanceConditionEditable, dataShift,
    columns, setColumns, dataAssistance, setDataAssistance, deleteAssistance } = props;

  const url = "'" + window.location.pathname + "'";
  const changeValue = (idx, key, value) => {
    let updDataAssistance = _.cloneDeep(dataAssistance);
    if (key === 'condicion') {
      if (!assistanceConditionEditable?.find(e => Number(e.id_asisCond) === value)) {
        updDataAssistance[idx]['horaIngreso'] = null;
        updDataAssistance[idx]['horaSalida'] = null;
      }
    }
    if (key === 'horaIngreso') {
      if (value) {
        let ingfecha = new Date(updDataAssistance[idx]['fecha'] + ' ' + value + ':00.000');
        let salFecha = new Date(updDataAssistance[idx]['fecha'] + ' ' + updDataAssistance[idx]['horaSalida'] + ':00.000');
        if (salFecha.valueOf() < ingfecha.valueOf()) {
          salFecha.setDate(salFecha.getDate() + 1);
        }
        updDataAssistance[idx]["ingreso"] = formatDate(ingfecha)
        updDataAssistance[idx]["fechaIngreso"] = formatDate(ingfecha, 'date')
        updDataAssistance[idx]["horaIngreso"] = formatDate(ingfecha, 'time')
      } else {
        updDataAssistance[idx]["ingreso"] = null
        updDataAssistance[idx]["fechaIngreso"] = null
        updDataAssistance[idx]["horaIngreso"] = null
      }
    }

    if (key === 'horaSalida') {
      if (value) {
        let ingfecha = new Date(updDataAssistance[idx]['fecha'] + ' ' + updDataAssistance[idx]['horaIngreso'] + ':00.000');
        let salFecha = new Date(updDataAssistance[idx]['fecha'] + ' ' + value + ':00.000');
        if (salFecha.valueOf() < ingfecha.valueOf()) {
          salFecha.setDate(salFecha.getDate() + 1);
        }
        updDataAssistance[idx]["salida"] = formatDate(salFecha)
        updDataAssistance[idx]["fechaSalida"] = formatDate(salFecha, 'date')
        updDataAssistance[idx]["horaSalida"] = formatDate(salFecha, 'time')
      } else {
        updDataAssistance[idx]["salida"] = null
        updDataAssistance[idx]["fechaSalida"] = null
        updDataAssistance[idx]["horaSalida"] = null
      }
    }
    updDataAssistance[idx][key] = value;
    updDataAssistance[idx]['edit'] = 1;
    return setDataAssistance(updDataAssistance);
  }

  const saveAssistance = async (info) => {
    info.usuario_id = id_usuarioLogin;
    const condicion = dataAssistanceCondition?.find(e => +e.id_asisCond === +info?.condicion);
    if (+condicion?.conMarca === 1 && (!info.ingreso || !info.salida)) {
      simpleAlerts("Verifique el ingreso y la salida registrada!");
      return;
    }
    if (+info?.condicion === 17) {
      simpleAlerts("Verifique la condición!");
      return;
    }
    try {
      const result = await AssistanceService.updateAsistencia(url, info);
      if (result.status === CODES.SUCCESS_200) {
        simpleAlerts(result.data.message, (Number(result.data.status) === 1 ? 'success' : 'error'));
      } else {
        simpleAlerts('Error en el procedimiento', 'error');
      }
    } catch (err) {
      console.log('updateAsistencia', err);
    }
  };

  /*Inicio mover columnas*/
  const handleDragStart = (e, index) => {
    e.dataTransfer.setData('index', index);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e, newIndex) => {
    const oldIndex = e.dataTransfer.getData('index');
    const newColumnas = [...columns];
    const [movedItem] = newColumnas.splice(oldIndex, 1);
    newColumnas.splice(newIndex, 0, movedItem);
    setColumns(newColumnas);
  };

  /*Fin mover columnas*/

  return (
    <Box className='page-body-main'>
      <Box className='page-body-table table-responsive-md'>
        <table className="table table-hover table-bordered">
          <thead className='sticky-top' style={{ backgroundColor: colors.quaternary.hex, color: '#ffff' }}>
            <tr>
              {columns?.map((col, idxCol) => {
                return (
                  col.view === true &&
                  <th
                    scope="col"
                    key={col.id}
                    style={{
                      textAlign: col.align,
                      minWidth: col.width,
                    }}
                    draggable
                    onDragStart={(e) => handleDragStart(e, idxCol)}
                    onDragOver={handleDragOver}
                    onDrop={(e) => handleDrop(e, idxCol)}
                  >
                    {col.id === 'actions' ?
                      <SettingsSuggestIcon sx={{ fontSize: '1rem' }} />
                      : col.label
                    }
                  </th>
                )
              })}
            </tr>
          </thead>
          <tbody className="table-group-divider font-table">
            {dataAssistance?.map((asis, indexRow) => {
              return (
                <tr key={"row" + indexRow} className={`align-middle ${+asis.cierre === 1 && 'table-info'}`}
                  style={{
                    backgroundColor:
                      ((+asis?.condicion === 17 || +asis?.turno === 1) ?
                        '#FFDFDC'
                        : minutesDiff(asis.ingreso, (asis.fecha + ' ' + asis.turnoIngreso)) >= 60 ?
                          '#FFF2CC'
                          : '')
                  }}
                >
                  {columns.map((column, indexColumn) => {
                    const value = asis[column.id];
                    return (
                      column.view === true &&
                      <td key={'col' + indexColumn} align={column.align}>
                        {+asis.cierre === 1 ?
                          (column.id === 'totalhoras' ?
                            (isNaN(parseFloat(value)) ? value : parseFloat(value)?.toFixed(3))
                            : column.id === 'turno' ?
                              dataShift?.find(e => +e.value === +value)?.label
                              : column.id === 'condicion' ?
                                dataAssistanceCondition?.find(e => +e.value === +value)?.label
                                : value
                          )
                          : column.id === 'actions' ?
                            <ButtonGroup disableElevation variant="contained" aria-label="Disabled elevation buttons">
                              <IconButton aria-label="delete" size="small" style={{ border: `solid 1px` }} color='error'
                                onClick={() => { deleteAssistance(asis); }}
                              >
                                <DeleteForeverIcon fontSize={'10px'} />
                              </IconButton>
                              &nbsp;
                              <IconButton aria-label="save" size="small" style={{ border: `solid 1px` }} color={'success'}
                                onClick={() => { saveAssistance(asis); }}
                              >
                                <SaveIcon fontSize={'10px'} />
                              </IconButton>
                            </ButtonGroup>
                            : column.id === 'turno' ?
                              assistanceConditionEditable?.find(e => Number(e.id_asisCond) === Number(asis.condicion)) ?
                                <Select
                                  placeholder={'Seleccionar turno'}
                                  size={'middle'}
                                  optionFilterProp="children"
                                  showSearch
                                  name={'turno'}
                                  popupClassName='my-drop-down'
                                  value={value}
                                  onChange={(value, event) => changeValue(indexRow, column.id, value)}
                                  style={{ width: '100%' }}
                                  options={dataShift}
                                />
                                : asis.desTurno
                              : column.id === 'condicion' ?
                                <Select
                                  placeholder={'Seleccionar condición'}
                                  size={'middle'}
                                  optionFilterProp="children"
                                  showSearch
                                  name={'condicion'}
                                  popupClassName='my-drop-down'
                                  value={Number(value)}
                                  onChange={(value, event) => changeValue(indexRow, 'condicion', value)}
                                  style={{ width: '100%' }}
                                  options={dataAssistanceCondition}
                                />
                                : column.label === 'Ingreso' || column.label === 'Salida' ?
                                  assistanceConditionEditable?.find(e => Number(e.id_asisCond) === Number(asis.condicion)) ?
                                    <TextField fullWidth
                                      className="input"
                                      size='small'
                                      type={'time'}
                                      value={value}
                                      onChange={(e) => changeValue(indexRow, column.id, e.target.value)}
                                      inputProps={{ style: { fontSize: 12, backgroundColor: 'white' }, }}
                                      InputLabelProps={{ style: { fontSize: 12 } }}
                                    />
                                    : value
                                  : column.id === 'totalhoras' ?
                                    (isNaN(parseFloat(value)) ? value : parseFloat(value)?.toFixed(3))
                                    : column.type === 'edit' ?
                                      <TextField fullWidth
                                        className="input"
                                        size='small'
                                        type={'number'}
                                        value={value}
                                        onChange={(e) => changeValue(indexRow, column.id, e.target.value)}
                                        inputProps={{ style: { fontSize: 12, backgroundColor: 'white' }, }}
                                        InputLabelProps={{ style: { fontSize: 12 } }}
                                      />
                                      : value
                        }
                      </td>
                    );
                  })}

                </tr>
              );
            })}
          </tbody>
        </table>
      </Box>
    </Box>
  );
}

export default BodyAssistance;