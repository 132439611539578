import React, { useEffect, useState } from 'react';
import { Box, Grid, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import MainButton from '../../../components/General/MainButton';
import MainSelect from '../../../components/General/MainSelect';
import AsistenciaPuestoService from '../../../../services/AsistenciaPuesto'
import { CODES } from '../../../../utils/responseCodes';
import { simpleAlerts } from '../../../../utils/alerts';
import SpinnerLoader from '../../../components/SpinnerLoad/SpinnerLoader';
import BodyAreaAsistenciaPuesto from '../../../components/AsistenciaPuesto/BodyAreaAsistenciaPuesto/BodyAreaAsistenciaPuesto';
import AddAreaAsistenciaPuesto from './AddAreaAsistenciaPuesto/AddAreaAsistenciaPuesto';

const AreaAsistenciaPuesto = ({ userToken }) => {
    const url = "'" + window.location.pathname + "'";
    const [titleSpinner, setTitleSpinner] = useState(null);
    const [openModalLoader, setOpenModalLoader] = useState(true);
    const [open, setOpen] = useState(false)
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [asistenciaPuesto, setAsistenciaPuesto] = useState(null);
    const [dataAsistenciaPuesto, setDataAsistenciaPuesto] = useState(null);
    const [dataAreaAsistenciaPuestos, setDataAreaAsistenciaPuestos] = useState(null);

    useEffect(() => {
        init();
    }, [])

    useEffect(() => {
        if (dataAsistenciaPuesto?.id_asistenciaPuesto)
            getAreaAsistenciaPuestos();
    }, [dataAsistenciaPuesto])

    const init = async () => {
        Promise.all([
            getAsistenciaPuesto(),
        ]).then(() => setOpenModalLoader(false));
    }

    const getAsistenciaPuesto = async () => {
        try {
            const result = await AsistenciaPuestoService.getAsistenciaPuesto();
            if (result.status === CODES.SUCCESS_200) {
                result.data?.forEach(element => {
                    element.value = element.id_asistenciaPuesto;
                    element.label = element.ubicacion+" - "+element.puesto;
                    
                })
                setAsistenciaPuesto(result.data);
            } else {
                simpleAlerts('Hubo un error la relacion usuarios areas', 'error');
            }
            return [];
        } catch (error) {
            console.log(`Error en getUsuarioAreas: ${error}`);
        }
    }

    const getAreaAsistenciaPuestos = async () => {
       
        setOpenModalLoader(true);
        setTitleSpinner('Cargando...');
        try {
            const result = await AsistenciaPuestoService.getAreaAsistenciaPuestos(url, dataAsistenciaPuesto?.id_asistenciaPuesto);
            if (result.status === CODES.SUCCESS_200)
                 setDataAreaAsistenciaPuestos(result.data);
            else
                simpleAlerts('Hubo un error la relacion area asistencia-puesto', 'error');
            return [];
        } catch (error) {
            console.log(`Error en getAreaAsistenciaPuestos: ${error}`);
        } finally {
            setOpenModalLoader(false);
            setTitleSpinner(null);
        }
    }
    return (
        <Box sx={{ flexGrow: 1 }}>
            <AddAreaAsistenciaPuesto
                open={open}
                handleClose={handleClose}
                setOpenModalLoader={setOpenModalLoader}
                setTitleSpinner={setTitleSpinner}
                dataAsistenciaPuesto={dataAsistenciaPuesto}
                dataAreaAsistenciaPuestos={dataAreaAsistenciaPuestos}
                getAreaAsistenciaPuestos={getAreaAsistenciaPuestos}
            />
            <Box display={'flex'} justifyContent={'space-evenly'} alignItems={'center'}>
                <Box sx={{ width: '30%' }}>
                    <MainSelect
                        title={'Asistencia-Puesto'}
                        options={asistenciaPuesto}
                        value={Number(dataAsistenciaPuesto?.value)}
                       
                        onChange={(value, event) => setDataAsistenciaPuesto(asistenciaPuesto?.find(element => Number(element.value) === Number(value)))}
                       
                    />
                </Box>
                <Box display={'flex'} justifyContent={'space-evenly'} alignItems={'end'}>
                    <IconButton aria-label="search-areas" color="primary" sx={{ border: 'solid 0.05rem' }} size='small'
                       onClick={(e) => { (dataAsistenciaPuesto?.id_asistenciaPuesto ? getAreaAsistenciaPuestos() : simpleAlerts('')) }}
                      
                    >
                        <SearchIcon fontSize='1px' />
                    </IconButton>
                    &nbsp;
                    <MainButton
                        onClick={(e) => { (dataAsistenciaPuesto?.id_asistenciaPuesto ? handleOpen() : simpleAlerts('¡Debe Seleccionar Asistencia-Puesto!')) }}
                        title={'Asociar Area'}
                        typeStyle={'outlined'}
                        color={'primary'}
                    />
                </Box>
            </Box>

            <Grid container
                direction="row"
                justifyContent="center"
                alignItems="center"
                pt={2}
            >
                <Grid item xs={12} sm={10} paddingLeft={'0.2rem'} paddingRight={'0.2rem'}>
                    <BodyAreaAsistenciaPuesto
                        setTitleSpinner={setTitleSpinner}
                        setOpenModalLoader={setOpenModalLoader}
                        dataAreaAsistenciaPuestos={dataAreaAsistenciaPuestos}
                        setDataAreaAsistenciaPuestos={setDataAreaAsistenciaPuestos}
                    />
                </Grid>

            </Grid>

            <SpinnerLoader open={openModalLoader} title={titleSpinner} />
        </Box>
    );
};

export default AreaAsistenciaPuesto;