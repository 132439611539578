import React, { useState, useEffect } from 'react';
import { Box, Grid, Button, TextField, } from '@mui/material';
import GetAppIcon from '@mui/icons-material/GetApp';
import BodyOfficeFurnitureReport from '../../components/OfficeFurniture/BodyOfficeFurnitureReport';
import SaleService from '../../../services/sale';
import { getDateOL } from '../../../utils/function';
import { CODES } from '../../../utils/responseCodes';
import Loader from '../../components/SpinnerLoad/SpinnerLoader';
import { Global } from '../../../utils/general';

const OfficeFurnitureReport = ({ userToken }) => {
    const info = Global.info;
    const [openLoader, setOpenLoader] = useState(false);
    const [messageLoader, setMessageLoader] = useState(null);
    const [dateStart, setDateStart] = useState(getDateOL("", "short", -7));
    const [dateEnd, setDateEnd] = useState(getDateOL());
    const [sales, setSales] = useState(null);


    
   
    useEffect(() => {
        setSales(null);
        getVentas();
    }, [dateStart, dateEnd])



    const exportSalesExcel = async () => {
        setOpenLoader(true);
        setMessageLoader('Exportando');

        try {
            const response = await fetch(SaleService.exportSalesExcel(dateStart, dateEnd), {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                },
            });

            if (response.ok) {
                const blob = await response.blob();
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'ventas.xlsx'); // Cambia 'ventas.xlsx' por el nombre que desees
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            } else {
                console.error('Error al exportar el archivo');
            }
        } catch (error) {
            console.error('Error en la solicitud de exportación', error);
        } finally {
            setOpenLoader(false);
        }
    };





    const getVentas = async () => {
        setOpenLoader(true);
        setMessageLoader('Cargando ventas ...');
        await SaleService.getVentas(dateStart, dateEnd)
            .then(result => {
                if (result.status === CODES.SUCCESS_200) {
                    setSales(result.data);
                }
                setOpenLoader(false);
            })
            .catch(err => {
                console.log('getVentas', err)
            })
    }

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={3}
                mt={1} mb={2}
            >
                <Grid item xs={6} sm={4} md={3} display={'flex'} justifyContent={'center'}>
                    <div className='group' style={{ width: '100%' }} >
                        <div className="contenido">
                            <label className="label">Fecha Inicio</label>
                            <TextField fullWidth required
                                className="input"
                                size='small'
                                id="outlined-required"
                                type={'date'}
                                value={dateStart}
                                onChange={(e) => setDateStart(e.target.value)}
                            />
                        </div>
                    </div>
                </Grid>
                <Grid item xs={6} sm={4} md={3} display={'flex'} justifyContent={'center'}>
                    <div className='group' style={{ width: '100%' }} >
                        <div className="contenido">
                            <label className="label">Fecha Fin</label>
                            <TextField fullWidth required
                                className="input"
                                size='small'
                                id="outlined-required"
                                type={'date'}
                                value={dateEnd}
                                onChange={(e) => setDateEnd(e.target.value)}
                            />
                        </div>
                    </div>
                </Grid>
            </Grid>
            <Grid container
                direction="row"
                justifyContent="center"
                alignItems="center"
                mt={2}
            >
                <Grid item xs={12} sm={8} md={12} paddingLeft={'2rem'} paddingRight={'2rem'}>
                    <Box display={'flex'} justifyContent={'center'} mb={3}>
                        <Button
                            variant="contained"
                            onClick={exportSalesExcel}
                            color='success'
                            startIcon={<GetAppIcon />}
                        >
                            Exportar
                        </Button>
                    </Box>
                    <BodyOfficeFurnitureReport 
                    
                    sales={sales} />
                </Grid>
            </Grid>
            <Loader
                open={openLoader}
                message={messageLoader}
            />
        </Box>
    );
};

export default OfficeFurnitureReport;