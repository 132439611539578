import React, { } from 'react';
import { ButtonGroup, IconButton, Box } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import MainPagination from '../../General/MainPagination'
import { colors } from '../../../../assets/colors';
import { CODES } from '../../../../utils/responseCodes';
import { simpleAlerts } from '../../../../utils/alerts';
import UserService from '../../../../services/user';
import Swal from 'sweetalert2';
import _ from 'lodash';

const columns = [
  { id: 'id_usuario', label: '#', width: 50, align: 'left', },
  { id: 'nombreCompleto', label: 'Trabajador', width: 220, align: 'left', },
  { id: 'usuario', label: 'Usuario', width: 120, align: 'center', },
  { id: 'numdoc', label: 'Documento', width: 120, align: 'center', },
  // { id: 'sistema1', label: 'Perfil', width: 120, align: 'center', },
  { id: 'desPerfil1', label: 'LA', width: 120, align: 'center', },
  // { id: 'sistema2', label: 'Perfil', width: 120, align: 'center', },
  { id: 'desPerfil2', label: 'ASISPROD', width: 120, align: 'center', },
  { id: 'estado', label: 'Estado', width: 50, align: 'center', },
  { id: 'actions', label: 'Acciones', width: 80, align: 'center', },
];

const validationMessages = {
  user: 'Debe Seleccionar un usuario!'
};

const BodyUsers = (props) => {
  const {
    id_usuarioLogin, setTitleSpinner, setOpenModalLoader,
    handleOpen, getUsuarios, status, dataUsers, setDataUsers,
    totalPagesUser, pageUser, setPageUser, setInfoUser,
    editUser, setTypeRegister,
  } = props;

  const url = "'" + window.location.pathname + "'";

  const disabledUser = (idx, key, value) => {
    let updDataUsers = _.cloneDeep(dataUsers);
    updDataUsers[idx][key] = value;
    updDataUsers[idx]['usuario_id'] = id_usuarioLogin;
    Swal.fire({
      title: (value === 2 ? '¿Seguro de desactivar el usuario?' : '¿Seguro de activar el usuario?'),
      text: (value === 2 ? 'Perdera todos los accesos al sistema!' : 'Se repondran todos sus ultimos accesos!'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: (value === 2 ? colors.secondary.hex : '#28A745'),
      cancelButtonColor: (value === 2 ? colors.primary.hex : colors.secondary.hex),
      confirmButtonText: (value === 2 ? 'Si, desactivar' : 'Si, activar')
    }).then((result) => {
      if (result.isConfirmed) {
        setOpenModalLoader(true);
        setTitleSpinner('Guardando...');
        setTimeout(async () => {
          try {
            const result = await UserService.saveUsuario(url, updDataUsers[idx]);
            if (result.status === CODES.CREATE_201) {
              simpleAlerts(result.data.message, 'success');
              setOpenModalLoader(false);
              setTitleSpinner(null);
              setDataUsers(updDataUsers);
            } else {
              simpleAlerts((result?.response?.data?.message || 'Error al guardar el usuario'), 'error');
            }
            return [];
          } catch (err) {
            console.log(`Error en editUser ${err}`);
          } finally {
            setOpenModalLoader(false);
            setTitleSpinner(null);
          }
        });
      }
    })

  }

  const duplicateUsuario = (info) => {
    if (!info?.id_usuario) {
      simpleAlerts(validationMessages.user);
      return
    }
    handleOpen();
    let newUser = {
      usuario: null,
      nombreCompleto: null,
      numdoc: null,
      documento_id: info.documento_id,
      apellidos: null,
      nombres: null,
      email: null,
      persona_id: null,
      usuario_id: null,
      estado: 1,
      minutos: info.minutos,
      isExternal: info.isExternal,
      id_Perfil1: info.id_Perfil1,
      id_Perfil2: info.id_Perfil2,
      id_usuarioDuplicar: info.id_usuario,
      usuario_id: id_usuarioLogin,
    }
    setInfoUser(newUser);
    setTypeRegister(2);
  }

  const duplicateUser = (info) => {
    if (!info?.id_usuario) {
      simpleAlerts(validationMessages.user);
      return
    }
    let newElement = {
      "usuario": info?.id_usuario,
      "sistema": 'ASISPROD',
      "usuarioMod": id_usuarioLogin
    }

    Swal.fire({
      title: '¿Seguro de duplicar el usuario?',
      text: 'Se copiaran todos los accesos del usuario!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#28A745',
      cancelButtonColor: colors.secondary.hex,
      confirmButtonText: 'Si, duplicar'
    }).then((result) => {
      if (result.isConfirmed) {
        setOpenModalLoader(true);
        setTitleSpinner('Duplicando...');
        setTimeout(async () => {
          try {
            const result = await UserService.duplicateUsuario(url, newElement);
            if (result.status === CODES.SUCCESS_200) {
              simpleAlerts(result.data.message, 'success');
              getUsuarios();
            } else {
              simpleAlerts('Error al guardar el usuario', 'error')
            }
            return [];
          } catch (err) {
            console.log(`Error en editUser ${err}`);
          } finally {
            setOpenModalLoader(false);
            setTitleSpinner(null);
          }
        });
      }
    })
  }

  return (
    <Box className='page-body-main'>
      <Box className='page-body-table table-responsive-md'>
        <table className="table table-hover table-striped table-bordered">
          <thead className='sticky-top' style={{ backgroundColor: colors.quaternary.hex, color: '#ffff' }}>
            <tr>
              {columns.map((column) => (
                <th
                  scope="col"
                  key={column.id}
                  style={{
                    textAlign: column.align,
                    width: column.width,
                    maxWidth: column.width
                  }}
                >
                  {column.label}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="table-group-divider">
            {dataUsers?.map((user, indexRow) => {
              return (
                <tr key={"row" + indexRow}>
                  {columns.map((column, indexColumn) => {
                    const value = user[column.id];
                    return (
                      <td key={'col' + indexColumn} align={column.align}>
                        {column.id === 'estado' ?
                          status?.find(e => Number(e.id) === Number(value))?.label
                          : column.id === 'actions' ?
                            <ButtonGroup
                              disableElevation
                              variant="contained"
                              aria-label="Disabled elevation buttons"
                            >
                              <IconButton aria-label="delete" size="small" title='Editar'
                                onClick={() => { editUser(user); }}
                              >
                                <EditIcon fontSize='small' color={'primary'} />
                              </IconButton>
                              <IconButton aria-label="delete" size="small"
                                title={Number(user?.estado) === 1 ? 'Desactivar' : 'Activar'}
                                onClick={() => disabledUser(indexRow, 'estado', (Number(user?.estado) === 1 ? 2 : 1))}
                              >
                                {Number(user?.estado) === 1 ?
                                  <ToggleOffIcon fontSize='small' color={'secondary'} /> :
                                  <VolunteerActivismIcon fontSize='small' color={'success'} />
                                }
                              </IconButton>
                              <IconButton aria-label="delete" size="small" title='Duplicar'
                                onClick={() => duplicateUsuario(user)}
                              >
                                <CopyAllIcon fontSize='small' color={'warning'} />
                              </IconButton>
                            </ButtonGroup> :
                            value}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </Box>

      {totalPagesUser > 1 &&
        <MainPagination
          size={'small'}
          totalPages={totalPagesUser}
          page={pageUser}
          setPage={setPageUser}
        />
      }
    </Box>
  );
}

export default BodyUsers;