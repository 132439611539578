import axios from "axios";
import { Global } from '../utils/general';
import headerRequest from "../utils/headers"

const API_URL = Global.url;
const SYS = Global.system;

class UserService {
    getUsuarios(url, { search = '', page = 1, pageSize = 20, pagination = '' }) {
        const ruta = `${API_URL}/user/users?sys=${SYS}&url=${url}&search=${search}&page=${page}&pageSize=${pageSize}&pagination=${pagination}`;
        return axios.get(ruta, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }

    saveUsuario(url, body) {
        const ruta = `${API_URL}/user/user?sys=${SYS}&url=${url}`;
        return axios.post(ruta, body, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }

    getUsuariosActivos(url) {
        const ruta = `${API_URL}/user/actives?sys=${SYS}&url=${url}`;
        return axios
            .get(ruta, {
                headers: headerRequest(),
            })
            .catch((error) => {
                return error;
            });
    }

    getUsuarioAreas(url, id) {
        const ruta = `${API_URL}/user/user/areas/${id}?sys=${SYS}&url=${url}`;
        return axios.get(ruta, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }

    getAgrupamientoByUsuario(url, id) {
        const ruta = `${API_URL}/user/user/grouping/${id}?sys=${SYS}&url=${url}`;
        return axios.get(ruta, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }

    saveUsuarioAreasGeneral(url, body) {
        const ruta = `${API_URL}/user/user/areas/massive?sys=${SYS}&url=${url}`;
        return axios.post(ruta, body, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }

    saveUsuarioAreas(url, body) {
        const ruta = `${API_URL}/user/user/areas?sys=${SYS}&url=${url}`;
        return axios
            .post(ruta, body, {
                headers: headerRequest(),
            })
            .catch((error) => {
                return error;
            });
    }

    deleteUsuarioAreas(url, body) {
        const ruta = `${API_URL}/user/user/areas?sys=${SYS}&url=${url}`;
        return axios.delete(ruta, {
            headers: headerRequest(),
            data: body
        }).catch((error) => {
            return error;
        });
    }

    duplicateUsuario(url, body) {
        const ruta = `${API_URL}/user/user-duplicate?sys=${SYS}&url=${url}`;
        return axios
            .post(ruta, body, {
                headers: headerRequest(),
            })
            .catch((error) => {
                return error;
            });
    }

    recoveryPassword(body) {
        const ruta = `${API_URL}/user/recovery-password`;
        return axios.post(ruta, body, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }

    requestCode(body) {
        const ruta = `${API_URL}/user/request-code`;
        return axios.post(ruta, body, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }

    getBossesApproveAssistance(url, id_sede) {
        const ruta = `${API_URL}/user/bosses/approve-assistance?sys=${SYS}&url=${url}&id_sede=${id_sede}`;
        return axios.get(ruta, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }

}

export default new UserService();
