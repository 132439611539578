import React, { useEffect, useState } from 'react';
import { Box, Grid, } from '@mui/material';
import AddAsistenciaPuesto from './AddAsistenciaPuesto/AddAsistenciaPuesto';
import MainButton from '../../../components/General/MainButton';
import AsistenciaPuestoService from '../../../../services/AsistenciaPuesto';
import { CODES } from '../../../../utils/responseCodes';
import { simpleAlerts } from '../../../../utils/alerts';
import SpinnerLoader from '../../../components/SpinnerLoad/SpinnerLoader';
import BodyPuestos from '../../../components/Maintenance/AsistenciaPuesto/BodyAsistenciaPuesto/BodyAsistenciaPuesto';


const newAsistenciaPuesto = {
    'ubicacion': '',
    'puesto': ''
}

const AsistenciaPuesto = ({ userToken }) => {
    const id_usuarioLogin = userToken.user.id_usuario;
    const [openModalLoader, setOpenModalLoader] = useState(true);
    const [titleSpinner, setTitleSpinner] = useState(null);
    const [open, setOpen] = useState(false)
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

  
  
    const [infoAsistenciaPuesto, setInfoAsistenciaPuesto] = useState(null);
    const [asistenciaPuesto, setAsistenciaPuesto] = useState(null);

    useEffect(() => {
        init();
    }, [])

    const init = async () => {
        Promise.all([
            getAsistenciaPuesto(),
        ]).then(() => setOpenModalLoader(false));
    }

 

    const getAsistenciaPuesto = async () => {
        try {
            const result = await AsistenciaPuestoService.getAsistenciaPuesto();
            if (result.status === CODES.SUCCESS_200) {
                result.data?.forEach(element => {
                    element.value = element.id_asistenciaPuesto;
                    element.label = element.puesto;
                    element.label = element.ubicacion;

                })
                setAsistenciaPuesto(result.data);
               
            } else {
                simpleAlerts('Hubo un error Asistencia Puesto', 'error');
            }
            return [];
        } catch (error) {
            console.log(`Error en getUsuarioAreas: ${error}`);
        }
    }


    const createAreaPuesto = () => {
        setInfoAsistenciaPuesto(newAsistenciaPuesto)
        handleOpen();
    };

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AddAsistenciaPuesto
                open={open}
                handleClose={handleClose}
                id_usuarioLogin={id_usuarioLogin}
                setOpenModalLoader={setOpenModalLoader}
                setTitleSpinner={setTitleSpinner}
                infoAsistenciaPuesto={infoAsistenciaPuesto}
                setInfoAsistenciaPuesto={setInfoAsistenciaPuesto}
                getAsistenciaPuesto={getAsistenciaPuesto}
            />
    
            <Grid container
                direction="row"
                justifyContent="center"
                alignItems="center"
            >
                <Grid item
                    xs={10}
                    display={'flex'}
                    justifyContent={'center'}
                    m={1}
                >
                    <Box width={'500px'}>
                        <MainButton
                            onClick={createAreaPuesto}
                            title={'Crear Asistencia Puesto'}
                            color={'primary'}
                            fullWidth={true}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={8} md={8} paddingLeft={'0.2rem'} paddingRight={'0.2rem'}>
                    <BodyPuestos
                        handleOpen={handleOpen}
                        asistenciaPuesto={asistenciaPuesto}
                        setAsistenciaPuesto={setAsistenciaPuesto}
                        setInfoAsistenciaPuesto={setInfoAsistenciaPuesto}
                        id_usuarioLogin={id_usuarioLogin}
                        setOpenModalLoader={setOpenModalLoader}
                        setTitleSpinner={setTitleSpinner}
                        getAsistenciaPuesto={getAsistenciaPuesto}
                    />
                </Grid>

            </Grid>

            <SpinnerLoader open={openModalLoader} title={titleSpinner} />
        </Box>
    );
};

export default AsistenciaPuesto;