import React, { } from 'react';
import DetailOpenClose from '../../../../components/Manage/OpenAndClose/Detail/DetailOpenClose'
import MainModal from '../../../../components/General/MainModal';

const DetailSede = (props) => {
    const { open, handleClose, setTitleSpinner, setOpenModalLoader, infoSede, dateInit, dateEnd, } = props;

    return (
        <>
            <MainModal size={'md'}
                open={open}
                handleClose={handleClose}
                title={'Adminitrador de Trabajador'}

                bodyModal={
                    <DetailOpenClose
                        setTitleSpinner={setTitleSpinner}
                        setOpenModalLoader={setOpenModalLoader}
                        infoSede={infoSede}
                        dateInit={dateInit}
                        dateEnd={dateEnd}
                    />
                }

            />
        </>
    );
};

export default DetailSede;