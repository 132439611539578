import React, { useState } from 'react'
import { useNavigate, } from 'react-router-dom';
import UserService from '../../../services/user';
import { simpleAlerts } from '../../../utils/alerts';
import { CODES } from '../../../utils/responseCodes';
import SpinnerLoader from '../../components/SpinnerLoad/SpinnerLoader';
import { Global } from '../../../utils/general';

const RecoverPassword = () => {
    const navigate = useNavigate();
    const [openModalLoader, setOpenModalLoader] = useState(false);
    const [titleSpinner, setTitleSpinner] = useState(null);
    const path = Global.path;

    localStorage.removeItem("infoAssisProd");

    const handleFormSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        try {
            setOpenModalLoader(true);
            setTitleSpinner('Consultando...!');
            const result = await UserService.recoveryPassword(formData)
            if (result?.status === CODES.CREATE_201) {
                simpleAlerts((result?.data?.message || 'Exito'), 'success', 'colored');
                setTimeout(() => {
                    navigate(path);
                }, 2000);
            } else {
                simpleAlerts((result?.response?.data?.message || 'Revise los datos ingresados'), 'error', 'colored');
            }
            return [];
        } catch (error) {
            console.log(`Error en getEstados: ${error}`);
        } finally {
            setOpenModalLoader(false);
            setTitleSpinner(null);
        }
    };

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '90vh',
                flex: '1',
            }}
        >
            <div
                className="form-box"
                style={{
                    maxWidth: '300px',
                    background: '#f1f7fe',
                    overflow: 'hidden',
                    borderRadius: '16px',
                    color: '#010101',
                    border: '0.05rem solid #2979ff'
                    //margin: '0 auto',   
                }}
            >
                <SpinnerLoader open={openModalLoader} title={titleSpinner} />
                <form
                    className="form"
                    style={{
                        position: 'relative',
                        display: 'flex',
                        flexDirection: 'column',
                        padding: '32px 24px 24px',
                        gap: '16px',
                        textAlign: 'center',
                    }}
                    onSubmit={handleFormSubmit}
                >
                    <span className="title"
                        style={{
                            fontWeight: 'bold',
                            fontSize: '1.6rem',
                        }}
                    >ASISPROD</span>
                    <span className="subtitle"
                        style={{
                            fontSize: '1rem',
                            color: '#666',
                        }}
                    >Restaurar contraseña</span>
                    <div className="form-container"
                        style={{
                            overflow: 'hidden',
                            borderRadius: '8px',
                            backgroundColor: '#fff',
                            margin: '1rem 0 .5rem',
                            width: '100%',
                        }}
                    >
                        <input type="text" className="input" placeholder="Usuario" name="usuario"
                            style={{
                                background: 'none',
                                border: '0',
                                outline: '0',
                                height: '40px',
                                width: '100%',
                                borderBottom: '1px solid #eee',
                                fontSize: '.9rem',
                                padding: '8px 15px',
                            }}
                            required
                        />
                        <input type="text" className="input" placeholder="Código validación" name="codValidacion"
                            style={{
                                background: 'none',
                                border: '0',
                                outline: '0',
                                height: '40px',
                                width: '100%',
                                borderBottom: '1px solid #eee',
                                fontSize: '.9rem',
                                padding: '8px 15px',
                            }}
                            required
                        />
                        <input type="password" className="input" placeholder="Nueva contraseña" name="clave"
                            style={{
                                background: 'none',
                                border: '0',
                                outline: '0',
                                height: '40px',
                                width: '100%',
                                borderBottom: '1px solid #eee',
                                fontSize: '.9rem',
                                padding: '8px 15px',
                            }}
                            required
                        />
                    </div>
                    <button
                        style={{
                            backgroundColor: '#0066ff',
                            color: '#fff',
                            border: '0',
                            borderRadius: '24px',
                            padding: '10px 16px',
                            fontSize: '1rem',
                            fontWeight: '600',
                            cursor: 'pointer',
                            transition: 'background-color .3s ease',
                            ":hover": {
                                backgroundColor: '#005ce6',
                            }
                        }}
                        type="submit"
                    >Sign up</button>
                </form>
                <div className="form-section"
                    style={{
                        padding: '16px',
                        fontSize: '.85rem',
                        backgroundColor: '#DCE6FA', //'#e0ecfb',
                        boxShadow: 'rgb(0 0 0 / 8%) 0 -1px',
                    }}
                >
                    <p>Si necesita ayuda, comuniquese con TI <a href=""
                        style={{
                            fontWeight: 'bold',
                            color: '#0066ff',
                            transition: 'color .3s ease',
                            ":hover": {
                                color: '#005ce6',
                                textDecoration: 'underline',
                            }
                        }}
                    >soporteti@obiettivolavoro.pe</a> </p>
                </div>
            </div >
        </div>
    )
}

export default RecoverPassword