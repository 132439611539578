import React, { } from 'react';

import MainModal from '../../../../components/General/MainModal';
import MainButton from '../../../../components/General/MainButton';

import { minutesDiff } from '../../../../../utils/function';
import { CODES } from '../../../../../utils/responseCodes';
import { simpleAlerts } from '../../../../../utils/alerts';

import InfoShift from '../../../../components/Maintenance/Shifts/AddShift/InfoShift';
import ShiftService from '../../../../../services/shift';
import _ from 'lodash';

const AddShift = (props) => {
    const url = "'" + window.location.pathname + "'";
    const { open, handleClose, setOpenModalLoader, setTitleSpinner, id_usuarioLogin, weekDays, regimes, infoTurno, setInfoTurno, getTurno, } = props;

    const cleanInfo = () => {
        return setInfoTurno(null);
    };

    const saveShift = async (e) => {
        e.preventDefault();
        let updInfoTurno = _.cloneDeep(infoTurno);
        updInfoTurno.usuario_id = id_usuarioLogin;

        updInfoTurno.ingreso = (updInfoTurno?.ingreso?.substring(0, 5) || null);
        updInfoTurno.salida = (updInfoTurno?.salida?.substring(0, 5) || null);

        updInfoTurno.ingresoNoc = (updInfoTurno?.ingresoNoc?.substring(0, 5) || null);
        updInfoTurno.salidaNoc = (updInfoTurno?.salidaNoc?.substring(0, 5) || null);

        //updInfoTurno.Hnormales = (Math.abs(minutesDiff(updInfoTurno.ingreso, updInfoTurno.salida, 'time')) / 60) - (updInfoTurno?.refrigerio || 0);
        //updInfoTurno.Hnormales = updInfoTurno.Hnormales.toFixed(2);
        updInfoTurno.Hnormales = parseFloat(updInfoTurno?.Hnormales)?.toFixed(2);

        if (updInfoTurno.regimen_id === '' || updInfoTurno.regimen_id === 0) {
            simpleAlerts('Falta seleccionar régimen!', 'warning');
            return;
        }

        setOpenModalLoader(true);
        setTitleSpinner('Guardando ...');
        try {
            const result = await ShiftService.saveTurno(url, updInfoTurno);
            if (result.status === CODES.CREATE_201) {
                simpleAlerts(result.data.message, 'success');
                cleanInfo();
                handleClose();
                getTurno();
            } else {
                simpleAlerts(result.response.data.message, 'error');
            }
        } catch (err) {
            console.log('Error en saveProfile', err);
        } finally {
            setOpenModalLoader(false);
            setTitleSpinner(null)
        }
    }

    return (
        <>
            <MainModal
                size={'md'}
                open={open}
                handleClose={() => { cleanInfo(); handleClose() }}
                formSubmit={saveShift}
                //centrado={'centrado'}
                title={'Mantenimiento de turnos'}
                subTitle={'Creación y edición de turnos'}
                bodyModal={
                    <InfoShift
                        weekDays={weekDays}
                        regimes={regimes}
                        infoTurno={infoTurno}
                        setInfoTurno={setInfoTurno}
                    />
                }
                buttonActions={[
                    <MainButton
                        key='btn-modal-user-save1'
                        title={'Guardar'}
                        typeStyle={'outlined'}
                        color={'primary'}
                        type={'submit'}
                    />
                ]}
            />
        </>
    );
};

export default AddShift;