import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import BodyDetailStaffs from './BodyDetailStaffs';
import StaffService from '../../../../services/staff';
import MainInputInfo from '../../General/MainInputInfo';
import { CODES } from '../../../../utils/responseCodes';
import { simpleAlerts } from '../../../../utils/alerts';

const DetailStaff = (props) => {
    const { setTitleSpinner, setOpenModalLoader, states, infoStaff, } = props;
    const [dataStaffs, setDataStaffs] = useState(null);

    useEffect(() => {
        getStaffWorker();
    }, [])

    useEffect(() => {
        if (dataStaffs?.id_persona)
            getStaffWorker();
    }, [dataStaffs])

    const getStaffWorker = async () => {
        try {
            setTitleSpinner('Cargando detalle...');
            setOpenModalLoader(true);
            const result = await StaffService.getStaffWorker(infoStaff?.id_persona);
            if (result.status === CODES.SUCCESS_200)
                setDataStaffs(result.data);
            else
                simpleAlerts(result.response.data.message, 'error');
            return []
        } catch (error) {
            console.log(`Error en getStaff: ${error}`);
        } finally {
            setOpenModalLoader(false);
            setTitleSpinner(null);
        }
    }

    return (
        <Grid container alignItems={'center'} spacing={1}>
            <Grid item md={6} xs={6} >
                <MainInputInfo
                    title={'Código:'}
                    value={infoStaff?.codSghh}
                />
            </Grid>
            <Grid item md={6} xs={6}>
                <MainInputInfo
                    title={'Nombres:'}
                    value={infoStaff?.nombres}
                />
            </Grid>
            <Grid item md={6} xs={6}>
                <MainInputInfo
                    title={'Apellido Paterno:'}
                    value={infoStaff?.apePaterno}
                />
            </Grid>
            <Grid item md={6} xs={6}>
                <MainInputInfo
                    title={'Apellido Materno:'}
                    value={infoStaff?.apeMaterno}
                />
            </Grid>
            <Grid item md={6} xs={6}>
                <MainInputInfo
                    title={'Situación:'}
                    value={states?.find(element => Number(element.value) === Number(infoStaff?.codSituacion))?.label}
                />
            </Grid>
            <Grid item md={6} xs={6}>
                <MainInputInfo
                    title={'Última fecha de modificación:'}
                    value={infoStaff?.fechaMod}
                />
            </Grid>
            <Grid item xs={12} sm={12} md={12} paddingTop={'2rem'} >
                <BodyDetailStaffs
                    states={states}
                    dataStaffs={dataStaffs}
                    setDataStaffs={setDataStaffs}
                />
            </Grid>
        </Grid>
    );
};

export default DetailStaff;