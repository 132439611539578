import React from 'react';
import './modalSmall.scss';

const MainCustomModal = ({ isOpen, onClose, sizeModal = '', title, buttons, children }) => {

    const handleCloseClick = (event) => {
        if (event.target === event.currentTarget) {
            onClose();
        }
    };

    if (!isOpen) return null;

    return (
        <div className="custom-modal-container" onClick={handleCloseClick}>
            <div className={`custom-modal ${sizeModal}`}>
                <div className="modal-header">
                    <div className="modal-title">{title}</div>
                    <button className="close-button" onClick={onClose}>×</button>
                </div>
                <div className="modal-content">
                    <div className="modal-body">{children}</div>
                </div>
                {buttons &&
                    <div className="modal-actions">
                        <div className="modal-buttons">
                            {buttons?.map((button, index) => (
                                <button type='button' key={index} className={`modal-button`}
                                    style={{
                                        backgroundColor:
                                            (button.color === 'secondary' ? '#198754' :
                                                button.color === 'success' ? '#198754' :
                                                    button.color === 'danger' ? '#B42B39' :
                                                        button.color === 'warning' ? '#FFC107' :
                                                            button.color === 'dark' ? '#212529' : '#0B5BD0')
                                    }}
                                    onClick={button.onClick}
                                >
                                    {button.label}
                                </button>
                            ))}
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};
export default MainCustomModal;